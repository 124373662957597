import { Analytics, Anchor, Shield, Umbrella, Users } from '@/icons'

export const insuranceCardData = {
  PP: {
    isComplexTopic: true,
    image: 'private-pension-picture',
    featureIcons: [Umbrella, Analytics, Shield],
    group: ['important', 'managingDirector'],
    showInDashboard: true,
  },
  HI: {
    isComplexTopic: false,
    image: 'private-health-picture',
    featureIcons: [Umbrella, Analytics, Shield],
    group: ['managingDirector'],
    showInDashboard: false,
  },
  PE: {
    isComplexTopic: false,
    image: 'approxLaborCosts',
    featureIcons: [Users, Anchor, Shield],
    group: ['employeesPension'],
    showInDashboard: false,
    backgroundImageY: '20%',
  },
  HE: {
    isComplexTopic: false,
    image: 'company-health-picture',
    featureIcons: [Umbrella, Analytics, Shield],
    group: ['employeesPension'],
    showInDashboard: false,
  },
}
