import { ImageFromCdn, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useTranslation } from 'react-i18next'
import {
  CompaniesTrustContainer,
  CompaniesTrustImagesContainer,
} from './styles'

export const CompaniesTrust = () => {
  const { t } = useTranslation()
  return (
    <CompaniesTrustContainer>
      <Typography color={ThemeColor.b50} variant="p2Body">
        {t('companiesTrustUs')}
      </Typography>
      <CompaniesTrustImagesContainer>
        <ImageFromCdn name="metro-color" type="webp" />
        <ImageFromCdn name="business-insider" type="webp" />
        <ImageFromCdn name="makita-color" type="webp" />
        <ImageFromCdn name="urbansports" type="webp" width={140} />
      </CompaniesTrustImagesContainer>
    </CompaniesTrustContainer>
  )
}
