import { api, newApi } from './api'
import z from 'zod'
import { validateAxiosResponse } from '@/utils/requestValidation'

// Define the FileCategory as an enum in Zod
const FileCategorySchema = z.enum([
  'contract',
  'offer',
  'application',
  'invoice',
  'incident',
  'claim',
  'cover_note',
  'contribution_arrears',
])

// Define the GetInsuranceApiResponse schema
const GetInsuranceApiResponseSchema = z.object({
  insurance_label: z.string().nullable(),
  payment_period: z
    .enum([
      'monthly',
      'quarterly',
      'half-yearly',
      'yearly',
      'once',
      'non-contributory',
    ])
    .nullable(),
  gross_price: z.number().nullable(),
  overallRating: z.number().nullable(),
  name: z.string().nullable(),
  policy_number: z.string().nullable(),
  amount_insured_unlimited: z.boolean(),
  amount_insured: z.number().nullable(),
  parameters_values: z
    .object({
      en: z.record(z.string()),
      de: z.record(z.string()),
    })
    .nullable(),
  yes_no_values: z.record(z.union([z.literal(0), z.literal(1)])).nullable(),
  carrier: z.object({
    name: z.string(),
    logo_url: z.string().nullable(),
    customer_satisfaction_rating: z.number().nullable(),
    response_time_rating: z.number().nullable(),
    claim_handling_rating: z.number().nullable(),
  }),
  location: z.object({
    name: z.string(),
  }),
  files: z.array(
    z.object({
      name: z.string(),
      display_name: z.string(),
      created_at: z.string(),
      /**
       * We shouldn't need this in the response.
       * FIXME: BE should return files that are visible to the user
       */
      visible_to_user: z.boolean(),
      category: FileCategorySchema.nullable(),
    }),
  ),
})

export type PostInsuranceApiResponse = {
  created: string[]
}

export type PostInsuranceApiPayload = {
  categoryId: string
  carrierId: string
  policyNumber?: string | null
  contractStatus?: string | null
}[]

export const getInsuranceById = async (id: string) =>
  validateAxiosResponse(
    GetInsuranceApiResponseSchema,
    newApi.get(`/insurance/${id}`),
  )

export const createMultipleInsurances = (data: PostInsuranceApiPayload) => {
  const created = api.post<PostInsuranceApiResponse>('/insurance', data)
  // we need to cast for now because api client transforms return type in interceptor
  return (created as unknown) as Promise<PostInsuranceApiResponse>
}

const UpdateInsuranceSchema = z.object({
  policyNumber: z.string().optional(),
  contractStatus: z.enum(['transfer-initiated']).optional(),
})

type UpdateInsuranceSchema = z.infer<typeof UpdateInsuranceSchema>

export const updateInsurance = async (
  id: string,
  data: UpdateInsuranceSchema,
) => api.put(`/user/insurance/${id}`, data)
