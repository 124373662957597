import { config } from '@/config'
import segmentPlugin from '@analytics/segment'
import Analytics from 'analytics'

export const useSegment = () =>
  Analytics({
    plugins: [
      segmentPlugin({
        writeKey: config.segmentId,
      }),
    ],
  })
