import {
  dynamicObjectPropType,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/utils'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  currentStepLocalStorageKey,
  formValuesLocalStorageKey,
  makeLocalStorageKey,
} from '../helpers/makeLocalStorageKey'

const StepperContext = createContext()

export const useStepper = () => useContext(StepperContext)

export const StepperProvider = ({
  children,
  defaultValues,
  locationId,
  steps,
  onFinish,
}) => {
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = localStorage.getItem(
      makeLocalStorageKey(currentStepLocalStorageKey, locationId),
    )
    return savedStep ? JSON.parse(savedStep).currentStep : 0
  })

  const isLast = Object.keys(steps).length - 1 === currentStep

  const [formValues, setFormValues] = useState(() => {
    const values = getLocalStorageItem(
      makeLocalStorageKey(formValuesLocalStorageKey, locationId),
    )

    if (!isEmpty(values) || !isNil(values)) {
      return { ...defaultValues, ...values }
    }
    return defaultValues
  })

  useEffect(() => {
    setLocalStorageItem(`yearlyAssessmentCurrentStep-${locationId}`, {
      currentStep,
    })
  }, [currentStep])

  useEffect(() => {
    setLocalStorageItem(`yearlyAssessmentFormValues-${locationId}`, formValues)
  }, [formValues])

  const onNext = (key, values) => {
    if (!isLast) {
      setFormValues((prev) => ({ ...prev, [key]: values }))
      setCurrentStep((prev) => prev + 1)
    } else {
      onFinish?.()
    }
  }

  const onPrevious = () =>
    currentStep > 0 ? setCurrentStep((prev) => prev - 1) : null

  return (
    <StepperContext.Provider
      value={{ currentStep, onNext, onPrevious, formValues, isLast }}
    >
      {children}
    </StepperContext.Provider>
  )
}

StepperProvider.propTypes = {
  children: PropTypes.node,
  defaultValues: dynamicObjectPropType,
  locationId: PropTypes.string,
  onFinish: PropTypes.func,
  steps: dynamicObjectPropType,
}
