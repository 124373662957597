import { BusinessOrgs } from '@/enums'
import { fromEventTarget, splitAtLastIndex } from '@/utils'
import PropTypes from 'prop-types'
import { join, values as rValues, trim } from 'ramda'
import { Option, Select } from '../../atoms'
import { GroupInputContainer, Input } from '../../styled'
import { BasicFormItem } from '../FormItem'

const businessOrgs = rValues(BusinessOrgs)

const makeInputValue = (companyName = '') => {
  const [init, last] = splitAtLastIndex(' ', companyName)

  if (businessOrgs.includes(last)) return [init, last]

  if (last) return [join(' ', [init, last])]

  return [init]
}

export const CompanyName = ({ value, onChange, disabled, error, ...props }) => {
  const [inputValue, businessOrg = BusinessOrgs.gmbh] = makeInputValue(value)

  const handleOrgChange = (org) => {
    onChange(join(' ', [trim(inputValue), org]))
  }

  const handleInputChange = (val) => {
    if (makeInputValue(val)[1] || !businessOrg) onChange(val)
    else onChange(join(' ', [val, businessOrg]))
  }

  const handleBlur = () => {
    onChange(join(' ', [trim(inputValue), businessOrg]))
  }

  return (
    <GroupInputContainer reversed>
      <Input
        disabled={disabled}
        error={error}
        onBlur={handleBlur}
        onChange={fromEventTarget(handleInputChange)}
        value={inputValue}
        {...props}
      />
      <Select
        autoComplete="off"
        disabled={disabled}
        error={error}
        onSelect={handleOrgChange}
        value={businessOrg}
      >
        {businessOrgs.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    </GroupInputContainer>
  )
}

CompanyName.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export const FormCompanyName = (props) => (
  <BasicFormItem component={CompanyName} {...props} />
)
