import { setUnauthorizedInterceptor } from '@/api/api'
import { useAuth } from '@/providers'
import { useEffect } from 'react'

export const UnauthorizedGuard = () => {
  const { logout } = useAuth()

  useEffect(() => {
    setUnauthorizedInterceptor(logout)
  }, [logout])

  return null
}
