import { downloadFile, exportCsv, onMessageSend, postFiles } from '@/api'
import { DocumentType } from '@/types/file'
import {
  returns,
  useDownloadCsv,
  useDownloadFile,
  useInvalidateOnSuccessMutation,
} from '@/utils'
import { prepend, when } from 'ramda'
import { useMutation } from 'react-query'

const makeQueryKey = (array: string[], el: string) =>
  when(returns(el), prepend(el), array)

export const useUploadFiles = (
  id: string,
  entity: string,
  type: DocumentType,
  extraKey: string,
) =>
  useInvalidateOnSuccessMutation(
    makeQueryKey([entity, id], extraKey),
    ({
      files,
      id: entityId,
      category,
    }: {
      files: File[]
      id?: string
      category?: string
    }) => postFiles(entityId || id, files, type, category),
  )

export const useSendMessage = () =>
  useMutation((message: string) => onMessageSend(message))

export const usePreviewFile = () => useDownloadFile(downloadFile)

export const useExportCsv = () => useDownloadCsv(exportCsv)
