import { makeStore, noop } from '@/utils'
import { assoc, compose, evolve, mergeLeft } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'

const setNextDisabled = (value) => ({
  type: 'NEXT_DISABLED',
  payload: value,
})

const setNextLoading = (value) => ({
  type: 'NEXT_LOADING',
  payload: value,
})

const setOnNextClick = (callback) => ({
  type: 'SET_ON_NEXT_CLICK',
  payload: callback,
})

const setOnBackClick = (callback) => ({
  type: 'SET_ON_BACK_CLICK',
  payload: callback,
})

const setSkipData = (value) => ({
  type: 'SET_SKIP_DATA',
  payload: value,
})

const setButtonText = (value) => ({
  type: 'SET_BUTTON_TEXT',
  payload: value,
})

const reducer = (state, action) => {
  switch (action.type) {
    case setNextDisabled().type:
      return assoc('nextDisabled', action.payload, state)
    case setNextLoading().type:
      return assoc('nextLoading', action.payload, state)
    case setOnNextClick().type:
      return assoc('onNextClick', action.payload, state)
    case setOnBackClick().type:
      return assoc('onBackClick', action.payload, state)
    case setButtonText().type:
      return assoc('buttonText', action.payload, state)
    case setSkipData().type:
      return evolve({ skipData: mergeLeft(action.payload) }, state)
    default:
      return state
  }
}

const initialState = {
  skipData: {
    text: '',
    skipButton: '',
    withModal: true,
    onSkipClick: noop,
  },
  buttonText: '',
  nextDisabled: false,
  nextLoading: false,
  onNextClick: noop,
}
export const [StepperProvider, useDispatchStepper, useStepperStore] = makeStore(
  initialState,
  reducer,
)

export const useOnNextClick = (onNextClick) => {
  const callback = useCallback(async () => {
    const pass = await onNextClick()
    return pass
  }, [onNextClick])

  const dispatch = useDispatchStepper()
  const dispatchSetOnNextClick = compose(dispatch, setOnNextClick)

  useEffect(() => {
    dispatchSetOnNextClick(callback)
    return () => {
      dispatchSetOnNextClick(initialState.onNextClick)
    }
  }, [callback])
}

export const useOnBackClick = (onBackClick) => {
  const dispatch = useDispatchStepper()
  const dispatchSetOnBackClick = compose(dispatch, setOnBackClick)

  useEffect(() => {
    dispatchSetOnBackClick(onBackClick)
    return () => {
      dispatchSetOnBackClick(initialState.onBackClick)
    }
  }, [onBackClick])
}

export const useNextDisabled = (factory, deps = []) => {
  const nextDisabled = useMemo(() => factory(), deps)
  const dispatch = useDispatchStepper()
  const dispatchSetNextDisabled = compose(dispatch, setNextDisabled)

  useEffect(() => {
    dispatchSetNextDisabled(nextDisabled)
    return () => {
      dispatchSetNextDisabled(initialState.nextDisabled)
    }
  }, [nextDisabled])
}

export const useNextLoading = (factory, deps = []) => {
  const nextLoading = useMemo(() => factory(), deps)
  const dispatch = useDispatchStepper()
  const dispatchSetNextLoading = compose(dispatch, setNextLoading)

  useEffect(() => {
    dispatchSetNextLoading(nextLoading)
    return () => {
      dispatchSetNextLoading(initialState.nextLoading)
    }
  }, [nextLoading])
}

export const useSetButtonText = (factory, deps = []) => {
  const buttonText = useMemo(() => factory(), deps)
  const dispatch = useDispatchStepper()
  const dispatchSetButtonText = compose(dispatch, setButtonText)

  useEffect(() => {
    dispatchSetButtonText(buttonText)
    return () => {
      dispatchSetButtonText(initialState.buttonText)
    }
  }, [buttonText])
}

export const useSkipData = (factory, deps = []) => {
  const skipData = useMemo(() => factory(), deps)
  const dispatch = useDispatchStepper()
  const dispatchSetSkipData = compose(dispatch, setSkipData)

  useEffect(() => {
    dispatchSetSkipData(skipData)
    return () => {
      dispatchSetSkipData(initialState.skipData)
    }
  }, [skipData])
}
