import { Column, Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

export const PageActions = styled(Row)`
  gap: ${toThemePixels(5)};
  margin-top: ${toThemePixels(2.5)};

  svg {
    width: ${toThemePixels(4)};
    height: ${toThemePixels(4)};
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    align-items: center;
    margin-top: ${toThemePixels(0)};

    button {
      width: 100%;
    }
  }
`

export const InsuranceHeaderDetails = styled(Column)`
  gap: ${toThemePixels(2)};
`

export const CarrierLogoWrapper = styled(Row)`
  gap: ${toThemePixels(3)};
  padding: ${toThemePixels(8)} 0 ${toThemePixels(4)} 0;
`

export const DefaultInsuranceDetails = styled(Column)`
  gap: ${toThemePixels(8)};

  li::marker {
    color: ${themeColor(ThemeColor.b50)};
  }
`

export const HeaderDetails = styled(Column)`
  gap: ${toThemePixels(2)};

  h5 {
    margin: 0;
  }
`

export const DetailsWrapper = styled(Column)``

export const InsuranceDetails = styled(Column)`
  gap: ${toThemePixels(6)};
  padding: ${toThemePixels(0)} 0 ${toThemePixels(10)};
`

export const InsuranceAttribute = styled.div<{ column: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: ${ifProp('column', 'column')};
    gap: ${ifProp('column', toThemePixels(1))};
    align-items: ${ifNotProp('column', 'center', 'baseline')};

    span {
      max-width: calc(100vw - 48px);
    }
  }
`

export const DocumentsContainer = styled(Column)`
  gap: ${toThemePixels(6)};
  margin-bottom: ${toThemePixels(8)};
`

export const UploadedDocumentsContainer = styled(Column)`
  ${themeBreakpointDown(Breakpoint.sm)} {
    display: flex;
    flex-direction: column;
  }
`

export const InsuranceDetail = styled(Row)`
  justify-content: space-between;
  gap: ${toThemePixels(15)};
  padding: 0 ${toThemePixels(4)};

  img {
    width: ${toThemePixels(5)};
    height: ${toThemePixels(5)};
  }

  & span:nth-child(2) {
    text-align: end;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    gap: ${toThemePixels(2)};
    & span:nth-child(2) {
      text-align: start;
    }
  }
`

export const UspsContainer = styled(Column)`
  gap: ${toThemePixels(4)};
  align-items: start;

  svg {
    flex-shrink: 0;
  }

  span:first-child {
    margin-bottom: ${toThemePixels(4)};
  }
`

export const InsuranceDefaultViewWrapper = styled.div`
  ${PageActions} {
    flex-wrap: wrap;
    ${themeBreakpointDown(Breakpoint.sm)} {
      margin-bottom: ${toThemePixels(5)};
    }
  }
`
export const CarrierDropdownContainer = styled.div`
  min-height: 300px;
`
export const InsuranceViewContainer = styled(Column)`
  margin-bottom: ${toThemePixels(12)};
`

export const CollapseContainer = styled.div`
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  padding: ${toThemePixels(4)} 0;
`

export const CollapseContent = styled(Column)`
  margin: ${toThemePixels(4)} 0;
  border-top: 1px solid ${themeColor(ThemeColor.b30)};
  padding-top: ${toThemePixels(4)};
  gap: ${toThemePixels(4)};
`

export const CurrentlyPaying = styled(Column)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${toThemePixels(4)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  margin: ${toThemePixels(3)} 0;
  gap: ${toThemePixels(2)};
`

export const RatingsCard = styled(Column)`
  position: relative;
  width: 100%;
  background-color: ${themeColor(ThemeColor.b10)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  gap: ${toThemePixels(6)};
`

export const RatingsTopSection = styled(Row)`
  gap: ${toThemePixels(2)};
  padding: ${toThemePixels(2)};
  padding-left: 0;
  margin-bottom: ${toThemePixels(3)};
  align-items: flex-start;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  width: 100%;
`

export const RatingRow = styled(Row)`
  gap: 8px;
  > div {
    margin-top: 2px;
  }
`

export const CardFeaturesContainer = styled(Column)`
  gap: ${toThemePixels(4)};
  align-items: start;

  svg {
    flex-shrink: 0;
  }
`

export const GraphicContainer = styled(Column)`
  width: 100%;
  margin-bottom: ${toThemePixels(3)};
  position: relative;

  img {
    border-radius: ${toThemePixels(3)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
  }
`

export const TopCardTitle = styled.div`
  margin-bottom: ${toThemePixels(2)};
`

export const PopoverIconContainer = styled.div`
  position: absolute;
  right: ${toThemePixels(4)};
  top: ${toThemePixels(4)};
`
