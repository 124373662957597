import 'react-phone-number-input/style.css'
import { toInt } from '@/utils'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import PropTypes from 'prop-types'
import { concat, pipe, when } from 'ramda'
import { useState } from 'react'
import PhoneInputComponent, {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input/input'
import { GroupInputContainer, Input } from '../../styled'
import { Option, Select } from '../Select'

const makePhoneValue = when(Boolean, concat('+'))

const makeDefaultValue = (value, defaultValue) => {
  const safeValue = (value || '').slice(0, 3).padEnd(5, '0')
  if (parsePhoneNumber(safeValue)?.country)
    return parsePhoneNumber(safeValue).country

  return defaultValue
}

export const PhoneInput = ({
  defaultCountry = 'DE',
  international = true,
  value = '',
  onChange,
  onBlur,
  disabled,
  ...rest
}) => {
  const [country, setCountry] = useState(
    makeDefaultValue(makePhoneValue(value), defaultCountry),
  )

  return (
    <GroupInputContainer>
      <Select
        autoComplete="off"
        disabled={disabled}
        onSelect={setCountry}
        readOnly
        value={country}
      >
        {getCountries().map((item) => (
          <Option key={item} value={item}>
            {`${getUnicodeFlagIcon(item)} +${getCountryCallingCode(item)}`}
          </Option>
        ))}
      </Select>
      <PhoneInputComponent
        {...rest}
        country={country}
        disabled={disabled}
        inputComponent={Input}
        international={international}
        onBlur={onBlur}
        onChange={pipe(when(Boolean, toInt), String, onChange)}
        placeholder="0123 456 7890"
        value={makePhoneValue(value)}
      />
    </GroupInputContainer>
  )
}

PhoneInput.propTypes = {
  defaultCountry: PropTypes.string,
  disabled: PropTypes.bool,
  international: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.number,
}
