import {
  FormCheckBox,
  FormInput,
  FormPhone,
  Option,
  SecondaryButton,
  PrimaryButton,
  FormSelect,
  PageMainContent,
  Typography,
  BackComponent,
  PageWidgetContainer,
  ContactExpert,
} from '@/components'
import { FormProvider, useFormContext, useSnackbar } from '@/providers'
import { useCompanyPoaStatus, useCreateCompanyUser } from '@/services'
import {
  useTranslationSectionObject,
  useGoBack,
  useTranslationSection,
} from '@/utils'
import { map, pick, pipe, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { PageActions, PageTitle } from './styles'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const { t } = useTranslation()
  const companyTranslation = useTranslationSection('companyPage')
  const userTranslations = useTranslationSectionObject(
    'companyPage.addCompanyUsers',
  )
  const commonTranslation = useTranslationSection('common')

  return {
    general: { ...useTranslationSectionObject('common') },
    locationTypes: {
      ...useTranslationSectionObject(`locationPage.locationTypes`),
    },
    selectCompany: companyTranslation('selectCompany'),
    addUser: companyTranslation('addCompanyUsers.addUser'),
    back: commonTranslation('back'),
    snackBars: { ...useTranslationSectionObject('snackbars') },
    form: { ...useTranslationSectionObject('profile.forms') },
    newCompany: { ...useTranslationSectionObject('profile.newCompany') },
    users: { ...useTranslationSectionObject('profile.users') },
    validations: useTranslationSectionObject('validations'),
    pageTitle: userTranslations.addUser,
    advisorText: t('advisor_text_base'),
  }
}

const RenderIsMDField = () => {
  const { companyId } = useParams()
  const { values } = useFormContext()
  const messages = useMessages()

  const id = companyId || values.companyId
  const { data: POAstatus, isLoading } = useCompanyPoaStatus(id, {
    enabled: Boolean(id),
  })

  if (!id) return null

  return (
    !isLoading &&
    !POAstatus?.poaSigned && (
      <FormCheckBox
        disabled={POAstatus?.poaSigned}
        initialValue={false}
        label={messages.users.isMD.label}
        name="isMd"
      />
    )
  )
}

const makeCompanies = pipe(prop('companies'), map(pick(['companyId', 'name'])))

const PageForm = () => {
  const { user } = useOutletContext()
  const { companyId } = useParams()
  const snackbar = useSnackbar()

  const goBack = useGoBack()

  const messages = useMessages()

  const companies = makeCompanies(user)

  const createNewUserMutation = useCreateCompanyUser(companyId)

  const handleSubmit = (formValues) =>
    createNewUserMutation
      .mutateAsync({ ...formValues })
      .then(() => {
        snackbar.success(messages.snackBars.success.addUserMsg)
        goBack()
      })
      .catch(() => {
        snackbar.error(messages.snackBars.error.general)
      })

  return (
    <FormProvider
      initialValues={{ companyId }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(messages.validations)}
    >
      {!companyId && (
        <FormSelect label={messages.selectCompany} name="companyId">
          {companies.map((el) => (
            <Option key={el.companyId} value={el.companyId}>
              {el.name}
            </Option>
          ))}
        </FormSelect>
      )}
      <FormInput label={messages.users?.name.label} name="name" />
      <FormInput label={messages.users.surname?.label} name="surname" />
      <FormInput label={messages.users.email?.label} name="email" />
      <FormPhone label={messages.users?.phone?.label} name="phone" />
      <RenderIsMDField />

      <PageActions>
        <SecondaryButton onClick={goBack}>
          {messages.general.cancel}
        </SecondaryButton>
        <PrimaryButton type="submit">{messages.general.save}</PrimaryButton>
      </PageActions>
    </FormProvider>
  )
}

export const AddCompanyUserPage = () => {
  const messages = useMessages()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.pageTitle}</Typography>
        </PageTitle>
        <PageForm />
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
