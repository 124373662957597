import { FormDatepicker, Loader } from '@/components'
import { FormProvider, useFormContext } from '@/providers'
import {
  usePublicCheckoutOverview,
  useUpdatePublicCheckoutOverview,
} from '@/services'
import { daysInFuture } from '@/utils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { makeStartDateValidationSchema } from './validationSchema'

const StepForm = ({ setButtonState }) => {
  const { categoryId } = useParams()
  const { submitForm, customValidate } = useFormContext()

  useEffect(() => {
    setButtonState({
      onClick: () =>
        customValidate({
          onSuccess: submitForm,
        }),
    })
  }, [])

  return (
    <FormDatepicker
      max={categoryId === 'LE' ? daysInFuture(90) : daysInFuture(14)}
      min={daysInFuture(1)}
      name="startDate"
      validateOnChange
    />
  )
}

StepForm.propTypes = {
  setButtonState: PropTypes.func,
}

export const PublicCheckoutStartDateStep = ({ onNext, setButtonState }) => {
  const { t } = useTranslation()
  const { categoryId } = useParams()
  const { data, isLoading } = usePublicCheckoutOverview()

  const updateCheckoutOverviewMutation = useUpdatePublicCheckoutOverview(
    data?.recommendationProductId,
  )

  useEffect(() => {
    setButtonState({
      loading: updateCheckoutOverviewMutation.isLoading,
    })
  }, [updateCheckoutOverviewMutation.isLoading])

  const handleSubmit = (formValues) => {
    updateCheckoutOverviewMutation.mutate(formValues, { onSuccess: onNext })
  }

  if (isLoading) return <Loader />

  return (
    <FormProvider
      initialValues={{ startDate: data?.startDate }}
      onSubmit={handleSubmit}
      validationSchema={makeStartDateValidationSchema(
        t('validations', { returnObjects: true }),
        categoryId,
      )}
    >
      <StepForm setButtonState={setButtonState} />
    </FormProvider>
  )
}

PublicCheckoutStartDateStep.propTypes = {
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
