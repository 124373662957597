import { Categories, InsuranceLabels, ThemeColor } from '@/enums'
import { InsuranceCardTextSection } from '@/pages/LocationPage/styles'
import { paths } from '@/routes/paths'
import {
  formatMoney,
  propTypeFromEnumKeys,
  useGoToPath,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CarrierLogo, Typography } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Column, Row } from '../../styled'
import { Container, DetailsContainer, Tag } from './styles'

const useMessages = (category) => {
  const { t } = useTranslation()
  const categoriesTranslations = useTranslationSection('categories')
  const recommendationsTranslations = useTranslationSectionObject(
    'recommendations',
  )
  const insuranceStagesTranslations = useTranslationSection('insuranceStages')

  return {
    subtitle: categoriesTranslations(`${category}.name`),
    description: categoriesTranslations(`${category}.shortDesc`),
    policyNumber: t('policyNo'),
    price: recommendationsTranslations.details.grossPrice,
    paymentPeriodOptions: t('paymentPeriodOptions', { returnObjects: true }),
    progress: t('in_progress'),
    label: insuranceStagesTranslations('important'),
  }
}

export const InsuranceCardBase = ({
  categoryId,
  carrierName,
  logoUrl,
  policyNumber,
  grossPrice,
  paymentPeriod,
  show = {},
  onClick,
}) => {
  const categoryData = Categories[categoryId]
  const messages = useMessages(categoryData.key)

  return (
    <Container onClick={onClick}>
      <Column gap="16px">
        <ShowWhen when={show.important}>
          <Tag>
            <Typography variant="captionR">{messages.label}</Typography>
          </Tag>
        </ShowWhen>
        <InsuranceCardTextSection>
          <Typography variant="captionB">{messages.subtitle}</Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {messages.subtitle}
            <ShowWhen when={show.submitted}>
              <Typography color={ThemeColor.warning1} variant="captionB">
                {messages.progress}
              </Typography>
            </ShowWhen>
          </Typography>
        </InsuranceCardTextSection>
      </Column>
      <ShowWhen when={show.details || logoUrl}>
        <DetailsContainer>
          <Row alignItems="center" gap="4px">
            {logoUrl && <CarrierLogo name={logoUrl} />}
            <Typography color={ThemeColor.b50} variant="badgeText">
              {carrierName}
            </Typography>
          </Row>
          <ShowWhen when={show.details}>
            <Column>
              <Row>
                <Typography variant="badgeText">
                  {formatMoney(grossPrice)}
                </Typography>
                {paymentPeriod && (
                  <Typography variant="badgeText">
                    {` / ${messages.paymentPeriodOptions[
                      paymentPeriod
                    ].toLowerCase()}`}
                  </Typography>
                )}
              </Row>
              <Typography color={ThemeColor.b50} variant="badgeText">
                {messages.price}
              </Typography>
            </Column>
            <Column>
              <Typography variant="badgeText">{policyNumber || '-'}</Typography>
              <Typography color={ThemeColor.b50} variant="badgeText">
                {messages.policyNumber}
              </Typography>
            </Column>
          </ShowWhen>
        </DetailsContainer>
      </ShowWhen>
    </Container>
  )
}

InsuranceCardBase.propTypes = {
  carrierName: PropTypes.string,
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  grossPrice: PropTypes.number,
  logoUrl: PropTypes.string,
  onClick: PropTypes.func,
  paymentPeriod: PropTypes.string,
  policyNumber: PropTypes.string,
  show: PropTypes.shape({
    details: PropTypes.bool,
    important: PropTypes.bool,
    submitted: PropTypes.bool,
  }),
}

export const InsuranceCard = ({
  categoryId,
  insuranceId,
  carrierName,
  logoUrl,
  policyNumber,
  name,
  grossPrice,
  paymentPeriod,
  insuranceLabel,
  show = {},
}) => {
  const { locationId } = useParams()

  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({ categoryId, insuranceId, locationId }),
  )

  return (
    <InsuranceCardBase
      carrierName={carrierName}
      categoryId={categoryId}
      grossPrice={grossPrice}
      logoUrl={logoUrl}
      name={name}
      onClick={goToInsurance}
      paymentPeriod={paymentPeriod}
      policyNumber={policyNumber}
      show={{
        ...show,
        important: !insuranceId,
        submitted: insuranceLabel === InsuranceLabels.submitted,
      }}
    />
  )
}

InsuranceCard.propTypes = {
  carrierName: PropTypes.string,
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  grossPrice: PropTypes.number,
  insuranceId: PropTypes.string,
  insuranceLabel: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  paymentPeriod: PropTypes.string,
  policyNumber: PropTypes.string,
  show: PropTypes.shape({
    details: PropTypes.bool,
  }),
}
