import { Breakpoint } from '@/enums'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp, ifNotProp } from 'styled-tools'
import { Image } from '../../atoms'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  gap: ${toThemePixels(6)};
  padding: 0 ${toThemePixels(6)};

  button {
    width: fit-content;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-top: ${ifNotProp('noMobilePadding', toThemePixels(15))};
  }
`

export const Header = styled(Row)`
  justify-content: space-between;
  flex-direction: ${ifProp('reverted', 'row-reverse')};
`

export const StyledImage = styled(Image)`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
`
