import { renderableType, safeRenderPropComponent } from '@/utils'
import { AuthGuard } from './AuthGuard'

export const ProtectedRoute = ({ element }) => {
  const Element = AuthGuard(safeRenderPropComponent(element))

  return <Element />
}

ProtectedRoute.propTypes = {
  element: renderableType,
}
