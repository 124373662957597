import { ThemeColor } from '@/enums'
import { ifNotProps, themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'
import { Square } from '../../styled'

const checkedStyles = css`
  background: ${themeColor(ThemeColor.b100)};
  border: 1px solid ${themeColor(ThemeColor.b100)};
`

const unCheckedStyles = css`
  background: ${themeColor(ThemeColor.b10)};
`

const hoverStyles = css`
  &:hover {
    border-color: ${ifNotProp('checked', themeColor(ThemeColor.b100))};
  }
`

export const InnerContainer = styled(Square).attrs({
  $size: 16,
})`
  cursor: pointer;
  border: 1px solid ${themeColor(ThemeColor.b40)};
  ${ifProp('checked', checkedStyles, unCheckedStyles)};
  border-color: ${ifProp('error', themeColor(ThemeColor.danger2))};
  border-radius: ${toThemePixels(0.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${themeTransition()};
  margin-top: ${toThemePixels(1)};

  ${ifNotProps(['disabled', 'error'], hoverStyles)}
`

export const InnerContainerB = styled(Square).attrs({
  $size: 24,
})`
  cursor: pointer;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  ${ifProp('checked', checkedStyles, unCheckedStyles)};
  border-color: ${ifProp('error', themeColor(ThemeColor.danger2))};
  border-radius: ${toThemePixels(1.25)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${themeTransition()};
  margin-top: ${toThemePixels(0.5)};

  > svg {
    color: white;
  }

  ${ifNotProps(['disabled', 'error'], hoverStyles)}
`

export const OuterContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: ${toThemePixels(3)};
  width: fit-content;
`
