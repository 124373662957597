import { Row } from '@/components/styled'
import { ThemeColor } from '@/enums'
import { useSnackbar } from '@/providers'
import { useDeleteFromCart } from '@/services'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import { ddMMyyyy, formatMoney, themeColor, toThemePixels } from '@/utils'
import { Skeleton } from '@surein/ui'
import { Edit, Trash } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Button, CarrierLogo, Typography } from '../../atoms'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'

export const LightTypography = styled(Typography)`
  font-weight: 400;
`

const ProductCardWrapper = styled.div`
  display: flex;
  padding: 16px;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid ${themeColor(ThemeColor.b30)};
`

export const StyleTrashButton = styled(Button)`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor(ThemeColor.danger4)};
  user-select: none;
  transition: 0.2s;
  padding: 12px;
  &:hover {
    border-color: ${ifProp('disabled', 'none', themeColor(ThemeColor.danger2))};
  }

  &:active {
    span {
      color: ${ifProp('disabled', 'none', themeColor(ThemeColor.b100))};
    }
  }
`

const ProductInfoSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
`

const MaxWidthWrapper = styled.div`
  display: flex;
  width: 100%;
`

const ProductCardHeader = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  img {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(2)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
    height: ${toThemePixels(10)};
    width: ${toThemePixels(10)};

    @media (max-width: 900px) {
      height: ${toThemePixels(6)};
      width: ${toThemePixels(6)};
      border-radius: ${toThemePixels(1)};
    }
  }
`

const EditButton = styled(Button)`
  padding: 0;
  height: 1rem;
  width: 1rem;
`

const ProductCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 8px;
`

const ProductCardItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

interface CartCardBaseProps {
  categoryId: string
  name: string
  logoUrl: string
  startDate: string
  grossPrice: string
  risksInsured: string
  paymentPeriod: string
  recommendationProductId: string
  handleOnEdit: (recommendationProductId: string) => void
  onDelete?: () => void
}

export const CartCardBase = ({
  categoryId,
  name,
  logoUrl,
  startDate,
  grossPrice,
  risksInsured,
  paymentPeriod,
  recommendationProductId,
  handleOnEdit,
  onDelete,
}: CartCardBaseProps) => {
  const categoryTranslation = useCategoryTranslations(categoryId)
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const handleDelete = (e: any) => {
    e.stopPropagation() // is this even needed?
    onDelete?.()
  }

  return (
    <ProductCardWrapper>
      <ProductCardHeader>
        {logoUrl && <CarrierLogo name={logoUrl} />}
        <ProductCardTitle>
          <>
            <Typography bold color={ThemeColor.b100} variant="p2Body">
              {categoryTranslation.categoryName}
            </Typography>
            <LightTypography color={ThemeColor.b50} variant="inputDescription">
              {name}
            </LightTypography>
          </>
          <Row>
            <Typography bold variant="badgeText">
              {formatMoney(grossPrice)}
            </Typography>
            {paymentPeriod && (
              <Typography bold variant="badgeText">
                / {t(`paymentPeriodOptions.${paymentPeriod}`).toLowerCase()}
              </Typography>
            )}
          </Row>
        </ProductCardTitle>
        {onDelete && (
          <StyleTrashButton onClick={handleDelete}>
            <Trash color={theme.color[ThemeColor.danger1]} size="16px" />
          </StyleTrashButton>
        )}
      </ProductCardHeader>

      <MaxWidthWrapper>
        <ProductInfoSection>
          <LightTypography color={ThemeColor.b50} variant="inputDescription">
            {t('checkout.titles.startDate')}
          </LightTypography>

          <ProductCardItem>
            <Typography bold color={ThemeColor.b100} variant="p2Body">
              {startDate ? ddMMyyyy(Date.parse(startDate)) : '-'}
            </Typography>
            {handleOnEdit && (
              <EditButton
                icon={Edit}
                onClick={() => handleOnEdit(recommendationProductId)}
                size="sm"
                variant="ghost"
              />
            )}
          </ProductCardItem>
        </ProductInfoSection>
        <ProductInfoSection>
          {risksInsured && (
            <>
              <LightTypography
                color={ThemeColor.b50}
                variant="inputDescription"
              >
                Risk insured
              </LightTypography>
              <Typography bold color={ThemeColor.b100} variant="p2Body">
                {locationTypesLoading ? (
                  <Skeleton />
                ) : (
                  locationTypesData?.getLocationLabel(risksInsured)
                )}
              </Typography>
            </>
          )}
        </ProductInfoSection>
      </MaxWidthWrapper>
    </ProductCardWrapper>
  )
}

interface CartCardProps {
  categoryId: string
  name: string
  carrierData: {
    logoUrl: string
  }
  risksInsured: string
  startDate: string
  handleOnEdit: (recommendationProductId: string) => void
  grossPrice: string
  paymentPeriod: string
  showDelete?: boolean
  recommendationProductId: string
}

export const CartCard = ({
  categoryId,
  name,
  carrierData,
  risksInsured,
  startDate,
  handleOnEdit,
  grossPrice,
  paymentPeriod,
  showDelete = true,
  recommendationProductId,
}: CartCardProps) => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const createDeleteItemMutation = useDeleteFromCart()

  const handleDelete = () => {
    createDeleteItemMutation.mutateAsync(recommendationProductId).then(() => {
      snackbar.success(t('snackbars.success.deleteSuccess'))
    })
  }

  return (
    <CartCardBase
      categoryId={categoryId}
      grossPrice={grossPrice}
      handleOnEdit={handleOnEdit}
      logoUrl={carrierData?.logoUrl}
      name={name}
      onDelete={showDelete ? handleDelete : undefined}
      paymentPeriod={paymentPeriod}
      recommendationProductId={recommendationProductId}
      risksInsured={risksInsured}
      startDate={startDate}
    />
  )
}
