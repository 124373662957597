import { Loader } from '@/components'
import { useAuth } from '@/providers'
import { useSelfAssessment } from '@/services'
import {
  getSessionStorageItem,
  isAdmin,
  isVerified,
  makeLocationPathFromCompanies,
  makeQueryString,
  returns,
  safeObject,
  shouldRedirectToDashboard,
} from '@/utils'
import { head, ifElse, pipe, prop, propEq } from 'ramda'
import { Navigate } from 'react-router-dom'
import { paths } from './paths'
import { Redirect } from './Redirect'

const makeRedirectPath = ifElse(
  propEq('onboarded', false),
  pipe(
    prop('companies'),
    head,
    prop('locations'),
    head,
    paths.assessmentWithKey,
  ),
  pipe(
    prop('companies'),
    ifElse(
      shouldRedirectToDashboard,
      returns(paths.dashboard),
      makeLocationPathFromCompanies(paths),
    ),
  ),
)

export const AuthRedirect = () => {
  const { user, isLoading: isAuthLoading } = useAuth()
  const { data, isLoading } = useSelfAssessment({
    enabled: !isAuthLoading && isVerified(user) && !isAdmin(user),
  })
  const searchParams = getSessionStorageItem('searchParams')

  if (isLoading) return <Loader />

  if (['/', '/en', '/de', '/en/', '/de/'].includes(window.location.pathname))
    return (
      <Redirect
        to={`${makeRedirectPath(data)}${makeQueryString(
          safeObject(searchParams),
        )}`}
      />
    )

  return (
    <Navigate
      to={`${window.location.pathname}${makeQueryString(
        safeObject(searchParams),
      )}`}
    />
  )
}
