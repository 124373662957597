const divideFactorForMontlyPrice = {
  yearly: 12,
  'half-yearly': 6,
  quarterly: 3,
  monthly: 1,
  once: 1,
  'non-contributory': 1,
}

const getMonthlyPriceFromGrossPrice = (
  grossPrice: number,
  paymentPeriod: string,
): number => {
  const divideFactor =
    divideFactorForMontlyPrice[
      paymentPeriod as keyof typeof divideFactorForMontlyPrice
    ] ?? 1

  return grossPrice / divideFactor
}

export const doesSupportMonthlyPriceCalculation = (
  paymentPeriod: string = 'yearly',
): boolean => {
  return ['yearly', 'half-yearly', 'quarterly'].includes(paymentPeriod)
}

const calculateDiscountedPrice = (
  grossPrice: number,
  discountValue: number,
  paymentPeriod: string = 'yearly',
  shouldForceMonthly: boolean = false,
) => {
  const grossPriceToUse =
    shouldForceMonthly && doesSupportMonthlyPriceCalculation(paymentPeriod)
      ? getMonthlyPriceFromGrossPrice(grossPrice, paymentPeriod)
      : grossPrice
  return discountValue > 0
    ? grossPriceToUse / (1 - discountValue / 100)
    : grossPrice
}

/**
 * This logic probably should be moved to BE
 */
export const getFinalPrices = (
  grossPrice: number,
  discountValue: number,
  paymentPeriod: string = 'yearly',
  shouldForceMonthly: boolean = false,
): {
  discountedPrice: number
  finalPrice: number
} => {
  const discountedPrice = calculateDiscountedPrice(
    grossPrice,
    discountValue,
    paymentPeriod,
    shouldForceMonthly,
  )
  const finalPrice =
    shouldForceMonthly && doesSupportMonthlyPriceCalculation(paymentPeriod)
      ? getMonthlyPriceFromGrossPrice(grossPrice, paymentPeriod)
      : grossPrice

  return {
    discountedPrice,
    finalPrice,
  }
}
