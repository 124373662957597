import {
  Absolute,
  FormCardSelect,
  Option,
  Relative,
  Typography,
} from '@/components'
import { AssessmentStepKeys, Categories, ThemeColor } from '@/enums'
import { DecorativeTag } from '@/icons'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { propTypeFromEnumKeys } from '@/utils'
import { filter, flip, pipe, prepend, uniq } from 'ramda'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    lookingForLabel: t('lookingForLabel'),
    liability: t('lookingForLiability'),
    content: t('lookingForContent'),
    legal: t('lookingForLegal'),
    lookingForExpertLabel: t('lookingForExpertLabel'),
    lookingForExpert: t('lookingForExpert'),
    optional: t('optional'),
    highlyRecommended: t('highlyRecommended'),
  }
}
const makeDefaultSelected = (categoryId) => {
  switch (categoryId) {
    case 'LI':
      return 'liability'
    case 'CO':
      return 'content'
    case 'LE':
      return 'legal'
    default:
      return ''
  }
}

const makeOptions = pipe(
  flip(prepend)(['liability', 'content', 'legal']),
  uniq,
  filter(Boolean),
)

export const HowCanWeHelpLeadStep = ({ categoryId }) => {
  const messages = useMessages()
  const defaultSelected = makeDefaultSelected(categoryId)

  const { value } = useFormItem('lookingFor')
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.howCanWeHelp,
  )

  useOnNextClick(save)

  useNextDisabled(() => !value || !value.length, [value])

  useNextLoading(() => isLoading, [isLoading])

  const WithDecorativeTag = useCallback((component, optionValue) => {
    if (optionValue === 'expert') return component

    return (
      <Relative>
        <Absolute left="-5px" top="-19px" zIndex={1}>
          <DecorativeTag />
          <Absolute left="8px" top="8px">
            <Typography color={ThemeColor.b0} variant="inputDescription">
              {optionValue === defaultSelected
                ? messages.highlyRecommended
                : messages.optional}
            </Typography>
          </Absolute>
        </Absolute>
        {component}
      </Relative>
    )
  }, [])

  return (
    <FormCardSelect
      hoc={WithDecorativeTag}
      initialValue={[defaultSelected]}
      multiple
      name="lookingFor"
    >
      <Typography color={ThemeColor.b50} variant="inputLabel">
        {messages.lookingForLabel}
      </Typography>
      {makeOptions(defaultSelected).map((item) => (
        <Option key={item} value={item}>
          {messages[item]}
        </Option>
      ))}
      <br />
      <br />
      <br />
      <Typography color={ThemeColor.b50} variant="inputLabel">
        {messages.lookingForExpertLabel}
      </Typography>
      <Option key="expert" value="expert">
        {messages.lookingForExpert}
      </Option>
    </FormCardSelect>
  )
}

HowCanWeHelpLeadStep.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
}
