/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { PublicPageContent, Typography, WithGap } from '@/components'
import { isString, useTranslationSectionObject } from '@/utils'
import { Container } from './styles'

export const Terms = () => {
  const messages = useTranslationSectionObject('terms')

  return (
    <PublicPageContent>
      <Container>
        <WithGap gap={4}>
          <Typography responsive variant="h1">
            {messages.title}
          </Typography>
          <Typography responsive variant="h3">
            {messages.whoAreWe.title}
          </Typography>
          <Typography variant="p1Body">{messages.whoAreWe.content}</Typography>
          <Typography responsive variant="h3">
            {messages.whatWeDo.title}
          </Typography>
          <Typography variant="p1Body">
            {messages.whatWeDo.content[0]}
          </Typography>
          <ul>
            {messages.whatWeDo.content[1].map((text, i) => (
              <li key={i}>
                <Typography variant="p1Body">{text}</Typography>
              </li>
            ))}
          </ul>
          <Typography variant="p1Body">
            {messages.whatWeDo.content[2]}
          </Typography>
          {messages.content.map(({ title, content }, i) => (
            <>
              <Typography responsive variant="h3">
                {i + 1} {title}
              </Typography>
              {isString(content) ? (
                <Typography variant="p1Body">{content}</Typography>
              ) : (
                content.map((item, j) =>
                  isString(item) ? (
                    <Typography variant="p1Body">
                      {i + 1}.{j + 1} {item}
                    </Typography>
                  ) : (
                    item.map((subitem, k) =>
                      k === 0 ? (
                        <Typography variant="p1Body">
                          {i + 1}.{j + 1} {subitem}
                        </Typography>
                      ) : isString(subitem) ? (
                        <Typography variant="p1Body">{subitem}</Typography>
                      ) : (
                        <ul>
                          {subitem.map((listItem, l) =>
                            isString(listItem) ? (
                              <li key={l}>
                                <Typography variant="p1Body">
                                  {listItem}
                                </Typography>
                              </li>
                            ) : (
                              <ul>
                                {listItem.map((sublistItem, m) => (
                                  <li key={m}>
                                    <Typography variant="p1Body">
                                      {sublistItem}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            ),
                          )}
                        </ul>
                      ),
                    )
                  ),
                )
              )}
            </>
          ))}
        </WithGap>
      </Container>
    </PublicPageContent>
  )
}
