import { getRecommendationById, requestRecommendation } from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

const keys = {
  recommendation: 'recommendation',
  recommendationSearchConfig: 'recommendationSearchConfig',
}

export const useRecommendation = (
  id: string,
  options: { enabled?: boolean } = {},
) =>
  useQuery([keys.recommendation, id], () => getRecommendationById(id), {
    ...options,
    select: (data) => ({
      ...data,
      isSearchable: false, // remove this once search criteria is ready | this is most likely obsolete - leaving for compatibility now
    }),
  })

export const useRequestRecommendation = (locationId: string) =>
  useInvalidateOnSuccessMutation(
    [['location', locationId]],
    // FIXME: is message even used?
    ({ categoryId, message }: { categoryId: string; message?: string }) =>
      requestRecommendation({ categoryId, locationId, message }),
  )
