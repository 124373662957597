import { ThemeColor } from '@/enums'
import { useFormContext, useFormItem } from '@/providers'
import { fromEventTarget, themeColorFrom } from '@/utils'
import { Input } from '@surein/ui'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { formItemPropType, getError } from './helpers'

export const StyledMessage = styled.p`
  cursor: inherit;
  white-space: pre-line;
  line-height: 140%;
  font-weight: 700;
  font-size: 12px;
  color: ${themeColorFrom(prop('color'))};
`

export const FormInputField = ({
  name,
  label,
  disabled,
  prefixIcon,
  suffixIcon,
  infoMessage,
  showErrorMessage = true,
  showValidCheck = false,
  validateOnChange = false,
  ...props
}) => {
  const { validateField } = useFormContext()
  const [touched, setIsTouched] = useState(false)
  const { value, onChange, error } = useFormItem(name)
  const errorMsg = getError(error)

  useEffect(() => {
    if (touched && validateOnChange && value) validateField(name)
  }, [validateOnChange, touched, value])

  const hasErrorMessage = errorMsg && showErrorMessage
  const isSuccessful = showValidCheck && touched && !error && value

  return (
    <div className="flex flex-col gap-2">
      <Input
        disabled={disabled}
        id={name}
        isErrored={Boolean(error)}
        isSuccessful={isSuccessful}
        label={label}
        name={name}
        onBlur={() => touched && validateField(name)}
        onChange={fromEventTarget(onChange)}
        onFocus={() => setIsTouched(true)}
        prefixIcon={prefixIcon}
        suffixIcon={suffixIcon}
        value={value}
        {...props}
      />

      {hasErrorMessage && (
        <StyledMessage color={ThemeColor.danger2}>{errorMsg}</StyledMessage>
      )}

      {infoMessage && !hasErrorMessage && (
        <StyledMessage color={ThemeColor.b50}>{infoMessage}</StyledMessage>
      )}
    </div>
  )
}

FormInputField.propTypes = {
  disabled: PropTypes.bool,
  infoMessage: PropTypes.string,
  initialValue: PropTypes.string,
  isErrored: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  prefixIcon: PropTypes.elementType,
  showErrorMessage: PropTypes.bool,
  showValidCheck: PropTypes.bool,
  suffixIcon: PropTypes.elementType,
  validateOnChange: PropTypes.bool,
  ...formItemPropType,
}
