import { curry, pipe, props } from 'ramda'
import { useMutation } from 'react-query'
import { spread } from './function'

export const base64ToBlob = (base64: string, type: string) =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob())

const download = curry((fileName, blob) => {
  const a = document.createElement('a')
  a.href = blob
  a.download = fileName
  a.click()
})

export const downloadBase64 = (
  base64: string,
  type: string,
  fileName: string,
) =>
  base64ToBlob(base64, type).then(pipe(URL.createObjectURL, download(fileName)))

export const previewResponseFile = pipe(
  props(['file', 'type', 'displayName']),
  spread(downloadBase64),
)

export const isImgTypeSupported = (type: string) =>
  new Promise((resolve) => {
    const image = new Image()
    image.addEventListener('error', () => resolve(false))
    image.addEventListener('load', () => resolve(image.width === 1))
    image.src = `data:image/${type};base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=`
  }).catch(() => false)

/**
 * TODO: fix type
 */
export const useDownloadFile = (queryFn: (name: string) => Promise<any>) =>
  useMutation(queryFn, {
    onSuccess: previewResponseFile,
  })

export const getFileNameAndExtension = (filename: string): [string, string] => {
  const extension = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
  )
  const fileName = filename.substring(0, filename.lastIndexOf('.'))
  return [fileName, extension]
}

export const downloadCsv = (data: { content: string; filename: string }) => {
  const blob = window.URL.createObjectURL(new Blob([data.content]))
  download(data.filename, blob)
}

/**
 * TODO: fix type
 */
export const useDownloadCsv = (queryFn: (companyId: string) => Promise<any>) =>
  useMutation(queryFn, {
    onSuccess: downloadCsv,
  })
