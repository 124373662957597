import { oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { buttonSizePropType, buttonVariantPropType } from '../ButtonBase'
import { Spinner } from '../Spinner'
import { Typography } from '../Typography'
import { Container, StyledSpinner } from './styles'

export const Button = ({
  children,
  loading,
  icon: Icon,
  suffixIcon: SuffixIcon,
  ellipsis,
  textAlign,
  ...rest
}) => (
  <Container loading={loading} {...rest}>
    {Icon && <Icon size="button" />}
    <Typography ellipsis={ellipsis} textAlign={textAlign} variant="button">
      {children}
    </Typography>
    {SuffixIcon && <SuffixIcon size="button" />}
    {loading && (
      <StyledSpinner data-testid="button-spinner">
        <Spinner />
      </StyledSpinner>
    )}
  </Container>
)

export const PrimaryButton = (props) => (
  <Button {...props} variant="contained" />
)

export const SecondaryButton = (props) => (
  <Button {...props} variant="outlined" />
)

Button.propTypes = {
  children: oneOrManyChildren,
  disabled: PropTypes.bool,
  ellipsis: PropTypes.bool,
  icon: PropTypes.elementType,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  reverted: PropTypes.bool,
  size: buttonSizePropType,
  suffixIcon: PropTypes.elementType,
  textAlign: PropTypes.string,
  variant: buttonVariantPropType,
}

PrimaryButton.propTypes = {
  children: oneOrManyChildren,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  reverted: PropTypes.bool,
  size: buttonSizePropType,
  suffixIcon: PropTypes.elementType,
}

SecondaryButton.propTypes = {
  children: oneOrManyChildren,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  reverted: PropTypes.bool,
  size: buttonSizePropType,
}
