import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 60px - 16px);
  display: flex;
  margin-left: -${toThemePixels(6)};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(25)};
  background-color: ${themeColor(ThemeColor.b10)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-top: ${toThemePixels(30)};
  }
`

export const PageInfo = styled(Column)`
  width: 450px;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(6)};
  text-align: center;

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: auto;
  }
`

export const Contact = styled.div``

export const Emoji = styled(Column)`
  align-items: center;
  justify-content: center;
  font-size: ${toThemePixels(30)};
  height: ${toThemePixels(30)};
`
