import { safeRenderPropComponent } from '@/utils'
import { StyledComponent } from 'styled-components'
import { Input } from '../../styled'
import { Addon, Container } from './styles'

interface InputWithAddonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  addonAfter?: JSX.Element | (() => JSX.Element)
  addonBefore?: JSX.Element | (() => JSX.Element)
  inputComponent?: StyledComponent<'input', any, any>
  rounded?: boolean
}

export const InputWithAddon = ({
  addonBefore,
  addonAfter,
  inputComponent: Component = Input,
  ...rest
}: InputWithAddonProps) => {
  const AddonBefore = safeRenderPropComponent(addonBefore)
  const AddonAfter = safeRenderPropComponent(addonAfter)

  return (
    <Container hasPrefix={Boolean(addonBefore)} hasSuffix={Boolean(addonAfter)}>
      {addonBefore && (
        <Addon before>
          <AddonBefore />
        </Addon>
      )}
      <Component {...rest} />
      {addonAfter && (
        <Addon before={false}>
          <AddonAfter />
        </Addon>
      )}
    </Container>
  )
}
