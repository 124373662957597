import { Breakpoint, ThemeColor } from '@/enums'
import { Checked } from '@/icons'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  padding-bottom: ${toThemePixels(14)};
  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100%;
    align-items: center;
    padding-bottom: ${toThemePixels(8)};
  }
`

export const ContentWrapper = styled(Column)`
  gap: ${toThemePixels(4)};
  width: 100%;
`

export const StyledCard = styled(Column)`
  justify-content: center;
  padding: ${toThemePixels(2)};
  background-color: ${themeColor(ThemeColor.b0)};
  cursor: pointer;
`

export const SelectedStyledCard = styled.div`
  border-radius: ${toThemePixels(3)};
  border: 1px solid ${themeColor(ThemeColor.success2)};
  background-color: ${themeColor(ThemeColor.success4)};
`

export const SelectedCardTop = styled.div`
  padding: ${toThemePixels(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed ${themeColor(ThemeColor.success2)};
`

export const SelectedCardBottom = styled.div`
  padding: ${toThemePixels(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleContainer = styled(Column)``

export const ButtonsContainer = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  gap: ${toThemePixels(2)};
`

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
  }
`

export const CarrierContainer = styled(Row)`
  gap: ${toThemePixels(1)};
  justify-content: flex-start;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
  }
`

export const StyledChecked = styled(Checked)`
  fill: none;
  width: ${toThemePixels(5.5)};
`

export const HeaderContainer = styled(Column)`
  gap: ${toThemePixels(2)};
  margin-bottom: ${toThemePixels(6)};
`

export const InsuranceGroup = styled(Column)`
  display: none;
  &:has(${StyledCard}) {
    display: flex;
  }
`

export const SelectedInsurancesGroup = styled(Column)`
  gap: ${toThemePixels(2)};
`

export const EmptySearchContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(3)};
  padding: ${toThemePixels(6)} 0;
  text-align: center;
  display: flex;

  > div {
    max-width: ${toThemePixels(100)};
  }
`
