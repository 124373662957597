import { ThemeColor } from '@/enums'
import {
  primaryColorFromComponentColor,
  secondaryColorFromComponentColor,
} from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Button } from '../Button'

const getColor1FromColor = primaryColorFromComponentColor(ThemeColor.b100)

const getColor2FromColor = secondaryColorFromComponentColor(ThemeColor.b50)

export const Container = styled(Button)`
  padding: 0;
  color: ${getColor1FromColor};
  text-decoration: ${ifProp('underline', 'underline', 'none')};

  svg {
    stroke: ${getColor1FromColor};
  }

  &:hover:not(:disabled) {
    color: ${getColor2FromColor};
    svg {
      stroke: ${getColor2FromColor};
    }
  }

  &:disabled {
    background-color: transparent;
  }
`
