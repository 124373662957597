import { useLocationRanking, useLocationTypes } from '@/services/locationTypes'
import { omit } from 'ramda'
import { useMemo } from 'react'

export const useBusinessTypeOptions = () => {
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const {
    data: locationRankingData,
    isLoading: locationRankingLoading,
  } = useLocationRanking()

  const isLoading = locationTypesLoading || locationRankingLoading

  const { verticalsMap = {}, getLocationLabel, getVerticalLabel } =
    locationTypesData || {}

  const { getLocationTypeRatio } = locationRankingData || {}

  const options = useMemo(() => {
    return Object.keys(omit(['other'], verticalsMap)).flatMap((value) =>
      verticalsMap[value].map((item) => ({
        value: item,
        label: getLocationLabel?.(item),
        subtitle: getVerticalLabel?.(value),
        ratio: getLocationTypeRatio?.(item),
      })),
    )
  }, [verticalsMap, getLocationLabel, getVerticalLabel, getLocationTypeRatio])

  return { options, isLoading }
}
