import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column, Row } from '../../styled'

export const Container = styled(Row)`
  padding: ${toThemePixels(4)} 0;
  justify-content: space-between;
  gap: ${toThemePixels(3)};
  align-items: center;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};

  &:first-child {
    padding-top: 0;
  }

  span {
    cursor: pointer;
  }
`

export const FileDetails = styled(Column)`
  gap: ${toThemePixels(1)};
  max-width: calc(100% - 120px - 12px);
  overflow: hidden;
`
