import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { switchProp } from 'styled-tools'
import { Column } from '../../styled'

export const Container = styled(Column)`
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  gap: ${switchProp('alignment', {
    vertical: toThemePixels(6),
    horizontal: toThemePixels(2),
  })};
`

const verticalStyles = css`
  flex-direction: column-reverse;
  gap: ${toThemePixels(1)};
`

const horizontalStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Detail = styled.div`
  display: flex;
  ${switchProp('alignment', {
    vertical: verticalStyles,
    horizontal: horizontalStyles,
  })}
`
