import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { ButtonBase } from '../ButtonBase'

export const Container = styled(ButtonBase)`
  display: flex;
  gap: ${toThemePixels(1.5)};
  align-items: center;
  justify-content: center;
  flex-direction: ${ifProp('reverted', 'row-reverse')};
  position: relative;

  span,
  > svg {
    opacity: ${ifProp('loading', '0', '1')};
  }
`

export const StyledSpinner = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
