import { Document, Typography } from '@/components/atoms'
import { useTranslation } from 'react-i18next'
import { CategoryTitleContainer, Container, FileCategory } from './styles'

type AppFile = {
  name: string
  displayName: string
  createdAt: string
  visibleToUser: boolean
  category: string | null
}

const groupFiles = (files: AppFile[]) => {
  const visibleFiles = files.filter((file) => file.visibleToUser)
  const sortedFiles = visibleFiles.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )

  return sortedFiles.reduce((acc, file) => {
    // translation file stores `null` key ;_;
    const category = String(file.category)
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(file)
    return acc
  }, {} as Record<string, AppFile[]>)
}

export const Documents = ({ files = [] }: { files: AppFile[] }) => {
  const { t } = useTranslation()
  const groupedFiles = groupFiles(files)

  return (
    <Container>
      {/* FIXME: the categories order is unpredictable */}
      {Object.keys(groupedFiles).map((key) => {
        // FIXME: we are relying on the translations here
        //  the component should explicitly know what are possible categories
        const categoryLabel = (t(`file_categories`, {
          returnObjects: true,
        }) as Record<string, string>)[key]
        return (
          <FileCategory key={key}>
            <CategoryTitleContainer>
              {categoryLabel && (
                <Typography variant="h5">{categoryLabel}</Typography>
              )}
            </CategoryTitleContainer>
            {groupedFiles[key].map((file) => (
              <Document key={file.name} {...file} />
            ))}
          </FileCategory>
        )
      })}
    </Container>
  )
}
