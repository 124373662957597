import { ThemeColor } from '@/enums'
import { themeColor, themeColorFromProp, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop, switchProp } from 'styled-tools'
import { Row } from '../../styled'

const getSize = switchProp(
  'size',
  {
    sm: '0 4px',
    md: '4px 8px',
  },
  '0 4px',
)

export const Container = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  position: relative;
  z-index: 1;
  padding: ${getSize};

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    background-color: ${ifProp(
      'backgroundColor',
      themeColorFromProp('backgroundColor'),
      themeColor(ThemeColor.b40),
    )};
    opacity: ${ifProp('bgOpacity', prop('bgOpacity'), 1)};
    border-radius: ${toThemePixels(1)};
    z-index: -1;
  }
`
