import {
  Column,
  Divider,
  FormCardSelect,
  InputWithAddon,
  Option,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import { Fragment, useState } from 'react'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { insuranceCategoryValidationSchema } from '../helpers/validationSchema'
import { useTranslation } from 'react-i18next'
import { InsuranceCategoryFormValues } from '../helpers/types'

interface CategoryEntry {
  value: string
  title: string
  subtitle: string
}

const InsuranceCategoryForm = () => {
  const { t } = useTranslation()
  const cardOptions = useInsuranceGroups()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredCarriers = cardOptions.filter((group) =>
    group.items.some((item) =>
      item.title.toLowerCase().startsWith(searchTerm.toLowerCase()),
    ),
  )

  return (
    <>
      <InputWithAddon
        addonBefore={() => <SearchIcon color={ThemeColor.b50} />}
        onChange={handleSearchChange}
        placeholder={t(
          'addInsuranceContractsSteps.insuranceCategory.searchPlaceholder',
        )}
        rounded
        value={searchTerm}
      />
      <Divider color={ThemeColor.b30} />
      {filteredCarriers.map((category) => (
        <Fragment key={category.groupName}>
          <Typography variant="p2Body" color={ThemeColor.glass500}>
            {category.groupName}
          </Typography>
          <FormCardSelect
            noMargin
            name="categoryId"
            showErrorHelperText={false}
          >
            {category.items.map((item: CategoryEntry) => (
              <Option
                key={`${item.value}-${category.groupName}`}
                value={item.value}
              >
                <Column>
                  <Typography bold variant="p1Body" color={ThemeColor.b100}>
                    {item.title}
                  </Typography>
                  <Typography variant="p2Body" color={ThemeColor.glass500}>
                    {item.subtitle}
                  </Typography>
                </Column>
              </Option>
            ))}
          </FormCardSelect>
        </Fragment>
      ))}
    </>
  )
}

export const InsuranceCategoryStep = () => {
  const { t } = useTranslation()
  const { onBack, onNext, setCategoryId } = useStepper()

  const handleNext = (values: InsuranceCategoryFormValues) => {
    setCategoryId(values.categoryId)
    onNext(values)
  }

  return (
    <StepLayout
      title={t('addInsuranceContractsSteps.insuranceCategory.title')}
      subtitle={t('addInsuranceContractsSteps.insuranceCategory.subtitle')}
      validationSchema={insuranceCategoryValidationSchema}
      onNext={handleNext}
      onBack={onBack}
    >
      <InsuranceCategoryForm />
    </StepLayout>
  )
}
