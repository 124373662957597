import * as Yup from 'yup'

export const validationSchema = (messages) =>
  Yup.object().shape({
    companyAddress: Yup.object()
      .shape({
        city: Yup.string().required(messages.city),
        country: Yup.string().required(messages.country),
        postalCode: Yup.string().required(messages.postalCode),
        street: Yup.string().required(messages.street),
        streetNr: Yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOption),
    companyName: Yup.string().required(messages.name),
    locationAddress: Yup.object()
      .shape({
        city: Yup.string().required(messages.street),
        country: Yup.string().required(messages.country),
        postalCode: Yup.string().required(messages.postalCode),
        street: Yup.string().required(messages.street),
        streetNr: Yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOption),
    locationName: Yup.string().required(messages.locationName),
  })
