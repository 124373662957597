import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { fromEventTarget, parseChildrenArray } from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { InputWithAddon, selectChildrenPropType } from '../../atoms'
import { selectOptionValuePropType } from '../../atoms/Select/helpers'
import { OptionNode } from './OptionNode'
import { Container, OptionsContainer } from './styles'

export const ListSelect = ({
  value,
  onChange,
  searchPlaceholder,
  children,
  disabled,
}) => {
  const options = parseChildrenArray(children)
  const [searchedValue, setSearchedValue] = useState('')

  const filteredOptions = options.filter(({ children: text }) =>
    String(text).toLowerCase().includes(searchedValue.toLowerCase()),
  )

  return (
    <Container>
      <InputWithAddon
        addonBefore={() => <SearchIcon color={ThemeColor.b50} />}
        onChange={fromEventTarget(setSearchedValue)}
        placeholder={searchPlaceholder}
        rounded
        value={searchedValue}
      />
      <OptionsContainer>
        {filteredOptions.map(
          ({
            key,
            value: optionValue,
            children: text,
            disabled: optionDisabled,
            icon,
          }) => (
            <OptionNode
              key={key}
              disabled={disabled || optionDisabled}
              icon={icon}
              onClick={onChange}
              selected={value === optionValue}
              text={text}
              value={optionValue}
            />
          ),
        )}
      </OptionsContainer>
    </Container>
  )
}

ListSelect.propTypes = {
  children: selectChildrenPropType,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  value: selectOptionValuePropType,
}
