import { AddInsuranceView } from '@/components'
import { ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import { useParams } from 'react-router-dom'

export const AddInsurancesPage = () => {
  const { locationId } = useParams()

  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))

  return (
    <AddInsuranceView
      locationId={locationId}
      onSuccess={goToLocation}
      textColor={ThemeColor.b100}
    />
  )
}
