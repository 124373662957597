import { fromEventTarget } from '@/utils'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { TextArea } from '../../styled'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

export const FormTextarea = ({
  name,
  label,
  rows = 3,
  initialValue,
  info,
  helperText,
  ignoreErrorKeys,
  ...props
}) => {
  const Component = useCallback(
    ({ onChange, ...field }) => (
      <TextArea
        onChange={fromEventTarget(onChange)}
        rows={rows}
        {...field}
        {...props}
      />
    ),
    [],
  )

  return (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      name={name}
    >
      <Component />
    </FormItem>
  )
}

FormTextarea.propTypes = {
  ...formItemPropType,
  resize: PropTypes.bool,
  rows: PropTypes.number,
}
