import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  gap: ${toThemePixels(5)};

  > * {
    width: calc(50% - ${toThemePixels(2.5)});

    > *:first-child {
      width: 100%;

      input {
        min-width: 100%;
      }
    }
  }
`
