import { any, find, findIndex, propEq } from 'ramda'
import { useState } from 'react'

export const useSteps = ({ initialStep, steps, keyPropName }) => {
  const isInitialInSteps = any(propEq('key', initialStep))(steps)

  const getStep = (i) => steps[i][keyPropName]

  const [step, setStep] = useState(isInitialInSteps ? initialStep : getStep(0))

  const isCurrentStep = propEq(keyPropName, step)

  const data = find(isCurrentStep, steps)
  const index = findIndex(isCurrentStep, steps)

  const isLast = index === steps.length - 1

  const isFirst = index === 0

  const back = () => setStep(getStep(index - 1))

  const next = () => setStep(getStep(index + 1))

  const goTo = setStep

  return { step, isLast, isFirst, index, data, back, next, goTo }
}
