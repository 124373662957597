import { Breakpoint } from '@/enums'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../styled'

export const ConfirmationModalHeader = styled(Column)`
  gap: ${toThemePixels(1)};
  padding: 0 0 ${toThemePixels(10)} 0;

  > * {
    margin: 0;
    max-width: 500px;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(15)} 0 ${toThemePixels(10)} 0;

    > * {
      min-width: auto;
    }
  }
`

const singleButtonStyles = css`
  padding: ${toThemePixels(8)} 0;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(4)} 0;
  }
`

const buttonStyles = css`
  padding: 0 0 ${toThemePixels(6)} 0;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    gap: ${toThemePixels(4)};

    button {
      width: 100%;
    }
  }
`

export const ModalActions = styled(Row)`
  justify-content: space-between;
  ${ifProp('singleButton', singleButtonStyles, buttonStyles)}
`
