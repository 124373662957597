import styled from 'styled-components'
import { DamageSample } from '../BasePageB'
import { Card } from '@surein/ui'
import { ThemeColor } from '@/enums'
import { Column, Row, Typography } from '@/components'
import { useTranslation } from 'react-i18next'

interface DamageSampleCardProps extends DamageSample {
  imgSrc: string
}

const StyledCard = styled(Card)`
  background-image: ${(props) => `url(${props.imgSrc})`};
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  padding: 16px;
  background-color: transparent !important;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const BottomEntries = styled(Row)`
  display: flex;
  align-items: end;
  gap: 16px;
  > div {
    flex: 1;
  }
`

export const DamageSampleCard = ({
  title,
  subtitle,
  price,
  successRate,
  imgSrc,
}: DamageSampleCardProps) => {
  const { t } = useTranslation()
  return (
    <StyledCard imgSrc={imgSrc} variant="borderless">
      <Column gap="8px">
        <Typography variant="body" color={ThemeColor.b0}>
          {title}
        </Typography>
        <Typography variant="description" color={ThemeColor.glass200}>
          {subtitle}
        </Typography>
      </Column>
      <BottomEntries justifyContent="space-between" alignItems="center">
        <Column>
          <Typography variant="micro" color={ThemeColor.glass200}>
            {t('claimPaid')}
          </Typography>
          <Typography variant="subheadline" color={ThemeColor.success1}>
            {price}
          </Typography>
        </Column>
        <Column>
          <Typography variant="micro" color={ThemeColor.glass200}>
            {t('claimSuccess')}
          </Typography>
          <Typography variant="subheadline" color={ThemeColor.b0}>
            {successRate}
          </Typography>
        </Column>
      </BottomEntries>
    </StyledCard>
  )
}
