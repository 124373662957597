import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled(Column)`
  position: relative;
  width: 100%;
  background-color: ${themeColor(ThemeColor.b10)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  gap: ${toThemePixels(6)};
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);

  h5 {
    margin: 0;
  }

  img {
    width: ${toThemePixels(8)};
    height: ${toThemePixels(8)};
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100%;
    height: auto;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${toThemePixels(6)};
  justify-content: space-between;

  > * {
    flex: 1;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    align-items: flex-start;
    gap: ${toThemePixels(4)};
  }
`

export const Tag = styled.div`
  width: fit-content;
  padding: ${toThemePixels(2)} ${toThemePixels(3)};
  border: 1px solid ${themeColor(ThemeColor.info3)};
  border-radius: ${toThemePixels(25)};
  color: ${themeColor(ThemeColor.b100)};
  background-color: ${themeColor(ThemeColor.b0)};
`
