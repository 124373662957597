import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  gap: ${toThemePixels(5)};

  > *:first-child {
    width: calc((100% - ${toThemePixels(10)}) / 3);

    > * {
      width: 100%;
      input {
        min-width: 100%;
      }
    }
  }

  > *:last-child {
    width: calc((100% - ${toThemePixels(10)}) / 3 * 2 + ${toThemePixels(5)});
  }
`
