import { ThemeColor } from '@/enums'
import { ifNotProps, themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column } from '../../styled'

export const Container = styled(Column)`
  gap: ${toThemePixels(3)};
  width: 100%;
  background-color: ${themeColor(ThemeColor.b0)};
`

export const DatesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  row-gap: ${toThemePixels(1)};
`

const selectedDateStyles = css`
  background-color: ${themeColor(ThemeColor.b100)};

  color: ${themeColor(ThemeColor.b0)};
`

export const DateCell = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  min-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: ${themeTransition()};

  &:hover {
    background-color: ${ifNotProps(
      ['selected', 'disabled'],
      themeColor(ThemeColor.b10),
    )};
  }

  ${ifProp('selected', selectedDateStyles)};

  &[disabled] {
    background: transparent;
    color: ${themeColor(ThemeColor.b30)};
  }
`
