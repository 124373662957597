import { Column, Loader } from '@/components'
import PaymentRadio from '@/modules/checkout/components/PaymentRadioButton'
import { useFormContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useCompanyPayments } from '@/services'
import { useGoToPath } from '@/utils'
import { Button } from '@surein/ui'
import { Plus } from 'lucide-react'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useValidateCheckoutStep } from './helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    addPayment: t('add_payment_details'),
    noPayments: t('payment_details_missing'),
    add: t('locationPage.addInsurance'),
  }
}

export const PaymentMethodStep = ({ companyId, setButtonState, onNext }) => {
  const { data, isLoading } = useCompanyPayments(companyId)
  const { values, setFieldValue } = useFormContext()
  const messages = useMessages()
  const { state } = useLocation()

  const reassessDefaultPayment = () => {
    if (state.fromPayments && data) {
      setFieldValue('payment', data.find(propEq('priority', 1)))
    }
  }

  useEffect(() => {
    reassessDefaultPayment()
  }, [data, state.fromPayments])

  const handleNext = useValidateCheckoutStep(onNext)

  const handleSelectPayment = (payment) => {
    setFieldValue('payment', payment)
  }

  const goToAddPayment = useGoToPath(paths.addPaymentWithKey(companyId))

  useEffect(() => {
    if (!isLoading && data.length) {
      const priorityPayment = find(propEq('priority', 1), data)
      if (priorityPayment && !values?.payment?.paymentId)
        handleSelectPayment(priorityPayment)
    }
  }, [isLoading, data])

  useEffect(() => {
    setButtonState({ onClick: handleNext })
  }, [values])

  if (isLoading) return <Loader />

  if (!data?.length)
    return (
      <Button icon={Plus} onClick={goToAddPayment} size="sm" variant="outline">
        {messages.addPayment}
      </Button>
    )

  return (
    <Column alignItems="start" gap="16px">
      <PaymentRadio
        onChange={handleSelectPayment}
        paymentMethods={data}
        value={values.payment}
      />
      <Button icon={Plus} onClick={goToAddPayment} variant="secondary">
        {messages.addPayment}
      </Button>
    </Column>
  )
}

PaymentMethodStep.propTypes = {
  companyId: PropTypes.string,
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
