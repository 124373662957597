import { formatMoney } from '@/utils'

export const chartColors = [
  '#010B65',
  '#0317CE',
  '#7987FF',
  '#A9AFE1',
  '#CFD4FF',
  '#818499',
]

export const prepareChartData = ({ companies, other }) => {
  const otherObject = { ...other, companyId: 'other', name: 'Other' }

  const chartCompanies = companies.map((item, index) => ({
    id: item.companyId,
    name: item.name,
    label: `${item.name} ${formatMoney(item.grossPrice)}`,
    value: item.grossPrice,
    color: chartColors[index],
  }))

  return other.grossPrice ? [...chartCompanies, otherObject] : chartCompanies
}

export const getInternalChartText = (grossPrice) => {
  const integerPart = Math.floor(grossPrice)
  const decimalPart = Number(grossPrice - integerPart)
    .toFixed(2)
    .split('.')[1]

  return {
    integer: formatMoney(integerPart, 'euro', {
      style: 'decimal',
    }),
    decimal: decimalPart,
  }
}
