import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Input } from '../../styled'

export const DateInput = styled(Input).attrs({
  type: 'date',
})`
  cursor: pointer;
  -webkit-text-fill-color: ${themeColor(ThemeColor.b50)};
  text-transform: uppercase;

  background: ${ifProp(
    'error',
    themeColor(ThemeColor.danger4),
    themeColor(ThemeColor.b10),
  )};

  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger2),
      themeColor(ThemeColor.b30),
    )};

  ::-webkit-datetime-edit {
    min-width: fit-content;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:not([disabled]) + * svg {
    cursor: pointer;
  }
`
