import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled(Column)`
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  gap: ${toThemePixels(1)};
  width: 100%;
  cursor: pointer;
`
