import { Row } from '@/components'
import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Row)`
  background-color: ${themeColor(ThemeColor.b10)};
  padding: ${toThemePixels(15)};
  justify-content: center;

  > div {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(4)};
    box-shadow: rgb(11 44 93 / 5%) 0px 6px 12px 0px;
    width: calc(100% - 120px);
    padding: ${toThemePixels(10)};
  }
`

export const Content = styled(Row)`
  gap: ${toThemePixels(4)};
  > div {
    width: calc(50% - ${toThemePixels(2)});
  }
`

export const Footer = styled(Row)`
  justify-content: space-between;
  border-top: 1px solid ${themeColor(ThemeColor.b30)};
  padding-top: ${toThemePixels(4)};

  > div > strong {
    font-size: ${toThemePixels(4)};
  }

  ${Row} {
    align-items: flex-end;
    gap: ${toThemePixels(2)};
  }
`
