import { getTempToken } from '@/api'
import decode from 'jwt-decode'
import { PublicCheckoutStepKeys, publicCheckoutSteps } from './steps'

export const getLocationIdFromToken = () => {
  const { location_id: locationId } = decode(getTempToken())
  return locationId
}

export const makeCheckoutSteps = (categoryId, messages, tooltips) => {
  let stepKeys = []

  switch (categoryId) {
    case 'LI':
      stepKeys = [
        { key: PublicCheckoutStepKeys.startDate, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.companyDetails, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.killerQuestions, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
      break
    case 'CO':
      stepKeys = [
        { key: PublicCheckoutStepKeys.startDate, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.killerQuestions, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
      break
    case 'LE':
      stepKeys = [
        { key: PublicCheckoutStepKeys.companyDetails, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.killerQuestions, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
      break
    case 'FL':
      stepKeys = [
        { key: PublicCheckoutStepKeys.startDate, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.companyDetails, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.killerQuestions, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
      break
    case 'CY':
      stepKeys = [
        { key: PublicCheckoutStepKeys.companyDetails, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.killerQuestions, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
      break
    default:
      stepKeys = [
        { key: PublicCheckoutStepKeys.startDate, keyIndex: 0 },
        { key: PublicCheckoutStepKeys.companyDetails, keyIndex: 1 },
        { key: PublicCheckoutStepKeys.payment, keyIndex: 2 },
        { key: PublicCheckoutStepKeys.overview, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.poa, keyIndex: 3 },
        { key: PublicCheckoutStepKeys.success, keyIndex: 3 },
      ]
  }

  return stepKeys.map(({ key, keyIndex }) => ({
    key,
    keyIndex,
    Component: publicCheckoutSteps[key],
    title: messages.titles[key],
    subtitle: messages.subtitles[key],
    breadcrumbs: messages.breadcrumbs[key],
    tooltip: tooltips?.[key],
  }))
}
