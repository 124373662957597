import { Column, FormNumber } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'
import { StepperTestimonial } from './StepperTestimonial'

export const useEmployeeCountMessages = () => {
  const { t } = useTranslation()

  return {
    owners: t('employeesCountOwners'),
    fullTime: t('employeesCountFullTime'),
    partTime: t('employeesCountpartTime'),
    miniJobbers: t('employeesCountMiniJobbers'),
    fullTimeInfo: t('fullTimeInfo'),
    partTimeInfo: t('partTimeInfo'),
    miniJobbersInfo: t('miniJobbersInfo'),
    employeesCountError: t('employeesCountError'),
    employeesCountAdvisorHint: t('employeesCountAdvisorHint'),
  }
}

export const EmployeesCountStep = () => {
  const messages = useEmployeeCountMessages()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.employeesCount,
  )

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <Column gap="24px">
      <Column>
        <FormNumber
          initialValue={0}
          label={messages.owners}
          min={0}
          name="ownersCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.fullTime}
          min={0}
          name="fullTimeEmployeesCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.partTime}
          min={0}
          name="partTimeEmployeesCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.miniJobbers}
          min={0}
          name="miniJobbersCount"
          noMargin
        />
      </Column>
    </Column>
  )
}

export const EmployeesCountStepTestimonial = () => (
  <StepperTestimonial stepKey="employeesCount" imageSrc="jonas-headshot" />
)
