/* eslint-disable no-nested-ternary */
import { AssessmentStatus, ThemeColor } from '@/enums'
import { TickIcon, UploadLine } from '@/icons'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useLocationHome, useRequestRecommendation } from '@/services'
import { useGoToPath, useItemDialog, useSearchParams } from '@/utils'
import { allPass, prop, propEq } from 'ramda'
import { useParams } from 'react-router-dom'
import {
  CategoryIcon,
  FilePicker,
  Loader,
  PrimaryButton,
  Row,
  SecondaryButton,
  ShowWhen,
  Typography,
} from '../../../components'
import { checkIfRecommendationExists } from '../../LocationPage/helpers'
import { useMessages } from '../helpers'
import {
  DefaultInsuranceDetails,
  HeaderDetails,
  InsuranceDefaultViewWrapper,
  PageActions,
  UspsContainer,
} from '../styles'
import { UploadContractModal } from '../UploadContractModal'

export const InsuranceDefaultView = () => {
  const { recommendationId } = useSearchParams()
  const { categoryId, locationId } = useParams()
  const snackbars = useSnackbar()
  const messages = useMessages(categoryId)
  const { data, isLoading } = useLocationHome(locationId)
  const requestOfferMutation = useRequestRecommendation(locationId)
  const {
    item: files,
    openItemDialog,
    isOpen,
    closeItemDialog,
  } = useItemDialog()
  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))
  const goToAssessment = useGoToPath(paths.assessmentWithKey(locationId))

  const goToRecommendation = useGoToPath(paths.viewRecommendationWithKey)

  const handleRequestOffer = () => {
    if (data.selfAssessment === AssessmentStatus.completed) {
      requestOfferMutation.mutateAsync({ categoryId }).then(() => {
        snackbars.success(messages.requestSuccess)
        goToLocation()
      })
    } else goToAssessment()
  }

  if (isLoading) return <Loader />

  const sent = data?.recommendations.find(
    allPass([
      prop('sended'),
      prop('lowestPrice'),
      propEq('categoryId', categoryId),
    ]),
  )

  return (
    <InsuranceDefaultViewWrapper>
      <DefaultInsuranceDetails>
        <HeaderDetails>
          <CategoryIcon categoryId={categoryId} />
          <Typography responsive variant="h4">
            {messages.categoryName}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.longDesc}
          </Typography>
        </HeaderDetails>
        <ShowWhen when={messages.usps.length}>
          <UspsContainer>
            {messages.usps.map((item) => (
              <Row key={item} alignItems="center" gap="16px">
                <TickIcon size="title" />
                <Typography variant="p1Body">{item}</Typography>
              </Row>
            ))}
          </UspsContainer>
        </ShowWhen>
        <PageActions>
          <FilePicker accept=".pdf,.jpg" onPick={openItemDialog}>
            <SecondaryButton icon={UploadLine} reverted>
              {messages.uploadExistingContract}
            </SecondaryButton>
          </FilePicker>
          {!checkIfRecommendationExists(data, categoryId) ? (
            <PrimaryButton onClick={handleRequestOffer}>
              {messages.requestOffer}
            </PrimaryButton>
          ) : sent ? (
            <PrimaryButton
              onClick={() =>
                goToRecommendation(recommendationId || sent.recommendationId)
              }
            >
              {messages.openOffer}
            </PrimaryButton>
          ) : (
            <PrimaryButton disabled>{messages.offerInProgress}</PrimaryButton>
          )}
        </PageActions>
      </DefaultInsuranceDetails>
      {isOpen && (
        <UploadContractModal files={files} onClose={closeItemDialog} />
      )}
    </InsuranceDefaultViewWrapper>
  )
}
