import { returnIfMinus1 } from '@/utils'
import { indexOf, pluck } from 'ramda'

const getTabIndex = (tabs, activeKey) =>
  returnIfMinus1(0, indexOf(activeKey, pluck('key', tabs)))

export const applyTabInkStyles = (tabPanelRef, tabs, activeKey) => {
  const tabInkElement = tabPanelRef.children.tabInk
  const tabContainerElement = tabPanelRef.children.tabWrapper
  const currentTabIndex = getTabIndex(tabs, activeKey)
  const currentTabElement = tabContainerElement?.children[currentTabIndex]

  if (currentTabElement) {
    const { offsetLeft, offsetWidth } = currentTabElement

    tabInkElement.style.width = `${offsetWidth}px`
    tabInkElement.style.left = `${offsetLeft}px`
  }
}
