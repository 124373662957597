import { config } from '@/config'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

// Hook made to use the MID and PID for marketing tracking purposes

export const useMarketingCookies = () => {
  const [cookies] = useCookies()
  const [marketingPid, setMarketingPid] = useState(null)
  const [marketingMid, setMarketingMid] = useState(null)

  useEffect(() => {
    if (cookies && config.pidCookieKey && config.pidCookieKey in cookies) {
      setMarketingPid(cookies[config.pidCookieKey])
    }
    if (cookies && config.midCookieKey && config.midCookieKey in cookies) {
      setMarketingMid(cookies[config.midCookieKey])
    }
  }, [JSON.stringify(cookies)])

  return { marketingPid, marketingMid }
}
