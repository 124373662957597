import { ThemeColor } from '@/enums'
import {
  Euro,
  HardDrive,
  HomeAlt,
  Layers,
  Monitor,
  Package,
  ShieldTwoTone,
  SteeringWheel,
  Truck,
  Users,
} from '@/icons'
import PropTypes from 'prop-types'
import { Container } from './styles'

const getIcon = (category) => {
  switch (category) {
    case 'LI':
      return Users
    case 'CO':
      return Package
    case 'LE':
      return ShieldTwoTone
    case 'CA':
      return SteeringWheel
    case 'BU':
      return Layers
    case 'RE':
      return HomeAlt
    case 'CY':
    case 'MI':
      return HardDrive
    case 'TR':
      return Truck
    case 'EL':
      return Monitor
    default:
      return Euro
  }
}

export const CategoryIcon = ({ categoryId }) => {
  const Icon = getIcon(categoryId)

  return (
    <Container>
      <Icon color={ThemeColor.b50} size="button" stroke />
    </Container>
  )
}
CategoryIcon.propTypes = {
  categoryId: PropTypes.string,
}
