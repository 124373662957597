import {
  Loader,
  PageMainContent,
  BackComponent,
  Typography,
  Column,
  CompanyCardHeader,
  ButtonLink,
  CompanyCard,
  ShowWhen,
  CompanyCardItem,
  Row,
  PageWidgetContainer,
  ContactExpert,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { useCompanyPayments } from '@/services'
import {
  mapWithKey,
  returns,
  useGoToPath,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { PageTitle, ItemContainer } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()
  const commonTranslations = useTranslationSection('common')

  return {
    common: { ...useTranslationSectionObject('common') },
    menu: { ...useTranslationSectionObject('profile.menu') },
    payments: { ...useTranslationSectionObject('profile.payments') },
    snackBars: { ...useTranslationSectionObject('snackbars') },
    name: commonTranslations('name'),
    edit: commonTranslations('edit'),
    add: t('add_common'),
    missing: t('payment_details_missing'),
    accountName: t('account_name'),
    advisorText: t('advisor_text_base'),
  }
}

export const PaymentItem = ({ paymentId, name, iban }) => {
  const messages = useMessages()

  const editItem = useGoToPath(paths.editPaymentWithKey(paymentId))

  return (
    <CompanyCardItem>
      <Column gap="24px">
        {name && (
          <Row alignItems="center" justifyContent="space-between">
            <Column gap="4px">
              <Typography variant="p2Body">{name}</Typography>
              <Typography color={ThemeColor.b50} variant="badgeText">
                {messages.accountName}
              </Typography>
            </Column>
            <ButtonLink
              color={ComponentColors.primary}
              onClick={editItem}
              size="small"
            >
              {messages.edit}
            </ButtonLink>
          </Row>
        )}
        {iban && (
          <Column gap="4px">
            <Typography variant="p2Body">{iban}</Typography>
            <Typography color={ThemeColor.b50} variant="badgeText">
              IBAN
            </Typography>
          </Column>
        )}
      </Column>
    </CompanyCardItem>
  )
}

PaymentItem.propTypes = {
  iban: PropTypes.string,
  name: PropTypes.string,
  paymentId: PropTypes.string,
}

export const ProfilePaymentsList = ({ companyId, name }) => {
  const messages = useMessages()
  const { data: payments, isLoading } = useCompanyPayments(companyId)

  const addNewPayment = useGoToPath(paths.addPaymentWithKey(companyId))

  if (isLoading) return <Loader />

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <ItemContainer>
          <Typography variant="captionR">{name}</Typography>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={addNewPayment}
            size="small"
          >
            {messages.add}
          </ButtonLink>
        </ItemContainer>
      </CompanyCardHeader>
      <ShowWhen
        fallback={returns(
          <CompanyCardItem>
            <Typography color={ThemeColor.b50} variant="badgeText">
              {messages.missing}
            </Typography>
          </CompanyCardItem>,
        )}
        when={payments.length}
      >
        {mapWithKey(PaymentItem, 'paymentId', payments)}
      </ShowWhen>
    </CompanyCard>
  )
}
ProfilePaymentsList.propTypes = {
  companyId: PropTypes.string,
  name: PropTypes.string,
}

export const ProfilePayments = () => {
  const { user } = useOutletContext()
  const messages = useMessages()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.menu.payments}</Typography>
        </PageTitle>
        <Column gap="16px">
          {mapWithKey(ProfilePaymentsList, 'companyId', user?.companies)}
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
