import mixpanel from 'mixpanel-browser'
import { config } from '../config'

export const mixpanelTrack = (eventName, options = {}) => {
  if (config.mixpanelKey)
    mixpanel.track(eventName, {
      ...options,
      $current_url: window.location.host,
    })
}

export const mixpanelIdentify = (identifier) => {
  if (config.mixpanelKey) {
    mixpanel.identify(identifier)
  }
}

export const mixpanelReset = () => {
  if (config.mixpanelKey) {
    mixpanel.reset()
  }
}

export const mixpanelOptIn = () => {
  if (config.mixpanelKey) {
    mixpanel.opt_in_tracking()
  }
}

export const mixpanelOptOut = () => {
  if (config.mixpanelKey) {
    mixpanel.opt_out_tracking()
  }
}

export const initMixpanel = () => {
  if (config.mixpanelKey) {
    mixpanel.init(config.mixpanelKey, {
      track_pageview: true,
      persistence: 'localStorage',
    })
  }
}
