import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled(Column)`
  gap: ${toThemePixels(3)};
`

export const UploadedFile = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
`
