import {
  getTempToken,
  setTempAuthToken,
  setTempUnauthorizedInterceptor,
} from '@/api'
import { Loader } from '@/components'
import { useAuth } from '@/providers'
import { useValidateTempToken } from '@/services'
import { useModal } from '@/utils'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { paths } from './paths'
import { Redirect } from './Redirect'

export const TempAuthGuard = () => {
  const { logout } = useAuth()
  const readyState = useModal()
  const token = getTempToken()
  const { data, isLoading } = useValidateTempToken(token, {
    enabled: Boolean(readyState.isOpen && token),
  })
  useEffect(() => {
    setTempAuthToken(token)
    setTempUnauthorizedInterceptor(logout)
    readyState.open()
  }, [])

  if (isLoading || !readyState.isOpen) return <Loader />

  if (!token || (data && !data.valid))
    return <Redirect to={paths.authRedirect} />

  return (
    <Outlet
      context={{
        // in this case headquarters is always going to be false because SSF
        // is not meant to be used by companies with headquarters
        hasHeadquarters: false,
      }}
    />
  )
}
