import { Absolute, ButtonLink, Header, Loader, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { LeftArrow } from '@/icons'
import { useSegment } from '@/modules/analytics'
import { BackContainer } from '@/pages/SelfAssessmentPage/public/styles'
import { useKillerAnswers, useSaveKillerAnswers } from '@/services'
import { returns, snakeToCamelCase } from '@/utils'
import PropTypes from 'prop-types'
import { isEmpty, map, pluck, reject } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import {
  ButtonContainer,
  KillerQuestionsContainer,
  KillerQuestionsList,
  TitleSection,
  WidgetArea,
  WidgetContent,
} from '../styles'
import { Button } from '@surein/ui'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    killerQuestionLabels: t('recommendations.killerQuestions', {
      returnObjects: true,
    }),
    yourDeclaration: t('yourDeclaration'),
    confirmDeclarations: t('confirmDeclarations'),
    iConfirm: t('iConfirm'),
    iDoNotConfirm: t('iDoNotConfirm'),
  }
}

export const KillerQuestionsStep = ({
  closeKillerQuestions,
  killerQuestions,
  openContact,
  onNext,
  openKillerQuestions,
}) => {
  const { locationId } = useParams()
  const { cart } = useOutletContext()

  const cartItems = cart[locationId] || []

  const { data, isLoading } = useKillerAnswers(
    locationId,
    pluck('categoryId', cartItems),
  )

  const analytics = useSegment()

  const messages = useMessages()

  const saveKillerQuestionsMutation = useSaveKillerAnswers(locationId)

  const makeAnswers = (accepted) => map(returns(accepted), data)

  const handleSubmit = (answers) => {
    saveKillerQuestionsMutation.mutate(answers, {
      onSuccess: async () => {
        if (isEmpty(reject(Boolean, answers))) {
          closeKillerQuestions()
          await analytics.track('app_check_out_killerquestions_success')
          onNext()
        } else {
          closeKillerQuestions()
          await analytics.track('app_check_out_lead_gen_success')
          openContact()
        }
      },
    })
  }

  useEffect(() => {
    openKillerQuestions()
  }, [])

  if (isLoading) return <Loader />

  return (
    <Absolute left={0} right={0} top={0}>
      <Header backgroundColor={ThemeColor.b10} simple />
      <KillerQuestionsContainer>
        <WidgetArea>
          <WidgetContent>
            <BackContainer onClick={closeKillerQuestions}>
              <Typography color={ThemeColor.b50} singleLine variant="captionR">
                <LeftArrow stroke={ThemeColor.b50} />
                <span>{messages.back}</span>
              </Typography>
            </BackContainer>
            <TitleSection>
              <Typography bold variant="h4">
                {messages.yourDeclaration}
              </Typography>
              <Typography color={ThemeColor.b50} variant="captionR">
                {messages.confirmDeclarations}
              </Typography>
            </TitleSection>
            <KillerQuestionsList>
              {killerQuestions.map((question) => (
                <li key={question}>
                  <Typography color={ThemeColor.b50} variant="p1Body">
                    {messages.killerQuestionLabels[snakeToCamelCase(question)]}
                  </Typography>
                </li>
              ))}
            </KillerQuestionsList>
          </WidgetContent>
          <ButtonContainer>
            <ButtonLink onClick={() => handleSubmit(makeAnswers(false))}>
              <Typography color={ThemeColor.b50} variant="captionR">
                {messages.iDoNotConfirm}
              </Typography>
            </ButtonLink>
            <Button
              variant="primary"
              shape="square"
              loading={isLoading}
              onClick={() => handleSubmit(makeAnswers(true))}
            >
              {messages.iConfirm}
            </Button>
          </ButtonContainer>
        </WidgetArea>
      </KillerQuestionsContainer>
    </Absolute>
  )
}

KillerQuestionsStep.propTypes = {
  closeKillerQuestions: PropTypes.func,
  killerQuestions: PropTypes.arrayOf(PropTypes.string),
  onNext: PropTypes.func,
  openContact: PropTypes.func,
  openKillerQuestions: PropTypes.func,
}
