import { useEffect } from 'react'

export const useClickOutsideEvent = (ref, callback, dependencies = []) =>
  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (target && target.classList.contains('modal-mask')) {
        callback()
        return
      }
      // We need to check for HTML because otherwise focus traps will not work properly
      if (ref && !ref.contains(target) && target.nodeName !== 'HTML') {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, ...dependencies])
