import {
  __,
  assoc,
  assocPath,
  complement,
  curry,
  dissoc,
  dissocPath,
  either,
  flip,
  fromPairs,
  ifElse,
  keys,
  last,
  map,
  path,
  pick,
  pipe,
  prop,
  reduce,
  reverse,
  toPairs,
  update,
  when,
} from 'ramda'
import { notEmptyOrFalsy } from './bool'
import { returns } from './function'
import { pack } from './list'
import { isArray, isObject } from './type'

export const objectLiteral = flip(prop)

export const rename = curry((key, newKey, obj) => {
  const value = prop(key, obj)

  return pipe(dissoc(key), assoc(newKey, value))(obj)
})

export const renamePath = curry((keyPath, newKey, obj) => {
  const value = path(keyPath, obj)

  return pipe(
    dissocPath(keyPath),
    assocPath(update(-1, newKey, keyPath), value),
  )(obj)
})

export const renameBy = curry((key, func, obj) => rename(key, func(key), obj))

export const renamePathBy = curry((keyPath, func, obj) =>
  renamePath(keyPath, func(last(keyPath)), obj),
)

export const renameObjKeysBy = curry((func, obj) =>
  when(
    either(isObject, isArray),
    ifElse(
      isArray,
      map(renameObjKeysBy(func)),
      pipe(
        keys,
        reduce(
          (acc, cur) => assoc(func(cur), renameObjKeysBy(func, obj[cur]), acc),
          {},
        ),
      ),
    ),
    obj,
  ),
)

export const toObject = curry((key, value) => fromPairs([[key, value]]))

export const pickOrAll = curry((list, object) =>
  ifElse(notEmptyOrFalsy, pipe(pack, pick(__, object)), returns(object))(list),
)

export const flipObject = pipe(toPairs, map(reverse), fromPairs)

export const safeObject = when(complement(isObject), returns({}))
