import PropTypes from 'prop-types'
import { CheckoutPriceCardBase } from './BaseComponent'

export const CheckoutPriceCard = ({
  data = [],
  hideProducts,
  buttonText,
  onButtonClick,
}) => (
  <CheckoutPriceCardBase
    buttonText={buttonText}
    data={data}
    hideProducts={hideProducts}
    onButtonClick={onButtonClick}
  />
)

CheckoutPriceCard.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grossPrice: PropTypes.number,
      originalGrossPrice: PropTypes.number,
      paymentPeriod: PropTypes.string,
    }),
  ),
  hideProducts: PropTypes.bool,
  onButtonClick: PropTypes.func,
}
