import { BusinessOrgs } from '@/enums'
import { values } from 'ramda'
import * as yup from 'yup'

export const makeLastYearFinancesValidationSchema = (messages) =>
  yup.object().shape({
    approxPersonelExpenses: yup
      .number()
      .required(messages.general)
      .min(0, messages.positive)
      .typeError(messages.general),
    approxTurnover: yup
      .number()
      .required(messages.general)
      .min(0, messages.positive)
      .typeError(messages.general),
  })

export const makeBusinessTypeValidationSchema = (messages) =>
  yup.object().shape({
    businessType: yup
      .string()
      .required(messages.general)
      .test({
        name: 'isValid',
        message: messages.general,
        test: (val) => Boolean(val),
      }),
    locationSubtypes: yup.array().of(yup.string()),
    locationType: yup.string(),
    locationTypeSpecification: yup.string().when('locationType', {
      is: (locationType) => locationType === 'other',
      then: yup.string().required(messages.general),
      otherwise: yup.string(),
    }),
  })

export const makeBusinessDetailsValidationSchema = (messages) =>
  yup.object().shape({
    address: yup
      .object()
      .shape({
        city: yup.string().required(messages.city),
        country: yup.string().required(messages.country),
        postalCode: yup.string().required(messages.postalCode),
        street: yup.string().required(messages.street),
        streetNr: yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOption),
    name: yup
      .string()
      .required(messages.name)
      .test(
        'isValid',
        messages.general,
        (val) =>
          !values(BusinessOrgs).some(
            (businessOrg) => businessOrg === val.trim(),
          ),
      ),
  })

export const makeEmployeesCountValidationSchema = (messages) =>
  yup.object().shape({
    fullTimeEmployeesCount: yup.number().min(0, messages.positive),
    miniJobbersCount: yup.number().min(0, messages.positive),
    ownersCount: yup
      .number()
      .required(messages.general)
      .min(1, messages.positive)
      .typeError(messages.general),
    partTimeEmployeesCount: yup.number().min(0, messages.positive),
  })
