import { useAuth } from '@/providers'
import { paths } from './paths'
import { Redirect } from './Redirect'

export const SignupRoute = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return <Redirect to={paths.authRedirect} />

  return null
}
