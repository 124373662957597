import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { Column, Row } from '../../styled'

const showModal = keyframes`
from {
  opacity: 0;
  transform:  translate(-50%, -50%) scale(0.3);
}

50% {
  opacity: 1;
}
`
const showModalMobile = keyframes`
from {
  opacity: 0;
  transform: translate(-50%,100%);
}

50% {
  opacity: 1;
}
`

export const Container = styled(Column)`
  background-color: ${themeColor(ThemeColor.b0)};
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: ${toThemePixels(218)};
  transform: translate(-50%, -50%);
  animation: ${showModal} 400ms;
  transform-origin: center;
  box-shadow: 0px 6px 12px rgba(11, 44, 93, 0.05),
    0px 0px 12px rgba(11, 44, 93, 0.05);

  ${themeBreakpointDown(Breakpoint.sm)} {
    animation: ${showModalMobile} 400ms;
    width: 90vw;
    min-width: auto;
    max-height: 80vh;
    button {
      min-width: ${toThemePixels(40)};
    }
  }
`

export const CloseModalButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${toThemePixels(-6)};
  right: 0;
`

export const ModalContent = styled(Row)`
  align-items: center;
  overflow-y: auto;
  justify-content: space-between;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column-reverse;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  min-width: 400px;
  height: ${toThemePixels(112)};
  background-color: ${themeColor(ThemeColor.b10)};

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    height: auto;
    min-height: 30vh;
    min-width: 100%;
  }

  ${themeBreakpointDown(Breakpoint.xs)} {
    min-height: 25vh;
  }
`

export const TextContent = styled(Column)`
  padding: ${toThemePixels(12)} ${toThemePixels(16)};
  overflow-y: scroll;
  max-height: ${toThemePixels(112)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(8)};
  }
`
