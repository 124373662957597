import {
  BackComponent,
  ButtonLink,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Row,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { SuccessIconLine } from '@/icons'
import {
  mapWithKey,
  useGoToPath,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { ItemContainer, PageHeader } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()
  const poaTranslation = useTranslationSection('poaSignPage')
  const profileTranslations = useTranslationSectionObject('profile.menu')

  return {
    title: profileTranslations.signPOA,
    subtitle: poaTranslation('prePageSubtitle'),
    poaSigned: poaTranslation('poaSigned'),
    poaNotSigned: poaTranslation('poaNotSigned'),
    advisorText: t('advisor_text_base'),
  }
}

const CompanyWrapper = ({ userType, companyId, name, poaSigned }) => {
  const messages = useMessages()
  const goToCompanyPOA = useGoToPath(`/company/poa/${companyId}`)

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <ItemContainer>
          <Typography variant="captionR">{name}</Typography>
          {userType.MD && !poaSigned && (
            <ButtonLink
              color={ComponentColors.primary}
              onClick={goToCompanyPOA}
              size="small"
            >
              {messages.title}
            </ButtonLink>
          )}
        </ItemContainer>
      </CompanyCardHeader>
      <CompanyCardItem>
        <Row alignItems="center" gap="4px">
          {poaSigned && <SuccessIconLine size="tag" />}
          <Typography color={ThemeColor.b50} variant="p2Body">
            {poaSigned ? messages.poaSigned : messages.poaNotSigned}
          </Typography>
        </Row>
      </CompanyCardItem>
    </CompanyCard>
  )
}

CompanyWrapper.propTypes = {
  companyId: PropTypes.string,
  name: PropTypes.string,
  poaSigned: PropTypes.bool,
  userType: PropTypes.shape({
    MD: PropTypes.bool,
  }),
}

export const ProfilePOA = () => {
  const { user } = useOutletContext()
  const messages = useMessages()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageHeader>
          <Typography variant="h4">{messages.title} </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.subtitle}
          </Typography>
        </PageHeader>
        <Column gap="16px">
          {mapWithKey(CompanyWrapper, 'companyId', user?.companies)}
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
