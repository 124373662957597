/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import {
  Column,
  PublicPageContent,
  Row,
  Typography,
  WithGap,
} from '@/components'
import { ThemeColor } from '@/enums'
import { Container, Content, Footer } from './styles'

const bodyContent = [
  {
    title: 'Vollmacht für Versicherungsmakler',
    content: [
      '<Company Name> (folgend „Kunde“ genannt), mit der Geschäftsadresse in <Company Address>, bevollmächtigt die SureIn GmbH (haftungsbeschränkt), mit der Geschäftsadresse in der Wattstraße 11 13355 Berlin (folgend „Makler“ genannt), in allen gewerblichen Versicherungs-Angelegenheiten zu vertreten.',
      '1. Der Makler vertritt den Kunden uneingeschränkt aktiv und passiv gegenüberden Versicherern.',
      'Dies umfasst:',
      'alle notwendigen Willenserklärungen abzugeben und entgegenzunehmen, die Versicherungsverträge des Kunden (folgend „Verträge“ genannt) betreffen, neue Verträge abzuschließen, bestehende Verträge zu verwalten, bestehende Verträge zu ändern, bestehende Verträge zu kündigen, sowie die Erteilung und Widerrufe von SEPA- Lastschriftmandaten. Dies bedeutet, dass - sofern vorhanden - SureIn die Verwaltung sämtlicher gewerblicher Versicherungsverträge übernimmt.',
      'Der Makler macht von dieser Vollmacht nur Gebrauch, wenn er dies mit dem Kunden vorher abgestimmt hat. Der Kunde bleibt zu jedem Zeitpunkt der Versicherungsnehmer und Schuldner der Beiträge.',
      'Im Übrigen wird hierzu auf die Allgemeinen Geschäftsbedingungen des Versicherungsmaklers Bezug genommen.',
      '2. Der Makler darf für den Kunden alle Informationen und Auskünfte zu bestehenden Verträgen von den Versicherern uneingeschränkt abfragen und erhalten. Das umfasst auch die Einholung von Policenkopien bzw. Informationen zum Vertragsstand. Dafür muss der Kunde nicht erneut zustimmen.',
      '3. Weiterhin wird der Versicherungsmakler zur Einholung datenschutzrechtlicher Selbstauskünfte bei Versicherern gemäß § 34 Bundesdatenschutzgesetz (BDSG) und § 13 Abs. 8 Telemediengesetz (TMG) bevollmächtigt.',
      '4. Der Versicherungsmakler soll ausdrücklich, auf Wunsch des Auftraggebers, von Versicherern umgehend von sämtlichen Schreiben und Mitteilungen in Kenntnis gesetzt werden, die an den Auftraggeber versandt wurden (bspw. durch Übersendung einer Kopie). Die Originalschreiben sollen jedoch weiterhin direkt an den Auftraggeber versandt werden.',
      'Der Auftraggeber weist die Versicherer ausdrücklich an, dem Versicherungsmakler auf dessen Verlangen uneingeschränkt Auskunft zu erteilen. Sollte dabei der Auskunftserteilung eine Pflicht zur Verschwiegenheit entgegenstehen, so wird der davon Betroffene von seiner Schweigepflicht hiermit ausdrücklich entbunden.',
      '5. Der Makler ist berechtigt, mit Maklerpools zusammenzuarbeiten und darf weitere Vollmachten (Untervollmachten) an andere Personen, die von Berufs wegen zur Verschwiegenheit verpflichtet sind, vergeben. Der Makler vergibt (Unter)Vollmachten nur, damit dieser seine Aufgaben gemäß Vollmacht erfüllen kann. Untervollmachten können helfen, den Kunden besser zu betreuen.',
      '6. Wenn ein Schaden eintritt, unterstützt der Makler den Kunden bei der Regulierung. Dies gilt für Schäden, die bei folgenden Verträgen eintreten: ',
      'Alle Verträge, die der Makler dem Kunden vermittelt hat und alle Verträge, die der Makler für den Kunden verwaltet. ',
      '7.Wenn der Kunde mit einer VersicherungsAngelegenheit unzufrieden is darf der Makler für den Kunden eine Beschwerde einleiten bei:',
      '- der Bundesanstalt für Finanzdienstleistungs- aufsicht (BaFin)',
      '- Einer Ombudsstelle.',
      'Dabei ist der Makler nicht nach §181 BGB beschränkt. Das bedeutet: Der Makler kann Rechtsgeschäfte zwischen sich und dem Kunden, den der Makler vertritt, vornehmen. Der Makler muss dieses Recht aber nicht gebrauchen.',
      '8. Diese Vollmacht ist zeitlich unbegrenzt gültig. ',
      'Der Kunde kann diese Vollmacht jederzeit widerrufen. Dies gilt auch dann, wenn andere Verträge zwischen Makler und Kunden bestehen. Um diese Vollmacht zu widerrufen, muss der Kunde dem Makler ein entsprechendes Schreiben schicken, dass eigenhändig unterschrieben ist. Es genügt, wenn der Kunde das Schreiben einscannt und als pdf- Datei elektronisch an den Makler sendet.',
      '9. Wenn eine Bestimmung dieser Vollmacht unwirksam sein sollte, bleiben alle weiteren Bestimmungen wirksam.',
      '10. Bei der englischen Fassung handelt es sich um eine reine Übersetzung. Im Fall von Widersprüchen zwischen der deutschen und der englischen Fassung dieses Vertrages gilt die deutsche Fassung.',
    ],
  },
  {
    title: 'Power of attorney for insurance broker',
    content: [
      "<Company Name> (hereinafter referred to as 'Client'), with business address at <Company Address>, authorizes SureIn GmbH (haftungsbeschränkt), with business address at Wattstraße 11 13355 Berlin (hereinafter referred to as 'Broker'), to represent Client in all commercial insurance matters.",
      '1. The broker represents the customer and his Company without restriction actively and passively vis-à-vis the insurers.',
      'This includes:',
      "to make and receive all necessary declarations of intent concerning the customer's insurance contracts (hereinafter referred to as 'contracts'), to conclude new contracts, to manage existing contracts, to amend existing contracts, to terminate existing contracts, as well as the granting and revocation of SEPA direct debit mandates. This means that, if any, SureIn will manage all commercial insurance contracts.",
      'The broker shall only make use of this power of attorney if he has agreed this with the customer in advance. The customer always remains the policyholder and debtor of the premiums.',
      'Apart from that, we reference to our terms and conditions.',
      '2. The broker may request and obtain all information and details on existing contracts from the insurers on behalf of the customer without restriction. This also includes obtaining copies of policies or information on the status of the contract. The customer does not have to agree to this again. ',
      '3. Furthermore, the insurance broker is authorized to obtain data protection selfdisclosures from insurers in accordance with Section 34 of the German Federal Data Protection Act (BDSG) and Section 13 (8) of the German Telemedia Act (TMG).',
      '4. The insurance broker shall be expressly informed, at the request of the client, by insurers immediately of all letters and notices sent to the client (e.g. by sending a copy). However, the original letters shall continue to be sent directly to the Principal.',
      "The principal expressly instructs the insurers to provide the insurance broker with unrestricted information at the latter's request. Should the provision of information be contrary to a duty of confidentiality, the person concerned is hereby expressly released from his duty of confidentiality.",
      '5. The broker is authorized to cooperate with broker pools and may grant further powers of attorney (sub-authorizations) to other persons who are professionally bound to secrecy. The broker grants (sub)powers of attorney only to enable him to perform his duties in accordance with the power of attorney. Sub-authorizations can help to take better care of the client.',
      '6. If a loss occurs, the broker shall assist the client in settling the claim. This applies to damages that occur in the following contracts:',
      'all contracts that the broker has arranged for the client and all contracts that the broker manages for the client.',
      '7. if the client is dissatisfied with an insurance matter, the broker may file a complaint on behalf of the client with:',
      '- the Federal Financial Supervisory Authority (BaFin)',
      "- An ombudsman's office.",
      'Thereby the broker is not limited according to §181 BGB. This means: The broker can perform legal transactions between himself and the customer whom the broker represents. However, the broker does not have to use this right.',
      '8. This power of attorney is valid for an unlimited period.',
      'The customer can revoke this power of attorney at any time. This also applies if other contracts exist between the broker and the customer. In order to revoke this power of attorney, the customer must send the broker a letter to this effect, signed by his own hand. It is sufficient if the customer scans the letter and sends it electronically to the broker as a pdf file.',
      '9. If a provision of this power of attorney should be ineffective, all other provisions remain effective.',
      '10. The English version is only a translation. In case of discrepancies between the German and the English version of this Contract, the German version prevails.',
    ],
  },
]

export const PoaPage = () => (
  <PublicPageContent>
    <Container>
      <WithGap gap={6}>
        <Typography variant="h1">
          Power of attorney for insurance broker (German and English)
        </Typography>
        <Content>
          {bodyContent.map(({ title, content }, i) => (
            <WithGap key={i} gap={4}>
              <Typography variant="h2">{title}</Typography>
              {content.map((item, j) => (
                <Typography key={j} color={ThemeColor.b50} variant="p2Body">
                  {item}
                </Typography>
              ))}
            </WithGap>
          ))}
        </Content>
        <Footer>
          <Column>
            <strong>SureIn GmbH (haftungsbeschränkt)</strong>
            <strong>Daniel Dierkes </strong>
            <strong>Managing Director</strong>
          </Column>
          <WithGap gap={2}>
            <Row>
              <Typography color={ThemeColor.b50} variant="p1Body">
                Firma:{' '}
              </Typography>
              <strong>XXX</strong>
            </Row>
            <Row>
              <Typography color={ThemeColor.b50} variant="p1Body">
                Name:{' '}
              </Typography>
              <strong>XXX</strong>
            </Row>
            <Row>
              <Typography color={ThemeColor.b50} variant="p1Body">
                Titel:{' '}
              </Typography>
              <strong>XXX</strong>
            </Row>
            <Row>
              <Typography color={ThemeColor.b50} variant="p1Body">
                Datum:{' '}
              </Typography>
              <strong>DD.MM.YYYY</strong>
            </Row>
          </WithGap>
        </Footer>
      </WithGap>
    </Container>
  </PublicPageContent>
)
