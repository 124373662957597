import { Typography } from '@/components/atoms'
import { selectOptionValuePropType } from '@/components/atoms/Select/helpers'
import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { StyledOption } from './styles'

export const OptionNode = ({
  value,
  text,
  icon,
  onClick,
  disabled,
  onIconError,
  selected,
}) => (
  <StyledOption
    disabled={disabled}
    onClick={() => {
      if (!disabled) onClick(value)
    }}
    selected={selected}
  >
    {icon && (
      <img
        alt="option icon"
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = onIconError ?? ''
        }}
        src={icon}
      />
    )}
    <Typography variant="captionR">{text}</Typography>
  </StyledOption>
)

OptionNode.propTypes = {
  disabled: PropTypes.bool,
  icon: renderableType,
  onClick: PropTypes.func.isRequired,
  onIconError: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: selectOptionValuePropType.isRequired,
}
