import {
  BasicFormItem,
  ButtonLink,
  Column,
  FormDate,
  Input,
  SignatureCanvas,
  TrustPartners,
  Typography,
} from '@/components'
import { AssessmentStepKeys, ComponentColors, ThemeColor } from '@/enums'
import {
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
  useSkipData,
} from '@/providers'
import {
  isValidDate,
  openInNewTab,
  timestampToDateString,
  useIsMobile,
} from '@/utils'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAssessmentValues, useSaveAssessmentStep } from '../helpers'
import { StyledRow, TextWithLinks } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('signPoa_subtitle'),
    name: t('name'),
    birthdate: t('birthday'),
    poaSkipDescription: t('poaSkipDescription'),
    agreement: t('signPoa_agreement'),
    willDoLater: t('willDoLater'),
  }
}

export const PowerOfAttorneyStep = () => {
  const messages = useMessages()
  const { isMobile } = useIsMobile()
  const {
    values: { userProfile },
  } = useAssessmentValues()
  const { values, setFieldValue } = useFormContext()

  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.powerOfAttorney,
  )

  useOnNextClick(save)

  const handleButtonClick = (fn) => (e) => {
    e.stopPropagation()
    fn()
  }

  useSkipData(() => ({
    text: messages.poaSkipDescription,
    skipButton: messages.willDoLater,
  }))

  useNextDisabled(
    () =>
      !isValidDate(new Date(values.birthdate)) ||
      isNil(values.birthdate) ||
      !values.signature,
    [values],
  )

  useNextLoading(() => isLoading, [isLoading])

  useEffect(
    () => () => {
      if (values.signature) setFieldValue('signature', '')
    },
    [],
  )

  return (
    <Column alignItems="center" gap="16px">
      <Typography color={ThemeColor.b50} variant="p1Body">
        {messages.title}
      </Typography>
      <BasicFormItem component={SignatureCanvas} name="signature" />
      <StyledRow>
        <Column gap="8px">
          <Typography variant="inputLabel">{messages.name}</Typography>
          <Input
            disabled
            value={[userProfile.name, userProfile.surname].join(' ')}
          />
        </Column>
        <FormDate
          label={messages.birthdate}
          max={timestampToDateString(Date.now())}
          name="birthdate"
        />
      </StyledRow>
      <TextWithLinks>
        <Trans
          components={[
            <ButtonLink
              color={ComponentColors.primary}
              onClick={handleButtonClick(() =>
                openInNewTab('https://app.surein.de/power-of-attorney'),
              )}
            />,
            <ButtonLink
              color={ComponentColors.primary}
              onClick={handleButtonClick(() =>
                openInNewTab('https://app.surein.de/terms'),
              )}
            />,
            <ButtonLink
              color={ComponentColors.primary}
              onClick={handleButtonClick(() =>
                openInNewTab('https://app.surein.de/initial-information'),
              )}
            />,
            <ButtonLink
              color={ComponentColors.primary}
              onClick={handleButtonClick(() =>
                openInNewTab('https://app.surein.de/privacy-policy'),
              )}
            />,
          ]}
          i18nKey={messages.agreement}
        />
      </TextWithLinks>
      {isMobile && <TrustPartners mode="dark" />}
    </Column>
  )
}
