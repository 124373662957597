import { ButtonLink, FormInput, FormPhone, TextWithLink } from '@/components'
import { Categories } from '@/enums'
import {
  useFormContext,
  useNextLoading,
  useOnBackClick,
  useOnNextClick,
} from '@/providers'
import { paths } from '@/routes/paths'
import {
  deleteLocalStorageItems,
  makeUrlWithLanguage,
  mixpanelIdentify,
  mixpanelTrack,
  openInNewTab,
  propTypeFromEnumKeys,
  setLocalStorageItem,
  useLanguage,
} from '@/utils'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useAssessmentValues } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    firstName: t('firstName'),
    lastName: t('surname'),
    phoneNumber: t('phone'),
    email: t('email'),
    acceptTerms: t('acceptTerms'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(paths.terms)
const makePolicyUrl = makeUrlWithLanguage(paths.policy)

export const CreateAccountStep = ({
  goTo,
  goToStepName,
  onBack,
  recommendationProductId,
  categoryId,
  mutation,
  transformSubmitValues,
}) => {
  const language = useLanguage()
  const messages = useMessages()
  const {
    values: { createAccount: values, ...assessmentValues },
  } = useAssessmentValues()
  const { customValidate } = useFormContext()
  const localStorageKeys = [
    `${categoryId}-values`,
    `${categoryId}-current-step`,
    `${categoryId}-confirmation-id`,
  ]
  const [searchParams] = useSearchParams()

  // Marketing specific params
  const { marketingPid, marketingMid } = useOutletContext()
  const partner = searchParams.get('partner')

  const save = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: () => {
            const [name, ...surname] = values.name.split(' ')
            const formattedSurname = surname.join(' ') || '_'

            const body = {
              ...values,
              name,
              surname: formattedSurname,
              partner,
              marketingPid,
              marketingMid,
            }

            if (transformSubmitValues) {
              body.assessmentValues = transformSubmitValues(assessmentValues)
            }

            mutation.mutate(body, {
              onSuccess: ({ accessToken }) => {
                deleteLocalStorageItems(localStorageKeys)
                if (accessToken) {
                  setLocalStorageItem('access-token', accessToken)
                  mixpanelIdentify(values.email)
                  mixpanelTrack('account_created')
                } else {
                  mixpanelTrack('account_created_failed_reco', {
                    name,
                    surname: formattedSurname,
                    email: values.email,
                  })
                }
                resolve()
              },
            })
          },
          onFail: reject,
        })
      }),
    [values],
  )

  const goBack = useCallback(() => {
    if (recommendationProductId) {
      onBack()
    } else {
      goTo(goToStepName)
    }
  }, [recommendationProductId])

  useOnBackClick(goBack)

  useOnNextClick(save)

  useNextLoading(() => mutation.isLoading, [mutation.isLoading])

  return (
    <>
      <FormInput label={messages.name} name="name" />
      <FormPhone label={messages.phoneNumber} name="phone" />
      <FormInput label={messages.email} name="email" />
      <TextWithLink>
        <Trans
          components={[
            <ButtonLink
              onClick={() => openInNewTab(makeTermsUrl(language))}
              size="small"
            />,
            <ButtonLink
              onClick={() => openInNewTab(makePolicyUrl(language))}
              size="small"
            />,
          ]}
          i18nKey={messages.acceptTerms}
        />
      </TextWithLink>
    </>
  )
}

CreateAccountStep.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  goTo: PropTypes.func.isRequired,
  goToStepName: PropTypes.string.isRequired,
  mutation: PropTypes.shape({
    isLoading: PropTypes.bool,
    mutate: PropTypes.func,
  }),
  onBack: PropTypes.func.isRequired,
  recommendationProductId: PropTypes.string,
  transformSubmitValues: PropTypes.func,
}
