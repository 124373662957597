import { FormGroupCardWithSelectModal } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
  useSkipData,
} from '@/providers'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { useSaveAssessmentStep } from '../helpers'

export const useCurrentInsuranceMessages = (search) => {
  const { t } = useTranslation()

  return {
    notInsuredTitle: t('currentInsuranceNotInsuredTitle'),
    notInsuredSubtitle: t('currentInsuranceNotInsuredSubtitle'),
    selectProvider: t('selectProvider'),
    selectProviderDescription: t('selectProviderDescription'),
    change: t('change'),
    searchProvider: t('searchProvider'),
    searchCoverage: t('yearlyAssessment.searchCoverage'),
    noInsuranceFound: t('yearlyAssessment.noInsuranceFound'),
    noInsuranceFoundDescription: t(
      'yearlyAssessment.noInsuranceFoundDescription',
      { search },
    ),
    noOtherCoverage: t('noOtherCoverage'),
    contractAdded: t('yearlyAssessment.contractAdded'),
  }
}

export const CurrentInsurancesStep = () => {
  const messages = useCurrentInsuranceMessages()
  const { carriers } = useOutletContext()
  const { value } = useFormItem('insurances')
  const { save, isLoading: isSaving } = useSaveAssessmentStep(
    AssessmentStepKeys.currentInsurances,
  )

  const onSkipClick = () => save()

  useOnNextClick(save)

  useSkipData(() => ({
    text: messages.noOtherCoverage,
    skipButton: messages.noOtherCoverage,
    withModal: false,
    onSkipClick,
  }))

  useNextDisabled(() => !value || !value.length, [value])

  useNextLoading(() => isSaving, [isSaving])

  const cardOptions = useInsuranceGroups()

  return (
    <FormGroupCardWithSelectModal
      cardKey="categoryId"
      cardOptions={cardOptions}
      initialValue={[]}
      listKey="carrierId"
      listOptions={carriers}
      modalSubtitle={messages.selectProviderDescription}
      modalTitle={messages.selectProvider}
      name="insurances"
      resetText={messages.change}
      searchPlaceholder={messages.searchProvider}
    />
  )
}
