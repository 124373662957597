import { Categories, ComponentColors, ThemeColor } from '@/enums'
import { Info } from '@/icons'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import { useIsMobile } from '@/utils/hooks/useIsMobile'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Badge,
  ButtonLink,
  ImageFromCdn,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '../../atoms'
import { ShowWhen } from '../../hoc'
import { Popover } from '../../molecules'
import { Column, Divider, Row } from '../../styled'
import { insuranceCardData } from './helpers'
import {
  ImageContainer,
  InsuranceCardBottom,
  InsuranceCardTitleContainer,
  InsuranceCardTop,
  InsuranceFinderCardContainer,
} from './styles'

const useMessages = (categoryId) => {
  const categoryKey = Categories[categoryId].key

  const { t } = useTranslation()
  // FIXME: pls don't do this - it's coupling presentation layer with translations
  const cardTranslations = t(
    `insuranceFinderTranslations.insuranceFinderCards.${categoryKey}`,
    {
      returnObjects: true,
    },
  )

  return {
    name: t(`categories.${categoryKey}.name`),
    remindMe: t('insuranceFinderTranslations.remindMe'),
    complexSubject: t('insuranceFinderTranslations.complexSubject'),
    getAdvice: t('insuranceFinderTranslations.getAdvice'),
    info: t('insuranceFinderTranslations.info', { returnObjects: true }),
    ...cardTranslations,
  }
}

const InfoContent = ({ info }) => (
  <Column gap="16px">
    <Row gap="16px">
      <Typography singleLine variant="captionR">
        <Badge color={ComponentColors.danger} count=" " size="small" />
      </Typography>
      <Row>
        <Column gap="8px">
          <Typography variant="p1Body">{info.complex.title}</Typography>
          <Typography variant="p2Body">{info.complex.subtitle}</Typography>
        </Column>
      </Row>
    </Row>
    <Divider color={ThemeColor.b30} />
    <Row gap="16px">
      <Typography singleLine variant="captionR">
        <Badge color={ComponentColors.warning} count=" " size="small" />
      </Typography>
      <Row>
        <Column gap="8px">
          <Typography variant="p1Body">{info.extensive.title}</Typography>
          <Typography variant="p2Body">{info.extensive.subtitle}</Typography>
        </Column>
      </Row>
    </Row>
    <Divider color={ThemeColor.b30} />
    <Row gap="16px">
      <Typography singleLine variant="captionR">
        <Badge color={ComponentColors.success} count=" " size="small" />
      </Typography>
      <Row>
        <Column gap="8px">
          <Typography variant="p1Body">{info.easy.title}</Typography>
          <Typography variant="p2Body">{info.easy.subtitle}</Typography>
        </Column>
      </Row>
    </Row>
  </Column>
)

export const InsuranceFinderCardBase = ({
  categoryId,
  buttonComponent: ButtonComponent,
  onSnooze,
  onClick,
  hide = {},
}) => {
  const messages = useMessages(categoryId)

  const { isMobile } = useIsMobile()

  const {
    image,
    featureIcons,
    backgroundImageX,
    backgroundImageY,
  } = insuranceCardData[categoryId]

  return (
    <Column gap="8px">
      <Typography color={ThemeColor.b100} variant="captionR">
        {messages.name}
      </Typography>
      <Row justifyContent="space-between" width="100%">
        <ShowWhen when={!hide.info}>
          <Row alignItems="center" gap="8px">
            <Badge color={ComponentColors.danger} count=" " size="small" />
            <Typography singleLine variant="p2Body">
              {`${messages.complexSubject} ${
                isMobile ? '' : messages.getAdvice
              }`}
            </Typography>
            <Popover content={<InfoContent info={messages.info} />} dark>
              <Info
                color={ThemeColor.b50}
                cursor="pointer"
                fill="none"
                size="menu"
                stroke
              />
            </Popover>
          </Row>
        </ShowWhen>
        <ShowWhen when={onSnooze}>
          <ButtonLink onClick={onSnooze} size="small">
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.remindMe}
            </Typography>
          </ButtonLink>
        </ShowWhen>
      </Row>
      <InsuranceFinderCardContainer>
        <InsuranceCardTop>
          <InsuranceCardTitleContainer>
            <Typography variant="h4">{messages.title}</Typography>
            <ButtonComponent block ellipsis onClick={onClick}>
              <Typography singleLine variant="captionR">
                {messages.buttonText}
              </Typography>
            </ButtonComponent>
          </InsuranceCardTitleContainer>
          <ImageContainer
            backgroundImageX={backgroundImageX}
            backgroundImageY={backgroundImageY}
          >
            <ImageFromCdn fallbackType="jpg" name={image} type="webp" />
          </ImageContainer>
        </InsuranceCardTop>
        <ShowWhen when={!hide.features && messages.features?.length}>
          <InsuranceCardBottom>
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.subtitle}
            </Typography>
            {messages.features.map((text, i) => {
              const Icon = featureIcons[i]

              return (
                <Row gap="16px" key={text}>
                  <div>
                    <Icon color={ThemeColor.b50} size="button" stroke />
                  </div>
                  <Typography variant="p2Body">{text}</Typography>
                </Row>
              )
            })}
          </InsuranceCardBottom>
        </ShowWhen>
      </InsuranceFinderCardContainer>
    </Column>
  )
}

InsuranceFinderCardBase.propTypes = {
  buttonComponent: PropTypes.oneOf([PrimaryButton, SecondaryButton]),
  categoryId: PropTypes.string,
  hide: PropTypes.shape({
    features: PropTypes.bool,
    info: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  onSnooze: PropTypes.func,
}

export const InsuranceFinderCard = ({
  categoryId,
  primaryCta,
  onSnooze,
  hide,
}) => {
  const { locationId } = useParams()

  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({ categoryId, locationId }),
  )

  return (
    <InsuranceFinderCardBase
      buttonComponent={primaryCta ? PrimaryButton : SecondaryButton}
      categoryId={categoryId}
      hide={{
        info: !insuranceCardData[categoryId].isComplexTopic,
        ...hide,
      }}
      onClick={goToInsurance}
      onSnooze={onSnooze}
    />
  )
}

InsuranceFinderCard.propTypes = {
  categoryId: PropTypes.string,
  hide: PropTypes.shape({
    features: PropTypes.bool,
    info: PropTypes.bool,
  }),
  onSnooze: PropTypes.func,
  primaryCta: PropTypes.bool,
}

InfoContent.propTypes = {
  info: PropTypes.objectOf({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
}
