import { Collapse, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { ErrorIconLine, SuccessIconLine } from '@/icons'
import { useAttributes } from '@/services/attributes'
import { isUndefined, useLanguage, useModal } from '@/utils'
import { isEmpty } from 'ramda'
import {
  CollapseContainer,
  CollapseContent,
  InsuranceAttribute,
} from './styles'
import { useTranslation } from 'react-i18next'

export const Attributes = ({
  parametersValues,
  yesNoValues = {},
}: {
  parametersValues: {
    en: Record<string, string>
    de: Record<string, string>
  } | null
  yesNoValues: Record<string, 0 | 1>
}) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const { toggle, isOpen } = useModal()
  const {
    isLoading: isAttributesLoading,
    data: attributesData,
  } = useAttributes()

  if (isAttributesLoading || !attributesData) {
    return null
  }

  if (
    ((isEmpty(parametersValues) || isEmpty(parametersValues?.[language])) &&
      isEmpty(yesNoValues)) ||
    (!parametersValues && !yesNoValues) ||
    !parametersValues?.[language]
  ) {
    return null
  }

  const visibleAttributes = new Set([
    ...Object.keys(parametersValues[language]),
    ...Object.keys(yesNoValues ?? {}),
  ])

  return (
    <CollapseContainer>
      <Collapse
        isOpen={isOpen}
        onCollapse={toggle}
        title={t('insurancePage.importantInformation')}
      >
        <CollapseContent>
          {[...visibleAttributes.keys()].map((item) => {
            const label = attributesData.getAttributeLabel(item)
            const yesNoValue = yesNoValues[item]
            const textValue = parametersValues[language][item]

            return (
              <InsuranceAttribute key={item} column>
                <Typography color={ThemeColor.b50} ellipsis variant="captionR">
                  {label}
                </Typography>
                <Row alignItems="center" gap="4px">
                  {Boolean(yesNoValue) && <SuccessIconLine size="menu" />}
                  {!isUndefined(yesNoValue) && !yesNoValue && (
                    <ErrorIconLine size="menu" />
                  )}
                  {textValue && (
                    <Typography ellipsis variant="captionR">
                      {textValue}
                    </Typography>
                  )}
                </Row>
              </InsuranceAttribute>
            )
          })}
          <div />
        </CollapseContent>
      </Collapse>
    </CollapseContainer>
  )
}
