import {
  dynamicArrayPropType,
  oneOrManyChildren,
  parseChildrenArray,
  returns,
} from '@/utils'
import PropTypes from 'prop-types'
import { append, identity, ifElse, without } from 'ramda'
import { CheckBox, CheckBoxB } from '../../atoms'
import { Alignment } from '../../styled'

export const CheckBoxGroup = ({
  value = [],
  onChange,
  disabled,
  columns,
  alignment = 'vertical',
  children,
  hoc = identity,
  error,
}) => {
  const checkboxes = parseChildrenArray(children.filter(Boolean))

  const handleChange = (checkboxValue, onCheckboxChange) => (checked) => {
    const makeNewValue = ifElse(
      returns(checked),
      without([checkboxValue]),
      append(checkboxValue),
    )

    onChange(makeNewValue(value), checkboxValue)

    onCheckboxChange?.(!checked)
  }

  return (
    <Alignment alignment={alignment} columns={columns}>
      {checkboxes.map(
        (
          {
            key,
            disabled: checkboxDisabled,
            value: checkboxValue,
            node,
            error: checkboxError,
            onChange: onCheckboxChange,
            ...props
          },
          i,
        ) => {
          if (node.type === CheckBox)
            return hoc(
              <CheckBox
                key={key}
                checked={value.includes(checkboxValue)}
                disabled={disabled || checkboxDisabled}
                error={error || checkboxError}
                onChange={handleChange(checkboxValue, onCheckboxChange)}
                {...props}
              />,
              checkboxValue,
            )
          if (node.type === CheckBoxB)
            return hoc(
              <CheckBoxB
                key={key}
                checked={value.includes(checkboxValue)}
                disabled={disabled || checkboxDisabled}
                error={error || checkboxError}
                onChange={handleChange(checkboxValue, onCheckboxChange)}
                {...props}
              />,
              checkboxValue,
            )
          return children[i]
        },
      )}
    </Alignment>
  )
}

CheckBoxGroup.propTypes = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical', 'grid']),
  children: oneOrManyChildren.isRequired,
  columns: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hoc: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: dynamicArrayPropType.isRequired,
}
