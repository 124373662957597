import {
  PostInsuranceApiPayload,
  PostInsuranceApiResponse,
  createMultipleInsurances,
  getInsuranceById,
  updateInsurance,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'
import { useUploadFiles } from './file'

const keys = {
  insurances: 'insurances',
  insurance: 'insurance',
}

export const useInsurance = (
  id: string | undefined,
  options: { enabled?: boolean } = {},
) =>
  useQuery(
    [keys.insurance, id],
    !id ? () => undefined : () => getInsuranceById(id),
    options,
  )

const transform = (locationId: string, data: PostInsuranceApiPayload) => {
  const withLocationId = data.map((item) => ({ ...item, locationId }))
  return createMultipleInsurances(withLocationId)
}

export const useCreateMultipleInsurances = () =>
  useInvalidateOnSuccessMutation<
    PostInsuranceApiResponse,
    unknown,
    {
      locationId: string
      data: PostInsuranceApiPayload
    },
    string
  >(keys.insurances, ({ locationId, data }) => transform(locationId, data))

export const useUploadInsuranceFiles = (id: string) =>
  useUploadFiles(id, keys.insurance, 'insurance', 'location')

export const useUpdateInsurance = () => {
  return useInvalidateOnSuccessMutation(keys.insurance, ({ id, data }) =>
    updateInsurance(id, data),
  )
}
