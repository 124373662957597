import { Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRight, ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FooterContainer = styled(Row)`
  align-items: center;
  padding: 16px 8px;
  border-top: 1px solid ${themeColor(ThemeColor.glass200)};
`

interface FooterProps {
  onSkip?: () => void
  onBack?: () => void
  hideBack?: boolean
  isLoading?: boolean
}

export const Footer = ({
  onSkip,
  onBack,
  hideBack = false,
  isLoading = false,
}: FooterProps) => {
  const { t } = useTranslation()

  return (
    <FooterContainer
      justifyContent={!hideBack && onBack ? 'space-between' : 'flex-end'}
    >
      {!hideBack && onBack && (
        <Button
          variant="muted"
          shape="square"
          icon={ChevronLeft}
          onClick={onBack}
          type="button"
          loading={isLoading}
        />
      )}
      <Row>
        {!isLoading && onSkip && (
          <Button type="button" variant="link" onClick={onSkip}>
            <Typography variant="p2Body" color={ThemeColor.b50}>
              {t('common.skip')}
            </Typography>
          </Button>
        )}
        <Button
          type="submit"
          variant="default"
          suffixIcon={ArrowRight}
          loading={isLoading}
        >
          {t('common.next')}
        </Button>
      </Row>
    </FooterContainer>
  )
}
