import { ThemeColor } from '@/enums'
import { DownloadLine } from '@/icons'
import { usePreviewFile } from '@/services'
import { ddMMyyyy, useIsMobile } from '@/utils'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '../Button'
import { IconButton } from '../IconButton'
import { Typography } from '../Typography'
import { Container, FileDetails } from './styles'

export const DocumentBase = ({
  displayName,
  createdAt,
  onClick,
  iconMode = false,
}: {
  createdAt: string
  displayName: string
  iconMode: boolean
  onClick: () => void
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <FileDetails>
        <Typography ellipsis onClick={onClick} variant="captionR">
          {displayName}
        </Typography>
        <Typography color={ThemeColor.b50} ellipsis variant="p2Body">
          {ddMMyyyy(Date.parse(createdAt))}
        </Typography>
      </FileDetails>
      {iconMode ? (
        <IconButton onClick={onClick} size="medium" variant="outlined">
          <DownloadLine />
        </IconButton>
      ) : (
        <SecondaryButton icon={DownloadLine} onClick={onClick} size="medium">
          {t('download')}
        </SecondaryButton>
      )}
    </Container>
  )
}

export const Document = ({
  name,
  displayName,
  createdAt,
}: {
  name: string
  displayName: string
  createdAt: string
}) => {
  const previewFile = usePreviewFile()
  const { isMobile } = useIsMobile()

  const handleClick = () => {
    previewFile.mutate(name)
  }

  return (
    <DocumentBase
      createdAt={createdAt}
      displayName={displayName}
      iconMode={isMobile}
      onClick={handleClick}
    />
  )
}
