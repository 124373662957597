import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div.attrs({
  'data-testid': 'loader',
})`
  width: 100vw;
  height: calc(100vh - 60px);
  margin-top: 60px;
  z-index: 15000;
  position: fixed;
  left: 0;
  top: 0;
  background: ${themeColor(ThemeColor.b10)};

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
