import { CheckoutOverviewStep } from './CheckoutOverviewStep'
import { KillerQuestionsStep } from './KillerQuestionsStep'
import { PaymentMethodStep } from './PaymentMethodStep'
import { SignPoaStep } from './SignPoaStep'
import { SuccessOrderStep } from './SuccessOrderStep'

export const CheckoutStepKeys = {
  payment: 'payment',
  killerQuestions: 'killerQuestions',
  poa: 'poa',
  overview: 'overview',
  success: 'success',
}

export const checkoutSteps = {
  [CheckoutStepKeys.payment]: PaymentMethodStep,
  [CheckoutStepKeys.killerQuestions]: KillerQuestionsStep,
  [CheckoutStepKeys.poa]: SignPoaStep,
  [CheckoutStepKeys.overview]: CheckoutOverviewStep,
  [CheckoutStepKeys.success]: SuccessOrderStep,
}
