import {
  BackComponent,
  CheckBox,
  ContactExpert,
  FormInput,
  FormLocation,
  PageActionsStyles,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { FormProvider, useFormContext, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useCreateCompany } from '@/services'
import {
  useGoBack,
  useGoToPath,
  useToggable,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import { equals, omit } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxWrapper, PageHeader } from './styles'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')
  const pageTranslations = useTranslationSection('companyPage')
  const snackBArsTranslations = useTranslationSection('snackbars')
  const { t } = useTranslation()

  return {
    save: commonTranslations('save'),
    cancel: commonTranslations('cancel'),
    ...useTranslationSectionObject('companyPage.addCompanyFields'),
    title: pageTranslations('addCompanyBtn'),
    error: snackBArsTranslations('error.generalMsg'),
    onCreateSuccess: snackBArsTranslations('success.createCompany'),
    validations: useTranslationSectionObject('validations'),
    sameAsAddress: t('sameAsAddress'),
    pageTitle: t('add_new_company'),
    pageSubtitle: t('business_info'),
    advisorText: t('business_advisor_text'),
  }
}

const modifyData = (data) => ({
  company: {
    name: data.companyName,
    address: { ...data.companyAddress },
  },
  location: {
    name: data.locationName,
    address: {
      ...data.locationAddress,
    },
  },
})

export const AddCompanyPage = () => {
  const messages = useMessages()
  const createCompanyMutation = useCreateCompany()
  const snackbar = useSnackbar()
  const goToDashboard = useGoToPath(paths.dashboard)

  const handleSubmit = (values) => {
    createCompanyMutation
      .mutateAsync(modifyData(values))
      .then(() => {
        snackbar.success(messages.onCreateSuccess)
        goToDashboard()
      })
      .catch(() => snackbar.error(messages.error))
  }

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageHeader>
          <Typography variant="h4">{messages.pageTitle}</Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {messages.pageSubtitle}
          </Typography>
        </PageHeader>
        <FormProvider
          onSubmit={handleSubmit}
          validationSchema={validationSchema(messages.validations)}
        >
          <Location />
        </FormProvider>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}

const Location = () => {
  const { values, setFieldValue } = useFormContext()
  const [same, setSame] = useToggable()
  const messages = useMessages()

  const withoutAddressId = omit(['addressId', 'googleLocation'])

  const checkIfSame = equals(
    withoutAddressId(values.companyAddress),
    withoutAddressId(values.locationAddress),
  )

  const sameAsAddress = () => {
    setFieldValue('locationAddress', {
      city: values.companyAddress.city,
      country: values.companyAddress.country,
      postalCode: values.companyAddress.postalCode,
      street: values.companyAddress.street,
      streetNr: values.companyAddress.streetNr,
    })
  }

  const handleSameSelect = (e) => {
    setSame(!e)
    if (!e) {
      sameAsAddress()
    } else {
      setFieldValue('locationAddress', {
        city: '',
        country: '',
        postalCode: '',
        street: '',
        streetNr: '',
      })
    }
  }

  const handleCancel = useGoBack()

  useEffect(() => {
    if (values.companyAddress?.street) {
      if (same && !checkIfSame) setSame(false)

      if (!same && checkIfSame) setSame(true)
    }
  }, [values.companyAddress, values.locationAddress])

  return (
    <>
      <FormInput label={messages.companyName.label} name="companyName" />
      <FormLocation
        label={messages.companyAddress.label}
        name="companyAddress"
      />
      <FormInput label={messages.locationName.label} name="locationName" />
      <CheckboxWrapper>
        <CheckBox
          checked={same}
          label={messages.sameAsAddress}
          onChange={handleSameSelect}
        />
      </CheckboxWrapper>
      <FormLocation
        label={messages.locationAddress.label}
        name="locationAddress"
      />
      <PageActionsStyles>
        <SecondaryButton onClick={handleCancel}>
          {messages.cancel}
        </SecondaryButton>
        <PrimaryButton type="submit" variant="contained">
          {messages.save}
        </PrimaryButton>
      </PageActionsStyles>
    </>
  )
}
