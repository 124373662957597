import { mergeLeft, omit } from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { warn } from '../environment'
import { getLocalStorageItem } from '../storage'
import { isFunction } from '../type'

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = getLocalStorageItem(key)

      return item || initialValue
    } catch (_error) {
      return initialValue
    }
  })

  const setValue = (value, stringify = true) => {
    try {
      const valueToStore = isFunction(value) ? value(storedValue) : value

      setStoredValue(valueToStore)
      localStorage.setItem(
        key,
        stringify ? JSON.stringify(valueToStore) : valueToStore,
      )
    } catch (error) {
      warn(error)
    }
  }

  return [storedValue, setValue]
}

export const useLocalStorageObject = ({ parent, key, initialValue }) => {
  const [object, setObject] = useLocalStorage(parent, {})

  const value = useMemo(() => object[key] || initialValue, [key, object])

  const setValue = useCallback(
    (newValue) => {
      setObject(mergeLeft({ [key]: newValue }))
    },
    [key],
  )
  const clearValue = useCallback(() => setObject(omit([key])), [key])

  return {
    value,
    setValue,
    clearValue,
  }
}
