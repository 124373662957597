import { updateAddress } from '@/api'
import { useMutation } from 'react-query'
import { getGeocode } from 'use-places-autocomplete'

export const useUpdateAddress = (id) =>
  useMutation(({ street, streetNr, postalCode, city, country }) =>
    updateAddress({
      id,
      street,
      streetNr,
      postalCode,
      city,
      country,
    }),
  )

export const useAddress = (options) =>
  useMutation((placeId) => getGeocode({ placeId }), options)
