import { Loader } from '@/components'
import { paths } from '@/routes/paths'
import { useGetTinkConnections } from '@/services/integrations'
import { useGoToPath } from '@/utils'
import { useEffect } from 'react'
import { Container } from './styles'

export const IntegrationsRedirectFailed = () => {
  const { data: connections } = useGetTinkConnections()
  const locationId = connections?.connections?.[0]?.metadata?.locationId
  const source = connections?.connections?.[0]?.source
  const goToIntegrations = useGoToPath(
    paths.integrations,
    {},
    {
      bankingIntegrationsError: true,
    },
  )
  const goToYearlyCheck = useGoToPath(
    paths.viewYearlyCheckWithKey(locationId),
    { withIntegrations: true },
    { bankingIntegrationsError: true },
  )

  const redirectUser = () => {
    if (source === 'yearly-check-in') {
      goToYearlyCheck()
    } else if (source === 'profile-integrations') {
      goToIntegrations()
    } else {
      goToIntegrations()
    }
  }

  useEffect(() => {
    if (connections?.connections?.length) {
      redirectUser()
    }
  }, [connections])

  return (
    <Container>
      <Loader />
    </Container>
  )
}
