import * as Yup from 'yup'

export const locationValidationSchema = () =>
  Yup.object().shape({
    locationData: Yup.object().shape({
      companyId: Yup.string().required('Company is required'),
      locationId: Yup.string().required('Location is required'),
      poaSigned: Yup.boolean().required('POA is required'),
    }),
  })

export const insuranceCategoryValidationSchema = () =>
  Yup.object().shape({
    categoryId: Yup.string().required('Category is required'),
  })

export const insuranceCarrierValidationSchema = () =>
  Yup.object().shape({
    carrierId: Yup.string().required('Carrier is required'),
  })
