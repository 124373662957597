import {
  FormCheckBox,
  Loader,
  FormInputField,
  StyledMessage,
} from '@/components'
import { ThemeColor } from '@/enums'
import { StyledLinkTypography } from '@/pages/SelfAssessmentPage/steps/styles'
import { FormProvider, useContactModal, useFormContext } from '@/providers'
import {
  usePublicCheckoutOverview,
  usePublicCheckoutPaymentMethod,
  useSavePublicCheckoutPaymentMethod,
  useUpdatePublicCheckoutPaymentMethod,
} from '@/services'
import { theme } from '@/theme'
import { useLanguage } from '@/utils'
import { Alert } from '@surein/ui'
import { Calendar, FileText, InfoIcon, Lock } from 'lucide-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { makePaymentsValidationSchema } from './validationSchema'

const StyledLink = styled(StyledLinkTypography)`
  font-weight: 700;
`
const useMessages = () => {
  const { t } = useTranslation()
  return {
    general: t('validations.general'),
    sepa: t('sepa'),
    invalidIban: t('profile.payments.invalidIban'),
    noneGermanIban: t('profile.payments.noneGermanIban'),
    authorizeSepa: t('profile.payments.authorizeSepaErrorLabel'),
  }
}

export const PaymentMethodForm = ({ setButtonState, startDate }) => {
  const { t } = useTranslation()
  const messages = useMessages()
  const language = useLanguage()
  const { openContact } = useContactModal()

  const { submitForm, errors, customValidate } = useFormContext()

  useEffect(() => {
    setButtonState({
      onClick: () =>
        customValidate({
          onSuccess: submitForm,
        }),
    })
  }, [])

  const date = new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(startDate)

  return (
    <div className="flex flex-col gap-4">
      {errors?.iban && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={
            <Trans
              components={
                <StyledLink onClick={openContact} variant="captionB" />
              }
              i18nKey={t('profile.payments.ibanAlertLabel')}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}

      <div className="flex flex-col gap-4">
        <FormInputField
          infoMessage={t('profile.payments.firstPaymentDateLabel', {
            date,
          })}
          label={t('profile.payments.name.label')}
          name="name"
        />
        <FormInputField
          label={t('profile.payments.iban.label')}
          name="iban"
          showValidCheck
          validateOnChange
        />

        {errors?.sepa && (
          <Alert
            icon={InfoIcon}
            iconAlignment="top"
            text={messages.authorizeSepa}
            variant="danger"
          />
        )}

        <FormCheckBox id="sepa" label={messages.sepa} name="sepa" />
      </div>
    </div>
  )
}

PaymentMethodForm.propTypes = {
  setButtonState: PropTypes.func,
  startDate: PropTypes.number,
}

const StaticPaymentInfo = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <FileText size={16} />
        <StyledMessage color={ThemeColor.b100}>
          {t('profile.payments.getCertificateInfoLabel')}
        </StyledMessage>
      </div>
      <div className="flex gap-1">
        <Calendar size={16} />
        <StyledMessage color={ThemeColor.b100}>
          {t('profile.payments.cancelInfoLabel')}
        </StyledMessage>
      </div>
      <div className="flex gap-1">
        <Lock color={theme.color[ThemeColor.success1]} size={16} />
        <StyledMessage color={ThemeColor.success1}>
          {t('profile.payments.securedInfoLabel')}
        </StyledMessage>
      </div>
    </div>
  )
}

export const PublicPaymentMethodStep = ({ onNext, setButtonState }) => {
  const messages = useMessages()
  const { data, isLoading } = usePublicCheckoutPaymentMethod()
  const { data: overviewData } = usePublicCheckoutOverview()

  const createPaymentMethodMutation = useSavePublicCheckoutPaymentMethod()
  const updatePaymentMethodMutation = useUpdatePublicCheckoutPaymentMethod(
    data?.paymentId,
  )

  const handleSubmit = ({ name, iban }) => {
    const mutation = data?.paymentId
      ? updatePaymentMethodMutation
      : createPaymentMethodMutation

    mutation.mutate({ name, iban }, { onSuccess: onNext })
  }

  useEffect(() => {
    setButtonState({
      loading:
        createPaymentMethodMutation.isLoading ||
        updatePaymentMethodMutation.isLoading,
    })
  }, [
    createPaymentMethodMutation.isLoading,
    updatePaymentMethodMutation.isLoading,
  ])

  if (isLoading) return <Loader />

  return (
    <div className="flex flex-col gap-6">
      <FormProvider
        initialValues={{ ...data, sepa: Boolean(data?.paymentId) }}
        onSubmit={handleSubmit}
        validationSchema={makePaymentsValidationSchema(messages)}
      >
        <PaymentMethodForm
          setButtonState={setButtonState}
          startDate={overviewData?.startDate}
        />
      </FormProvider>
      <StaticPaymentInfo />
    </div>
  )
}

PublicPaymentMethodStep.propTypes = {
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
