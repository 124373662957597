import { Column, Row } from '@/components'
import { Breakpoint } from '@/enums'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const PageContainer = styled(Column)`
  gap: ${toThemePixels(8)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-top: ${toThemePixels(4)};
  }
`

export const InsurancesContainer = styled(Column)`
  gap: ${toThemePixels(4)};
  margin-top: ${toThemePixels(4)};
`

export const InsurancesGrid = styled.div`
  display: grid;
  grid-template-columns: ${ifProp({ length: 1 }, '1fr', '1fr 1fr')};
  margin-top: ${toThemePixels(4)};
  gap: ${toThemePixels(8)};

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

export const InsurancesHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    border-radius: 50%;
    object-fit: cover;
  }
`

export const AddinsuranceContainer = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const CompanyInsuranceCategoryContainer = styled.div`
  margin-top: ${toThemePixels(4)};
  margin-bottom: ${toThemePixels(8)};
`
