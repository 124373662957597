import { ShowWhen } from '@/components/hoc'
import { ThemeColor } from '@/enums'
import {
  dynamicObjectPropType,
  fromEventTarget,
  includesIgnoringCase,
  useStateObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { assoc, filter, intersection, pipe, pluck, reduce, reject } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckBox, InputWithAddon, Typography } from '../../atoms'
import { BasicFormItem, CheckBoxGroup, Collapse } from '../../molecules'
import { Column, DividerWithText } from '../../styled'
import { CollapseContainer, CollapseContent } from './styles'

const CheckboxSection = ({ value = [], onChange, options }) => {
  const sectionValues = value.filter((key) =>
    pluck('value', options).includes(key),
  )

  const otherSectionValues = value.filter((key) => !sectionValues.includes(key))

  return (
    <CheckBoxGroup
      alignment="vertical"
      onChange={(newValue, changedOption) => {
        onChange([...otherSectionValues, ...newValue], changedOption)
      }}
      value={sectionValues}
    >
      {options.map((checkbox) => (
        <CheckBox
          key={checkbox.value}
          label={checkbox.text}
          value={checkbox.value}
        />
      ))}
    </CheckBoxGroup>
  )
}

CheckboxSection.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.string,
}

const isSelected = (value) => (panel) =>
  Boolean(intersection(value || [], pluck('value', panel.options)).length)

const makeDefaultOpened = (value, options) =>
  pipe(
    filter(isSelected(value)),
    pluck('value'),
    reduce((acc, cur) => assoc(cur, true, acc), {}),
  )(options)

export const MultiCollapseSelect = ({
  value = [],
  onChange,
  options,
  searchPlaceholder,
  dividerText,
  onSearch,
}) => {
  const [search, setSearch] = useState('')
  const [openedPanels, dispatchOpenedPanels, closePanels] = useStateObject(
    makeDefaultOpened(value, options),
  )

  const hasSelectedOptions = useCallback(isSelected(value), [value])

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (item) =>
          hasSelectedOptions(item) ||
          includesIgnoringCase(search, item.text) ||
          item.options.find((it) => includesIgnoringCase(search, it.text)),
      ),
    [JSON.stringify(options), search, hasSelectedOptions],
  )

  const handleChange = (newValue, lastChangedOption) => {
    const panels = pluck(
      'value',
      reject(isSelected([...newValue, lastChangedOption]), filteredOptions),
    )
    closePanels(...panels)
    onChange(newValue)
  }

  useEffect(() => {
    if (search.length > 2) {
      dispatchOpenedPanels(
        filteredOptions.reduce((acc, cur) => assoc(cur.value, true, acc), {}),
      )
    } else {
      const panels = pluck('value', reject(hasSelectedOptions, filteredOptions))
      closePanels(...panels)
    }
    onSearch?.(search)
  }, [search])

  return (
    <Column gap="16px">
      <InputWithAddon
        addonBefore={() => <span>🔍</span>}
        onChange={fromEventTarget(setSearch)}
        placeholder={searchPlaceholder}
        rounded
        value={search}
      />
      <ShowWhen when={dividerText && !search}>
        <DividerWithText>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {dividerText}
          </Typography>
        </DividerWithText>
      </ShowWhen>
      {filteredOptions.map((item) => (
        <CollapseContainer>
          <Collapse
            divide
            isOpen={openedPanels[item.value]}
            onCollapse={() => {
              if (!hasSelectedOptions(item)) {
                if (openedPanels[item.value]) {
                  closePanels(item.value)
                } else {
                  dispatchOpenedPanels({ [item.value]: true })
                }
              }
            }}
            title={item.text}
          >
            <CollapseContent>
              <CheckboxSection
                onChange={handleChange}
                options={item.options.filter(
                  (it) =>
                    value.includes(it.value) ||
                    includesIgnoringCase(search, item.text) ||
                    includesIgnoringCase(search, it.text),
                )}
                value={value || []}
              />
            </CollapseContent>
          </Collapse>
        </CollapseContainer>
      ))}
    </Column>
  )
}

export const FormMultiCollapseSelect = (props) => (
  <BasicFormItem component={MultiCollapseSelect} {...props} initialValue={[]} />
)

MultiCollapseSelect.propTypes = {
  dividerText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  searchPlaceholder: PropTypes.string,
  value: dynamicObjectPropType,
}
