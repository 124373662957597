import { ifNotProp, ifProp } from 'styled-tools'

export const ifNotProps = (props, whenTrue, whenFalse) => {
  const [prop, ...rest] = props

  if (!rest.length) return ifNotProp(prop, whenTrue, whenFalse)

  return ifNotProp(prop, ifNotProps(rest, whenTrue, whenFalse))
}

export const ifProps = (props, whenTrue, whenFalse) => {
  const [prop, ...rest] = props

  if (!rest.length) return ifProp(prop, whenTrue, whenFalse)

  return ifProp(prop, ifProps(rest, whenTrue, whenFalse))
}
