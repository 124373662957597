import {
  AdvisorBubble,
  BasicFormItem,
  Column,
  FormDatepicker,
  FormInputField,
  Row,
  SignatureCanvas,
} from '@/components'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { Trans, useTranslation } from 'react-i18next'
import { useContactModal, useFormContext, useSnackbar } from '@/providers'
import { Alert } from '@surein/ui'
import { InfoIcon } from 'lucide-react'
import { StyledLink } from '@/components/molecules/ContactInfo/styles'
import styled from 'styled-components'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import { Breakpoint } from '@/enums'
import { useSignPoa } from '@/services'
import { useOutletContext } from 'react-router-dom'

const StyledRow = styled(Row)`
  width: 100%;
  gap: ${toThemePixels(4)};

  > * {
    width: calc(50% - ${toThemePixels(3)});

    input {
      width: 100%;
    }
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    gap: ${toThemePixels(1)};

    > * {
      width: 100%;
    }
  }
`

interface SignPOAFormValues {
  signature?: string
  fullname?: string
  birthdate?: Date
}

const FormContainer = styled(Column)`
  gap: 16px;
  margin-bottom: 24px;
`

const SignPOAForm = () => {
  const { t } = useTranslation()
  const { errors } = useFormContext<SignPOAFormValues>()
  const { openContact } = useContactModal()

  return (
    <FormContainer>
      {errors.signature && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          // @ts-expect-error fix type in toolkit
          text={
            <Trans
              // @ts-expect-error fix type
              components={<StyledLink onClick={openContact} />}
              i18nKey={t('signPoaErrorHint')}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}
      <AdvisorBubble text={t('poaAdvisorBubbleText')} />
      <Column gap="4px">
        <BasicFormItem
          component={SignatureCanvas}
          name="signature"
          showErrorHelperText={false}
        />
        <StyledRow>
          {
            // @ts-expect-error fix type
            <FormInputField label={t('name')} name="fullname" showValidCheck />
          }
          {
            // @ts-expect-error fix type
            <FormDatepicker
              label={t('birthday')}
              max={new Date()}
              name="birthdate"
              showCalendar={false}
            />
          }
        </StyledRow>
      </Column>
    </FormContainer>
  )
}

export const SignPOAStep = () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { onNext, companyData, setCompanyData } = useStepper()
  const { user } = useOutletContext<{
    user: {
      fullname: string
    }
  }>()

  const signPoaMutation = useSignPoa(companyData!.companyId)

  const handleNext = async (values: SignPOAFormValues) => {
    try {
      await signPoaMutation.mutateAsync({
        fullname: values.fullname,
        birthdate: values.birthdate,
        signature: values.signature,
      })
      if (!companyData) {
        snackbar.error(t('snackbars.error.generalMsg'))
        return
      }
      setCompanyData({ ...companyData, poaSigned: true })
    } catch (_error) {
      snackbar.error(t('snackbars.error.generalMsg'))
      return
    }

    onNext(values)
  }

  return (
    <StepLayout
      hideBack
      title={t('addInsuranceContractsSteps.signPoa.title')}
      subtitle={t('addInsuranceContractsSteps.signPoa.subtitle')}
      onNext={handleNext}
      isLoading={signPoaMutation.isLoading}
      initialValues={{
        fullname: user.fullname,
      }}
    >
      <SignPOAForm />
    </StepLayout>
  )
}
