import {
  GetRecommendationByIdApiResponse,
  GetSelfAssessmentApiResponse,
} from '@/api'
import { Column, Typography } from '@/components'
import { config } from '@/config'
import { ThemeColor } from '@/enums'
import { useComparisonTable } from '@/pages/RecommendationPage/hooks/useComparisonTable'
import { useRiskAdditionalInfo } from '@/pages/RecommendationPage/hooks/useRiskAdditionalInfo'
import { useContactModal } from '@/providers'
import { useAttributes } from '@/services/attributes'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import { useIsMobile } from '@/utils'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'
import { ComparisonTableOld, ContactBanner, Skeleton } from '@surein/ui'
import { BadgeCheck, MessageCircleMore, User2 } from 'lucide-react'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CompaniesTrust } from './CompaniesTrust'
import { Faqs } from './Faqs'
import { IndependentBroker } from './IndependentBroker'
import { Products } from './Products'
import { TestimonialSection } from './TestimonialSection'
import { TopActions } from './TopActions'
import { WhatIsProtected } from './WhatIsProtected'
import {
  formatRiskAddress,
  prepareRecommendation,
  useMessages,
} from './helpers'
import {
  AbsoluteWrapper,
  CollapsibleAdvisorContentWrapper,
  ComparisonTableCard,
  FaqsWrapper,
  FlexWrapper,
  RecommendationTitle,
  RiskInsuredSpacer,
  RiskInsuredTypography,
} from './styles'

const RisksInsured = ({
  categoryId,
  assessmentValues = {},
}: {
  categoryId: string
  assessmentValues?: {
    businessType?: {
      locationType: string
      locationSubtypes: string[]
    }
    companyDetails?: {
      address: {
        street: string
        streetNumber?: string | undefined
        streetNr?: string | undefined
        postalCode: string
        city: string
      }
    }
  }
}) => {
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()
  // As discussed with the BE, this component is not meant to last long
  // and will be replaced by a more dynamic BE handling in the future
  // @ts-expect-error TODO: fix this - no idea why this could be an array
  if (assessmentValues.length === 0) return null

  if (categoryId === 'CO' && !!assessmentValues?.companyDetails?.address) {
    return (
      <RiskInsuredTypography variant="p1Body">
        <Trans
          i18nKey="recommendationRisksInsured"
          values={{
            risk: formatRiskAddress(assessmentValues.companyDetails.address),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  if (
    assessmentValues?.businessType?.locationType &&
    assessmentValues?.businessType?.locationSubtypes?.length > 0
  ) {
    return locationTypesLoading ? (
      <Skeleton center count={2} withParagraphEnding />
    ) : (
      <RiskInsuredTypography variant="p1Body">
        {t('analyzedOffers')}
        <br />
        <Trans
          count={assessmentValues.businessType.locationSubtypes.length}
          i18nKey="recommendationRisksInsuredWithSubtypes"
          values={{
            risk: locationTypesData?.getLocationLabel(
              assessmentValues.businessType.locationType,
            ),
            subtypes: assessmentValues.businessType.locationSubtypes
              .map((item) => locationTypesData?.getLocationLabel(item))
              .join(', '),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  if (assessmentValues?.businessType?.locationType) {
    return locationTypesLoading ? (
      <Skeleton center count={2} withParagraphEnding />
    ) : (
      <RiskInsuredTypography variant="p1Body">
        {t('analyzedOffers')}
        <br />
        <Trans
          i18nKey="recommendationRisksInsured"
          values={{
            risk: locationTypesData?.getLocationLabel(
              assessmentValues.businessType.locationType,
            ),
          }}
        />
      </RiskInsuredTypography>
    )
  }

  return null
}

const collapsibleAdvisorContentIcons = [User2, BadgeCheck, MessageCircleMore]

interface CollapsibleAdvisorContentProps {
  advisorName: string
}

const CollapsibleAdvisorContent = ({
  advisorName,
}: CollapsibleAdvisorContentProps) => {
  const { t } = useTranslation()
  const content: string[] = t(
    `contactCarouselContent.${advisorName}.features`,
    {
      returnObjects: true,
    },
  )

  return (
    <Column gap="12px">
      {content.map((item, index) => {
        const Icon = collapsibleAdvisorContentIcons[index]
        return (
          <CollapsibleAdvisorContentWrapper key={item}>
            <Icon color={theme.color[ThemeColor.info1]} size={24} />
            <Typography key={content[index]} variant="p2Body">
              {item}
            </Typography>
          </CollapsibleAdvisorContentWrapper>
        )
      })}
    </Column>
  )
}

const MemoizedRisksInsured = memo(RisksInsured)

interface BasePageProps {
  assessmentValues?: GetSelfAssessmentApiResponse['values']
  bannerOffset?: number
  data: GetRecommendationByIdApiResponse
  handleFileClick: (file: { fileId: string; name: string }) => void
  noBack?: boolean
  onBack?: () => void
  onChoose: (id: string) => void
}

export const BasePage = ({
  assessmentValues,
  bannerOffset,
  data,
  onChoose,
  onBack,
  noBack,
  handleFileClick,
}: BasePageProps) => {
  const { t } = useTranslation()
  const messages = useMessages(data.categoryId)
  const {
    isLoading: isLoadingAttributes,
    data: attributesData,
  } = useAttributes()
  const { isMobile } = useIsMobile()
  const { items } = useComparisonTable(data, handleFileClick)

  const { categoryKey } = useCategoryTranslations(data.categoryId)

  const { additionalInfo } = useRiskAdditionalInfo(
    assessmentValues,
    categoryKey,
  )
  const { openContact } = useContactModal()

  if (isLoadingAttributes || !attributesData) {
    return null
  }

  const labels = [
    {
      label: t('insured_sum'),
      annotationText: t('info.amountInsured'),
    },
    {
      label: t('deductible'),
      annotationText: t('info.deductible'),
    },
    ...(data.visibleAttributes || []).map((attr) => ({
      label: attributesData.getAttributeLabel(attr),
      annotationText: attributesData.getAttributeDescription(attr),
    })),
    {
      label: t('insurance_details'),
    },
  ]

  return (
    <AbsoluteWrapper bannerOffset={bannerOffset}>
      <FlexWrapper>
        <TopActions
          categoryId={data.categoryId}
          noBack={noBack}
          onBack={onBack}
        />
        <Column gap="16px">
          <RecommendationTitle data-testid="recommendation-title">
            <Typography bold breakWord variant="h2">
              {messages.offerTitle}
            </Typography>
          </RecommendationTitle>
          <CompaniesTrust />
        </Column>
        <RiskInsuredSpacer>
          <MemoizedRisksInsured
            assessmentValues={assessmentValues}
            categoryId={data.categoryId}
          />
        </RiskInsuredSpacer>
        <Products data={prepareRecommendation(data)} onChoose={onChoose} />
        <ComparisonTableCard className="px-4" id="details" variant="borderless">
          <ComparisonTableOld
            additionalInfo={additionalInfo}
            items={items}
            labels={labels}
            onCTAClick={(selectedItem) => onChoose(selectedItem.id)}
            tableLabel={t('recommendations.title')}
          />
        </ComparisonTableCard>
        <Column gap="16px">
          <WhatIsProtected categoryId={data.categoryId} />
          <ContactBanner
            buttonText={t('recommendations.contactBannerBtn')}
            collapsibleContent={
              <CollapsibleAdvisorContent advisorName={'daniel'} />
            }
            collapsibleTriggerText={t(
              'recommendations.contactBannerCollapsibleTriggerText',
            )}
            imageUrl={`${config.cdn}/chatblock.png`}
            onButtonClick={openContact}
            subtitle={t('recommendations.contactBannerSubtitle')}
            title={t('recommendations.contactBannerTitle')}
          />
        </Column>

        <IndependentBroker />

        <TestimonialSection isMobile={isMobile} />
      </FlexWrapper>
      <FaqsWrapper>
        <Faqs />
      </FaqsWrapper>
    </AbsoluteWrapper>
  )
}
