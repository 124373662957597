import { toPixels } from '@/utils/convert'
import { concat } from '@/utils/string'
import { last, keys as objectKeys } from 'ramda'

const MIN = 'min'
const MAX = 'max'

const baseMedia = (bound) => `@media (${bound}-width:`

export const createBreakpoints = (breakpoints) => {
  const keys = objectKeys(breakpoints)

  const up = (key) => {
    const value = breakpoints[key] || key
    return concat(baseMedia(MIN), toPixels(value), ')')
  }

  const down = (key) => {
    const value = breakpoints[key] || key
    return concat(baseMedia(MAX), toPixels(value), ')')
  }

  const between = (start, end) => {
    if (end === last(keys)) {
      return up(start)
    }

    return concat(
      up(start),
      ' and (max-width:',
      toPixels(breakpoints[end] || end),
      ')',
    )
  }

  const only = (key) => between(key, key)

  return {
    keys,
    values: breakpoints,
    up,
    down,
    between,
    only,
  }
}
