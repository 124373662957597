import styled, { keyframes } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'
import { ButtonBase } from '../ButtonBase'

const getSize = switchProp(
  'size',
  {
    small: '32px',
    medium: '40px',
    large: '52px',
  },
  '40px',
)

const showOptions = keyframes`
from {
  opacity: 0;
}

50% {
  opacity: 1;
}
`

export const OptionsContainer = styled.div<{ isOpen: boolean }>`
  display: ${ifProp('isOpen', 'flex', 'none')};
  flex-direction: column;
  gap: 6px;
  margin: 0;
  padding: 0;
  position: absolute;
  animation: ${showOptions} 0.4s;
  z-index: 1000;
  > :first-child {
    margin-top: 6px;
  }
`

export const ButtonAction = styled(ButtonBase)`
  position: relative;
  display: none;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${getSize};
  width: ${getSize};
  max-width: ${getSize};
  min-width: ${getSize};
`
