import { oneChild, renderableType, useModal } from '@/utils'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { Typography } from '../../atoms'
import { Row } from '../../styled'
import { useFloatPositioning } from '../Tooltip/helpers'
import { Container, Content, StyledClose } from './styles'

export const Popover = ({ content, children, dark }) => {
  const popoverRef = useRef()
  const elementRef = useRef()

  const { isOpen, toggle } = useModal()

  useFloatPositioning({
    isOpen,
    ref: popoverRef,
    relatedToRef: elementRef,
    noRepositionTop: true,
  })

  return (
    <Container dark={dark} isOpen={isOpen}>
      {isOpen &&
        createPortal(
          <Content ref={popoverRef} dark={dark}>
            <Row justifyContent="end">
              <StyledClose onClick={toggle} size="tag" />
            </Row>
            {content}
          </Content>,
          document.body,
        )}
      <Typography ref={elementRef} onClick={toggle} variant="p2Body">
        {children}
      </Typography>
    </Container>
  )
}

Popover.propTypes = {
  children: oneChild.isRequired,
  content: renderableType.isRequired,
  dark: PropTypes.string,
}
