import { Column, Flex, ImageFromCdn, Row, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { LeftLine } from '@/icons'
import {
  themeBreakpointDown,
  themeBreakpointUp,
  themeColor,
  themeColorFrom,
  themeTransition,
  toThemePixels,
} from '@/utils'
import { Card } from '@surein/ui'
import { prop } from 'ramda'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 48px;

  ${themeBreakpointUp(Breakpoint.sm)} {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 16px;
  }
`

export const FaqsWrapper = styled.div`
  margin-top: 32px;
  background-color: #141e33;
`

export const AbsoluteWrapper = styled.div<{ bannerOffset?: number }>`
  background-color: ${themeColor(ThemeColor.glass100)};
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -${toThemePixels(4)};
  padding-top: calc(${(props) => (props.bannerOffset || 0) + 48}px);
`

export const RecommendationTitle = styled(Column)`
  gap: ${toThemePixels(4)};
  align-items: center;
  text-align: center;
  padding: 0 16px;
  max-width: 800px;
  margin: 0 auto;

  > span:first-child {
    text-transform: capitalize;
  }
`

export const RecommendationOffersCard = styled.div`
  border-radius: ${toThemePixels(3)};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
  background-color: ${themeColor(ThemeColor.b0)};
  overflow: hidden;
  grid-column: col-start 3 / span 8 !important;
  height: fit-content;

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-column: col-start / span 2 !important;
    margin: 0 ${toThemePixels(6)};
  }
`

export const RecommendationPriceSection2 = styled(Row)`
  gap: ${toThemePixels(1)};
  align-items: center;
  margin-bottom: ${toThemePixels(2)};
`

export const RecommendationPriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toThemePixels(1)};

  > span:first-child {
    text-decoration: line-through;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
  }
`

export const ProductsContainer = styled.div<{ columns: number }>`
  display: grid;
  gap: 24px;
  grid-template-columns: 30% 35% 30%;
  align-items: center;

  > div {
    height: 100%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const ProductCard = styled(Card)`
  height: 100%;
`

export const ProductWrapper = styled(Row)`
  height: 100%;
`

export const Product = styled(Column)`
  position: relative;
  width: 100%;
  gap: ${toThemePixels(8)};
  padding: ${toThemePixels(4)};
  height: 100%;
  justify-content: space-between;

  img {
    border-radius: ${toThemePixels(2.5)};
    height: ${toThemePixels(12.5)};
    width: ${toThemePixels(12.5)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
  }
`

export const ComparisonSection = styled(Column)`
  background-color: ${ifProp(
    'primary',
    themeColor(ThemeColor.info4),
    themeColor(ThemeColor.b0),
  )};
  width: 100%;
  align-items: center;
`

export const ComparisonSectionHeader = styled(Row)`
  gap: ${toThemePixels(2)};
  padding: ${toThemePixels(2)};
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  border: ${ifProp('primary', 'none')};
  width: 100%;
  background-color: ${ifProp('dark', themeColor(ThemeColor.b10))};

  > * {
    cursor: ${ifProp('primary', 'pointer')};
  }
`

export const ComparisonRow = styled(Row)`
  width: 100%;
  padding: 0 ${toThemePixels(2)};
  gap: ${toThemePixels(2)};
  align-items: center;
  justify-content: center;
  text-align: center;

  ${themeBreakpointDown(Breakpoint.xs)} {
    padding: 0 ${toThemePixels(1)};
    gap: ${toThemePixels(1)};
  }
`

export const DocumentsRow = styled(Row)`
  width: 100%;
  padding: ${toThemePixels(5)} 0;
  align-items: start;

  > * {
    max-width: 33.3%;
    justify-content: center;
  }

  button {
    max-width: 100%;
  }
`

export const DocumentsContainer = styled(Column)`
  gap: ${toThemePixels(2)};
  aling-items: center;
  padding: 0 ${toThemePixels(2)};
  width: 100%;
`

export const ComparisonItem = styled(Row)`
  padding: ${toThemePixels(5)} 0;
  justify-content: center;
  width: 100%;
  word-break: break-word;
`
export const CollapseIcon = styled(LeftLine).attrs({
  size: 'info',
})`
  transform: ${ifProp('$isOpen', 'rotate(90deg)', 'rotate(270deg)')};
  transition: ${themeTransition('transform')};

  svg {
    fill: ${themeColor(ThemeColor.b50)};
  }
`

export const InsuranceDetailsHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  gap: ${toThemePixels(4)};
  padding: ${toThemePixels(7)} ${toThemePixels(4)};
  background-color: ${themeColor(ThemeColor.info4)};
`

export const TrustConfessionSection = styled(Column)`
  gap: ${toThemePixels(6)};
  align-items: baseline;
  padding: ${toThemePixels(10)} 0;
  grid-column: col-start 4 / span 6;

  * {
    margin: 0;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-template-columns: repeat(2, [col-start] 1fr);
    grid-column: col-start / span 2 !important;
    padding: ${toThemePixels(6)};
  }
`

export const AdvertisementSection = styled.div`
  grid-column: col-start 1 / span 12;
  background-color: ${themeColor(ThemeColor.b10)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: ${toThemePixels(10)} calc(100vw / 12);
  gap: ${toThemePixels(5)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-template-columns: repeat(1, 1fr);
    padding: ${toThemePixels(10)} ${toThemePixels(6)};
  }
`

export const AdvertisementCard = styled(Column)`
  width: 100%;
  padding: ${toThemePixels(6)} ${toThemePixels(4)};
  border-radius: ${toThemePixels(3)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  gap: ${toThemePixels(4)};
  background-color: ${themeColor(ThemeColor.b0)};
  border: ${ifProp('withoutBorder', 'none')};
`

export const AdvertisementTitle = styled(Row)`
  height: ${toThemePixels(9)};
  gap: ${prop('gap')};
  align-items: center;
`

export const FooterSection = styled.div`
  grid-column: col-start / span 12;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${themeColor(ThemeColor.b0)};
  padding: ${toThemePixels(15)} calc(100vw / 12);
  align-items: center;

  img {
    width: 320px;
    height: auto;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-template-columns: repeat(1, 1fr);
    padding: ${toThemePixels(10)} ${toThemePixels(6)};
    gap: ${toThemePixels(8)};

    > *:first-child {
      order: 1;
    }
  }
`

export const OfferValiditySection = styled.div`
  text-align: center;
  margin-top: ${toThemePixels(8)};

  ${themeBreakpointUp(Breakpoint.sm)} {
    grid-column: 1 / span 3;
  }
`

export const SearchCriteriaContainer = styled(Column)`
  background: ${themeColor(ThemeColor.b0)};
  gap: ${toThemePixels(5)};

  @media (min-width: 970px) {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: ${toThemePixels(3)};
    padding: ${toThemePixels(6)};
  }
`

export const SectionTitle = styled(Row)`
  padding: ${toThemePixels(2)} 0;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
`

export const DiscountPlaceholder = styled.div`
  &::after {
    content: '.';
    visibility: hidden;
    line-height: 140%;
  }
`

export const Highlight = styled(Row)`
  align-items: center;
  gap: ${toThemePixels(1)};
  > span {
    gap: ${toThemePixels(1)};
  }
  & svg {
    margin-right: 4px;
    flex-shrink: 0;
  }
`

export const ProductSection = styled(Column)`
  gap: ${toThemePixels(6)};
  align-items: center;
`

export const ProductCardsContainer = styled.div`
  grid-column: col-start 2 / span 10 !important;
  height: fit-content;
  margin: ${toThemePixels(6)} 0;
  width: 100%;

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-column: col-start / span 2 !important;
  }

  ${themeBreakpointDown(Breakpoint.tab)} {
    margin: 0;
  }

  > div > div {
    overflow-x: hidden;
  }

  > div > div:nth-child(2) {
    height: 107.5%;
  }
`

export const BestOfferBadge = styled(Row)`
  background-color: ${themeColor(ThemeColor.glass100)};
  color: ${themeColor(ThemeColor.info1)};
  border-radius: 16px;
  padding: ${toThemePixels(1)} ${toThemePixels(3)};
  text-align: center;
  font-weight: 500;
  gap: 4px;
  align-items: center;
  border: ${ifProp('isBestChoice', '1px solid #0D5DFF')};

  svg {
    flex-shrink: 0;
  }

  span {
    white-space: nowrap;
  }
`

export const MobileCardFooter = styled(Flex)`
  gap: ${toThemePixels(3)};
  flex-direction: column;

  ${themeBreakpointDown(Breakpoint.md)} {
    > span {
      text-align: center;
    }
  }
`

export const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${toThemePixels(6)};
  margin-top: ${toThemePixels(6)};
  grid-column: col-start 2 / span 10 !important;
  height: fit-content;

  ${themeBreakpointDown(Breakpoint.tab)} {
    grid-template-columns: 1fr;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    padding: 0 16px;
  }

  > div {
    overflow-x: hidden;
  }
`

export const FaqContainer = styled(Column)`
  gap: ${toThemePixels(6)};
  padding: 64px 16px;
  background-color: #141e33;
  align-items: center;

  button,
  div,
  span {
    text-align: start;
    color: white;
    border: none;
  }

  button > div {
    background-color: rgba(54, 60, 69, 0.4);
  }

  > div {
    width: 100%;
    max-width: 1000px;
  }

  svg {
    stroke: white;
  }
`

export const ProtectionItem = styled(Row)`
  gap: ${toThemePixels(2)};
  align-items: center;
  > svg {
    flex-shrink: 0;
  }
`

export const CompaniesTrustImagesContainer = styled(Row)`
  gap: ${toThemePixels(6)};
  justify-content: center;
  align-items: center;

  > img {
    flex: 1;
    height: 27px;
  }

  > img:last-child {
    height: auto;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-wrap: wrap;
    > img {
      max-width: 70px;
    }
  }
`

export const WhatIsProtectedSection = styled(Column)`
  gap: 16px;

  ${themeBreakpointDown(Breakpoint.md)} {
    padding: 0 16px;
  }
`

export const TopActionsContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  gap: ${toThemePixels(4)};
  padding: 0 ${toThemePixels(2)};

  span,
  svg,
  button {
    align-items: center;
    font-weight: 500;
    color: ${themeColor(ThemeColor.b50)};
    stroke: ${themeColor(ThemeColor.b50)};
  }

  button:hover:not(:disabled) {
    color: ${themeColor(ThemeColor.b50)};
    svg {
      stroke: ${themeColor(ThemeColor.b50)};
    }
  }
`

export const ComparisonTableCard = styled.div<{ variant: string }>`
  scroll-margin-top: 60px;
  background-color: ${themeColor(ThemeColor.b0)};
  border-radius: 16px;
  padding: 16px;

  table {
    table-layout: auto;
  }
`

export const HeaderCancellationNotice = styled(Row)`
  padding: ${toThemePixels(2)} 0;
  width: 100vw;
  background-color: #141e33;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100000;
  top: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const HeaderArea = styled.div<{ headerOffset: number }>`
  > div:nth-child(2) {
    margin-top: ${prop('headerOffset')}px;
  }
`

export const RiskInsuredTypography = styled(Typography)`
  text-align: center;
  font-weight: 500;
`

export const RiskInsuredSpacer = styled(Row)`
  justify-content: center;
  margin-top: 24px;
`

export const CompaniesTrustContainer = styled(Column)`
  align-items: center;
  gap: ${toThemePixels(4)};

  span {
    font-weight: 500;
  }
`

export const ProductFooter = styled(Row)`
  justify-content: space-between;
  align-items: center;
  gap: ${toThemePixels(2)};
`

export const StarRating = styled(Row)`
  gap: ${toThemePixels(1)};
  align-items: center;
  cursor: pointer;

  > div > svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    > div > svg {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
  }
`

export const IndependentBrokerContainer = styled(Column)`
  align-items: center;
  padding-bottom: 64px;
`

export const IndependentBrokerTextContainer = styled(Column)`
  gap: ${toThemePixels(6)};
  align-items: center;

  span {
    text-align: center;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    padding: 0 16px;
  }
`

export const RatingTooltipFootnote = styled(Typography)`
  font-weight: 500;
`

export const AbsoluteImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 387px;
  overflow: hidden;
`

export const StyledImageFromCdn = styled(ImageFromCdn)`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
`

export const CollapsibleAdvisorContentWrapper = styled(Row)`
  gap: ${toThemePixels(2)};

  > svg {
    flex-shrink: 0;
  }
`

export const SizeContainer = styled.div<{
  backgroundColor: string
  noSectionPadding?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: ${ifProp('noSectionPadding', '0', '64px 0px')};
  background-color: ${themeColorFrom(prop('backgroundColor'))};
  overflow-x: hidden;
  position: relative;

  & > div {
    width: 100%;
    ${themeBreakpointUp(Breakpoint.sm)} {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`

export const RecommendationAbsoluteWrapper = styled(AbsoluteWrapper)`
  background-color: ${themeColor(ThemeColor.glass100)};
`

export const RecommendationCarouselCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${ifProp('noPadding', '0', '16px')};
  background-color: ${themeColor(ThemeColor.b0)};
`

export const ExamplesTitleContainer = styled(Column)`
  gap: 8px;
  padding: 0 16px;
  align-items: center;
  > span {
    max-width: 375px;
  }
`

export const CountdownRow = styled(Row)`
  gap: 4px;
  align-items: center;
  cursor: ${ifProp('withTooltip', 'pointer', 'default')};
`

export const TestimonialCardWrapper = styled.div`
  > div {
    background: ${themeColor(ThemeColor.b0)};
  }
`
