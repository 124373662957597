import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Row } from '../../styled'

export const StyledButton = styled(Row)`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor(ThemeColor.b0)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  user-select: none;
  transition: 0.2s;
  &:hover {
    border-color: ${ifProp('disabled', 'none', themeColor(ThemeColor.b100))};
  }

  &:active {
    span {
      color: ${ifProp('disabled', 'none', themeColor(ThemeColor.b100))};
    }
  }
`
