import { FormItem } from './Component'

export const BasicFormItem = ({
  ignoreErrorKeys,
  label,
  component: Component,
  initialValue,
  info,
  helperText,
  layout,
  noMargin,
  ...props
}: {
  component: React.ComponentType<any>
  ignoreErrorKeys?: string[]
  label?: string
  initialValue?: any
  info?: string
  helperText?: string
  layout?: 'horizontal' | 'vertical'
  noMargin?: boolean
  [key: string]: any
}) => (
  <FormItem
    helperText={helperText}
    ignoreErrorKeys={ignoreErrorKeys}
    info={info}
    initialValue={initialValue}
    label={label}
    layout={layout}
    noMargin={noMargin}
    {...props}
  >
    <Component {...props} />
  </FormItem>
)
