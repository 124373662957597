import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { InputWithAddon } from '../InputWithAddon'
import { NumberInputBase } from '../NumberInputBase'
import { Typography } from '../Typography'

export const UnitInput = ({ unit, ...props }) => (
  <InputWithAddon
    addonAfter={
      <Typography color={ThemeColor.b50} variant="p1Body">
        {unit}
      </Typography>
    }
    inputComponent={NumberInputBase}
    {...props}
  />
)

UnitInput.propTypes = {
  unit: PropTypes.string,
}
