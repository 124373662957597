import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { Typography } from '../../atoms'
import { StyledCard } from './styles'

export const CardNode = ({ value, text, subtext, onClick }) => (
  <StyledCard onClick={() => onClick({ value, text })}>
    <Typography variant="captionB">{text}</Typography>
    <Typography color={ThemeColor.b50} variant="p2Body">
      {subtext}
    </Typography>
  </StyledCard>
)

CardNode.propTypes = {
  onClick: PropTypes.func,
  subtext: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
