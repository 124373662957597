import { api } from './api'

export type VerticalsMap = Record<string, string[]>
type LocationsLabels = Record<string, string>
type VerticalsLabels = Record<string, string>

export const getLocationTypes = async (language = 'de') => {
  const data = await api.get(`/public/locations?language=${language}`)

  const locationsLabels = Object.values(data).reduce<LocationsLabels>(
    (acc, val) => ({ ...acc, ...val.locationTypes }),
    {},
  )

  const verticalsLabels = Object.entries(data).reduce<VerticalsLabels>(
    (acc, [verticalCode, { label }]) => ({ ...acc, [verticalCode]: label }),
    {},
  )

  const verticalsMap = Object.entries(data).reduce<VerticalsMap>(
    (acc, [verticalCode, { locationTypes }]) => ({
      ...acc,
      [verticalCode]: Object.keys(locationTypes),
    }),
    {},
  )

  return {
    getLocationLabel(code: string) {
      return locationsLabels[code] ?? null
    },
    getVerticalLabel(code: string) {
      return verticalsLabels[code] ?? null
    },
    verticalsMap,
    locationsLabels,
  }
}

type LocationRankingItem = {
  locationType: string
  ratio: number
}

export const getLocationRanking = async () => {
  const data = (await api.get(
    '/public/locations/ranking',
  )) as LocationRankingItem[]

  return {
    data,
    getLocationTypeRatio(code: string) {
      return data.find((item) => item.locationType === code)?.ratio ?? 0
    },
  }
}
