import { updateAddress } from './address'
import { api } from './api'

export const getCompanies = () => api.get('/company')

export const createCompany = ({ company, location }) =>
  api.post('/company', { company, location })

export const updateCompany = (id, { name }) =>
  api.put(`/company/${id}`, { name })

export const updateCompanyWithAddress = ({ id, name, address }) =>
  Promise.all([updateAddress(address), updateCompany(id, { name })])

export const createCompanyUser = (id, { name, surname, email, phone, isMd }) =>
  api.post(`/company/add-user/${id}`, { name, surname, email, phone, isMd })

export const updateCompanyUser = (id, { name, surname, phone }) =>
  api.put(`/user/${id}`, { name, surname, phone })

export const removeCompanyUser = (auth0, companyId) =>
  api.delete(`/company/deleteUserFromCompany/${auth0}/${companyId}`)

export const getCompanyById = (id) => api.get(`/company/${id}`)

export const getUsersByCompanyId = (id) =>
  api.get(`/company/getCompanyUsers/${id}`)

export const deleteCompany = (id) => api.delete(`/company/${id}`)

export const signPoa = ({ companyId, signature, birthdate }) =>
  api.post(`/home/poa-signature/${companyId}`, { signature, birthdate })

export const getContractsOverview = () => api.get('/company/insurances')
