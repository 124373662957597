import { curry, forEach } from 'ramda'

export const getLocalStorageItem = curry((key) => {
  const item = localStorage.getItem(key)
  try {
    const jsonItem = JSON.parse(item)
    return jsonItem
  } catch (_e) {
    return item
  }
})

export const setLocalStorageItem = curry((key, data) =>
  localStorage.setItem(key, JSON.stringify(data)),
)

export const deleteLocalStorageItem = (key) => localStorage.removeItem(key)

export const deleteLocalStorageItems = forEach(deleteLocalStorageItem)

export const clearLocalStorage = () => localStorage.clear()

export const getSessionStorageItem = curry((key) => {
  const item = sessionStorage.getItem(key)
  try {
    const jsonItem = JSON.parse(item)
    return jsonItem
  } catch (_e) {
    return item
  }
})

export const updateSessionStorageItem = curry((key, data) =>
  sessionStorage.setItem(key, JSON.stringify(data)),
)

export const setSessionStorageItem = curry((key, data) => {
  if (!getSessionStorageItem(key)) updateSessionStorageItem(key, data)
})

export const deleteSessionStorageItem = (key) => sessionStorage.removeItem(key)

export const clearSessionStorage = () => sessionStorage.clear()
