import {
  BackComponent,
  ContactExpert,
  FormCheckBox,
  FormInput,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { transform } from '@/pages/EditCompanyPage/helpers'
import { FormProvider, useSnackbar } from '@/providers'
import { usePayment, useUpdatePayment } from '@/services'
import {
  useTranslationSection,
  useTranslationSectionObject,
  useGoBack,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { makeInitialValues } from './helpers'
import { PageActions, PageTitle } from './styles'
import { usePaymentMethodValidation } from './usePaymentMethodValidation'

const useMessages = () => {
  const { t } = useTranslation()
  const commonTranslations = useTranslationSection('common')

  return {
    cancel: commonTranslations('cancel'),
    save: commonTranslations('save'),
    general: { ...useTranslationSectionObject('common') },
    snackBars: { ...useTranslationSectionObject('snackbars') },
    payments: { ...useTranslationSectionObject('profile.payments') },
    validations: useTranslationSectionObject('validations'),
    advisorText: t('advisor_text_base'),
  }
}

const PageForm = ({ data }) => {
  const { paymentId } = useParams()
  const snackbar = useSnackbar()
  const goBack = useGoBack()
  const createUpdatePaymentMutation = useUpdatePayment(paymentId)
  const messages = useMessages()
  const validationSchema = usePaymentMethodValidation()

  const handleSubmit = (values) => {
    createUpdatePaymentMutation
      .mutateAsync({ ...values, priority: values.priority ? 1 : 0 })
      .then(() => {
        snackbar.success(messages.snackBars.success.updatePayment)
        goBack()
      })
      .catch(() => {
        snackbar.error(messages.snackBars.error.general)
        goBack()
      })
  }

  return (
    <FormProvider
      initialValues={makeInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <FormInput label={messages.payments.name.label} name="name" />
      <FormInput label={messages.payments.iban.label} name="iban" />
      <PageActions>
        <FormCheckBox label={messages.payments.isStandard} name="priority" />
      </PageActions>
      <PageActions>
        <SecondaryButton onClick={goBack}>{messages.cancel}</SecondaryButton>
        <PrimaryButton type="submit">{messages.general.save}</PrimaryButton>
      </PageActions>
    </FormProvider>
  )
}
export const EditPaymentPage = () => {
  const { paymentId } = useParams()
  const messages = useMessages()
  const { data, isLoading } = usePayment(paymentId, { select: transform })

  if (isLoading) return <Loader />

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.payments.editPayment}</Typography>
        </PageTitle>
        <PageForm data={data} />
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}

PageForm.propTypes = {
  data: PropTypes.shape({
    company: PropTypes.shape({
      companyId: PropTypes.string,
      name: PropTypes.string,
    }),
    iban: PropTypes.string,
    name: PropTypes.string || null,
    paymentId: PropTypes.string,
    priority: PropTypes.number || PropTypes.bool,
  }),
}
