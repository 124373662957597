import { isDev, noop } from '@/utils'
import PropTypes from 'prop-types'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const makeQueryConfig = () => {
  const cacheConfig = { onError: noop }

  return new QueryClient({
    defaultOptions: {
      queries: {
        /**
         * @todo ¯\_(ツ)_/¯ react-query refetches data once they're marked
         * as stale to keep server state as up-to-date as possible. If we
         * don't care about how many requests are fired, we can remove
         * this. Otherwise I think we should use Infinity so we have to
         * manually ask for new data.
         */
        staleTime: 500,
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
    queryCache: new QueryCache(cacheConfig),
    mutationCache: new MutationCache(cacheConfig),
  })
}

export const ReactQueryProvider = ({ children }) => {
  const client = makeQueryConfig()

  return (
    <QueryClientProvider client={client}>
      {children}
      {isDev() && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
  queryClientConfig: PropTypes.shape({
    onError: PropTypes.func,
  }),
}
