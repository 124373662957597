import PropTypes from 'prop-types'
import { config } from '../../../config'

export const CarrierLogo = ({ name, ...rest }) => (
  <img
    alt={name}
    onError={({ currentTarget }) => {
      // eslint-disable-next-line no-param-reassign
      currentTarget.onerror = null
      // eslint-disable-next-line no-param-reassign
      currentTarget.src = `${config.cdn}/${config.genericCarrierLogo}`
    }}
    src={`${config.cdn}/${name}`}
    {...rest}
  />
)

CarrierLogo.propTypes = {
  name: PropTypes.string,
}
