import { parseChildrenArray } from '@/utils'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { selectChildrenPropType } from '../Select'
import { selectOptionValuePropType } from '../Select/helpers'
import { Typography } from '../Typography'
import { OptionNode } from './OptionNode'
import { Container } from './styles'

export const YesNoSelect = ({
  children,
  disabled,
  error,
  onSelect,
  value = [],
}) => {
  const options = parseChildrenArray(children)

  const selected = equals(value)

  return (
    <Container>
      {options.map(
        ({
          key,
          value: optionValue,
          children: text,
          disabled: optionDisabled,
        }) => (
          <OptionNode
            key={key}
            disabled={disabled || optionDisabled}
            error={error}
            onClick={onSelect}
            selected={selected(optionValue)}
            value={optionValue}
            width={100 / options.length - (options.length - 1)}
          >
            <Typography ellipsis variant="placeholder">
              {text}
            </Typography>
          </OptionNode>
        ),
      )}
    </Container>
  )
}

YesNoSelect.propTypes = {
  children: selectChildrenPropType,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  value: selectOptionValuePropType,
}
