import { dynamicObjectPropType, timestampToDateString } from '@/utils'
import dayjs from 'dayjs'
import locale from 'dayjs/locale/cs'
import weekday from 'dayjs/plugin/weekday'
import PropTypes from 'prop-types'
import { eqBy } from 'ramda'
import { useMemo, useState } from 'react'
import { Typography } from '../../atoms'
import { MonthSelect } from '../MonthSelect'
import { makeDates } from './helpers'
import { Container, DateCell, DatesContainer } from './styles'

dayjs.extend(weekday)
dayjs.locale(locale)

export const Calendar = ({
  value,
  onChange,
  minDate = new Date('1900'),
  maxDate = new Date(),
}) => {
  const date = useMemo(() => {
    if (value || value === 0) return new Date(value)

    return new Date()
  }, [value])

  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())

  const days = useMemo(() => makeDates({ month, year, minDate, maxDate }), [
    month,
    year,
    minDate,
    maxDate,
  ])

  return (
    <Container>
      <MonthSelect
        maxDate={maxDate}
        minDate={minDate}
        onChange={(val) => {
          if (val.month && val.month !== month) {
            setMonth(val.month)
          }
          if (val.year && val.year !== year) {
            setYear(val.year)
          }
        }}
        value={{ month, year }}
      />
      <DatesContainer>
        {days.map((item) =>
          item.value !== '' ? (
            <DateCell
              disabled={item.disabled}
              onClick={() => {
                if (!item.disabled) onChange(item.value)
              }}
              selected={eqBy(timestampToDateString, item.value, value)}
            >
              <Typography variant="p1Body">{item.text}</Typography>
            </DateCell>
          ) : (
            <div />
          ),
        )}
      </DatesContainer>
    </Container>
  )
}

Calendar.propTypes = {
  maxDate: dynamicObjectPropType,
  minDate: dynamicObjectPropType,
  onChange: PropTypes.func,
  value: PropTypes.number,
}
