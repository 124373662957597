import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

const AppContext = {
  SSF: 'ssf',
}

/**
 * Use if you want to wrap a component (including Route) and add Sentry tags
 */
const WithSentryTags = ({ element, tags }) => {
  useEffect(() => {
    Sentry.setTags(tags)
  })

  return element
}

export const WithSSFSentryTags = (element) => (
  <WithSentryTags
    element={element}
    tags={{
      appContext: AppContext.SSF,
    }}
  />
)
