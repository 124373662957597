import { ArrowLeftLine, ArrowRightLine } from '@/icons'
import { oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { IconButton } from '../../atoms'
import { Absolute, Relative, Row } from '../../styled'
import { CarouselItem, Container, Step, CarouselViewport } from './styles'

export const Carousel = ({ children, width, autoPlayDuration }) => {
  const [active, setActive] = useState(0)
  const ref = useRef()

  const handleChange = (i) => {
    const carousel = ref.current
    let newActive = i

    if (i < 0) {
      newActive = -1
    }
    if (i > children.length - 1) {
      newActive = children.length
    }
    carousel.style.transition = 'transform 500ms ease'

    setActive(newActive)

    if ([-1, children.length].includes(newActive)) {
      setTimeout(() => {
        carousel.style.transition = ''
        if (newActive === -1) {
          setActive(children.length - 1)
        } else {
          setActive(0)
        }
      }, 500)
    }
  }

  const handleNext = () => {
    handleChange(active + 1)
  }

  const handlePrev = () => {
    handleChange(active - 1)
  }

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  })

  let timeout
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }

    if (autoPlayDuration) {
      timeout = setTimeout(() => {
        handleChange(active + 1)
      }, autoPlayDuration)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [active, autoPlayDuration])

  return (
    <Relative>
      <Container $width={width}>
        <CarouselViewport
          $active={-1 * (active + 1)}
          $width={width}
          {...handlers}
          ref={ref}
        >
          <CarouselItem>{children[children.length - 1]}</CarouselItem>
          {children.map((item) => (
            <CarouselItem>{item}</CarouselItem>
          ))}
          <CarouselItem>{children[0]}</CarouselItem>
        </CarouselViewport>
      </Container>
      <Absolute left="-60px" top="50%">
        <IconButton onClick={handlePrev} size="small" variant="outlined">
          <ArrowLeftLine />
        </IconButton>
      </Absolute>
      <Absolute right="-60px" top="50%">
        <IconButton onClick={handleNext} size="small" variant="outlined">
          <ArrowRightLine />
        </IconButton>
      </Absolute>
      <Absolute bottom="-20px" left={0} right={0}>
        <Row gap="4px" justifyContent="center">
          {children.map((_, i) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <Step // eslint-disable-line jsx-a11y/no-static-element-interactions
              onClick={() => {
                handleChange(i)
              }}
              selected={i === active}
            />
          ))}
        </Row>
      </Absolute>
    </Relative>
  )
}

Carousel.propTypes = {
  autoPlayDuration: PropTypes.number,
  children: oneOrManyChildren.isRequired,
  width: PropTypes.number.isRequired,
}
