import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const Container = styled(Column)`
  padding: 0 ${toThemePixels(6)};
  gap: ${toThemePixels(6)};

  button {
    margin-top: ${toThemePixels(2)};
  }

  @media (max-width: 900px) {
    padding-bottom: ${toThemePixels(6)};
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    background-color: ${themeColor(ThemeColor.b10)};
    border-top: 1px solid ${themeColor(ThemeColor.b30)};
    padding: ${toThemePixels(6)};
  }
`

export const MobileContainer = styled(Column)`
  width: 100%;
`
