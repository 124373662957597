import { maybeRenderInPortal, oneChild, useClickOutsideEvent } from '@/utils'
import PropTypes from 'prop-types'
import { cloneElement, useState } from 'react'
import { Mask } from '../styled'

export const WithMask = ({
  absolute,
  isOpen,
  children,
  closable,
  onClose,
  shouldRenderInPortal = true,
  noAnimation,
}) => {
  const [ref, setRef] = useState()

  const handleMaskClick = () => {
    if (closable) onClose()
  }

  useClickOutsideEvent(ref, handleMaskClick)

  if (!isOpen) return null

  return maybeRenderInPortal(
    <Mask absolute={absolute} className="modal-mask" noAnimation={noAnimation}>
      {cloneElement(children, { ref: setRef })}
    </Mask>,
    document.body,
    shouldRenderInPortal,
  )
}
WithMask.propTypes = {
  absolute: PropTypes.bool,
  children: oneChild,
  closable: PropTypes.bool,
  isOpen: PropTypes.bool,
  noAnimation: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  shouldRenderInPortal: PropTypes.bool,
}
