import { FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

export const useLastYearFinancesMessages = () => {
  const { t } = useTranslation()
  return {
    approxTurnover: t('lastYearFinancesApproxTurnover'),
    approxPersonelExpenses: t('lastYearFinancesApproxPersonelExpenses'),
    approxTurnoverInfo: t('approxTurnoverInfo'),
    approxPersonelExpensesInfo: t('approxPersonelExpensesInfo'),
    approxCostOfGoods: t('lastYearFinancesApproxCostOfGoods'),
  }
}

export const LastYearFinancesStep = () => {
  const messages = useLastYearFinancesMessages()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.lastYearFinances,
  )

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <>
      <FormUnit
        // info={messages.approxTurnoverInfo}
        label={messages.approxTurnover}
        name="approxTurnover"
        unit="€"
      />
      <FormUnit
        // info={messages.approxCostOfGoodsInfo}
        label={messages.approxCostOfGoods}
        name="approxCostOfGoods"
        unit="€"
      />
      <FormUnit
        // info={messages.approxPersonelExpensesInfo}
        label={messages.approxPersonelExpenses}
        name="approxPersonelExpenses"
        unit="€"
      />
    </>
  )
}
