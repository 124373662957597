import { Column } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Column)`
  width: 100vw;
  margin-left: -${toThemePixels(6)};
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  padding: 0 ${toThemePixels(6)};
  background-color: ${themeColor(ThemeColor.b10)};
`
export const ContentWrapper = styled.div`
  gap: ${toThemePixels(8)};
  ${themeBreakpointDown(Breakpoint.sm)} {
    gap: ${toThemePixels(10)};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  text-align: center;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toThemePixels(4)};
`

export const EnvelopeContainer = styled.div`
  font-size: ${toThemePixels(30)};
`

export const StyledColumn = styled(Column)`
  width: 100%;
  gap: ${toThemePixels(2)};
`
