import { both, complement, compose, curry, is, pipe, sortBy } from 'ramda'
import { timestampToDateString } from './convert'
import { coercedIsNaN } from './type'

export const currentYear = () => new Date().getFullYear()

export const safeTimestamp = (date) => Date.parse(new Date(date))

export const safeDateString = pipe(safeTimestamp, timestampToDateString)

export const sortDate = sortBy(safeTimestamp)

export const sortDateBy = curry((fn, list) =>
  sortBy(compose(safeTimestamp, fn))(list),
)

export const getYear = (date) => date.getFullYear()

export const isValidDate = both(is(Date), complement(coercedIsNaN))

export const daysInFuture = (days) =>
  new Date(new Date().setDate(new Date().getDate() + days))

export const getTomorrow = () => daysInFuture(1)
