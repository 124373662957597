import { useTranslation } from 'react-i18next'

export const useMessages = (search) => {
  const { t } = useTranslation()

  return {
    total: t('total'),
    insurance: t('insurance'),
    startDate: t('startDate'),
    nextPayment: t('nextPayment'),
    information: t('information'),
    insuranceNumber: t('insuranceNumber'),
    cost: t('cost'),
    year: t('year'),
    paymentPeriodOptions: t('paymentPeriodOptions', { returnObjects: true }),
    contractsOverview: t('contractsOverview'),
    searchByCompanyOrLocation: t('searchByCompanyOrLocation'),
    noMatchInContractsSearch: search
      ? t('noMatchInContractsSearch', { search })
      : t('noContractsDescription'),
    noContractsTitle: t('noContractsTitle'),
    exportCsv: t('exportCsv'),
  }
}

// Needed so that the mobile table has the right sorting

export const reorderCellsForMobile = (tableState) => {
  const data = [...tableState]
  const contractIndex = data.findIndex((obj) => obj.id === 'contract')
  const grossPriceIndex = data.findIndex((obj) => obj.id === 'grossPrice')

  if (contractIndex !== -1 && grossPriceIndex !== -1) {
    const contractObj = data[contractIndex]
    const grossPriceObj = data[grossPriceIndex]

    data.splice(contractIndex, 1)
    data.splice(grossPriceIndex - (grossPriceIndex > contractIndex ? 1 : 0), 1)

    data.unshift(grossPriceObj)
    data.unshift(contractObj)
  }

  return data.map((d) => d.id)
}
