import { config } from '@/config'
import { useEffect } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'
import { useSegment } from './segment'

const ignoreLocationLanguage = (location) => {
  if (location.pathname.startsWith('/en')) {
    return location.pathname.replace('/en', '')
  }
  return location.pathname
}

export const usePageView = (ignoredPaths, routes) => {
  const analytics = useSegment()
  const location = useLocation()
  const locationWithoutLanguage = ignoreLocationLanguage(location)
  const [{ route = '/' }] = matchRoutes(routes, locationWithoutLanguage) || [{}]

  useEffect(() => {
    if (analytics && config.segmentId) {
      const shouldIgnore = ignoredPaths.some((path) =>
        location.pathname.includes(path),
      )

      if (shouldIgnore) return

      analytics.page({
        name: 'Page View',
        page_name: route.path,
      })
    }
  }, [JSON.stringify(location)])
}
