import { ComponentColors } from '@/enums'
import { LeftArrow } from '@/icons'
import { useGoBack, useTranslationSectionObject } from '@/utils'
import PropTypes from 'prop-types'
import { ButtonLink } from '../ButtonLink'
import { Container } from './styles'

const useMessages = () => {
  const commonTranslations = useTranslationSectionObject('common')

  return {
    back: commonTranslations.back,
  }
}

export const BackComponent = ({ onBack, noContentGutter, noMargin }) => {
  const messages = useMessages()
  const goBack = useGoBack()

  return (
    <Container noContentGutter={noContentGutter} noMargin={noMargin}>
      <ButtonLink
        color={ComponentColors.primary}
        icon={LeftArrow}
        onClick={onBack || goBack}
      >
        {messages.back}
      </ButtonLink>
    </Container>
  )
}

BackComponent.propTypes = {
  noContentGutter: PropTypes.bool,
  noMargin: PropTypes.bool,
  onBack: PropTypes.func,
}
