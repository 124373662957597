import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import {
  dynamicArrayPropType,
  fromEventTarget,
  includesIgnoringCase,
  renderableType,
  useItemDialog,
} from '@/utils'
import PropTypes, { arrayOf } from 'prop-types'
import { uniq } from 'ramda'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputWithAddon, Option, Typography } from '../../atoms'
import { BasicFormItem, ListSelect, ModalBase } from '../../molecules'
import { Column, Divider } from '../../styled'
import { SearchContainer } from '../AddInsuranceView/styles'
import { CardNode } from './CardNode'
import { SelectedCardNode } from './SelectedCardNode'
import {
  Container,
  ContentWrapper,
  EmptySearchContainer,
  HeaderContainer,
  InsuranceGroup,
  SelectedInsurancesGroup,
} from './styles'

export const GroupCardSelectWithModal = ({
  value = [],
  onChange,
  resetText,
  cardOptions,
  listOptions,
  listKey,
  cardKey,
  searchPlaceholder,
  modalTitle,
  modalSubtitle,
}) => {
  const {
    item: dialogItem,
    openItemDialog,
    isOpen,
    closeItemDialog,
  } = useItemDialog({})

  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const isSelected = (selectedCardValue) =>
    value.find((item) => item[cardKey] === selectedCardValue)

  const onDelete = (selectedCardValue) =>
    onChange(value.filter((item) => item[cardKey] !== selectedCardValue))

  const findListItem = (selectedCardValue) => {
    const { [listKey]: listValue } = value.find(
      (item) => item[cardKey] === selectedCardValue,
    )

    return listOptions.find((item) => item.value === listValue)
  }

  const onListChange = (optionValue) => {
    onChange([
      ...value.filter((item) => item[cardKey] !== dialogItem.value),
      { [listKey]: optionValue, [cardKey]: dialogItem.value },
    ])
    setSearch('')

    closeItemDialog()
  }

  const selected = useMemo(() => {
    const options = cardOptions
      .flatMap(({ items }) => items)
      .filter(({ value: option }) => isSelected(option))

    return uniq(options)
  }, [value])

  const insuranceGroups = useInsuranceGroups()

  const filteredOptions = useMemo(
    () =>
      insuranceGroups
        .map((group) => ({
          ...group,
          items: group.items.filter(
            (item) =>
              includesIgnoringCase(search, item.title) &&
              !isSelected(item.value),
          ),
        }))
        .filter((group) => group.items.length),
    [JSON.stringify(cardOptions), search],
  )

  return (
    <Container>
      <ContentWrapper>
        <SearchContainer>
          <InputWithAddon
            addonBefore={() => <SearchIcon color={ThemeColor.b50} />}
            onChange={fromEventTarget(setSearch)}
            placeholder={t('yearlyAssessment.searchCoverage')}
            rounded
            value={search}
          />
          <Divider color={ThemeColor.b30} />
        </SearchContainer>
        {!search && selected.length ? (
          <SelectedInsurancesGroup>
            <>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {t('selected')}
              </Typography>
              {selected.map(({ title, subtitle, value: selectedValue }) => (
                <SelectedCardNode
                  key={selectedValue}
                  listItem={findListItem(selectedValue)}
                  onDelete={onDelete}
                  onResetClick={openItemDialog}
                  resetText={resetText}
                  subtext={subtitle}
                  text={title}
                  value={selectedValue}
                />
              ))}
            </>
          </SelectedInsurancesGroup>
        ) : null}
        {filteredOptions.length ? (
          filteredOptions?.map(({ groupName, items }) => (
            <InsuranceGroup key={groupName}>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {groupName}
              </Typography>
              {items?.map(({ title, subtitle, value: selectedValue }) => (
                <Fragment key={selectedValue}>
                  {isSelected(selectedValue) ? null : (
                    <CardNode
                      onClick={openItemDialog}
                      subtext={subtitle}
                      text={title}
                      value={selectedValue}
                    />
                  )}
                </Fragment>
              ))}
            </InsuranceGroup>
          ))
        ) : (
          <EmptySearchState search={search} />
        )}
        {isOpen && (
          <ModalBase
            closable
            enableClickOutside={false}
            isOpen={isOpen}
            onClose={closeItemDialog}
            shouldRenderInPortal
          >
            <HeaderContainer>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {dialogItem.text}
              </Typography>
              <Typography bold variant="h4">
                {modalTitle}
              </Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {modalSubtitle}
              </Typography>
            </HeaderContainer>
            <ListSelect
              onChange={onListChange}
              searchPlaceholder={searchPlaceholder}
              value={
                value.find((item) => item[cardKey] === dialogItem.value)?.[
                  listKey
                ]
              }
            >
              {listOptions.map(({ text, value: optionValue, icon }) => (
                <Option key={optionValue} icon={icon} value={optionValue}>
                  {text}
                </Option>
              ))}
            </ListSelect>
          </ModalBase>
        )}
      </ContentWrapper>
    </Container>
  )
}

GroupCardSelectWithModal.propTypes = {
  cardKey: PropTypes.string,
  cardOptions: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: renderableType,
      items: arrayOf(
        PropTypes.shape({
          subtitle: PropTypes.string,
          title: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  ),
  listKey: PropTypes.string,
  listOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  modalSubtitle: PropTypes.string,
  modalTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  resetText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  value: dynamicArrayPropType,
}

export const FormGroupCardWithSelectModal = (props) => (
  <BasicFormItem component={GroupCardSelectWithModal} {...props} />
)

export const EmptySearchState = ({ search }) => {
  const { t } = useTranslation()
  return (
    <EmptySearchContainer>
      <SearchIcon size="button" />
      <Column gap="4px">
        <Typography bold variant="p1Body">
          {t('yearlyAssessment.noInsuranceFound')}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {t('yearlyAssessment.noInsuranceFoundDescription', { search })}
        </Typography>
      </Column>
    </EmptySearchContainer>
  )
}

EmptySearchState.propTypes = {
  search: PropTypes.string,
}
