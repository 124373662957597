import { ThemeColor } from '@/enums'
import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Typography } from '../Typography'
import { Container, IconContainer } from './styles'

export const Banner = ({
  title,
  text,
  color,
  icon: Icon,
  iconAlignment,
  children,
}) =>
  children ? (
    <Container color={color} vertical={Boolean(title)}>
      {children}
    </Container>
  ) : (
    <Container color={color} vertical={Boolean(title)}>
      <IconContainer iconAlignment={iconAlignment}>
        {Icon && <Icon size="menu" />}
      </IconContainer>
      <ShowWhen when={title}>
        <Typography variant="captionB">{title}</Typography>
      </ShowWhen>
      <Typography color={ThemeColor.b50} variant="captionR">
        {text}
      </Typography>
    </Container>
  )

Banner.propTypes = {
  children: renderableType,
  color: PropTypes.string,
  icon: renderableType,
  iconAlignment: PropTypes.oneOf(['top', 'center', 'bottom']),
  text: PropTypes.string,
  title: PropTypes.string,
}
