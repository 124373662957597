import { Column, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { theme } from '@/theme'
import { openInNewTab } from '@/utils'
import { Button } from '@surein/ui'
import { FilePlus, FileText, Trash2 } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { DeleteButton, DropArea, DropzoneContainer } from './styles'

export interface SelectedFile {
  file: File
  preview: string
  id: string
}

interface DragAndDropProps {
  onChange?: (files: SelectedFile[]) => void
  uploaderText?: string
}

export const DragAndDrop = ({ onChange, uploaderText }: DragAndDropProps) => {
  const { t } = useTranslation()
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])

  const dropzoneText = uploaderText || t('dragAndDrop')

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        id: uuidv4(),
      }))

      setSelectedFiles((prev) => [...prev, ...newFiles])
    },
    [setSelectedFiles],
  )

  useEffect(() => {
    if (onChange) {
      onChange(selectedFiles)
    }
  }, [selectedFiles, onChange])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxSize: 10485760, // 10MB
  })

  const removeFile = (idToRemove: SelectedFile['id']) => {
    setSelectedFiles((prev) => {
      const fileToRemove = prev.find(({ id }) => id === idToRemove)
      if (fileToRemove) {
        URL.revokeObjectURL(fileToRemove.preview)
      }
      return prev.filter(({ id }) => id !== idToRemove)
    })
  }

  // Cleanup previews when unmounting
  useEffect(() => {
    return () => {
      selectedFiles.forEach((file) => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [selectedFiles])

  return (
    <DropzoneContainer>
      <DropArea {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        <FilePlus size={32} color={theme.color.glass300} />
        <Column alignItems="center" gap="4px">
          <Typography bold variant="p1Body">
            {isDragActive ? t('dropFilesHere') : dropzoneText}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            or
          </Typography>
        </Column>
        {!isDragActive && (
          <Button type="button" variant="secondary">
            {t('chooseFromFiles')}
          </Button>
        )}
      </DropArea>

      <Column gap="8px">
        {selectedFiles.length > 0 && (
          <>
            {selectedFiles.map(({ file, preview, id }) => (
              <Row alignItems="center" gap="8px" key={id}>
                <FileText size={24} />
                <Typography
                  onClick={() => openInNewTab(preview)}
                  variant="p2Body"
                >
                  {file.name}
                </Typography>
                <DeleteButton>
                  <Trash2
                    size={16}
                    color={theme.color.danger1}
                    onClick={(e) => {
                      e.stopPropagation()
                      removeFile(id)
                    }}
                  />
                </DeleteButton>
              </Row>
            ))}
          </>
        )}
      </Column>
    </DropzoneContainer>
  )
}
