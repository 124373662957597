import {
  DesktopOfferExample,
  OfferGradient,
  OfferReadyModal,
  OfferReadyModalContainer,
} from '@/components/organisms/Stepper/styles'
import { Breakpoint, ThemeColor } from '@/enums'
import { Shield } from '@/icons'
import { useSegment } from '@/modules'
import { useFormContext, useOnNextClick } from '@/providers'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import {
  makeUrlWithLanguage,
  openInNewTab,
  useIsMobile,
  useLanguage,
  useTranslationSectionObject,
} from '@/utils'
import { Check } from 'lucide-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonLink,
  ImageFromCdn,
  Typography,
} from '../../../../components/atoms'
import { FormInput, TrustPartners } from '../../../../components/molecules'
import { Column, TextWithLink } from '../../../../components/styled'
import { config } from '../../../../config'
import { StyledSSL } from '../../public/styles'
import {
  ButtonLinkFontWrapper,
  ContactDataImageTextWrapper,
  ContactDataImageWrapper,
  OfferReadyImageContainer,
} from '../../styles'
import { ContactDataStepWrapper } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    phoneNumber: t('phone'),
    email: t('email'),
    acceptTerms: t('acceptTerms'),
    phoneInfo: t('phoneInfo'),
    createLeadImageTitle: t('createLeadImageTitle'),
    createLeadImageContent: t('createLeadImageContent'),
    contactDataImageText: useTranslationSectionObject('contactDataImageText'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(
  paths.terms,
  `https://${config.appDomain}/`,
)
const makePolicyUrl = makeUrlWithLanguage(
  paths.policy,
  `https://${config.appDomain}/`,
)

export const ButtonLinkWrapper = ({ children, onClick }) => (
  <ButtonLinkFontWrapper>
    <ButtonLink onClick={onClick} size="small">
      <Typography variant="badgeText">{children}</Typography>
    </ButtonLink>
  </ButtonLinkFontWrapper>
)

ButtonLinkWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

// eslint-disable-next-line import/no-unused-modules
export const ContactDataStepA = ({ handleCalculationSuccessful }) => {
  const language = useLanguage()
  const messages = useMessages()
  const { values, customValidate } = useFormContext()
  const analytics = useSegment()
  const { isMobile } = useIsMobile(Breakpoint.md)

  const handleNext = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: async () => {
            await analytics.track('ssf_lead_gen_email_success')
            handleCalculationSuccessful?.()
          },
          onFail: reject,
        })
      }),
    [values],
  )

  useOnNextClick(handleNext)

  return (
    <ContactDataStepWrapper>
      <Column flex="1" justifyContent="space-between" gap="16px">
        <Column>
          <Column gap="12px">
            <FormInput name="name" placeholder="John Müller" />
            <FormInput name="email" placeholder="example@mail.com" />
          </Column>
          {isMobile ? (
            <StyledSSL alignItems="center" gap="4px">
              <Shield width={24} height={24} color={ThemeColor.success1} />
              <Typography singleLine variant="badgeText">
                <Trans i18nKey="mockRecommendationModalText.ssl" />
              </Typography>
            </StyledSSL>
          ) : (
            <TrustPartners mode="dark" />
          )}
        </Column>
        <TextWithLink>
          <Trans
            components={[
              <ButtonLinkWrapper
                onClick={() => openInNewTab(makePolicyUrl(language))}
                size="small"
              />,
              <ButtonLinkWrapper
                onClick={() => openInNewTab(makeTermsUrl(language))}
                size="small"
              />,
            ]}
            i18nKey={messages.acceptTerms}
          />
        </TextWithLink>
      </Column>
    </ContactDataStepWrapper>
  )
}

ContactDataStepA.propTypes = {
  handleCalculationSuccessful: PropTypes.func,
}

export const ContactDataImage = () => {
  const messages = useMessages()
  const { isMobile } = useIsMobile(Breakpoint.md)
  return isMobile ? (
    <ContactDataImageWrapper>
      <OfferReadyImageContainer>
        <ImageFromCdn
          fallbackType="jpg"
          name="offer-example-blurred"
          type="jpg"
        />
        <ImageFromCdn fallbackType="jpg" name="carrierBubbles" type="webp" />
      </OfferReadyImageContainer>
      <ContactDataImageTextWrapper>
        <Typography bold variant="h5">
          {messages.contactDataImageText.title}
        </Typography>
        <Typography color={ThemeColor.b50} variant="footnote">
          {messages.contactDataImageText.subtitle}
        </Typography>
      </ContactDataImageTextWrapper>
    </ContactDataImageWrapper>
  ) : (
    <DesktopOfferExample>
      <OfferReadyModalContainer>
        <OfferReadyModal>
          <div>
            <Check size={24} color={theme.color.success1} />
          </div>
          <Typography bold variant="h5">
            {messages.contactDataImageText.title}
          </Typography>
          <Typography color={ThemeColor.b50} variant="footnote">
            {messages.contactDataImageText.subtitle}
          </Typography>
          <ImageFromCdn fallbackType="jpg" name="carrierBubbles" type="webp" />
        </OfferReadyModal>
      </OfferReadyModalContainer>
      <ImageFromCdn
        fallbackType="jpg"
        name="recommendation-example-full"
        type="webp"
      />
      <OfferGradient />
    </DesktopOfferExample>
  )
}
