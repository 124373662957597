import { ThemeColor } from '@/enums'
import { themeColorPropType } from '@/utils'
import PropTypes, { oneOf } from 'prop-types'
import { Typography } from '../Typography'
import { Container } from './styles'

export const TextBadge = ({
  backgroundColor,
  bgOpacity,
  text,
  textColor,
  size = 'sm',
}) => (
  <Container
    backgroundColor={backgroundColor}
    bgOpacity={bgOpacity}
    size={size}
  >
    <Typography bold color={textColor || ThemeColor.b0} variant="badgeText">
      {text}
    </Typography>
  </Container>
)

TextBadge.propTypes = {
  backgroundColor: themeColorPropType,
  bgOpacity: PropTypes.number,
  size: oneOf(['sm', 'md']),
  text: PropTypes.string.isRequired,
  textColor: themeColorPropType,
}
