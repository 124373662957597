import { FormNumber } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    count: t('locationCountLabel'),
    countInfo: t('countInfo'),
  }
}

export const LocationCountStep = () => {
  const messages = useMessages()
  const { value } = useFormItem('locationsCount')
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationsCount,
  )

  useOnNextClick(save)

  useNextDisabled(() => !value, [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormNumber
      // info={messages.countInfo}
      initialValue={1}
      label={messages.count}
      min={1}
      name="locationsCount"
    />
  )
}
