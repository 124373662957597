import { MessageUnread } from '@/icons'
import { renderableType, useModal } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Image, Typography } from '../../atoms'
import { ContactExpert } from '../ContactExpert'
import { ModalBase } from '../ModalBase'
import { Container } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    newMessage: t('newMessage'),
  }
}

export const OpenMessageComponent = ({ text, expertButtonComponent }) => {
  const messages = useMessages()
  const { isOpen, toggle } = useModal()

  return (
    <>
      <Container onClick={toggle}>
        <Image height={32} name="chatblock" type="png" width={32} />
        <Typography variant="captionR">{messages.newMessage}</Typography>
        <MessageUnread fill="none" size="info" />
      </Container>
      {isOpen && (
        <ModalBase isOpen onClose={toggle}>
          <ContactExpert buttonComponent={expertButtonComponent} text={text} />
        </ModalBase>
      )}
    </>
  )
}

OpenMessageComponent.propTypes = {
  expertButtonComponent: renderableType,
  text: PropTypes.string,
}
