import { ThemeColor } from '@/enums'
import { themeColor, themeColorFrom, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import { Relative, Row } from '../../styled'

const slide = keyframes`
      0% {
        left: -100%;
        top: 0;
        opacity: 1;
      }
      50% {
        left: 50%;
        top: 0px;
        opacity: 1;
      }
      75% {
        left: 100%;
        top: 0;
        opacity: 0.75;
      }
      100% {
        left: 200%;
        top: 0;
        opacity: 0;
      }
    `

export const Container = styled(Row)`
  display: ${ifProp('hidden', 'none')};
  z-index: 10000;
  box-sizing: border-box;
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${ifProp(
    'backgroundColor',
    themeColorFrom(prop('backgroundColor')),
    themeColor(ThemeColor.b0),
  )};
  padding: 0 ${toThemePixels(6)};
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-width: ${ifNotProp('border', 0)};

  svg {
    cursor: pointer;
  }

  > ${Relative} {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 10%;
      height: 100%;
      transform: skewX(-30deg);
      animation-name: ${slide};
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-delay: 0.3s;
      animation-iteration-count: infinite;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.33) 0%,
        rgba(255, 255, 255, 0.33) 77%,
        rgba(255, 255, 255, 0.7) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`
