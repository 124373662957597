import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryButton, Typography } from '../../atoms'
import { Column } from '../../styled'
import { Card } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    accountName: t('account_name'),
    select: t('recommendations.choose'),
  }
}

export const PaymentCard = ({ name, iban, selected, onClick }) => {
  const messages = useMessages()

  return (
    <Card selected={selected}>
      <Column gap="4px">
        <Typography variant="captionR">{name}</Typography>
        <Typography color={ThemeColor.b50} variant="p2Body">
          {messages.accountName}
        </Typography>
      </Column>
      <Column gap="4px">
        <Typography variant="captionR">{iban}</Typography>
        <Typography color={ThemeColor.b50} variant="p2Body">
          IBAN
        </Typography>
      </Column>
      <SecondaryButton disabled={selected} onClick={onClick}>
        {messages.select}
      </SecondaryButton>
    </Card>
  )
}

PaymentCard.propTypes = {
  iban: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
