import { insuranceCardData } from '@/components/organisms/InsuranceFinderCard/helpers'
import { Categories } from '@/enums'
import { useTranslationSection, useTranslationSectionObject } from '@/utils'
import {
  both,
  filter,
  flip,
  groupBy,
  keys,
  pipe,
  prop,
  propEq,
  propOr,
} from 'ramda'
import { useTranslation } from 'react-i18next'

export const getGroupCategories = pipe(
  propEq('group'),
  both(prop('show')),
  flip(filter)(Categories),
  keys,
)

export const makeCompanySubgroups = () =>
  pipe(
    filter(prop('subgroup')),
    keys,
    groupBy((key) => Categories[key].subgroup),
  )(Categories)

const makeGroupCards = (object) => {
  const groupedData = {}

  keys(object).forEach((item) => {
    object[item].group.forEach((groupName) => {
      if (!groupedData[groupName]) {
        groupedData[groupName] = []
      }
      groupedData[groupName].push(item)
    })
  })

  return groupedData
}

export const makeCardSectionData = (section) =>
  pipe(makeGroupCards, propOr([], section))(insuranceCardData)

export const useMessages = () => {
  const { t } = useTranslation()
  const categoryGroups = useTranslationSectionObject('insuranceGroups')
  const pageTranslations = useTranslationSection('insuranceFinderTranslations')

  return {
    pageTitle: pageTranslations('insuranceFinderTitle'),
    categoryGroups,
    categories: t('categories', { returnObjects: true }),
    addInsurance: t('locationPage.addInsurance'),
    recommended: pageTranslations('recommended'),
    remindMe: pageTranslations('remindMe'),
    complexSubject: t('insuranceFinderTranslations.complexSubject'),
    getAdvice: t('insuranceFinderTranslations.getAdvice'),
    insuranceFinderSubgroups: t(
      'insuranceFinderTranslations.insuranceFinderSubgroups',
      { returnObjects: true },
    ),
    info: t('insuranceFinderTranslations.info', { returnObjects: true }),
  }
}
