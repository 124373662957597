import { ContactModal, PageMainContent, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import {
  Alert,
  ArrowRight,
  CalendarNew,
  Document,
  DocumentOverview,
  Flag,
  Logo,
  Message,
  Mobile,
  ShieldSmall,
} from '@/icons'
import { paths } from '@/routes/paths'
import {
  makeUrlWithLanguage,
  useLanguage,
  useModal,
  useTranslationSection,
  useIsMobile,
  openHubspotChat,
} from '@/utils'
import { config } from '../../config'
import { Container, Details, MenuItemWrapper, SubsectionsTitle } from './styles'

const useMessages = () => {
  const commonTranslation = useTranslationSection('common')
  const servicesTranslations = useTranslationSection('services')
  return {
    phone: commonTranslation('phone'),
    close: commonTranslation('close'),
    yes: commonTranslation('yes'),
    title: servicesTranslations('title'),
    report: servicesTranslations('report'),
    questions: servicesTranslations('questions'),
    aboutUs: servicesTranslations('aboutUs'),
    appointment: servicesTranslations('appointment'),
    contacts: servicesTranslations('contacts'),
    chat: servicesTranslations('chat'),
    about: servicesTranslations('about'),
    dataSafe: servicesTranslations('dataSafe'),
    agb: servicesTranslations('agb'),
    info: servicesTranslations('info'),
    impression: servicesTranslations('impression'),
    cancel: commonTranslation('cancel'),
    finish: commonTranslation('finish'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(paths.terms)
const makePolicyUrl = makeUrlWithLanguage(paths.policy)
const makeInitialInformtationUrl = makeUrlWithLanguage(paths.initialInformation)
const makeImprintUrl = makeUrlWithLanguage(paths.imprint)
const makeAboutUsUrl = (language) =>
  language === 'en'
    ? 'https://www.surein.de/en/en-surein#how-it-works'
    : 'https://www.surein.de/#so-funktioniert-es'
const aboutUs = (language) => [
  { title: 'about', url: makeAboutUsUrl(language), icon: <Logo /> },
  { title: 'dataSafe', url: makePolicyUrl(language), icon: <ShieldSmall /> },
  { title: 'agb', url: makeTermsUrl(language), icon: <DocumentOverview /> },
  {
    title: 'info',
    url: makeInitialInformtationUrl(language),
    icon: <Document />,
  },
  { title: 'impression', url: makeImprintUrl(language), icon: <Flag /> },
]

export const Services = () => {
  const messages = useMessages()
  const { isOpen, close, toggle } = useModal(false)
  const language = useLanguage()
  const { isMobile } = useIsMobile(Breakpoint.sm)

  return (
    <PageMainContent>
      <Container>
        <ContactModal hide={{ chat: true }} isOpen={isOpen} onClose={close} />
        <SubsectionsTitle>
          <Typography variant="h5">{messages.title} </Typography>
        </SubsectionsTitle>
        <a
          href={config.reportDamageUrl[language]}
          rel="noreferrer"
          target="_blank"
        >
          <MenuItemWrapper>
            <Details>
              <Alert />
              <Typography variant="p1Body">{messages.report} </Typography>
            </Details>
            {isMobile && <ArrowRight />}
          </MenuItemWrapper>
        </a>
        <SubsectionsTitle>
          <Typography variant="h5">{messages.questions} </Typography>
        </SubsectionsTitle>{' '}
        <a href={config.appointmentUrl} rel="noreferrer" target="_blank">
          <MenuItemWrapper>
            <Details>
              <CalendarNew />
              <Typography variant="p1Body">{messages.appointment} </Typography>
            </Details>
            {isMobile && <ArrowRight />}
          </MenuItemWrapper>
        </a>
        <MenuItemWrapper onClick={toggle}>
          <Details>
            <Mobile />
            <Typography variant="p1Body">{messages.contacts} </Typography>
          </Details>
          {isMobile && <ArrowRight />}
        </MenuItemWrapper>
        <MenuItemWrapper onClick={openHubspotChat}>
          <Details>
            <Message color={ThemeColor.b100} />
            <Typography variant="p1Body">{messages.chat} </Typography>
          </Details>
          {isMobile && <ArrowRight />}
        </MenuItemWrapper>
        <SubsectionsTitle>
          <Typography variant="h5">{messages.aboutUs} </Typography>
        </SubsectionsTitle>
        {aboutUs(language).map((el) => (
          <a key={el.title} href={el.url} rel="noreferrer" target="_blank">
            <MenuItemWrapper>
              <Details>
                {el.icon}
                <Typography variant="p1Body">{messages[el.title]} </Typography>
              </Details>
              {isMobile && <ArrowRight />}
            </MenuItemWrapper>
          </a>
        ))}
      </Container>
    </PageMainContent>
  )
}
