import { Column, Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const StyledRow = styled(Row)`
  width: 100%;
  gap: ${toThemePixels(4)};

  > * {
    width: calc(50% - ${toThemePixels(3)});

    input {
      width: 100%;
    }
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    gap: ${toThemePixels(1)};

    > * {
      width: 100%;
    }
  }
`

export const SuccessContainer = styled(Column)`
  gap: ${toThemePixels(6)};
  padding-top: ${toThemePixels(8)};

  button {
    width: fit-content;
  }
`

export const ContactContainer = styled.div`
  padding: ${toThemePixels(2)} ${toThemePixels(6)};
`

export const KillerQuestionsContainer = styled(Column)`
  background: ${themeColor(ThemeColor.b10)};
  height: 100vh;
`

export const WidgetArea = styled(Column)`
  max-width: 780px;
  height: calc(100% - 133px);
  max-height: 780px;
  margin: auto;
  background: white;
  border-radius: ${toThemePixels(6)};
  position: relative;

  ${themeBreakpointDown(Breakpoint.md)} {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const ButtonContainer = styled(Row)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid;
  border-color: ${themeColor(ThemeColor.b30)};
  padding: ${toThemePixels(4)} ${toThemePixels(6)} ${toThemePixels(6)}
    ${toThemePixels(6)};
  background: white;
  border-radius: 0 0 ${toThemePixels(6)} ${toThemePixels(6)};
`

export const WidgetContent = styled(Column)`
  padding: ${toThemePixels(6)} ${toThemePixels(6)} 0 ${toThemePixels(6)};
  margin-bottom: ${toThemePixels(23)};
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  > div:first-of-type {
    margin-bottom: ${toThemePixels(10)};
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    padding-top: ${toThemePixels(19)};
  }
`

export const TitleSection = styled(Column)`
  margin-bottom: ${toThemePixels(6)};
  gap: ${toThemePixels(1)};
`

export const KillerQuestionsList = styled.ul`
  padding-left: ${toThemePixels(5)};

  > li {
    list-style: none;
    margin-bottom: ${toThemePixels(2)};
  }

  > li::before {
    content: '• ';
    color: ${themeColor(ThemeColor.b50)};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`
