import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div`
  margin: ${toThemePixels(4)} 0;
`

export const CategoryTitleContainer = styled.div`
  padding: 6px 0;
  justify-content: space-between;
  gap: ${toThemePixels(3)};
  align-items: center;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
`

export const FileCategory = styled.div`
  > *:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: ${toThemePixels(6)};
  }
`
