import { Typography } from '@/components/atoms'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import { Button } from '@surein/ui'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../styled'

export const Container = styled(Row)`
  width: 100vw;
  height: 100%;
  background-color: ${themeColor(ThemeColor.b10)};
  gap: ${toThemePixels(12)};
`

export const StepperContainer = styled(Row)`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  gap: ${toThemePixels(12)};
  background-color: ${themeColor(ThemeColor.b10)};
  margin: 0 auto;
  align-items: center;
`

export const StepViewport = styled(Column)`
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 700px;
  width: 600px;
  position: relative;
  background-color: ${themeColor(ThemeColor.b0)};
  margin: 32px 0 32px 32px;
  border-radius: ${toThemePixels(6)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  overflow: hidden;
  z-index: 10;

  ${themeBreakpointDown(Breakpoint.md)} {
    max-height: unset;
    margin: 32px auto;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100vw;
    margin: 0;
    border-radius: 0;
  }
`

const fixedStyles = css`
  position: absolute;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
`

const contentPadding = css`
  padding: ${toThemePixels(4)} ${toThemePixels(6)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(6)} ${toThemePixels(6)};
  }
`

const applyBreadcrumbStyles = () => css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    left: 50%;
    width: 110%;
    padding: ${toThemePixels(4)} 0;
    top: 4.3rem;
  }
`

export const StepperHeader = styled(Row)`
  ${fixedStyles};
  ${contentPadding};
  top: 0;

  img {
    cursor: pointer;
  }

  > *:nth-child(2) {
    position: absolute;
    left: ${ifProp('hasBreadcrumbs', '52%', '50%')};
    transform: translateX(-50%);
    ${ifProp('hasBreadcrumbs', applyBreadcrumbStyles)};
  }
`

export const StepContainer = styled(Column)`
  ${contentPadding};
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  bottom: ${ifProp('withButtons', '100px', 0)};
  height: ${ifProp('withButtons', 'calc(100% - 73px)')};
  overflow-y: auto;

  padding-top: ${toThemePixels(6)};
  padding-bottom: 92px;

  flex: 1;
  > div {
    flex: 1;
  }
  form {
    height: 100%;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-top: ${toThemePixels(10)};
    padding-bottom: 92px;
  }
`

export const ButtonContainer = styled(Row)`
  ${fixedStyles};
  padding: ${toThemePixels(4)} ${toThemePixels(6)} ${toThemePixels(9)}
    ${toThemePixels(6)};
  justify-content: ${ifProp('withSkipButton', 'space-between', 'flex-end')};
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0,
    rgba(255, 255, 255, 1) 20%,
    rgba(0, 212, 255, 0) 100%
  );
  gap: ${toThemePixels(4)};
  z-index: 110;

  bottom: 0;

  > button {
    -webkit-text-fill-color: unset !important;
  }
`

export const SkipModalSubheader = styled.div`
  max-width: ${toThemePixels(90)};
`

export const BreadcrumbsContainer = styled(Row)`
  padding: ${toThemePixels(4)} ${toThemePixels(6)};
  align-items: center;
  justify-content: space-between;
  width: 80%;

  ${themeBreakpointDown(Breakpoint.sm)} {
    background: white;
    z-index: 10;
    width: 100%;
  }
`

export const Step = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > span {
    white-space: wrap;
    text-align: center;
    ${themeBreakpointDown(Breakpoint.md)} {
      display: none;
    }
  }

  &:before,
  &:after {
    content: ' ';
    width: 50%;
    display: block;
    position: absolute;
    height: 2px;
  }

  &:after {
    left: 50%;
    background: ${ifProp(
      'current',
      themeColor(ThemeColor.info1),
      ifProp('done', themeColor(ThemeColor.info1), themeColor(ThemeColor.b30)),
    )};
  }

  &:before {
    right: 50%;
    background: ${ifProp(
      'current',
      themeColor(ThemeColor.info1),
      ifProp('done', themeColor(ThemeColor.info1), themeColor(ThemeColor.b30)),
    )};
  }

  &:first-child:before,
  &:last-child:after {
    content: none;
  }
`

export const BreadcrumbBorder = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(1)};
  border: 2px solid ${themeColor(ThemeColor.info1)};
  border-color: ${ifProp(
    'current',
    themeColor(ThemeColor.info1),
    ifProp('checked', themeColor(ThemeColor.info1), themeColor(ThemeColor.b30)),
  )};
  border-radius: 100%;
  width: ${toThemePixels(5)};
  height: ${toThemePixels(5)};
  background: ${ifProp(
    'checked',
    themeColor(ThemeColor.info1),
    themeColor(ThemeColor.b0),
  )};
  z-index: 2;

  span {
    font-weight: 700;
    font-size: 10px;
  }

  svg {
    display: block;
    padding: 6px;
  }
`

export const BreadcrumbText = styled(Typography).attrs({
  variant: 'badgeText',
  bold: true,
})`
  position: absolute;
  top: ${toThemePixels(5.25)};
  text-wrap: nowrap;
  color: ${ifProp(
    'current',
    themeColor(ThemeColor.info1),
    ifProp('checked', themeColor(ThemeColor.info1), themeColor(ThemeColor.b50)),
  )};
`

export const BackContainer = styled(Row)`
  align-self: baseline;
  cursor: pointer;
  padding: ${toThemePixels(2)} ${toThemePixels(4)} ${toThemePixels(2)} 0;
  margin-bottom: 8px;
  > span {
    display: flex;
    align-items: center;
    gap: ${toThemePixels(1)};
  }

  svg {
    margin-right: ${toThemePixels(1)};
    stroke: ${themeColor(ThemeColor.b50)}!important;
    fill: ${themeColor(ThemeColor.b50)}!important;
  }
`

export const FunnelTestimonialContainer = styled(Column)`
  margin: 32px 32px 32px 0;
  flex: 1;
  justify-content: center;
  gap: 16px;

  > span {
    display: inline-block;
    max-width: 45ch;
  }
`

export const ContinueButton = styled(Button)`
  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100%;
  }
`

export const DesktopOfferExample = styled.div`
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: -80px;
  right: -80px;
  bottom: 0;
  width: 60%;
  z-index: 0;
  height: calc(100% + 80px);

  > img {
    width: 100vw;
    height: calc(100vh + 80px);
    object-fit: cover;
  }

  @media (max-width: 1100px) {
    width: 50%;
  }
`

export const OfferGradient = styled.div`
  background: linear-gradient(
    270deg,
    rgb(245, 247, 250, 0) 55.71%,
    rgb(248, 250, 252) 92.92%
  );
  position: absolute;
  top: 0;
  left: -100px;
  width: 100%;
  height: 100%;
`

export const OfferReadyModalContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 80px);
  margin-inline: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 1;
`

export const OfferReadyModal = styled(Column)`
  background: white;
  border-radius: 16px;
  max-width: 430px;
  height: 320px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  padding: 16px;
  text-align: center;
  gap: 8px;

  > div:first-child {
    width: 72px;
    height: 72px;
    border: 2px solid ${themeColor(ThemeColor.success1)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  > img {
    height: 27px;
  }

  @media (max-width: 1100px) {
    max-width: 320px;
    margin-right: 24px;
  }
`
