import { Breakpoint } from '@/enums'
import { theme } from '@/theme'

export const isHubspotChatLoaded = () =>
  !!window.HubSpotConversations?.widget?.status?.().loaded

export const loadDesktopHubspotChat = () => {
  if (
    window.HubSpotConversations?.widget?.load &&
    window.innerWidth > theme.breakpoints.values[Breakpoint.tab]
  ) {
    window.HubSpotConversations.widget.load()
  }
}

export const openHubspotChat = () => {
  if (isHubspotChatLoaded) {
    window.HubSpotConversations?.widget?.open()
  }
}
