import { concat } from 'ramda'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isFunction } from '../type'
import {
  makePathnameWithLanguage,
  makeQueryString,
  withoutLanguage,
} from '../url'
import { useLanguage } from './useLanguage'

export const useGoToPath = (path, queryObject = {}, state = {}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const language = useLanguage()
  const makePath = makePathnameWithLanguage(language)

  return useCallback(
    (key) =>
      navigate(
        concat(
          makePath(isFunction(path) ? path(key) : path),
          makeQueryString(queryObject),
        ),
        {
          state: {
            from: withoutLanguage(pathname),
            ...state,
          },
        },
      ),
    [path, language],
  )
}
