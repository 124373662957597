import { isIOS } from '@/utils'
import { useEffect } from 'react'

export const PreventMobileZoom = () => {
  useEffect(() => {
    if (isIOS()) {
      const el = document.querySelector('meta[name=viewport]')

      if (el !== null) {
        let content = el.getAttribute('content')
        const re = /maximum-scale=[0-9.]+/g

        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0')
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ')
        }

        el.setAttribute('content', content)
      }
    }
  })

  return null
}
