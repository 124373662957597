import {
  BackComponent,
  CardSelect,
  ContactExpert,
  Option,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { i18nHelpers } from '@/i18n/init'
import { useSnackbar } from '@/providers'
import { useUpdateUserLanguage } from '@/services'
import { useTranslationSectionObject } from '@/utils'
import { equals } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import { PageHeader } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    snackBars: { ...useTranslationSectionObject('snackbars') },
    data: { ...useTranslationSectionObject('profile.data') },
    menu: { ...useTranslationSectionObject('profile.menu') },
    choose: t('choose_language'),
    advisorText: t('advisor_text_base'),
  }
}

export const EditLanguagePage = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language
  const { languages } = i18nHelpers
  const messages = useMessages()
  const { user: data } = useOutletContext()
  const snackbar = useSnackbar()
  const updateLanguageMutation = useUpdateUserLanguage(data.auth0)
  const queryClient = useQueryClient()

  const onUpdateUser = (language) => {
    if (language === locale) {
      return
    }

    updateLanguageMutation
      .mutateAsync({
        language,
      })
      .then(() => {
        snackbar.success(messages.snackBars.success.updateUserMsg)
        i18n.changeLanguage(language)
        queryClient.invalidateQueries('location-types')
      })
      .catch(() => {
        snackbar.error(messages.snackBars.error.generalMsg)
      })
  }

  const selected = languages.find(equals(locale))

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageHeader>
          <Typography variant="h4">{messages.menu.language}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.choose}
          </Typography>
        </PageHeader>
        <CardSelect onSelect={onUpdateUser} value={selected}>
          {languages.map((el) => (
            <Option key={el} value={el}>
              <Typography color={ThemeColor.b100} variant="captionB">
                {messages.data.language[el]}
              </Typography>
            </Option>
          ))}
        </CardSelect>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
