import { useSnackbar } from '@/providers/SnackbarProvider'
import { pluralize } from '@/utils'
import { cloneElement, useState, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { GhostInput } from './styles'

const useMessages = (max: number, maxMbSize: number) => {
  const { t } = useTranslation()

  return {
    maxNoSingular: t('upload_max_files_singular', { max }),
    maxNoPlural: t('upload_max_files_plural', { max }),
    maxMb: t('upload_max_size', { maxMbSize }),
    maxPick: t('insurance_documents_upload_max'),
  }
}

const sumMbSize = (files: { size: number }[]): number => {
  const sum = [...files].map((file) => file.size).reduce((a, b) => a + b, 0)
  return sum / 1024 ** 2
}

export const usePicker = ({
  multiple,
  accept,
  maxMbSize = 200,
  max,
  disabled,
}: {
  multiple?: boolean
  accept: string
  maxMbSize?: number
  max: number
  disabled?: boolean
}) => {
  const messages = useMessages(max, maxMbSize)
  const snackbar = useSnackbar()
  const [ref, setRef] = useState<HTMLInputElement | null>()

  const openFilePicker = () => {
    if (ref && !(disabled || max === 0)) {
      ref.value = ''
      ref.click()
    }
  }

  const onChangeFile = (onPick: (files: File[]) => void) => (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation()
    event.preventDefault()

    const fileList = (event.target as HTMLInputElement).files

    if (!fileList) {
      return
    }
    const files = Array.from(fileList)

    if (sumMbSize(files) > maxMbSize) {
      snackbar.error(messages.maxMb)
      return
    }

    if (max && files.length > max) {
      snackbar.error(
        pluralize(max, messages.maxNoSingular, messages.maxNoPlural, false),
      )
      return
    }

    if (files.length > 10) {
      snackbar.error(messages.maxPick)
      return
    }

    onPick(files)
  }

  const RefComponent = useCallback(
    ({ onPick }: { onPick: (files: File[]) => void }) => (
      <GhostInput
        ref={setRef}
        accept={accept}
        multiple={multiple}
        onChange={onChangeFile(onPick)}
      />
    ),
    [],
  )

  return {
    RefComponent,
    openFilePicker,
  }
}

export const FilePicker = ({
  children,
  multiple,
  accept,
  onPick,
  maxMbSize = 200,
  max,
  disabled,
}: {
  accept: string
  children: ReactElement
  disabled?: boolean
  max: number
  maxMbSize?: number
  multiple?: boolean
  onPick: (files: File[]) => void
}) => {
  const { openFilePicker, RefComponent } = usePicker({
    multiple,
    accept,
    maxMbSize,
    max,
    disabled,
  })

  const clonedChildren = cloneElement(children, {
    onClick: openFilePicker,
    style: { cursor: 'pointer' },
    disabled: disabled || max === 0,
  })

  return (
    <>
      <RefComponent onPick={onPick} />
      {children && clonedChildren}
    </>
  )
}
