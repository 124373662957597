import { Column } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { RightArrow } from '@/icons'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Column)`
  height: calc(100vh - 60px - 16px);
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #f0f1ff;
  margin-left: -${toThemePixels(6)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: 0 ${toThemePixels(4)};
  }
`

export const Content = styled(Column)`
  background-color: ${themeColor(ThemeColor.b0)};
  border-radius: ${toThemePixels(3)};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
  gap: ${toThemePixels(6)};
  width: 500px;
  padding: ${toThemePixels(6)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 95%;
  }
`

export const List = styled(Column)`
  gap: ${toThemePixels(7)};
`

export const ButtonContainer = styled.div`
  padding-top: ${toThemePixels(5)};
`

export const StyledRightArrow = styled(RightArrow)`
  height: ${toThemePixels(4)};
  width: ${toThemePixels(4)};
  fill: none;
`

export const NumberContainer = styled.div`
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: 50%;
  width: ${toThemePixels(8)};
  height: ${toThemePixels(8)};
  display: flex;
  justify-content: center;
  align-items: center;
`
