import { AssessmentStepKeys, AssessmentType, Categories } from '@/enums'
import {
  dynamicObjectPropType,
  extractFunnelFromPathname,
  getLocalStorageItem,
  propTypeFromEnumKeys,
  pushEventToDataLayer,
  useModal,
} from '@/utils'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AssessmentStepper } from '../../AssessmentStepper'
import { SuccessPage } from '../SuccessPage'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    subtitle: t('leadStepSubtitle'),
    titles: {
      [AssessmentStepKeys.businessType]: t('businessTypeTitle'),
      [AssessmentStepKeys.companyFoundedDateLead]: t('companyFoundedDateTitle'),
      [AssessmentStepKeys.contactData]: t('createLeadTitle'),
      [AssessmentStepKeys.approxLaborCosts]: t('approxLaborCostsTitle'),
      [AssessmentStepKeys.approxRevenue]: t('approxRevenueTitle'),
      [AssessmentStepKeys.employeesCount]: t('employeesCountTitle'),
      [AssessmentStepKeys.createLead]: t('createLeadTitle'),
      [AssessmentStepKeys.inventory]: t('inventoryTitle'),
      [AssessmentStepKeys.constructionArea]: t('constructionAreaTitle'),
      [AssessmentStepKeys.constructionDetails]: t('constructionDetailsTitle'),
      [AssessmentStepKeys.constructionDate]: t('constructionDateTitle'),
    },
    subtitles: {
      [AssessmentStepKeys.companyFoundedDateLead]: t(
        'companyFoundedDateSubtitle',
      ),
      [AssessmentStepKeys.contactData]: t('contactDataLeadSubtitle'),
      [AssessmentStepKeys.approxLaborCosts]: t('approxLaborCostsSubtitle'),
      [AssessmentStepKeys.approxRevenue]: t('approxRevenueSubtitle'),
      [AssessmentStepKeys.employeesCount]: t('employeesCountSubtitle'),
    },
  }
}

export const LeadGenerationAssessment = ({
  onLogoClick,
  categoryId,
  stepKeys,
  makeStepProps,
  stepperBreadcrumbTitles,
}) => {
  const messages = useMessages()

  const finishedState = useModal()

  const stepLocalStorageKey = `lead-${categoryId}-current-step`
  const valuesLocalStorageKey = `lead-${categoryId}-values`

  const currentStep = useMemo(
    () => getLocalStorageItem(stepLocalStorageKey),
    [],
  )

  const onFinish = () => {
    finishedState.open()

    pushEventToDataLayer('Step Change', {
      funnel_product: extractFunnelFromPathname(),
      funnel_step: 'funnel_finished',
    })
  }

  return finishedState.isOpen ? (
    <SuccessPage onLogoClick={onLogoClick} />
  ) : (
    <AssessmentStepper
      initialStep={currentStep}
      initialValues={getLocalStorageItem(valuesLocalStorageKey)}
      messages={messages}
      onFinish={onFinish}
      onLogoClick={onLogoClick}
      skipSuccessPage
      stepKeys={stepKeys}
      stepLocalStorageKey={stepLocalStorageKey}
      stepperBreadcrumbTitles={stepperBreadcrumbTitles}
      stepProps={makeStepProps}
      type={AssessmentType.firstTimeUser}
      valuesLocalStorageKey={valuesLocalStorageKey}
    />
  )
}

LeadGenerationAssessment.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  makeStepProps: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func,
  stepKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepperBreadcrumbTitles: dynamicObjectPropType,
}
