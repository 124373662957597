import { ThemeColor } from '@/enums'
import { Close } from '@/icons'
import { oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { ImageFromCdn } from '../../atoms'
import { WithMask } from '../../hoc/WithMask'
import {
  CloseModalButton,
  Container,
  ImageContainer,
  ModalContent,
  TextContent,
} from './styles'

export const PictureModal = forwardRef(
  (
    { isOpen, children, closable, onClose, image, shouldRenderInPortal = true },
    ref,
  ) => (
    <WithMask
      closable={closable}
      isOpen={isOpen}
      onClose={onClose}
      shouldRenderInPortal={shouldRenderInPortal}
    >
      <Container ref={ref}>
        {onClose && (
          <CloseModalButton onClick={onClose}>
            <Close color={ThemeColor.b0} height={12} width={12} />
          </CloseModalButton>
        )}
        {children && (
          <ModalContent>
            <TextContent>{children}</TextContent>
            <ImageContainer>
              <ImageFromCdn fallbackType="jpg" name={image} type="webp" />
            </ImageContainer>
          </ModalContent>
        )}
      </Container>
    </WithMask>
  ),
)

PictureModal.propTypes = {
  children: oneOrManyChildren,
  closable: PropTypes.bool,
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  shouldRenderInPortal: PropTypes.bool,
}
