import { FormProvider } from '@/providers'
import * as Yup from 'yup'
import { InferType } from 'yup'
import { useStepper } from '../hooks/useStepper'
import { Footer } from './Footer'
import { Header } from './Header'
import styled from 'styled-components'
import { Column } from '@/components'

const ScrollableContent = styled(Column)`
  flex: 1;
  overflow-y: auto;
  gap: 24px;
  padding: 0 8px;
`

const FormWrapper = styled(Column)`
  gap: 24px;
  height: 100%;

  > form {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }
`

interface StepLayoutProps<T> {
  children: React.ReactNode
  title: string
  subtitle?: string
  onNext: (values: T) => void
  onBack?: () => void
  onSkip?: () => void
  validationSchema?: () => Yup.ObjectSchema<InferType<Yup.AnyObjectSchema>>
  isLoading?: boolean
  hideBack?: boolean
  initialValues?: T
}

export const StepLayout = <T,>({
  children,
  validationSchema,
  onNext,
  onSkip,
  title,
  subtitle,
  isLoading,
  hideBack,
  initialValues,
}: StepLayoutProps<T>) => {
  const { categoryId, isFirst, onBack, formValues } = useStepper()

  return (
    <FormWrapper>
      <FormProvider
        initialValues={{ ...formValues, ...(initialValues || {}) }}
        validationSchema={validationSchema}
        onSubmit={(values) => onNext({ ...values })}
      >
        <Header title={title} subtitle={subtitle} categoryId={categoryId} />
        <ScrollableContent>{children}</ScrollableContent>
        <Footer
          onSkip={onSkip}
          hideBack={hideBack || isFirst}
          onBack={onBack}
          isLoading={isLoading}
        />
      </FormProvider>
    </FormWrapper>
  )
}
