import { AssessmentStepKeys } from '@/enums'
import { LeadGenerationAssessment } from './BasePage'

const professionalLiabilityAssessmentSteps = [
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.companyFoundedDateLead,
  AssessmentStepKeys.approxRevenue,
  AssessmentStepKeys.employeesCount,
  AssessmentStepKeys.approxLaborCosts,
  AssessmentStepKeys.createLead,
]

const makeSubmitValues = ({
  approxRevenue,
  approxLaborCosts,
  companyFoundedDateLead,
  ...steps
}) => ({
  ...steps,
  lastYearFinances: { ...approxLaborCosts, ...approxRevenue },
  companyFoundedDate: companyFoundedDateLead,
})

const makeStepProps = (transformSubmitValues) => ({
  [AssessmentStepKeys.createLead]: {
    categoryId: 'PF',
    transformSubmitValues,
  },
})

export const ProfessionalLiabilityLead = () => (
  <LeadGenerationAssessment
    categoryId="PF"
    makeStepProps={makeStepProps(makeSubmitValues)}
    stepKeys={professionalLiabilityAssessmentSteps}
  />
)
