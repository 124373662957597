import { api } from './api'

export const updateAddress = ({
  id,
  street,
  streetNr,
  postalCode,
  city,
  country,
}) =>
  api.put(`/address/${id}`, {
    street,
    streetNr,
    postalCode,
    city,
    country,
  })
