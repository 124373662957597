import { isNumber, isValidDate } from '@/utils'
import { useCallback } from 'react'
import { DateSelect } from '../DateSelect'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

const makeValue = (value) => {
  const date = new Date(value)
  if (isNumber(value) && isValidDate(date)) {
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      date: date.getDate(),
    }
  }

  return value
}

export const FormDateSelect = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  ignoreErrorKeys,
  ...props
}) => {
  const Component = useCallback(({ onChange, value, ...field }) => {
    const handleOnChange = ({ month, year, date }) => {
      if (month && year && date) {
        onChange(Date.parse(new Date(year, month - 1, date)))
      } else {
        onChange({ month, year, date })
      }
    }

    return (
      <DateSelect
        onChange={handleOnChange}
        value={makeValue(value)}
        {...field}
        {...props}
        dateKey="date"
        monthKey="month"
        yearKey="year"
      />
    )
  }, [])

  return (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      name={name}
    >
      <Component />
    </FormItem>
  )
}
FormDateSelect.propTypes = formItemPropType
