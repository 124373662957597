import { ComponentColors, InsuranceLabels, ThemeColor } from '@/enums'
import { useInsurance } from '@/services'
import { formatMoney } from '@/utils'
import { isEmpty, isNil } from 'ramda'
import { useParams } from 'react-router-dom'
import {
  BackComponent,
  Banner,
  CarrierLogo,
  Column,
  ContactExpert,
  InfoTooltip,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  Rating,
  Row,
  ShowWhen,
  TabPane,
  TabPanel,
  Typography,
} from '../../components'
import { Attributes } from './Attributes'
import { makeRatings } from './helpers'
import { InsuranceDocuments } from './InsuranceDocuments'
import {
  CarrierLogoWrapper,
  CurrentlyPaying,
  DetailsWrapper,
  InsuranceDetail,
  InsuranceDetails,
  InsuranceHeaderDetails,
  InsuranceViewContainer,
  RatingRow,
  RatingsCard,
  RatingsTopSection,
} from './styles'
import { InsuranceCustomBaseView } from './views/InsuranceCustomBaseView'
import { InsuranceDefaultView } from './views/InsuranceDefaultView'
import { PrivatePensionInsuranceView } from './views/PrivatePensionInsuranceView'
import { useTranslation } from 'react-i18next'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'

const InsuranceRatingRow = ({
  info,
  label,
  rating,
}: {
  info: string
  label: string
  rating: number
}) => (
  <Row alignItems="center" justifyContent="space-between" width="100%">
    <RatingRow>
      <Typography color={ThemeColor.b50} variant="p2Body">
        {label}
      </Typography>
      <InfoTooltip content={info} dark iconColor={ThemeColor.b50} size="menu" />
    </RatingRow>
    <Rating value={rating} />
  </Row>
)

const InsuranceView = () => {
  const { categoryId, insuranceId } = useParams()

  if (!categoryId) {
    throw new Error('categoryId is required')
  }

  const { t } = useTranslation()
  const { data, isLoading } = useInsurance(insuranceId, {
    enabled: !!insuranceId,
  })

  const categoryTranslations = useCategoryTranslations(categoryId)

  const paymentPeriodLabel = {
    monthly: t('monthlyPaying'),
    quarterly: t('quarterlyPaying'),
    'half-yearly': t('halfYearPaying'),
    yearly: t('yearlyPaying'),
    once: t('paying'),
    'non-contributory': t('paying'),
  }

  if (isLoading || !data) {
    return <Loader />
  }

  const ratings = makeRatings(data)

  return (
    <InsuranceViewContainer>
      <DetailsWrapper>
        <InsuranceHeaderDetails>
          {data.insurance_label === InsuranceLabels.submitted && (
            <Banner
              color={ComponentColors.warning}
              text={t('insurance_submitted')}
            />
          )}
          <Typography ellipsis responsive variant="h4">
            {categoryTranslations.categoryName}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {categoryTranslations.longDesc}
          </Typography>
        </InsuranceHeaderDetails>
        <CarrierLogoWrapper alignItems="center" gap="16px">
          <CarrierLogo name={data.carrier.logo_url} width="60px" />
          <Typography color={ThemeColor.b50} variant="p1Body">
            {data.carrier.name}
          </Typography>
        </CarrierLogoWrapper>
      </DetailsWrapper>
      <TabPanel>
        <TabPane key="overview" text={t('recommendations.tabs.overview')}>
          <InsuranceDetails>
            <CurrentlyPaying>
              <Row
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography color={ThemeColor.b50} variant="badgeText">
                  {data.payment_period
                    ? paymentPeriodLabel[data.payment_period] ?? t('paying')
                    : t('paying')}
                </Typography>
                <Typography variant="captionR">
                  {formatMoney(data.gross_price)}
                </Typography>
              </Row>
            </CurrentlyPaying>
            <ShowWhen when={!isEmpty(ratings)}>
              <RatingsCard>
                <Column gap="4px">
                  <Rating value={data?.overallRating} />
                  <RatingsTopSection>
                    <RatingRow>
                      <Typography variant="p2Body">
                        {t('overall_rating')}
                      </Typography>
                      <InfoTooltip
                        content={t('info.overallRating')}
                        dark
                        iconColor={ThemeColor.b50}
                        size="menu"
                      />
                    </RatingRow>
                  </RatingsTopSection>
                  <Column gap="16px">
                    {!isNil(ratings.contractCoverageRating) && (
                      <InsuranceRatingRow
                        info={t('info.contractCoverageRating')}
                        label={t('scope_of_coverage')}
                        rating={ratings.contractCoverageRating}
                      />
                    )}

                    {!isNil(ratings.customerSatisfactionRating) && (
                      <InsuranceRatingRow
                        info={t('info.customerSatisfactionRating')}
                        label={t('costumer_satisfaction')}
                        rating={ratings.customerSatisfactionRating}
                      />
                    )}

                    {!isNil(ratings.responseTimeRating) && (
                      <InsuranceRatingRow
                        info={t('info.responseTimeRating')}
                        label={t('processing_speed_enquiries')}
                        rating={ratings.responseTimeRating}
                      />
                    )}

                    {!isNil(ratings.claimHandlingRating) && (
                      <InsuranceRatingRow
                        info={t('info.claimHandlingRating')}
                        label={t('claims_handling')}
                        rating={ratings.claimHandlingRating}
                      />
                    )}
                  </Column>
                </Column>
              </RatingsCard>
            </ShowWhen>
            <Column gap="16px">
              <InsuranceDetail>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t('insurancePage.tariff')}
                </Typography>
                <Typography variant="p1Body">{data.name || '--'}</Typography>
              </InsuranceDetail>
              <InsuranceDetail>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t('insurancePage.policyNumber')}
                </Typography>
                <Typography variant="p1Body">
                  {data.policy_number || '--'}
                </Typography>
              </InsuranceDetail>
              <InsuranceDetail>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t('insurancePage.amountInsured')}
                </Typography>
                {data.amount_insured_unlimited
                  ? t('unlimited')
                  : formatMoney(data.amount_insured)}
              </InsuranceDetail>
              <InsuranceDetail>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t('location')}
                </Typography>
                <Typography variant="p1Body">{data.location.name}</Typography>
              </InsuranceDetail>
            </Column>
          </InsuranceDetails>
          <Attributes
            parametersValues={data.parameters_values}
            yesNoValues={data.yes_no_values ?? {}}
          />
        </TabPane>
        <TabPane key="documents" text={t('insuranceDocuments')}>
          <InsuranceDocuments
            files={data.files.map((file) => ({
              name: file.name,
              displayName: file.display_name,
              createdAt: file.created_at,
              visibleToUser: file.visible_to_user,
              category: file.category,
            }))}
          />
        </TabPane>
      </TabPanel>
    </InsuranceViewContainer>
  )
}

const FallbackInsuranceView = () => {
  const { categoryId } = useParams()
  switch (categoryId) {
    case 'PP':
      return <PrivatePensionInsuranceView />
    case 'HI':
      return <InsuranceCustomBaseView />
    case 'PE':
    case 'HE':
      return <InsuranceCustomBaseView graphicSection={2} />
    default:
      return <InsuranceDefaultView />
  }
}

export const InsuranceViewPage = () => {
  const { insuranceId } = useParams()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <ShowWhen fallback={InsuranceView} when={!insuranceId}>
          <FallbackInsuranceView />
        </ShowWhen>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert />
      </PageWidgetContainer>
    </>
  )
}
