import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'
import { Typography } from '../../atoms'

const getTabTransition = (attr) => css`
  transition: ${attr} 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`

export const TabText = styled(Typography).attrs({
  color: ThemeColor.b50,
  variant: 'p2Body',
})`
  ${getTabTransition('color')}
`

const TabBadge = styled(Typography).attrs({
  'data-testid': 'tab-badge',
  variant: 'badgeText',
})`
  padding: 1px 5px 2px;
  background-color: ${themeColor(ThemeColor.b100)};
  border-radius: 16px;
  margin-left: ${toThemePixels(0.5)};

  ${getTabTransition('background-color')}
`

const getTabContentStyles = (color) => css`
  ${TabText} {
    color: ${themeColor(ThemeColor.b100)};
  }

  ${TabBadge} {
    background-color: ${color};
  }
`

const tabActiveStyles = getTabContentStyles()
const tabHoverStyles = getTabContentStyles()

export const Container = styled.div`
  width: 100%;
`

export const TabList = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
`

export const TabsWrapper = styled.div.attrs({
  id: 'tabWrapper',
})`
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  display: flex;
  flex: 1 1 auto;
  gap: ${toThemePixels(8)};
  padding: 0;
  margin: 0 0 ${toThemePixels(4)} 0;
  white-space: nowrap;
`

export const StyledTab = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: ${toThemePixels(4)} 0;

  &&& {
    ${ifProp('active', tabActiveStyles)}

    :hover {
      ${ifNotProp('active', tabHoverStyles)}
    }
  }
`

export const TabInk = styled.div.attrs({ id: 'tabInk' })`
  position: absolute;
  height: 1px;
  background-color: ${themeColor(ThemeColor.b100)};
  bottom: ${toThemePixels(4)};
  ${getTabTransition('all')}
`
