import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'
import { ThemeColor } from '../../../enums'
import { Row } from '../../styled'

export const Container = styled.div`
  position: relative;

  input {
    cursor: pointer;
  }
`

const showOptions = keyframes`
  from {
    opacity: 0;
  }
`

export const YearPickerOptionContainer = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 100;
  display: ${ifProp('isOpen', 'block', 'none')};
  border-radius: ${toThemePixels(1)};
  background-color: ${themeColor(ThemeColor.b0)};
  top: ${toThemePixels(16)};
  left: 0;
  border: 1px solid ${themeColor(ThemeColor.b50)};
  animation: ${showOptions} 0.4s;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -6px;
    left: 20%;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid ${themeColor(ThemeColor.b0)};
  }
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -7px;
    left: calc(20% - 1px);
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid ${themeColor(ThemeColor.b50)};
  }
`
export const YearPickerOptionContainerHeader = styled.div`
  position: relative;
  padding-left: ${toThemePixels(1)};
  padding-right: ${toThemePixels(1)};
  height: ${toThemePixels(10)};
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const YearPickerLeftIcon = styled.span`
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 10px;
`
export const YearPickerRightIcon = styled.span`
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 10px;
`
export const YearPickerOptionContainerBody = styled.div`
  display: grid;
  height: calc(100% - 50px);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, fit-content);
  padding: ${toThemePixels(1)};
`
export const YearCell = styled(Row)`
  justify-content: center;
  align-items: center;
  background-color: ${ifProp('selected', themeColor(ThemeColor.info1))};
  border-radius: 5px;
  color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b0),
    ifProp('isCurrentYear', themeColor(ThemeColor.info1)),
  )};
  transition: ${themeTransition()};

  &:hover {
    background-color: ${ifProp(
      'selected',
      themeColor(ThemeColor.info1),
      themeColor(ThemeColor.b10),
    )};
    border-radius: 5px;
    cursor: pointer;
  }
`
