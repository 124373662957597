import { ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../components/styled'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: -webkit-fill-available;
`

export const ContactDataImageWrapper = styled(Column)`
  position: relative;
  pointer-events: none;
  border-radius: ${toThemePixels(3.5)};
  background-color: ${themeColor(ThemeColor.glass100)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
`

export const OfferReadyImageContainer = styled.div`
  position: relative;
  min-width: 50%;
  height: ${toThemePixels(36)};
  background-color: ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)} ${toThemePixels(3)} 0 0;
  overflow: hidden;
  padding: 24px 24px 1px 24px;

  img:first-child {
    object-fit: cover;
    object-position: top;
    overflow: hidden;
    height: 100%;
    margin: 0;
    border-radius: ${toThemePixels(3)} ${toThemePixels(3)} 0 0;
    border: 1px solid ${themeColor(ThemeColor.glass200)};
    width: 100%;
    width: 100vw;
  }

  img:last-child {
    width: 104px;
    height: auto;
    border-radius: 0;

    ${themeBreakpointDown('md')} {
      position: absolute;
      bottom: 16px;
      left: 42px;
    }
  }

  ${themeBreakpointDown('sm')} {
    height: ${toThemePixels(28)};
  }
`

export const ButtonLinkFontWrapper = styled.div`
  display: inline;
  span {
    font-size: 12px !important;
    font-weight: 500;
  }
`

export const ContactDataImageTextWrapper = styled(Column)`
  padding: 8px 16px;
  gap: 8px;
  background-color: ${themeColor(ThemeColor.glass100)};
  border-radius: 0 0 ${toThemePixels(3)} ${toThemePixels(3)};
`
