import { Categories } from '@/enums'
import { dynamicObjectPropType, propTypeFromEnumKeys } from '@/utils'
import PropTypes from 'prop-types'

export const locationPropType = {
  address: dynamicObjectPropType,
  locationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  riskScore: PropTypes.number.isRequired,
  essentialCategoryId: PropTypes.arrayOf(propTypeFromEnumKeys(Categories)),
}
