import { FormMonthSelect } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { currentYear, isValidDate } from '@/utils'
import { isNil } from 'ramda'
import { useSaveAssessmentStep } from '../helpers'

export const ConstructionDateStep = () => {
  const { value } = useFormItem('constructionDate')

  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.constructionDate,
  )

  useOnNextClick(save)

  useNextDisabled(() => !isValidDate(new Date(value)) || isNil(value), [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormMonthSelect
      initialValue={Date.parse(new Date(currentYear(), 0))}
      maxDate={new Date(currentYear(), 11, 31, 12)}
      name="constructionDate"
    />
  )
}
