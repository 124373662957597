import { dynamicObjectPropType } from '@/utils'
import PropTypes from 'prop-types'
import { mergeLeft, pick } from 'ramda'
import { Option, Select } from '../../atoms'
import { MonthSelect } from '../MonthSelect'
import { makeDates } from './helpers'
import { Container } from './styles'

export const DateSelect = ({
  value = {},
  onChange,
  dateKey = 'date',
  yearKey = 'year',
  monthKey = 'month',
  minDate = new Date('1900'),
  maxDate = new Date(),
  shouldDisable = true,
}) => {
  const dates = makeDates(value[monthKey], value[yearKey])

  const handleChange = (newValue) => onChange(mergeLeft(newValue, value))

  return (
    <Container>
      <Select
        onSelect={(date) => handleChange({ [dateKey]: date })}
        value={value[dateKey]}
      >
        {dates.map((item) => {
          const date = new Date(
            `${value[yearKey]}-${String(value[monthKey]).padStart(
              2,
              '0',
            )}-${String(item).padStart(2, '0')}`,
          )

          return (
            <Option
              key={item}
              disabled={shouldDisable && (date > maxDate || date < minDate)}
              value={item}
            >
              {item}
            </Option>
          )
        })}
      </Select>
      <MonthSelect
        maxDate={maxDate}
        minDate={minDate}
        monthKey={monthKey}
        onChange={handleChange}
        shouldDisable={shouldDisable}
        value={pick([monthKey, yearKey], value)}
        yearKey={yearKey}
      />
    </Container>
  )
}

DateSelect.propTypes = {
  dateKey: PropTypes.string,
  maxDate: dynamicObjectPropType,
  minDate: dynamicObjectPropType,
  monthKey: PropTypes.string,
  onChange: PropTypes.func,
  shouldDisable: PropTypes.bool,
  value: PropTypes.number,
  yearKey: PropTypes.string,
}
