import PropTypes from 'prop-types'
import { buttonSizePropType, buttonVariantPropType } from './propTypes'
import { Container } from './styles'

export const ButtonBase = ({
  size = 'large',
  variant,
  disabled = false,
  type = 'button',
  loading,
  ...rest
}) => (
  <Container
    disabled={disabled || loading}
    size={size}
    type={type}
    variant={variant}
    {...rest}
  />
)

ButtonBase.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: buttonSizePropType,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: buttonVariantPropType,
}
