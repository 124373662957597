import { getAttributes } from '@/api/attributes'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

export const useAttributes = () => {
  const { i18n } = useTranslation()

  return useQuery(
    'attributes',
    () => getAttributes((i18n.language as 'de' | 'en') ?? 'de'),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}
