import {
  primaryColorFromComponentColor,
  quaternaryColorFromComponentColor,
  toThemePixels,
  trinaryColorFromComponentColor,
} from '@/utils'
import styled from 'styled-components'
import { ifNotProp, ifProp, switchProp } from 'styled-tools'
import { Row } from '../../styled'

export const Container = styled(Row)`
  background-color: ${quaternaryColorFromComponentColor};
  border: 1px solid ${trinaryColorFromComponentColor};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(3)};
  gap: ${toThemePixels(3)};
  align-items: ${ifNotProp('vertical', 'center')};
  flex-direction: ${ifProp('vertical', 'column')};

  span {
    color: ${primaryColorFromComponentColor};
  }

  svg {
    background: transparent;
    fill: transparent;
    stroke: ${primaryColorFromComponentColor};
  }
`

const getAlignment = switchProp(
  'iconAlignment',
  {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end',
  },
  'center',
)

export const IconContainer = styled.div`
  min-height: fit-content;
  min-width: fit-content;
  align-self: ${getAlignment};
`
