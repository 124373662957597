import PropTypes from 'prop-types'
import { selectOptionValuePropType } from '../Select/helpers'
import { StyledOption } from './styles'

export const OptionNode = ({
  width,
  onClick,
  error,
  disabled,
  selected,
  value,
  children,
  centered,
}) => (
  <StyledOption
    centered={centered}
    disabled={disabled}
    error={error}
    onClick={() => {
      if (!disabled) onClick(value)
    }}
    selected={selected}
    value={value}
    width={width}
  >
    {children}
  </StyledOption>
)

OptionNode.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: selectOptionValuePropType.isRequired,
  width: PropTypes.number.isRequired,
}
