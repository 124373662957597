import { isValidIBAN } from 'ibantools'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const usePaymentMethodValidation = () => {
  const { t } = useTranslation()

  return Yup.object().shape({
    iban: Yup.string()
      .required(t('validations.general'))
      .test('is-valid-iban', t('profile.payments.invalidIban'), (value) => {
        if (!value) return false
        return isValidIBAN(value)
      })
      .test(
        'is-german-iban',
        t('profile.payments.noneGermanIban'),
        (value) => value && value.startsWith('DE'),
      ),
    name: Yup.string().required(t('validations.general')),
  })
}
