import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Row } from '../../styled'

export const Card = styled(Row)`
  justify-content: space-between;
  align-items: center;
  gap: ${toThemePixels(15)};
  width: 100%;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  justify-content: space-between;
  align-items: center;
  border-color: ${ifProp('selected', themeColor(ThemeColor.success2))};

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    align-items: flex-start;
    gap: ${toThemePixels(4)};
  }

  > *:not(button) {
    flex: 1;
  }
`
