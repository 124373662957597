import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { Column } from '../../styled'

export const InsuranceFinderCardContainer = styled.div`
  width: 100%;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  overflow: hidden;
`

export const ImageContainer = styled.div`
  position: relative;
  width: 35%;
  background-color: ${themeColor(ThemeColor.b10)};

  img {
    object-fit: cover;
    object-position: ${prop('backgroundImageX', 'center')}
      ${prop('backgroundImageY', 'center')};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: -1px;
  }

  @media (max-width: 820px) {
    width: 100%;
    height: 140px;

    img {
      margin: 0px;
    }
  }
`

export const InsuranceCardTop = styled.div`
  display: flex;
  background-color: ${themeColor(ThemeColor.b10)};

  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`

export const InsuranceCardBottom = styled(Column)`
  gap: 16px;
  padding: ${toThemePixels(8)};
  padding-top: ${toThemePixels(5)};

  & > button {
    align-self: flex-start;
  }
`

export const InsuranceCardTitleContainer = styled(Column)`
  gap: ${toThemePixels(4)};
  width: 70%;
  padding: ${toThemePixels(8)};

  @media (max-width: 820px) {
    width: 100%;
    padding: ${toThemePixels(8)};
    padding-top: ${toThemePixels(5)};
  }
`
