/* eslint-disable import/no-cycle */

import { ComponentColors } from './theme'

export const Categories = {
  LI: {
    key: 'liability',
    show: true,
    group: 'company',
    subgroup: 'basic',
  },
  CO: {
    key: 'content',
    show: true,
    group: 'company',
    subgroup: 'basic',
  },
  LE: {
    key: 'legal',
    show: true,
    group: 'company',
    subgroup: 'basic',
  },
  CA: {
    key: 'car',
    show: true,
    group: 'company',
    subgroup: 'mobility',
  },
  BU: {
    key: 'building',
    show: true,
    group: 'company',
    subgroup: 'realEstate',
  },
  RE: {
    key: 'rent',
    show: true,
    group: 'company',
    subgroup: 'realEstate',
  },
  CY: {
    key: 'cyber',
    show: true,
    group: 'company',
    subgroup: 'digital',
  },
  TR: {
    key: 'transport',
    show: true,
    group: 'company',
    subgroup: 'mobility',
  },
  HE: {
    key: 'companyHealth',
    show: true,
  },
  PE: {
    key: 'companyPension',
    show: true,
  },
  EL: {
    key: 'electronics',
    show: true,
    group: 'company',
    subgroup: 'digital',
  },
  DO: {
    key: 'd&o',
    show: true,
    group: 'managingDirector',
  },
  DI: {
    key: 'direct',
    show: true,
    group: 'employeesPension',
  },
  PP: {
    key: 'pensionPlan',
    show: true,
    group: 'important',
  },
  PF: {
    key: 'professionalLiability',
  },
  CC: {
    key: 'carContents',
  },
  GL: {
    key: 'glass',
  },
  HL: {
    key: 'homeLandowner',
  },
  HO: {
    key: 'homeowner',
  },
  TL: {
    key: 'termLife',
  },
  AI: {
    key: 'accident',
  },
  EV: {
    key: 'eventLiability',
  },
  FL: {
    key: 'financialLoss',
  },
  BS: {
    key: 'businessClosure',
  },
  AL: {
    key: 'multiline',
  },
  CL: {
    key: 'clubLiability',
  },
  BP: {
    key: 'basicPension',
  },
  TH: {
    key: 'travelHealth',
  },
  EX: {
    key: 'exhibition',
  },
  BL: { key: 'builderLiability' },
  PR: { key: 'buildingPerformance' },
  DS: { key: 'disability' },
  IN: {
    key: 'businessInterruption',
  },
  CR: {
    key: 'carrierLiability',
  },
  DB: {
    key: 'dailyBenefit',
  },
  HI: { key: 'health', show: true, group: 'important' },
  SH: {
    key: 'statutoryHealth',
  },
  CH: {
    key: 'comprehensiveHealth',
  },
  SU: {
    key: 'supplementaryHealth',
  },
  MI: {
    key: 'machinery',
    show: true,
    group: 'company',
    subgroup: 'digital',
  },
  LC: {
    key: 'longTermCare',
  },
  PL: {
    key: 'personalLiability',
  },
  TC: {
    key: 'travelCancellation',
  },
  AN: {
    key: 'annuity',
  },
  RP: {
    key: 'riesterPension',
  },
  AO: {
    key: 'animalOwner',
  },
  AH: {
    key: 'animalHealth',
  },
  LF: {
    key: 'life',
  },
  OD: {
    key: 'occupationalDissability',
  },
  WD: {
    key: 'waterDamage',
  },
  BC: {
    key: 'basicPensionClassic',
  },
  SV: {
    key: 'buildingSavings',
  },
  OL: {
    key: 'operatorsLiability',
  },
  LP: {
    key: 'profitLoss',
  },
  CP: {
    key: 'camping',
  },
  LO: {
    key: 'loan',
  },
  EM: {
    key: 'employersLiability',
  },
  DR: {
    key: 'dreadDisease',
  },
  FU: { key: 'funds' },
  CM: { key: 'camera' },
  WA: { key: 'warranty' },
  BA: { key: 'basicAbilities' },
  HU: { key: 'huntingLiability' },
  DH: { key: 'dailyHospital' },
  AR: { key: 'art' },
  SP: { key: 'spa' },
  LU: {
    key: 'lifeUnit',
  },
  LL: {
    key: 'lifeClasic',
  },
  RL: { key: 'rentLoss' },
  MU: { key: 'music' },
  NC: { key: 'nursingCare' },
  DN: { key: 'dailyNursing' },
  BG: {
    key: 'baggage',
  },
  PC: { key: 'pensionClassic' },
  ST: {
    key: 'statutoryPension',
  },
  RI: {
    key: 'riesterClassic',
  },
  PO: {
    key: 'protectiveCover',
  },
  ML: {
    key: 'marineLiability',
  },
  OT: {
    key: 'otherLines',
  },
  SB: {
    key: 'sportBoat',
  },
  DE: {
    key: 'deathBenefit',
  },
  TE: {
    key: 'technical',
  },
  AP: {
    key: 'accidentPremium',
  },
  AM: {
    key: 'accidentMulti',
  },
  TA: {
    key: 'trafficLiability',
  },
  BI: {
    key: 'bicycleInsurance',
  },
}

export const InsuranceLabels = {
  important: 'important',
  recommended: 'recommended',
  useful: 'useful',
  veryGood: 'veryGood',
  optimisable: 'optimisable',
  weak: 'weak',
  submitted: 'submitted',
}

export const InsuranceStages = {
  SAF: 'SAF',
  POT: 'POT',
  SUB: 'SUB',
  IMP: 'IMP',
  REC: 'REC',
  OPT: 'OPT',
}

export const InsuranceLabelColors = {
  [InsuranceLabels.important]: ComponentColors.danger,
  [InsuranceLabels.recommended]: ComponentColors.warning,
  [InsuranceLabels.useful]: ComponentColors.primary,
  [InsuranceLabels.veryGood]: ComponentColors.success,
  [InsuranceLabels.optimisable]: ComponentColors.primary,
  [InsuranceLabels.weak]: ComponentColors.danger,
  [InsuranceLabels.submitted]: ComponentColors.warning,
}

export const InsuranceLabelVariant = {
  [InsuranceLabels.important]: 'outlined',
  [InsuranceLabels.recommended]: 'outlined',
  [InsuranceLabels.useful]: 'outlined',
  [InsuranceLabels.veryGood]: 'contained',
  [InsuranceLabels.optimisable]: 'contained',
  [InsuranceLabels.weak]: 'contained',
  [InsuranceLabels.submitted]: 'contained',
}

export const InsuranceLabelStep = {
  [InsuranceLabels.submitted]: 3,
  [InsuranceLabels.veryGood]: 3,
  [InsuranceLabels.optimisable]: 2,
  [InsuranceLabels.weak]: 1,
}

export const StageColors = {
  [InsuranceStages.SAF]: ComponentColors.success,
  [InsuranceStages.SUB]: ComponentColors.success,
  [InsuranceStages.IMP]: ComponentColors.danger,
  [InsuranceStages.POT]: ComponentColors.warning,
  [InsuranceStages.REC]: ComponentColors.primary,
  [InsuranceStages.OPT]: ComponentColors.primary,
}

export const locationTypeEmojis = {
  gastronomy: {
    restaurant: '🧑‍🍳',
    caffee: '☕️',
    bar: '🍻',
    iceCream: '🍦',
    canteen: '🍴',
    snack: '🍪',
    delivery: '🚚',
    bakery: '🥖',
    hotel: '🏨',
  },
}
