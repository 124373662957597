import { Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useTranslation } from 'react-i18next'
import {
  AbsoluteImageContainer,
  IndependentBrokerContainer,
  IndependentBrokerTextContainer,
  StyledImageFromCdn,
} from './styles'
import { useIsMobile } from '@/utils'

export const IndependentBroker = () => {
  const { isMobile } = useIsMobile()
  const { t } = useTranslation()
  return (
    <IndependentBrokerContainer gap="32px">
      <AbsoluteImageContainer>
        <StyledImageFromCdn name="carrier-waterfall" type="png" width="100%" />
      </AbsoluteImageContainer>
      <IndependentBrokerTextContainer>
        <Typography bold variant="h3">
          {isMobile
            ? t('recommendationIndependentSection.mobileTitle')
            : t('recommendationIndependentSection.title')}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {t('recommendationIndependentSection.description')}
        </Typography>
        <Typography color={ThemeColor.b40} variant="p2Body">
          {t('recommendationIndependentSection.footnote')}
        </Typography>
      </IndependentBrokerTextContainer>
    </IndependentBrokerContainer>
  )
}
