import { ThemeColor } from '@/enums'
import { ifNotProps, themeColor, themeTransition, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const Container = styled.div`
  gap: ${toThemePixels(5)};
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const StyledOption = styled.div`
  gap: ${toThemePixels(2)};
  border-radius: ${toThemePixels(25)};
  cursor: pointer;
  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger2),
      ifProp(
        'selected',
        themeColor(ThemeColor.b100),
        themeColor(ThemeColor.b30),
      ),
    )};
  display: flex;
  justify-content: space - between;
  align-items: center;
  padding: ${toThemePixels(3)} ${toThemePixels(3.25)};
  transition: ${themeTransition('background-color')};
  background-color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b100),
    ifProp('error', themeColor(ThemeColor.danger4)),
  )};
  color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b0),
    themeColor(ThemeColor.b100),
  )};

  &:hover {
    border-color: ${ifNotProps(
      ['selected', 'disabled'],
      themeColor(ThemeColor.b100),
    )};
    color: ${ifProp('selected', themeColor(ThemeColor.b30))};
  }
`
