import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  max-width: 510px;
  gap: ${toThemePixels(2)};

  img {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const TextBlock = styled(Row)`
  max-width: 400px;
  padding: ${toThemePixels(2)};
  background-color: ${themeColor(ThemeColor.b10)};
  border-radius: 0 ${toThemePixels(2)} ${toThemePixels(2)} ${toThemePixels(2)};
`
