import { Categories, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import {
  propTypeFromEnumKeys,
  useGoToPath,
  useTranslationSection,
} from '@/utils'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CategoryIcon, Typography } from '../../atoms'
import { Container } from './styles'

const useMessages = (category) => {
  const categoriesTranslations = useTranslationSection('categories')

  return {
    title: categoriesTranslations(`${category}.name`),
    description: categoriesTranslations(`${category}.shortDesc`),
  }
}

export const OtherInsuranceCardBase = ({ categoryId, onClick }) => {
  const categoryData = Categories[categoryId]
  const messages = useMessages(categoryData.key)

  return (
    <Container onClick={onClick}>
      <CategoryIcon categoryId={categoryId} />
      <Typography variant="captionB">{messages.title}</Typography>
      <Typography color={ThemeColor.b50} variant="p2Body">
        {messages.description}
      </Typography>
    </Container>
  )
}

OtherInsuranceCardBase.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  onClick: PropTypes.func,
}

export const OtherInsuranceCard = ({ categoryId }) => {
  const { locationId } = useParams()

  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({ categoryId, locationId }),
  )

  return (
    <OtherInsuranceCardBase categoryId={categoryId} onClick={goToInsurance} />
  )
}

OtherInsuranceCard.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
}
