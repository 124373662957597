import { ModalBase, Option, Select, Typography } from '@/components'
import { paths } from '@/routes/paths'
import {
  useCreateMultipleInsurances,
  useUploadInsuranceFiles,
} from '@/services'
import {
  dynamicArrayPropType,
  useGoToPath,
  useTranslationSection,
} from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { CarrierDropdownContainer } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  const pageTranslations = useTranslationSection('addInsurance')
  return {
    chooseCompany: pageTranslations('chooseCompany'),
    prompt: pageTranslations('prompt'),
    upload: t('uploadContract'),
  }
}

export const UploadContractModal = ({ files, onClose }) => {
  const { categoryId, locationId } = useParams()
  const messages = useMessages(categoryId)
  const { carriers } = useOutletContext()
  const uploadContractMutation = useUploadInsuranceFiles()
  const createInsuranceMutation = useCreateMultipleInsurances()
  const [carrierId, setCarrierId] = useState()

  const goToInsurance = useGoToPath(paths.viewInsuranceWithKey)

  const handleUploadContract = () => {
    createInsuranceMutation
      .mutateAsync({ locationId, data: [{ categoryId, carrierId }] })
      .then(({ created }) =>
        uploadContractMutation
          .mutateAsync({ id: created[0], files })
          .then(() => {
            goToInsurance({
              locationId,
              categoryId,
              insuranceId: created[0],
            })
          }),
      )
  }

  return (
    <ModalBase
      closable
      header={<Typography variant="h3">{messages.prompt}</Typography>}
      isOpen
      onClose={onClose}
      onPrimaryClick={handleUploadContract}
      primaryDisabled={!carrierId}
      primaryText={messages.upload}
    >
      <CarrierDropdownContainer>
        <Select
          onSelect={setCarrierId}
          placeholder={messages.chooseCompany}
          value={carrierId}
        >
          {carriers?.map(({ text, value, icon }) => (
            <Option key={value} icon={icon} value={value}>
              {text}
            </Option>
          ))}
        </Select>
      </CarrierDropdownContainer>
    </ModalBase>
  )
}

UploadContractModal.propTypes = {
  files: dynamicArrayPropType,
  onClose: PropTypes.func.isRequired,
}
