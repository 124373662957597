import { currentYear, useClickOutsideEvent, useToggable } from '@/utils'
import PropTypes from 'prop-types'
import { add, evolve, range, subtract, __ } from 'ramda'
import { useState } from 'react'
import { Input } from '../../styled'
import { makeDefaultRange } from './helpers'
import {
  Container,
  YearCell,
  YearPickerLeftIcon,
  YearPickerOptionContainer,
  YearPickerOptionContainerBody,
  YearPickerOptionContainerHeader,
  YearPickerRightIcon,
} from './styles'

export const YearPicker = ({
  fromYear,
  toYear = currentYear(),
  value,
  onChange,
}) => {
  const [isOpen, setOpen, toggle] = useToggable(false)
  const [ref, setRef] = useState()

  const [{ from, to }, setRange] = useState(
    makeDefaultRange(toYear, value || toYear),
  )

  const onPrev = () =>
    setRange(evolve({ from: subtract(__, 12), to: subtract(__, 12) }))

  const onNext = () => setRange(evolve({ from: add(12), to: add(12) }))

  const handleChange = (val) => {
    if (fromYear <= val) {
      onChange(val)
      setTimeout(toggle, 400)
    }
  }

  const handleBlur = () => setTimeout(setOpen(false), 400)

  useClickOutsideEvent(ref, handleBlur, [value])

  return (
    <Container ref={setRef}>
      <Input onClick={toggle} readOnly value={value} />
      <YearPickerOptionContainer isOpen={isOpen}>
        <YearPickerOptionContainerHeader onBlur={(e) => e.stopPropagation()}>
          {from > fromYear && (
            <YearPickerLeftIcon onClick={onPrev}>{'<-'}</YearPickerLeftIcon>
          )}
          {from} - {to}
          {to !== toYear && (
            <YearPickerRightIcon onClick={onNext}>{'->'}</YearPickerRightIcon>
          )}
        </YearPickerOptionContainerHeader>
        <YearPickerOptionContainerBody>
          {range(from, to + 1).map((year) => (
            <YearCell
              disabled={fromYear > year}
              isCurrentYear={year === currentYear()}
              onClick={() => handleChange(year)}
              selected={value === year}
            >
              {year}
            </YearCell>
          ))}
        </YearPickerOptionContainerBody>
      </YearPickerOptionContainer>
    </Container>
  )
}

YearPicker.propTypes = {
  fromYear: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  toYear: PropTypes.number,
  value: PropTypes.number,
}
