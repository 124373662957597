import { FormCalendar } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { isValidDate, currentYear } from '@/utils'
import { isNil } from 'ramda'
import { useSaveAssessmentStep } from '../helpers'

export const CompanyFoundedDateStep = () => {
  const { value } = useFormItem('foundedDate')

  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.companyFoundedDate,
  )

  useOnNextClick(save)

  useNextDisabled(() => !isValidDate(new Date(value)) || isNil(value), [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormCalendar
      maxDate={new Date(currentYear(), 11, 31, 12)}
      name="foundedDate"
    />
  )
}
