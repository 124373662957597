import { CheckBox, Column, FormInput, FormLocation } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { anyPropMissing, spread, useToggable } from '@/utils'
import { equals, map, omit, pipe, prop } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssessmentValues, useSaveAssessmentStep } from '../helpers'
import { Divider } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    locationName: t('name'),
    locationNameExample: t('locationNameExample'),
    sameAsCompanyName: t('sameAsCompanyName'),
    multipleLocationBannerTitle: t('multipleLocationBannerTitle'),
    multipleLocationBanner: t('multipleLocationBanner'),
  }
}

const withoutAddressId = omit(['addressId'])

const makeInitialSameValue = (...p) =>
  pipe(map(pipe(prop('address'), withoutAddressId)), spread(equals))(p)

export const LocationDetailsStep = () => {
  const messages = useMessages()
  const { values, setFieldValue } = useFormContext()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationDetails,
  )
  const {
    values: { companyDetails },
  } = useAssessmentValues()

  const [same, setSame] = useToggable(
    makeInitialSameValue(values, companyDetails),
  )

  useOnNextClick(save)

  useNextDisabled(() => anyPropMissing(['name', 'address'], values), [values])

  useNextLoading(() => isLoading, [isLoading])

  useEffect(() => {
    if (!values.address?.street) {
      setFieldValue('address', withoutAddressId(companyDetails.address))
    }
  }, [])

  useEffect(() => {
    if (same && !makeInitialSameValue(values, companyDetails)) setSame(false)

    if (!same && makeInitialSameValue(values, companyDetails)) setSame(true)
  }, [values])

  const handleSameSelect = (e) => {
    setSame(!e)
    if (e) {
      setFieldValue('address', {
        city: '',
        postalCode: '',
        street: '',
        streetNr: '',
        country: values.address.country,
      })
    } else {
      setFieldValue('address', withoutAddressId(companyDetails.address))
    }
  }

  return (
    <Column gap="32px">
      <FormInput
        helperText={messages.locationNameExample}
        label={messages.locationName}
        name="name"
      />
      <Divider />
      <CheckBox
        checked={same}
        label={messages.sameAsCompanyName}
        onChange={handleSameSelect}
      />
      <FormLocation label={messages.addressLabel} name="address" />
    </Column>
  )
}
