import { ThemeColor } from '@/enums'
import { componentColorPropType } from '@/utils'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { Container } from './styles'

export const Badge = ({ count, color, size }) => {
  if (!count) return null

  return (
    <Container color={color} size={size}>
      <Typography color={ThemeColor.b0} variant="badgeText">
        {count}
      </Typography>
    </Container>
  )
}

Badge.propTypes = {
  color: componentColorPropType,
  count: PropTypes.number,
  size: PropTypes.string,
}
