import { fromEventTarget, preventDefault } from '@/utils'
import {
  both,
  ifElse,
  includes,
  length,
  pipe,
  propSatisfies,
  range,
  replace,
  when,
  __,
} from 'ramda'

export const replaceAllComas = replace(/\./g, '')

export const handleKeyDown = ifElse(
  propSatisfies(
    includes(__, [
      '-',
      '+',
      ...range(0, 10).map(String),
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ]),
    'key',
  ),
  when(
    both(
      pipe(fromEventTarget, length),
      propSatisfies(includes(__, ['-', '+']), 'key'),
    ),
    preventDefault,
  ),
  preventDefault,
)
