import { Categories } from '@/enums'
import { useTranslationSectionObject } from '@/utils'
import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'

/**
 * @deprecated this feels redundant - just use t() directly
 * FIXME: this is used by multiple components - don't do that - it creates coupling and is hard to understand
 * @param categoryId
 */
export const useMessages = (categoryId: keyof typeof Categories) => {
  const { t } = useTranslation()
  const paymentTranslations = t('paymentPeriodOptions', { returnObjects: true })

  const insuranceFinderViewsTranslations = useTranslationSectionObject(
    `insuranceFinderTranslations.insuranceFinderViews.${Categories[categoryId].key}`,
  )

  const categoriesTranslations = useCategoryTranslations(categoryId)

  return {
    requestSuccess: t('snackbars.success.requestSuccess'),
    categoryName: categoriesTranslations.categoryName,
    longDesc: categoriesTranslations.longDesc,
    shortDesc: categoriesTranslations.shortDesc,
    usps: categoriesTranslations.usps.filter(Boolean),
    year: t('year'),
    unlimited: t('unlimited'),
    back: t('common.back'),
    insuranceDetails: t('insurance_details'),
    insurance: t('common.insurance'),
    description: t('common.description'),
    years: t('common.years'),
    contractDetails: t('insurancePage.contractDetails'),
    sumInsured: t('insurancePage.sumInsured'),
    tariff: t('insurancePage.tariff'),
    runtime: t('insurancePage.runtime'),
    paymentMethodPrice: t('insurancePage.paymentMethodPrice'),
    contractEnd: t('insurancePage.contractEnd'),
    policy: t('insurancePage.policy'),
    policyNumber: t('insurancePage.policyNumber'),
    amountInsured: t('insurancePage.amountInsured'),
    overview: t('recommendations.tabs.overview'),
    price: t('recommendations.details.grossPrice'),
    requestOffer: t('requestOffer'),
    uploadExistingContract: t('uploadExistingContract'),
    aboutInsurance: t('aboutInsurance'),
    openOffer: t('openOffer'),
    offerInProgress: t('offerInProgress'),
    paymentTranslations,
    whatsCovered: t('whatsCovered'),
    insuranceDocuments: t('insuranceDocuments'),
    location: t('location'),
    submitted: t('insurance_submitted'),
    yearlyPaying: t('yearlyPaying'),
    halfYearPaying: t('halfYearPaying'),
    quarterlyPaying: t('quarterlyPaying'),
    monthlyPaying: t('monthlyPaying'),
    paying: t('paying'),
    overallRating: t('overall_rating'),
    overallRatingInfo: t('info.overallRating'),
    customerSatisfactionRating: t('costumer_satisfaction'),
    customerSatisfactionInfo: t('info.customerSatisfactionRating'),
    contractCoverageRating: t('scope_of_coverage'),
    scopeOfCoverageInfo: t('info.contractCoverageRating'),
    claimHandlingRating: t('claims_handling'),
    claimsHandlingInfo: t('info.claimHandlingRating'),
    responseTimeRating: t('processing_speed_enquiries'),
    processingSpeedEnquiriesInfo: t('info.responseTimeRating'),
    haveAContract: t('insuranceFinderTranslations.haveAContract'),
    uploadContract: t('insuranceFinderTranslations.uploadContract'),
    finderSectionTranslations: insuranceFinderViewsTranslations,
    features: t(
      `insuranceFinderTranslations.insuranceFinderCards.${Categories[categoryId].key}.features`,
      { returnObjects: true },
    ),
  } as const
}

export const makeRatings = (data: {
  carrier: Partial<{
    customer_satisfaction_rating: number | null
    response_time_rating: number | null
    claim_handling_rating: number | null
  }>
  contractCoverageRating?: number | null
}): Partial<{
  customerSatisfactionRating: number
  responseTimeRating: number
  claimHandlingRating: number
  contractCoverageRating: number
}> => {
  const ratings: Partial<{
    customerSatisfactionRating: number
    responseTimeRating: number
    claimHandlingRating: number
    contractCoverageRating: number
  }> = {}

  if (!isNil(data.carrier.claim_handling_rating)) {
    ratings.claimHandlingRating = data.carrier.claim_handling_rating
  }

  if (!isNil(data.carrier.customer_satisfaction_rating)) {
    ratings.customerSatisfactionRating =
      data.carrier.customer_satisfaction_rating
  }

  if (!isNil(data.carrier.response_time_rating)) {
    ratings.responseTimeRating = data.carrier.response_time_rating
  }

  if (!isNil(data.contractCoverageRating)) {
    ratings.contractCoverageRating = data.contractCoverageRating
  }

  return ratings
}

export const graphicsImages = {
  en: {
    PP: 'private-pension-graphic-en',
    HI: 'private-health-graphic-en',
    PE: 'company-pension-graphic-en',
    HE: 'company-health-graphic-en',
  },
  de: {
    PP: 'private-pension-graphic-de',
    HI: 'private-health-graphic-de',
    PE: 'company-pension-graphic-de',
    HE: 'company-health-graphic-de',
  },
} as const

export const mobileGraphicsImages = {
  en: {
    PP: 'private-pension-graphic-mobile-en',
    HI: 'private-health-graphic-mobile-en',
    PE: 'company-pension-graphic-mobile-en',
    HE: 'company-health-graphic-mobile-en',
  },
  de: {
    PP: 'private-pension-graphic-mobile-de',
    HI: 'private-health-graphic-mobile-de',
    PE: 'company-pension-graphic-mobile-de',
    HE: 'company-health-graphic-mobile-de',
  },
} as const
