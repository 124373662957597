import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

export const Container = styled.div<{ hasPrefix: boolean; hasSuffix: boolean }>`
  position: relative;
  width: 100%;
  input {
    padding-left: ${ifProp('hasPrefix', toThemePixels(10))};
    padding-right: ${ifProp('hasSuffix', toThemePixels(10))};
  }
`

export const Addon = styled.span<{ before: boolean }>`
  position: absolute;
  height: 100%;
  width: ${ifProp('before', toThemePixels(10))};
  min-width: ${toThemePixels(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: ${ifProp('before', 0)};
  right: ${ifNotProp('before', 0)};
`
