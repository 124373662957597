import { setAuthToken } from '@/api/api'
import { Loader } from '@/components/atoms/Loader'
import { i18nHelpers } from '@/i18n/init'
import { paths } from '@/routes/paths'
import {
  clearLocalStorage,
  clearSessionStorage,
  makeUrlWithLanguage,
  oneOrManyChildren,
  renameObjKeysBy,
  snakeToCamelCase,
  useLanguage,
} from '@/utils'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { assoc, isEmpty, omit, pipe } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { config } from '../config'

class FailedToGetAccessToken extends Error {
  name = FailedToGetAccessToken.name

  constructor(cause) {
    super('Failed to get access token', { cause })
  }
}

const makeRedirectURL = makeUrlWithLanguage(paths.authRedirect)

const TokenProvider = ({ children }) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true)
      const payload = {
        audience: config.backendApiAudience,
        scope: 'openid profile email',
      }

      const getToken = window.origin.includes('localhost')
        ? getAccessTokenWithPopup
        : getAccessTokenSilently

      getToken(payload)
        .then((accessToken) => {
          setAuthToken(accessToken)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          throw new FailedToGetAccessToken(err)
        })
    }
  }, [isAuthenticated, getAccessTokenWithPopup, getAccessTokenSilently])

  if (isLoading && isAuthenticated) return <Loader />

  return children
}

TokenProvider.propTypes = {
  children: oneOrManyChildren,
}

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation()

  return (
    <Auth0Provider
      clientId={config.auth0ClientId}
      domain={config.authDomain}
      redirectUri={makeRedirectURL(i18n.language || i18nHelpers.fallbackLng)}
    >
      <TokenProvider>{children}</TokenProvider>
    </Auth0Provider>
  )
}
AuthProvider.propTypes = {
  children: oneOrManyChildren,
}

const assignRole = (user = {}) => {
  const [role] = user?.['https://surein.auth0/role'] || []

  if (isEmpty(user)) return undefined

  return pipe(omit(['https://surein.auth0/role']), assoc('role', role))(user)
}

const transform = renameObjKeysBy(snakeToCamelCase)

export const useAuth = () => {
  const authContext = useAuth0()

  const language = useLanguage()

  const login = (returnTo, options) => {
    authContext.loginWithRedirect({
      ...options,
      redirectUri: returnTo || makeRedirectURL(language),
      ui_locales: language,
    })
  }

  const logout = (returnTo, options) => {
    clearSessionStorage()
    clearLocalStorage()
    authContext.logout({
      ...options,
      returnTo: returnTo || makeRedirectURL(language),
    })
  }

  const user = assignRole(authContext.user)

  return transform({
    ...authContext,
    login,
    logout,
    user,
  })
}
