import { TypographyVariants } from '@/enums'
import { AnyStyledComponent } from 'styled-components'
import { forwardRef, ReactNode } from 'react'

import {
  BadgeText,
  Body,
  Button,
  CaptionB,
  CaptionR,
  Description,
  Footnote,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  InputDescription,
  InputLabel,
  Inter,
  ItemTitle,
  Micro,
  P1Body,
  P2Body,
  PageTitle,
  Placeholder,
  SectionTitle,
  Subheadline,
} from './styles'

const variants: Record<keyof typeof TypographyVariants, AnyStyledComponent> = {
  // deprecated variants
  [TypographyVariants.h1]: H1, // text1
  [TypographyVariants.h2]: H2, // text2
  [TypographyVariants.h3]: H3, // text2.5
  [TypographyVariants.h4]: H4, // text3
  [TypographyVariants.h5]: H5, // text4
  [TypographyVariants.h6]: H6, // text4.5
  [TypographyVariants.captionB]: CaptionB, // text5
  [TypographyVariants.captionR]: CaptionR, // text6
  [TypographyVariants.p1Body]: P1Body, // textBase
  [TypographyVariants.p2Body]: P2Body, // textSmall
  [TypographyVariants.button]: Button,
  [TypographyVariants.placeholder]: Placeholder,
  [TypographyVariants.inputDescription]: InputDescription,
  [TypographyVariants.inputLabel]: InputLabel,
  [TypographyVariants.badgeText]: BadgeText, // textXS
  // end deprecated variants

  // new variants
  [TypographyVariants.pageTitle]: PageTitle,
  [TypographyVariants.sectionTitle]: SectionTitle,
  [TypographyVariants.itemTitle]: ItemTitle,
  [TypographyVariants.subheadline]: Subheadline,
  [TypographyVariants.inter]: Inter,
  [TypographyVariants.body]: Body,
  [TypographyVariants.description]: Description,
  [TypographyVariants.footnote]: Footnote,
  [TypographyVariants.micro]: Micro,
} as const

export const Typography = forwardRef(
  (
    {
      variant,
      responsive,
      children,
      ...rest
    }: {
      variant: keyof typeof TypographyVariants
      children?: string | string[] | ReactNode
      responsive?: boolean
      color?: string
      singleLine?: boolean
      ellipsis?: boolean
      onClick?: () => void
      bold?: boolean
      breakWord?: boolean
      textAlign?: string
    },
    ref,
  ) => {
    const Component = variants[variant]
    return (
      <Component ref={ref} responsive={responsive} {...rest}>
        {children}
      </Component>
    )
  },
)

Typography.displayName = 'Typography'
