import { formatNumberValue, fromEventTarget } from '@/utils'
import PropTypes from 'prop-types'
import { pipe } from 'ramda'
import { Input } from '../../styled'
import { handleKeyDown, replaceAllComas } from './helpers'

export const NumberInputBase = ({
  value,
  onChange,
  onBlur,
  error,
  disabled,
  ...props
}) => {
  const handleChange = fromEventTarget(pipe(replaceAllComas, onChange))

  const handleBlur = (e) => {
    if (value !== '') onChange(parseFloat(replaceAllComas(e.target.value)))
    onBlur?.(e)
  }

  return (
    <Input
      disabled={disabled}
      error={error}
      inputMode="numeric"
      onBlur={handleBlur}
      onChange={handleChange}
      value={formatNumberValue(value)}
      {...props}
      onKeyDown={handleKeyDown}
    />
  )
}

NumberInputBase.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
