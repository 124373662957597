import { PrimaryButton, Row, Typography } from '@/components'
import { paths } from '@/routes/paths'
import { updateSessionStorageItem, useGoToPath, useSearchParams } from '@/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonContainer,
  Container,
  Content,
  List,
  NumberContainer,
  StyledRightArrow,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    title: t('whySureinTitle'),
    whySureinFirst: t('whySureinFirst'),
    whySureinSecond: t('whySureinSecond'),
    whySureinThird: t('whySureinThird'),
    buttonText: t('whySureinButtonText'),
  }
}

export const WhySureinPage = () => {
  const messages = useMessages()
  const searchParams = useSearchParams()

  useEffect(() => {
    updateSessionStorageItem('searchParams', searchParams)
  }, [JSON.stringify(searchParams)])

  const goToSignUpScreen = useGoToPath(paths.signup)

  return (
    <Container>
      <Content>
        <Typography variant="h4">{messages.title}</Typography>
        <List>
          <Row alignItems="center" gap="16px">
            <NumberContainer>1</NumberContainer>
            <Typography variant="p1Body">{messages.whySureinFirst}</Typography>
          </Row>
          <Row alignItems="center" gap="16px">
            <NumberContainer>2</NumberContainer>

            <Typography variant="p1Body">{messages.whySureinSecond}</Typography>
          </Row>
          <Row alignItems="center" gap="16px">
            <NumberContainer>3</NumberContainer>

            <Typography variant="p1Body">{messages.whySureinThird}</Typography>
          </Row>
        </List>
        <ButtonContainer>
          <PrimaryButton
            block
            icon={StyledRightArrow}
            onClick={goToSignUpScreen}
            reverted
          >
            {messages.buttonText}
          </PrimaryButton>
        </ButtonContainer>
      </Content>
    </Container>
  )
}
