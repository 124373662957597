import { FilePicker, ButtonLink, TextWithLink, Documents } from '@/components'
import { Breakpoint, ComponentColors } from '@/enums'
import { useSnackbar } from '@/providers'
import { useUploadInsuranceFiles } from '@/services'
import { useIsMobile } from '@/utils'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DocumentsContainer, UploadedDocumentsContainer } from './styles'

export const InsuranceDocuments = ({
  files = [],
}: {
  files: {
    name: string
    displayName: string
    createdAt: string
    visibleToUser: boolean
    category: string | null
  }[]
}) => {
  const { t } = useTranslation()
  const { insuranceId } = useParams()
  const uploadFilesMutation = useUploadInsuranceFiles(insuranceId!)
  const snackbars = useSnackbar()
  const { isMobile } = useIsMobile(Breakpoint.sm)

  const handleUploadContract = (newFiles: File[]): void => {
    uploadFilesMutation.mutateAsync({ files: newFiles }).then(() => {
      snackbars.success(t('snackbars.success.uploadContract'))
    })
  }

  const FileUpload = useCallback(
    () => (
      <FilePicker
        accept=".pdf,.jpg"
        disabled={files.length === 100}
        max={100 - (files?.length || 0)}
        multiple
        onPick={handleUploadContract}
      >
        <TextWithLink>
          <Trans
            components={[
              <ButtonLink color={ComponentColors.primary} size="small">
                {/* TS is complaining if the ButtonLink doesn't have any children in reality it doesn't need it - it's overwritten by Trans component */}
                <></>
              </ButtonLink>,
            ]}
            i18nKey={t('uploadDocument')}
          />
        </TextWithLink>
      </FilePicker>
    ),
    [files.length, isMobile],
  )

  return (
    <DocumentsContainer>
      {Boolean(files.length) && (
        <UploadedDocumentsContainer>
          <Documents files={files} />
        </UploadedDocumentsContainer>
      )}
      <FileUpload />
    </DocumentsContainer>
  )
}
