import { useTranslation } from 'react-i18next'
import { Categories } from '@/enums'

export const useCategoryTranslations = (categoryId: string) => {
  const { t } = useTranslation()

  const categoryKey = Categories[categoryId as keyof typeof Categories].key

  if (!categoryKey) {
    throw new Error(`Unknown category: ${categoryId}`)
  }

  return {
    categoryKey,
    categoryName: t(`categories.${categoryKey}.name`),
    longDesc: t(`categories.${categoryKey}.longDesc`),
    shortDesc: t(`categories.${categoryKey}.shortDesc`),
    usps: t(`categories.${categoryKey}`, {
      returnObjects: true,
      // @ts-expect-error t can return objects - TODO: filtering like this probably should not be here
    }).usps.filter(Boolean),
  }
}
