import { Column, Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { SettingsIcon } from '@/icons'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
span {
  font-size: ${toThemePixels(3.5)};
  font-weight: 400;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: ${toThemePixels(8)} 0;
`

export const ReferralWrapper = styled(Column)`
  width: 100%;
`

export const ProfileButtonsWrapper = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: end;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-wrap: wrap;
    justify-content: start;
  }
`

export const LinksContainer = styled(Column)`
  margin-top: ${toThemePixels(8)};
  gap: ${toThemePixels(4)};

  > * {
    justify-content: start;
    height: unset;
    padding-left: ${toThemePixels(8)};
  }

  span {
    font-weight: 400;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    span {
      font-size ${toThemePixels(4)};
    }
  }
`

export const PageTitle = styled.div`
  margin-bottom: ${toThemePixels(8)};
`

export const PageHeader = styled(Column)`
  gap: ${toThemePixels(4)};
  margin-bottom: ${toThemePixels(8)};
`

export const ButtonContainer = styled(Column)`
  gap: ${toThemePixels(3)};
`

export const ItemContainer = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  button {
    height: unset;
  }
`

export const IntegratedScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

export const IntegrationsButtonContainer = styled.div`
  max-width: 24rem;
  ${themeBreakpointDown(Breakpoint.md)} {
    width: 100%;
  }
`

export const IntegratedBankAccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const IntegratedBankAccountData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`

export const SectionTitle = styled(Row)`
  gap: ${toThemePixels(3)};
  align-items: center;
  padding: ${toThemePixels(2)} 0;
  margin-bottom: ${toThemePixels(2)};
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  max-width: 250px;

  svg {
    fill: transparent;
  }
`

export const StyledSettingsIcon = styled(SettingsIcon)`
  fill: ${themeColor(ThemeColor.b50)} !important;
`

export const ButtonLinkWithPermissionsWrapper = styled.div`
  position: relative;
  display: inline-block;

  button {
    height: unset;
  }
`
