import {
  Breakpoint,
  BreakpointType,
  ComponentColors,
  ThemeColor,
} from '@/enums'
import {
  pipe,
  multiply,
  __,
  path,
  prop,
  values,
  includes,
  join,
  append,
} from 'ramda'
import { useTheme } from 'styled-components'
import { switchProp } from 'styled-tools'
import { toPixels } from './convert'
import { isString } from './type'

const fromTheme = (...properties) => ({ theme }) => path(properties, theme)

export const themeColor = (color) => fromTheme('color', color)

export const themeColorWith = (modifier, color) => ({ theme }) =>
  modifier(fromTheme('color', color)({ theme }))

export const themeColorFrom = (fn) => (props) => {
  const color = fn(props)
  return themeColor(color)
}

export const themeColorFromProp = (propName) => themeColorFrom(prop(propName))

export const themeColorFromPropColor = themeColorFromProp('color')

export const themeColorSwitch = (cases, defaultCase) =>
  themeColorFrom(switchProp('color', cases, defaultCase))

export const primaryColorFromComponentColor = (defaultCase) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info1,
      [ComponentColors.success]: ThemeColor.success1,
      [ComponentColors.warning]: ThemeColor.warning1,
      [ComponentColors.danger]: ThemeColor.danger1,
      [ComponentColors.text]: ThemeColor.b100,
    },
    defaultCase,
  )

export const secondaryColorFromComponentColor = (defaultCase) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info2,
      [ComponentColors.success]: ThemeColor.success2,
      [ComponentColors.warning]: ThemeColor.warning2,
      [ComponentColors.danger]: ThemeColor.danger2,
      [ComponentColors.text]: ThemeColor.b50,
    },
    defaultCase,
  )

export const trinaryColorFromComponentColor = (defaultCase) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info3,
      [ComponentColors.success]: ThemeColor.success3,
      [ComponentColors.warning]: ThemeColor.warning3,
      [ComponentColors.danger]: ThemeColor.danger3,
    },
    defaultCase,
  )

export const quaternaryColorFromComponentColor = (defaultCase) =>
  themeColorSwitch(
    {
      [ComponentColors.primary]: ThemeColor.info4,
      [ComponentColors.success]: ThemeColor.success4,
      [ComponentColors.warning]: ThemeColor.warning4,
      [ComponentColors.danger]: ThemeColor.danger4,
    },
    defaultCase,
  )

export const useThemeColor = (color) => {
  const theme = useTheme()
  return themeColor(color)({ theme })
}

export const themeFontWeight = (fontWeight) =>
  fromTheme('font', 'weight', fontWeight)

export const toThemePixels = (fontSizePx) => ({
  theme,
  [fontSizePx]: otherProp,
}) => {
  const getThemeFontSizeInPx = pipe(
    multiply(prop('baseFontSize', theme)),
    toPixels,
  )

  const propPx = isString(fontSizePx) ? otherProp : fontSizePx
  return getThemeFontSizeInPx(propPx)
}

export const pixelsFromProp = (propName) => pipe(prop(propName), toPixels)

const breakpointValues = values(Breakpoint)
const breakpointTypeValues = values(BreakpointType)

const hasType = includes(__, breakpointTypeValues)
const hasBreakpoint = includes(__, breakpointValues)

export const themeBreakpoint = (type, breakpoint) => ({ theme }) => {
  if (!hasType(type))
    throw Error(`Unknown method '${type}'. Use one of ${breakpointTypeValues}`)

  if (!hasBreakpoint(breakpoint))
    throw Error(
      `Unknown breakpoint '${breakpoint}'.  Use one of ${breakpointValues}`,
    )

  const withDirection = path(['breakpoints', type], theme)
  return withDirection(breakpoint)
}

export const themeBreakpointUp = (breakpoint) =>
  themeBreakpoint(BreakpointType.up, breakpoint)

export const themeBreakpointDown = (breakpoint) =>
  themeBreakpoint(BreakpointType.down, breakpoint)

export const themeBreakpointBetween = (start, end) => ({ theme }) => {
  if (!hasBreakpoint(start))
    throw Error(
      `Unknown breakpoint start'${start}'.  Use one of ${breakpointValues}`,
    )

  if (!hasBreakpoint(end))
    throw Error(
      `Unknown breakpoint end '${end}'.  Use one of ${breakpointValues}`,
    )

  const withDirection = path(['breakpoints', 'between'], theme)
  return withDirection(start, end)
}

export const themeTransition = (attr = 'all', duration = '0.4s') =>
  pipe(path(['theme', 'transition']), append(__, [attr, duration]), join(' '))
