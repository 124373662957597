import { Typography } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import { GoogleRatingIcon, Shield } from '@/icons'
import PropTypes from 'prop-types'
import { range } from 'ramda'
import { useTranslation } from 'react-i18next'
import { Row } from '../../styled'
import {
  Container,
  Google,
  GoogleRating,
  Rating,
  StyledGoogleIcon,
  StyledSSL,
  StyledSureinTrustLogo,
} from './styles'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'

const colors = {
  light: {
    primary: ThemeColor.b0,
    secondary: ThemeColor.b0,
  },
  dark: {
    primary: ThemeColor.b100,
    secondary: ThemeColor.b50,
  },
}

export const TrustPartners = ({ mode = 'light', hide = {} }) => {
  const { t } = useTranslation()
  return (
    <Container>
      {!hide.ssl && (
        <StyledSSL alignItems="center" gap="4px">
          <Shield width={24} height={24} color={ThemeColor.success1} />
          <Typography variant="footnote">
            {t('mockRecommendationModalText.ssl')}
          </Typography>
        </StyledSSL>
      )}
      <Row alignItems="center" justifyContent="center" gap="6px">
        <TrustPilotExtended width="170px" />
      </Row>
      {!hide.surein && <StyledSureinTrustLogo />}
      <Google>
        <StyledGoogleIcon />
        <GoogleRating>
          {range(1, 6).map((item) => (
            <GoogleRatingIcon
              key={item}
              color={ThemeColor.warning2}
              size="menu"
            />
          ))}
          <Rating color={colors[mode].secondary}>4.7</Rating>
        </GoogleRating>
      </Google>
    </Container>
  )
}

TrustPartners.propTypes = {
  hide: PropTypes.shape({ surein: PropTypes.bool }),
  mode: PropTypes.oneOf(['light', 'dark']),
}
