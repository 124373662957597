import {
  BackComponent,
  ContactExpert,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  Spinner,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { LinkIcon } from '@/icons/LinkIcon'
import { useBankingIntegrationSnackbar } from '@/modules/integrations/hooks/useBankingIntegrationSnackbar'
import { paths } from '@/routes/paths'
import { Redirect } from '@/routes/Redirect'
import { useGoToPath, useTranslationSection } from '@/utils'
import { useBankingIntegration } from '@/utils/hooks/useBankingIntegration'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { makeBankTranslation } from '../helpers'
import {
  IntegratedBankAccountContainer,
  IntegratedBankAccountData,
  IntegratedScreenWrapper,
  IntegrationsButtonContainer,
  PageHeader,
  PageTitle,
} from '../styles'

const useMessages = (bankName) => {
  const { t, i18n } = useTranslation()

  const integrationsTranslations = useTranslationSection('integrationsPage')

  const bankTranslation = makeBankTranslation(i18n.language, bankName)

  return {
    advisorText: t('recommend_advisor_text'),
    backToDashboard: t('back_to_dashboard'),
    dataCorrectDescription: t('integrationsPage.dataCorrectDescription', {
      bank: bankTranslation,
    }),
    currentConnectedAccount: t('integrationsPage.currentConnectedAccount', {
      bank: bankTranslation,
    }),
    connectSureIn: integrationsTranslations('connectSureIn'),
    connectDescription: integrationsTranslations('connectDescription'),
    connectButtonText: integrationsTranslations('connectButtonText'),
    integrations: integrationsTranslations('integrations'),
    processingConnection: integrationsTranslations('processingConnection'),
    bankingConnectionAborted: integrationsTranslations(
      'bankingConnectionAborted',
    ),
  }
}

const config = {
  source: 'profile-integrations',
}

export const ProfileIntegrations = () => {
  const { hasProtectedAccess } = useOutletContext()
  const goToDashboard = useGoToPath('/dashboard')
  const {
    redirectUserToTink,
    canGenerateTinkLink,
    reports,
    isLoading: isBankingIntegrationLoading,
  } = useBankingIntegration(config)
  const messages = useMessages(reports?.general?.bankName)

  const handleButtonClick = () => {
    redirectUserToTink()
  }

  // Encapsulates the logic to show a snackbar message when the banking integration is aborted or succeeded
  useBankingIntegrationSnackbar()

  if (isBankingIntegrationLoading) return <Loader />

  if (!hasProtectedAccess) return <Redirect to={paths.authRedirect} />

  if (reports && !isEmpty(reports)) {
    return (
      <>
        <PageMainContent>
          <BackComponent />
          <PageTitle>
            <PageHeader>
              <Typography variant="h4">{messages.integrations}</Typography>
            </PageHeader>
          </PageTitle>
          <IntegratedScreenWrapper>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.currentConnectedAccount}
            </Typography>
            <IntegratedBankAccountContainer>
              <LinkIcon height="18" width="18" />
              <IntegratedBankAccountData>
                <Typography variant="p1Body">
                  {reports.general.bankName}
                </Typography>
                <Typography variant="p1Body">{reports.general.iban}</Typography>
              </IntegratedBankAccountData>
            </IntegratedBankAccountContainer>
            <IntegrationsButtonContainer>
              <PrimaryButton
                block
                color={ComponentColors.primary}
                onClick={() => goToDashboard()}
                reverted
                size="large"
              >
                {messages.backToDashboard}
              </PrimaryButton>
            </IntegrationsButtonContainer>
          </IntegratedScreenWrapper>
        </PageMainContent>
        <PageWidgetContainer>
          <ContactExpert text={messages.advisorText} />
        </PageWidgetContainer>
      </>
    )
  }
  if (canGenerateTinkLink) {
    return (
      <>
        <PageMainContent>
          <BackComponent />
          <PageTitle>
            <PageHeader>
              <Typography variant="h4">{messages.integrations}</Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {messages.connectDescription}
              </Typography>
            </PageHeader>
          </PageTitle>
          <IntegrationsButtonContainer>
            <PrimaryButton
              color={ComponentColors.primary}
              onClick={handleButtonClick}
              reverted
              size="large"
            >
              {messages.connectButtonText}
            </PrimaryButton>
          </IntegrationsButtonContainer>
        </PageMainContent>
        <PageWidgetContainer>
          <ContactExpert text={messages.advisorText} />
        </PageWidgetContainer>
      </>
    )
  }
  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <PageHeader>
            <Typography variant="h4">{messages.integrations}</Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.connectDescription}
            </Typography>
          </PageHeader>
          <IntegrationsButtonContainer>
            <PrimaryButton
              block
              color={ComponentColors.primary}
              disabled
              icon={Spinner}
              size="large"
            >
              {messages.processingConnection}
            </PrimaryButton>
          </IntegrationsButtonContainer>
        </PageTitle>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
