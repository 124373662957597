import { Typography } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import { Info } from '@/icons'
import { Tooltip } from '../Tooltip'
import { TooltipText } from './styles'
import { ReactNode } from 'react'

export const InfoTooltip = ({
  content,
  size,
  dark,
  iconColor = ThemeColor.b10,
  text,
}: {
  content: ReactNode
  dark?: boolean
  iconColor?: keyof typeof ThemeColor
  size: 'button' | 'info' | 'menu' | 'header' | 'tag' | 'title'
  text?: string
}) => {
  if (!content) return null

  return (
    <Tooltip content={content} dark={dark} width={400}>
      <TooltipText alignItems="center" gap="4px">
        <Info color={iconColor} fill="none" size={size} stroke />
        {typeof text === 'string' ? (
          <Typography variant="captionR">{text}</Typography>
        ) : (
          text
        )}
      </TooltipText>
    </Tooltip>
  )
}
