import { ThemeColor } from '@/enums'
import { ArrowRightLine, Checked } from '@/icons'
import PropTypes from 'prop-types'
import { intersperse } from 'ramda'
import { Typography } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Container } from './styles'

export const Steps = ({ steps, current }) => (
  <Container>
    {intersperse(
      <ArrowRightLine color={ThemeColor.b50} />,
      steps.map((item, i) => (
        <>
          <ShowWhen when={current > i}>
            <Checked fill="none" size={24} />
          </ShowWhen>
          <Typography
            color={
              // eslint-disable-next-line no-nested-ternary
              current === i
                ? ThemeColor.b100
                : current > i
                ? ThemeColor.success2
                : ThemeColor.b50
            }
            variant="captionR"
          >
            {item}
          </Typography>
        </>
      )),
    )}
  </Container>
)

Steps.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
}
