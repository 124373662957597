import {
  curry,
  ifElse,
  complement,
  prepend,
  __,
  append,
  identity,
  head,
  both,
  pipe,
  length,
  equals,
} from 'ramda'
import { notEmptyOrFalsy } from './bool'
// eslint-disable-next-line import/no-cycle
import { returns } from './function'
import { isArray, isUndefined } from './type'

export const prependSafe = curry((el, list) =>
  ifElse(complement(isUndefined), prepend(__, list), returns(list))(el),
)

export const appendSafe = curry((el, list) =>
  ifElse(complement(isUndefined), append(__, list), returns(list))(el),
)

export const pack = (x) => {
  if (!isArray(x)) return [x]
  return x
}

export const safePack = ifElse(notEmptyOrFalsy, pack, returns(undefined))

export const unpack = ifElse(
  both(isArray, pipe(length, equals(1))),
  head,
  identity,
)

export const randomElement = (list) =>
  list[Math.floor(Math.random() * list.length)]
