import { is, allPass, complement } from 'ramda'

export const isUndefined = (x) => typeof x === 'undefined'
export const isNumber = is(Number)
export const isString = is(String)
export const isArray = is(Array)
export const isFunction = is(Function)
export const isObject = allPass([
  is(Object),
  complement(is(FormData)),
  complement(isArray),
  complement(isFunction),
])
export const isPromise = (p) => typeof p === 'object' && isFunction(p.then)
export const isAsync = (func) =>
  func.constructor.name === 'AsyncFunction' ||
  (typeof func === 'function' && isPromise(func()))

// eslint-disable-next-line no-restricted-globals
export const coercedIsNaN = isNaN
