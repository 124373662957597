import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const CollapseContainer = styled.div`
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};

  > *:first-child {
    padding: ${toThemePixels(5)} ${toThemePixels(4)};
  }
`

export const CollapseContent = styled.div`
  padding: ${toThemePixels(5)} ${toThemePixels(4)};
`
