/* eslint-disable no-console */

export const isDev = () => import.meta.env.DEV

export const isProd = () => import.meta.env.PROD

export const log = (...args) => {
  if (isDev()) {
    console.log(...args)
  }
}

export const warn = (...args) => {
  if (isDev()) {
    console.warn(...args)
  }
}

export const error = (...args) => {
  if (isDev()) {
    console.error(...args)
  }
}

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
