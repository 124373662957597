import { ThemeColor } from '@/enums'
import { RatingStarIcon } from '@/icons'
import { isFloat, toInt } from '@/utils'
import PropTypes from 'prop-types'
import { divide, flip, modulo, multiply, pipe, range } from 'ramda'
import { useEffect, useRef } from 'react'
import { Row } from '../../styled'
import { Typography } from '../Typography'
import { Container } from './styles'

const SIZE = 16

const makeRectX = pipe(
  multiply(10),
  toInt,
  flip(modulo)(10),
  multiply(SIZE),
  flip(divide)(10),
)

export const Rating = ({
  min = 1,
  max = 5,
  value,
  reverse,
  ratingAmount,
  iconSize = 16,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current && isFloat(value)) {
      const notFullStar = ref.current.children[Math.ceil(value)]

      const clipPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'clipPath',
      )
      clipPath.setAttribute('id', 'cp')

      const rect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect',
      )
      rect.setAttribute('height', SIZE)
      rect.setAttribute('width', SIZE)
      rect.setAttribute('x', makeRectX(value))
      rect.setAttribute('y', 0)

      clipPath?.appendChild(rect)
      notFullStar?.appendChild(clipPath)

      const use = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      use.setAttribute('clip-path', 'url(#cp)')
      use.setAttribute('href', '#star-icon')

      notFullStar?.appendChild(use)
    }
  }, [])

  return (
    <Container reverse={reverse}>
      <Typography variant="badgeText">
        {parseFloat(value || 0).toFixed(1)}
        {ratingAmount && ` (${ratingAmount})`}
      </Typography>
      <Row ref={ref} gap="4px">
        {range(min, max + 1).map((val) => (
          <RatingStarIcon
            key={val}
            color={
              Math.ceil(value) >= val
                ? ThemeColor.warning1
                : ThemeColor.warning3
            }
            size={iconSize}
            stroke
          />
        ))}
      </Row>
    </Container>
  )
}

Rating.propTypes = {
  iconSize: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  ratingAmount: PropTypes.number,
  reverse: PropTypes.bool,
  value: PropTypes.number,
}
