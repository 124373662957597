import { dynamicObjectPropType } from '@/utils'
import PropTypes from 'prop-types'
import { mergeLeft } from 'ramda'
import { RadioButton } from '../../atoms'
import { BasicFormItem, Collapse, RadioGroup } from '../../molecules'
import { Column } from '../../styled'
import { CollapseContainer, CollapseContent } from './styles'

export const CollapseSelect = ({
  value = {},
  onChange,
  parentKey,
  childKey,
  options,
}) => {
  const handleChange = (newValue) => onChange(mergeLeft(newValue, value))

  return (
    <Column gap="16px">
      {options.map((item) => (
        <CollapseContainer>
          <Collapse
            divide
            isOpen={value[parentKey] === item.value}
            onCollapse={() => onChange({ [parentKey]: item.value })}
            title={item.text}
          >
            <CollapseContent>
              <RadioGroup
                alignment="vertical"
                onChange={(val) => handleChange({ [childKey]: val })}
                value={value[childKey]}
              >
                {item.options.map((radio) => (
                  <RadioButton
                    key={radio.value}
                    label={radio.text}
                    value={radio.value}
                  />
                ))}
              </RadioGroup>
            </CollapseContent>
          </Collapse>
        </CollapseContainer>
      ))}
    </Column>
  )
}

export const FormCollapseSelect = (props) => (
  <BasicFormItem component={CollapseSelect} {...props} />
)

CollapseSelect.propTypes = {
  childKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  parentKey: PropTypes.string.isRequired,
  value: dynamicObjectPropType,
}
