import { FormNumber } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from '@/modules/yearlyCheck/components/steps/components'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { useEmployeeCountMessages } from '@/pages/SelfAssessmentPage/steps/EmployeesCountStep'
import { FormProvider, useFormContext } from '@/providers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeEmployeesCountValidationSchema } from '../../helpers/validationSchema'

const EmployeesCountForm = () => {
  const { t } = useTranslation()
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const messages = useEmployeeCountMessages()
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.employeesCount,
    undefined,
    true,
  )

  const handleEmployeeCountChange = () => {
    // TODO: rethink validation
    if (!values) return

    save().then(() => {
      onNext(AssessmentStepKeys.employeesCount, values)
    })
  }
  return (
    <>
      <StepHeader
        sectionLabel={t('yearlyAssessment.employeesCount-category')}
        title={t('yearlyAssessment.employeesCount-title')}
      />
      <FormContent>
        <FormNumber
          initialValue={0}
          label={messages.owners}
          min={0}
          name="ownersCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.fullTime}
          min={0}
          name="fullTimeEmployeesCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.partTime}
          min={0}
          name="partTimeEmployeesCount"
        />
        <FormNumber
          initialValue={0}
          label={messages.miniJobbers}
          min={0}
          name="miniJobbersCount"
        />
      </FormContent>
      <StepFooter
        isButtonLoading={isButtonLoading}
        onSubmit={handleEmployeeCountChange}
      />
    </>
  )
}

export const EmployeesCount = () => {
  const { formValues } = useStepper()
  const { t } = useTranslation()

  return (
    <StepContainer>
      <FormProvider
        initialValues={{
          fullTimeEmployeesCount:
            formValues.employees_count?.fullTimeEmployeesCount,
          miniJobbersCount: formValues.employees_count?.miniJobbersCount,
          ownersCount: formValues.employees_count?.ownersCount,
          partTimeEmployeesCount:
            formValues.employees_count?.partTimeEmployeesCount,
        }}
        validationSchema={() =>
          makeEmployeesCountValidationSchema(
            t('validations', { returnObjects: true }),
          )
        }
      >
        <EmployeesCountForm />
      </FormProvider>
    </StepContainer>
  )
}
