import {
  BackComponent,
  Column,
  InputWithAddon,
  Loader,
  Row,
  TextBadge,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { DocumentIcon } from '@/icons/DocumentIcon'
import { useContractsOverview, useExportCsv } from '@/services'
import { theme } from '@/theme'
import { formatMoney, fromEventTarget, getFileNameAndExtension } from '@/utils'
import { Button } from '@surein/ui'
import { Download } from 'lucide-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import {
  StyledHeadquarterLogo,
  StyledHeadquarterLogoContainer,
} from '../Dashboard/styles'
import { useMessages } from './helpers'
import LocationTable from './LocationTable'
import {
  CompanyAndLocationList,
  CompanyUserAddon,
  Container,
  EmptyContractsSearchContainer,
  LocationTablesWrapper,
  SearchBarContainer,
  TotalGrossPriceContainer,
} from './styles'

const DownloadIcon = () => (
  <Download color={theme.color[ThemeColor.b50]} size="16" />
)

export const ContractsOverview = () => {
  const {
    data: contractsOverviewData,
    isLoading: isContractsOverviewDataLoading,
  } = useContractsOverview()
  const [search, setSearch] = useState('')
  const messages = useMessages(search)
  const { companies } = useOutletContext()

  const exportCsvMutation = useExportCsv()

  const handleExportCsv = (companyId) => {
    exportCsvMutation.mutate(companyId)
  }

  const foundHeadquarter = companies.find((company) => company.isHeadquarters)

  const [
    headquartersLogoUrl,
    headquartersLogoExtension,
  ] = getFileNameAndExtension(foundHeadquarter.logoUrl || '')

  if (isContractsOverviewDataLoading) return <Loader />

  return (
    <Container>
      <Column gap="8px">
        <BackComponent noContentGutter noMargin />
        <Typography bold variant="h3">
          {messages.contractsOverview}
        </Typography>
        <CompanyUserAddon>
          {headquartersLogoUrl && (
            <StyledHeadquarterLogoContainer>
              <StyledHeadquarterLogo
                name={headquartersLogoUrl}
                type={headquartersLogoExtension}
              />
            </StyledHeadquarterLogoContainer>
          )}
          <Typography bold color={ThemeColor.b50} variant="button">
            {foundHeadquarter.name}
          </Typography>
          <TextBadge text="Pro" />
        </CompanyUserAddon>
      </Column>
      <SearchBarContainer>
        <InputWithAddon
          addonBefore={() => <SearchIcon />}
          onChange={fromEventTarget(setSearch)}
          placeholder={messages.searchByCompanyOrLocation}
          value={search}
        />
      </SearchBarContainer>
      <CompanyAndLocationList>
        <EmptyContractsSearch
          search={search}
          searchMessage={messages.noMatchInContractsSearch}
        />
        {contractsOverviewData.companies.map(
          ({ name, locations, grossPrice, companyId }) =>
            locations.length &&
            locations.some((location) => location.insurances.length) ? (
              <LocationTablesWrapper key={companyId}>
                <Row alignItems="center" justifyContent="space-between">
                  <TotalGrossPriceContainer>
                    <Typography bold variant="h4">
                      {name}
                    </Typography>
                    <Typography color={ThemeColor.b40} variant="badgeText">
                      {messages.total}
                    </Typography>
                    <Typography color={ThemeColor.b50} variant="badgeText">
                      {`${formatMoney(grossPrice)}/${messages.year}`}
                    </Typography>
                  </TotalGrossPriceContainer>
                  <Row>
                    <Button
                      icon={DownloadIcon}
                      onClick={() => handleExportCsv(companyId)}
                      size="sm"
                      variant="ghost"
                    >
                      <Typography color={ThemeColor.b50} variant="p2Body">
                        {messages.exportCsv}
                      </Typography>
                    </Button>
                  </Row>
                </Row>
                {locations.map((locationData) =>
                  locationData.insurances.length ? (
                    <LocationTable
                      key={locationData.locationId}
                      companyId={companyId}
                      companyName={name}
                      search={search}
                      {...locationData}
                    />
                  ) : null,
                )}
              </LocationTablesWrapper>
            ) : null,
        )}
      </CompanyAndLocationList>
    </Container>
  )
}

const EmptyContractsSearch = ({ search, searchMessage }) => {
  const messages = useMessages()

  return (
    <EmptyContractsSearchContainer>
      {search ? <SearchIcon size="button" /> : <DocumentIcon size="button" />}
      <Column gap="4px">
        <Typography bold variant="p1Body">
          {search ? messages.noResultsFound : messages.noContractsTitle}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {searchMessage}
        </Typography>
      </Column>
    </EmptyContractsSearchContainer>
  )
}

EmptyContractsSearch.propTypes = {
  search: PropTypes.string,
  searchMessage: PropTypes.string,
}
