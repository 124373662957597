/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ComponentColors, ThemeColor } from '@/enums'
import { useSnackbar } from '@/providers'
import { useCompany, useSignPoa } from '@/services'
import {
  safeTimestamp,
  themeColorPropType,
  timestampToDateString,
  useGoBack,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import {
  ButtonLink,
  Loader,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '../../atoms'
import { DatePicker, SignatureCanvas, TrustPartners } from '../../molecules'
import { Column } from '../../styled'
import {
  UserInfo,
  StyledInput,
  PageHeader,
  ButtonContainer,
  Content,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  const snackBars = useTranslationSectionObject('snackbars')
  const profileTranslations = useTranslationSectionObject('profile.menu')

  return {
    signPoa_title: profileTranslations.signPOA,
    signPoa_subtitle: t('signPoa_subtitle'),
    birthday: t('birthday'),
    name: t('name'),
    signNow: t('signNow'),
    later: t('later'),
    successPoa: snackBars.success.onPoaSigned,
    contactMD: snackBars.success.contactMD,
    yourName: t('your_name'),
  }
}

export const SignPoaView = ({
  onSkip,
  companyId,
  onSuccess,
  textColor = ThemeColor.b100,
  showTrustPartners = false,
  cancelText,
}) => {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const signPoaMutation = useSignPoa(companyId)
  const { user: userProfile } = useOutletContext()
  const [birthdate, setBirthdate] = useState(
    safeTimestamp(userProfile.birthdate),
  )
  const [signature, setSignature] = useState()
  const { data, isLoading } = useCompany(companyId)

  const handleCancel = useGoBack()

  if (isLoading) return <Loader />

  const disabled = !find(propEq('email', userProfile.email), data.users)
    ?.userTypes?.MD

  const handleSubmit = () => {
    if (disabled) snackbar.error(messages.contactMD)
    else
      signPoaMutation.mutateAsync({ signature, birthdate }).then(() => {
        snackbar.success(messages.successPoa)
        onSuccess()
      })
  }

  return (
    <>
      <Content>
        <PageHeader>
          <Typography color={textColor} variant="h4">
            {messages.signPoa_title}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.signPoa_subtitle}
          </Typography>
        </PageHeader>
        <SignatureCanvas onChange={setSignature} value={signature} />
        <UserInfo>
          <Column gap="8px" width="100%">
            <Typography variant="captionR">{messages.name}</Typography>
            <StyledInput
              defaultValue={userProfile.fullname}
              disabled
              placeholder={messages.yourName}
            />
          </Column>
          <Column gap="8px" width="100%">
            <Typography variant="captionR">{messages.birthday}</Typography>
            <DatePicker
              max={timestampToDateString(Date.now())}
              onChange={setBirthdate}
              value={birthdate}
            />
          </Column>
        </UserInfo>
        {showTrustPartners && <TrustPartners mode="dark" />}
        <ButtonContainer singleButton={!cancelText}>
          {cancelText && (
            <SecondaryButton
              loading={signPoaMutation.isLoading}
              onClick={handleCancel}
            >
              {cancelText}
            </SecondaryButton>
          )}
          <PrimaryButton
            disabled={!signature || typeof birthdate === 'undefined'}
            loading={signPoaMutation.isLoading}
            onClick={handleSubmit}
          >
            {messages.signNow}
          </PrimaryButton>
        </ButtonContainer>
      </Content>
      {onSkip && (
        <ButtonLink block color={ComponentColors.primary} onClick={onSkip}>
          {messages.later}
        </ButtonLink>
      )}
    </>
  )
}

SignPoaView.propTypes = {
  cancelText: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  onSkip: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  showTrustPartners: PropTypes.bool,
  textColor: themeColorPropType,
}
