import { ThemeColor } from '@/enums'
import { LeftLine } from '@/icons'
import { ifProps, themeColor, themeTransition } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Row } from '../../styled'

export const CollapseHeader = styled(Row)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`

export const CollapseIcon = styled(LeftLine).attrs({ size: 'button' })`
  transform: ${ifProp('$isOpen', 'rotate(90deg)', 'rotate(270deg)')};
  transition: ${themeTransition('transform')};
`

export const CollapseBody = styled.div`
  overflow: hidden;
  height: ${ifProp('isOpen', prop('height'), 0)}px;
  transition: ${themeTransition('height')};
  border-top: ${ifProps(['isOpen', 'divide'], '1px solid')};
  border-color: ${themeColor(ThemeColor.b30)};
`

export const CollapsibleContainer = styled.div`
  overflow: hidden;
  height: ${ifProp('isOpen', prop('height'), 0)}px;
  transition: height 0.4s ease-in-out;
`
