import { oneChild, renderableType, useListenHover } from '@/utils'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Typography } from '../../atoms'
import { useFloatPositioning } from './helpers'
import { Container, HoverContent } from './styles'

export const Tooltip = ({ content, width, children, dark }) => {
  const tooltipRef = useRef()
  const elementRef = useRef()
  const [arrowX, setArrowX] = useState('')

  const { isOpen, onMouseEnter, onMouseLeave } = useListenHover()

  useFloatPositioning({
    isOpen,
    ref: tooltipRef,
    relatedToRef: elementRef,
    onOpen: (tooltip) => setArrowX(tooltip.offsetWidth / 2),
    onPositionRight: (_, elementBounds) =>
      setArrowX(elementBounds.right - elementBounds.width / 2),
    onPositionLeft: (tooltip, elementBounds) =>
      setArrowX(
        tooltip.offsetWidth -
          elementBounds.width / 2 -
          (window.innerWidth - elementBounds.right),
      ),
    onPositionBottom: (tooltip) => tooltip.classList.remove('top'),
    onPositionTop: (tooltip) => tooltip.classList.add('top'),
  })

  return (
    <Container dark={dark} isOpen={isOpen} onMouseLeave={onMouseLeave}>
      {isOpen &&
        createPortal(
          <HoverContent
            ref={tooltipRef}
            arrowX={arrowX}
            dark={dark}
            width={width}
          >
            {content}
          </HoverContent>,
          document.body,
        )}
      <Typography ref={elementRef} onMouseEnter={onMouseEnter} variant="p2Body">
        {children}
      </Typography>
    </Container>
  )
}

Tooltip.propTypes = {
  children: oneChild.isRequired,
  content: renderableType.isRequired,
  dark: PropTypes.bool,
  width: PropTypes.number,
}
