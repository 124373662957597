/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { PublicPageContent, Typography, WithGap } from '@/components'
import { isString, useTranslationSectionObject } from '@/utils'
import { Container } from './styles'

export const InitialInformationPage = () => {
  const messages = useTranslationSectionObject('initialInfo')

  return (
    <PublicPageContent>
      <Container>
        <WithGap gap={6}>
          <Typography responsive variant="h1">
            {messages.title}
          </Typography>
          {messages.content.map(({ subtitle, content }) => (
            <>
              <Typography responsive variant="h3">
                {subtitle}
              </Typography>
              {isString(content) ? (
                <Typography variant="p1Body">{content}</Typography>
              ) : (
                content.map((item) => (
                  <Typography variant="p1Body">{item}</Typography>
                ))
              )}
            </>
          ))}
        </WithGap>
      </Container>
    </PublicPageContent>
  )
}
