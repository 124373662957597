import { dynamicArrayPropType, dynamicObjectPropType } from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Stepper } from '../Stepper'
import { SuccessView } from '../SuccessView'
import { Container } from './styles'

export const StepperWithSuccessPage = ({
  initialStep,
  onCancel,
  onFinish,
  steps,
  title,
  subtitle,
  successButtonText,
  skipSuccessPage,
  stepperBreadcrumbTitles,
  ...rest
}) => {
  const [finished, setFinished] = useState(false)

  if (finished)
    return (
      <Container>
        <SuccessView
          buttonText={successButtonText}
          onButtonClick={() => onFinish()}
          subtitle={subtitle}
          title={title}
        />
      </Container>
    )

  return (
    <Stepper
      initialStep={initialStep}
      onCancel={onCancel}
      onFinish={skipSuccessPage ? onFinish : () => setFinished(true)}
      stepperBreadcrumbTitles={stepperBreadcrumbTitles}
      steps={steps}
      {...rest}
    />
  )
}
StepperWithSuccessPage.propTypes = {
  initialStep: PropTypes.number,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  skipSuccessPage: PropTypes.bool,
  stepperBreadcrumbTitles: dynamicObjectPropType,
  steps: dynamicArrayPropType.isRequired,
  subtitle: PropTypes.string,
  successButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withButtons: PropTypes.bool,
}
