import * as Yup from 'yup'

export const validationSchema = (messages) =>
  Yup.object().shape({
    insurances: Yup.array().of(
      Yup.object().shape({
        carrierId: Yup.string().required(messages.general),
        categoryId: Yup.string().required(messages.general),
      }),
    ),
  })
