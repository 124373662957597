import { AdvisorBubble, Column, FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useFormItem, useNextLoading, useOnNextClick } from '@/providers'
import { Alert } from '@surein/ui'
import { TriangleAlert } from 'lucide-react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useSaveAssessmentStep, useStepperWarning } from '../helpers'
import { StepperTestimonial } from './StepperTestimonial'

export const ApproxLaborCostStep = ({ helperText }) => {
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.approxLaborCosts,
  )

  const { t } = useTranslation()

  const { value } = useFormItem('approxPersonelExpenses')

  const { shouldShowWarning, handleOnBlur } = useStepperWarning(value)

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <Column gap="24px">
      <FormUnit
        helperText={helperText}
        name="approxPersonelExpenses"
        onBlur={handleOnBlur}
        placeholder={t('currencyInputPlaceholder')}
        unit="€"
      />
      {shouldShowWarning && (
        <Alert
          icon={TriangleAlert}
          text={<Trans i18nKey="approxLaborCostsWarning" />}
          variant="warning"
        />
      )}
      <AdvisorBubble text={t('approxLaborCostsAdvisorHint')} />
    </Column>
  )
}

ApproxLaborCostStep.propTypes = {
  helperText: PropTypes.string,
}

export const ApproxLaborCostStepTestimonial = () => (
  <StepperTestimonial
    stepKey="approxLaborCosts"
    imageSrc="katharina-headshot"
  />
)
