import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import { prop } from 'ramda'
import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

const showTooltip = keyframes`
  from {
    opacity:0
  }
`

export const Container = styled.div`
  position: relative;
  width: fit-content;
`

export const HoverContent = styled.div`
  max-width: 250px;
  min-width: 200px;
  z-index: 10000;
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(3)};
  animation: ${showTooltip} 0.4s linear;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  background-color: ${ifProp(
    'dark',
    themeColor(ThemeColor.info3),
    themeColor(ThemeColor.b0),
  )};
  text-overflow: ellipsis;
  font-size: ${toThemePixels(3.5)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: ${toThemePixels(80)};
  }

  &.top:after {
    top: unset;
    bottom: 0;
    transform: translate(-50%, 50%) rotate(225deg);
  }

  &:after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    top: -11px;
    left: ${prop('arrowX')}px;
    transform: translateX(-50%) rotate(45deg);
    z-index: 10000;
    border: 1px solid ${themeColor(ThemeColor.b30)};
    border-right-color: transparent;
    border-bottom-color: transparent;
    background-color: ${ifProp(
      'dark',
      themeColor(ThemeColor.info3),
      themeColor(ThemeColor.b0),
    )};
  }
`
