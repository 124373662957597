import {
  BackComponent,
  ContactExpert,
  FormInput,
  FormLocation,
  FormSelect,
  Option,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { FormProvider } from '@/providers'
import { paths } from '@/routes/paths'
import { useCreateLocation } from '@/services'
import {
  useGoBack,
  useGoToPath,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import { path, pipe } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { PageActions, PageTitle } from './styles'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')
  const locationTranslations = useTranslationSection('locationPage')
  const companyTranslations = useTranslationSection('companyPage')
  const { t } = useTranslation()

  return {
    cancel: commonTranslations('cancel'),
    save: commonTranslations('save'),
    address: commonTranslations('address'),
    title: locationTranslations('title'),
    editLocation: locationTranslations('editLocation'),
    locationName: locationTranslations('locationName'),
    selectCompany: companyTranslations('selectCompany'),
    validations: useTranslationSectionObject('validations'),
    pageTitle: t('add_new_location'),
    advisorText: t('company_advisor_text'),
  }
}

export const AddLocationPage = () => {
  const messages = useMessages()
  const { companyId } = useParams()
  const { user } = useOutletContext()

  const createLocationMutation = useCreateLocation(companyId)
  const goToLocation = useGoToPath(paths.viewLocationWithKey)
  const handleBack = useGoBack()
  const handleSubmit = (values) =>
    createLocationMutation
      .mutateAsync(values)
      .then(pipe(path(['item', 'locationId']), goToLocation))

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.pageTitle}</Typography>
        </PageTitle>
        <FormProvider
          initialValues={{ companyId }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(messages.validations)}
        >
          {!companyId && (
            <FormSelect label={messages.selectCompany} name="companyId">
              {user.companies.map((el) => (
                <Option key={el.companyId} value={el.companyId}>
                  {el.name}
                </Option>
              ))}
            </FormSelect>
          )}
          <FormInput label={messages.locationName} name="name" />
          <FormLocation label={messages.address} name="address" />
          <PageActions>
            <SecondaryButton onClick={handleBack}>
              {messages.cancel}
            </SecondaryButton>
            <PrimaryButton type="submit">{messages.save}</PrimaryButton>
          </PageActions>
        </FormProvider>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
