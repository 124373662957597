import { Column, FormInput, Typography } from '@/components'
import { Card } from '@surein/ui'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { PolicyNumberFormValues } from '../helpers/types'

const PolicyNumberFormContainer = styled(Column)`
  height: 100%;
  justify-content: space-between;
`

const GradientCard = styled(Card)`
  border-radius: 8px;
  background: linear-gradient(270deg, #f5f6ff 0%, #e6e8ff 97.1%);
  padding: 16px;
  margin-bottom: 16px;
`

const PolicyNumberForm = () => {
  const { t } = useTranslation()
  return (
    <PolicyNumberFormContainer>
      <FormInput label="Policy Number" name="policyNumber" />
      <GradientCard variant="borderless">
        <Typography variant="p1Body">
          {t('addInsuranceContractsSteps.policyNumber.footerText')}
        </Typography>
      </GradientCard>
    </PolicyNumberFormContainer>
  )
}

export const PolicyNumberStep = () => {
  const { t } = useTranslation()
  const { onBack, onNext } = useStepper()

  const handleNext = async (values: PolicyNumberFormValues) => {
    onNext(values)
  }

  const handleSkip = () => {
    onNext({ policyNumber: null })
  }

  return (
    <StepLayout
      title={t('addInsuranceContractsSteps.policyNumber.title')}
      subtitle={t('addInsuranceContractsSteps.policyNumber.subtitle')}
      onNext={handleNext}
      onBack={onBack}
      onSkip={handleSkip}
    >
      <PolicyNumberForm />
    </StepLayout>
  )
}
