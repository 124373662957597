import { paths } from '@/routes/paths'
import { oneOrManyChildren, useIsInPath, useStateObject } from '@/utils'
import { createContext, useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const accessContext = createContext()

export const AccessProvider = ({ children }) => {
  const { pathname } = useLocation()
  const { locationId } = useParams()
  const [access, dispatchAccess] = useStateObject()

  const isInDashboard = useIsInPath(paths.dashboard)

  useEffect(() => {
    if (isInDashboard && access.locationId) {
      dispatchAccess({ locationId: '' })
    } else if (pathname.includes('/location/view')) {
      dispatchAccess({ locationId })
    } else if (locationId && !access.locationId) {
      dispatchAccess({ locationId })
    }
  }, [pathname, locationId, access.locationId])

  return (
    <accessContext.Provider value={access}>{children}</accessContext.Provider>
  )
}

AccessProvider.propTypes = {
  children: oneOrManyChildren.isRequired,
}

export const useAccessContext = () => useContext(accessContext)
