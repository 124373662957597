import { fromEventTarget } from '@/utils'
import { useCallback } from 'react'
import { Input } from '../../styled'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

interface FormInputProps {
  name: string
  label: string
  initialValue?: string
  info?: string
  helperText?: string
  ignoreErrorKeys?: string[]
  disabled?: boolean
  darkInfo?: boolean
  showErrorHelperText?: boolean
}

interface ComponentProps {
  onChange?: (event: Event) => void
}

export const FormInput = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  ignoreErrorKeys,
  disabled,
  darkInfo,
  showErrorHelperText,
  ...props
}: FormInputProps) => {
  // TODO: remove useless useCallback.
  // Make sure the change to `disabled` works correctly
  const Component = useCallback(
    ({ onChange, ...field }: ComponentProps) => (
      <Input
        onChange={fromEventTarget(onChange)}
        {...field}
        disabled={disabled}
        id={name}
        name={name}
        {...props}
      />
    ),
    [disabled],
  )

  return (
    <FormItem
      darkInfo={darkInfo}
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      name={name}
      showErrorHelperText={showErrorHelperText}
    >
      <Component />
    </FormItem>
  )
}

FormInput.propTypes = formItemPropType
