import {
  FormInput,
  FormPhone,
  PrimaryButton,
  PageMainContent,
  Typography,
  BackComponent,
  PageWidgetContainer,
  ContactExpert,
} from '@/components'
import { hasCompaniesPoASigned, isUserMD } from '@/pages/Profile/helpers'
import { FormProvider, useSnackbar } from '@/providers'
import { useUpdateUser } from '@/services'
import { useTranslationSectionObject } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { PageTitle, ProfileButtonsWrapper } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    snackBars: { ...useTranslationSectionObject('snackbars') },
    data: { ...useTranslationSectionObject('profile.data') },
    myProfile: { ...useTranslationSectionObject('profile.titles') },
    saveChanges: t('save_changes'),
    advisorText: t('personal_details_advisor_text'),
  }
}

export const ProfileData = () => {
  const { user } = useOutletContext()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const createUpdateUserMutation = useUpdateUser(user ? user?.auth0 : '')
  const handleSubmit = (values) => {
    createUpdateUserMutation
      .mutateAsync(values)
      .then(() => {
        snackbar.success(messages.snackBars.success.updateUserMsg)
      })
      .catch(() => snackbar.error(messages.snackBars.error.generalMsg))
  }

  const userIsMD = isUserMD(user) && hasCompaniesPoASigned(user)

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.myProfile.profile}</Typography>
        </PageTitle>
        <FormProvider initialValues={user} onSubmit={handleSubmit}>
          <FormInput
            disabled={userIsMD}
            label={messages.data.name.label}
            name="name"
          />
          <FormInput
            disabled={userIsMD}
            label={messages.data.surname.label}
            name="surname"
          />
          <FormInput disabled label={messages.data.email.label} name="email" />
          <FormPhone label={messages.data.phone.label} name="phone" />
          <ProfileButtonsWrapper justifyContent="end">
            <PrimaryButton type="submit">{messages.saveChanges}</PrimaryButton>
          </ProfileButtonsWrapper>
        </FormProvider>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}

ProfileData.propTypes = {
  data: PropTypes.shape({
    auth0: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
}
