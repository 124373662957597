import styled from 'styled-components'
import { switchProp } from 'styled-tools'
import { ButtonBase } from '../ButtonBase'

const getWidth = switchProp(
  'size',
  {
    small: '32px',
    medium: '40px',
    large: '52px',
  },
  '52px',
)

export const Container = styled(ButtonBase)`
  padding: 0;
  border-radius: 50%;

  width: ${getWidth};
  max-width: ${getWidth};
  min-width: ${getWidth};
  display: flex;
  justify-content: center;
  align-items: center;
`
