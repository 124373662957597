import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

export const Container = styled.div`
  border-bottom: ${ifProp(
    'noMargin',
    'none',
    `1px solid ${themeColor(ThemeColor.b30)}`,
  )};
  margin-bottom: ${ifNotProp('noContentGutter', toThemePixels(8))};

  > button {
    height: auto;
    padding: 8px;
  }

  span {
    font-size: 14px;
  }

  svg {
    width: ${toThemePixels(4)};
    height: ${toThemePixels(4)};
  }
`
