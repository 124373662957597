import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    debug: false,
    whitelist: ['en', 'de'],
    detection: {
      order: ['path'],
      caches: ['cookie'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },

    interpolation: {
      escapeValue: false,
      defaultVariables: {},
    },
  })

export const i18nHelpers = {
  fallbackLng: i18n.options.fallbackLng[0],
  languages: i18n.options.whitelist.filter((item) => item.length === 2),
}

i18n.on('languageChanged', (lng) => {
  const { location, history } = window
  const { pathname, search = '' } = location
  // eslint-disable-next-line no-use-before-define
  const { fallbackLng, languages } = i18nHelpers

  let [, oldLng] = pathname.split('/')
  oldLng = languages.includes(oldLng) ? oldLng : fallbackLng

  const changeUrlLanguage = (newPathname) =>
    history.replaceState(null, null, newPathname + search)

  if (lng !== oldLng) {
    if (lng === fallbackLng)
      changeUrlLanguage(pathname.replace(`/${oldLng}/`, `/`))
    else changeUrlLanguage(`/${lng}${pathname}`)
  }
  if (lng === fallbackLng) {
    if (pathname.includes(`/${fallbackLng}/`)) {
      const newUrl = pathname.replace(`/${fallbackLng}/`, '/')
      changeUrlLanguage(newUrl)
    }
  }

  document.documentElement.lang = lng
})
