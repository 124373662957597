import PropTypes from 'prop-types'
import { UnitInput } from '../../atoms'
import { BasicFormItem } from './BasicFormItem'
import { formItemPropType } from './helpers'

export const FormUnit = ({ unit, ...props }) => (
  <BasicFormItem component={UnitInput} unit={unit} {...props} />
)

FormUnit.propTypes = {
  unit: PropTypes.string.isRequired,
  ...formItemPropType,
}
