import { Absolute, AllInOne, Column, Header, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { CheckThin } from '@/icons'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'
import {
  IconBackground,
  ThankYouPageAnimationContainer,
  ThankYouPageContainer,
  ThankYouTextContainer,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    checkOutDashboard: t('checkOutDashboard'),
    weSentInvite: t('weSentInvite'),
    backToDashboard: t('back_to_dashboard'),
    orderSent: t('orderSent'),
    checkInbox: t('checkInbox'),
  }
}

export const ThankYouPage = () => {
  const messages = useMessages()
  const goToDashboard = useGoToPath(paths.dashboard)
  return (
    <Absolute left={0} right={0} top={0}>
      <Header backgroundColor={ThemeColor.b10} simple />
      <ThankYouPageContainer>
        <Column alignItems="center" gap="24px">
          <IconBackground>
            <CheckThin color={ThemeColor.success3} height="36px" width="36px" />
          </IconBackground>
          <ThankYouTextContainer>
            <Typography bold variant="h3">
              {messages.orderSent}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.checkInbox}
            </Typography>
          </ThankYouTextContainer>
        </Column>
        <ThankYouPageAnimationContainer>
          <AllInOne
            buttonText={messages.backToDashboard}
            onButtonClick={goToDashboard}
            subtitle={messages.weSentInvite}
            title={messages.checkOutDashboard}
          />
        </ThankYouPageAnimationContainer>
      </ThankYouPageContainer>
      <Confetti recycle={false} />
    </Absolute>
  )
}
