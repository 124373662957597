import {
  Column,
  ImageFromCdn,
  ModalBase,
  PrimaryButton,
  Row,
  SecondaryButton,
  Typography,
} from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { PiggyBank } from '@/icons'
import { HouseLightning } from '@/icons/HouseLightning'
import {
  themeBreakpointDown,
  themeColor,
  toThemePixels,
  useGoToPath,
} from '@/utils'
import { useBankingIntegration } from '@/utils/hooks/useBankingIntegration'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prop } from 'styled-tools'

const Container = styled(Row)`
  border-radius: 8px;
  font-family: 'Arial', sans-serif;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column-reverse;
  }
`

const Body = styled(Column)`
  padding: 24px;
  gap: 24px;
  flex: 1;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const Statistic = styled(Row)`
  margin-bottom: 10px;
  align-items: center;
  gap: 8px;

  > svg {
    align-self: flex-start;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 45%;
  background-color: ${themeColor(ThemeColor.b10)};
  border-radius: 0 ${toThemePixels(4)} ${toThemePixels(4)} 0;
  flex: 1;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    border-radius: 0 ${toThemePixels(4)} ${toThemePixels(4)} 0;
  }
  ${themeBreakpointDown(Breakpoint.md)} {
    /* This makes it so that the image does not get too thin
  and looks buggy - just leaves a slicker pictureless modal instead */
    display: none;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    display: flex;
    height: auto;
    min-height: 30vh;
    min-width: 100%;
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }

  ${themeBreakpointDown(Breakpoint.xs)} {
    min-height: 25vh;
    min-width: 100%;
  }
`

const Statistics = styled(Column)`
  svg {
    flex-shrink: 0;
  }
`

const ButtonAddon = styled.div`
  position: relative;
  display: inline-block;

  &:before {
    content: '${prop('text')}';
    position: absolute;
    top: -12px;
    left: -16px;
    padding: ${toThemePixels(1)} ${toThemePixels(2)};
    border-radius: 16px;
    z-index: 1;
    background-color: ${themeColor(ThemeColor.info3)};
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    color: #2137fc;
  }
`

const stats = [
  {
    label: 'optimiseCoverageModal-stats-1',
    icon: PiggyBank,
  },
  {
    label: 'optimiseCoverageModal-stats-2',
    icon: HouseLightning,
  },
]

export const OptimiseCoverageModal = ({
  isOpen: propsIsOpen,
  onClose,
  locationId,
}) => {
  const [cookies, setCookie] = useCookies(['hasSeenOptimiseModal'])
  const [isOpen, setIsOpen] = useState(false)
  const goToYearlyAssessment = useGoToPath(`/yearlyAssessment/${locationId}`)
  const goToYearlyAssessmentWithIntegration = useGoToPath(
    `/yearlyAssessment/${locationId}`,
    { withIntegrations: true },
  )

  const config = {
    source: 'yearly-check-in',
    metadata: { locationId },
  }

  const {
    canGenerateTinkLink,
    redirectUserToTink,
    isLoading: isBankingIntegrationLoading,
  } = useBankingIntegration(config)

  const { t } = useTranslation()

  useEffect(() => {
    if (!cookies.hasSeenOptimiseModal) {
      setIsOpen(true)
      setCookie('hasSeenOptimiseModal', 'true', { path: '/' }) // Set a cookie to remember that the modal has been shown
    }
  }, [cookies, setCookie])

  const handleOnClose = () => {
    setIsOpen(false)
    onClose()
  }

  const onOptimize = () => {
    goToYearlyAssessment()
  }

  const onOptimizeWithIntegration = () => {
    if (canGenerateTinkLink && redirectUserToTink) {
      redirectUserToTink()
    } else {
      goToYearlyAssessmentWithIntegration()
    }
  }

  return (
    <ModalBase
      closable
      isOpen={isOpen || propsIsOpen}
      onClose={handleOnClose}
      padding="0"
    >
      <Container>
        <Body>
          <Column gap="24px" justifyContent="space-between">
            <Column gap="8px">
              <Typography color={ThemeColor.info1} variant="p1Body">
                {t('yearlyAssessment.optimiseCoverageModal-pretitle')}
              </Typography>
              <Typography bold variant="h4">
                {t('yearlyAssessment.optimiseCoverageModal-title')}
              </Typography>
              <Typography color={ThemeColor.b50} variant="p2Body">
                {t('yearlyAssessment.optimiseCoverageModal-description')}
              </Typography>
            </Column>
            <Statistics>
              {stats.map(({ icon, label }) => {
                const Icon = icon
                return (
                  <Statistic key={`optimise-coverage-modal-${label}`}>
                    <Icon
                      color={ThemeColor.b100}
                      height={24}
                      stroke
                      width={24}
                    />
                    <Typography variant="p1Body">
                      {t(`yearlyAssessment.${label}.description`)}
                    </Typography>
                  </Statistic>
                )
              })}
            </Statistics>
          </Column>
          <Column gap="8px">
            <ButtonAddon text={t('yearlyAssessment.twiceAsFast')}>
              <PrimaryButton
                block
                disabled={isBankingIntegrationLoading}
                loading={isBankingIntegrationLoading}
                onClick={onOptimizeWithIntegration}
              >
                <Typography ellipsis variant="p1Body">
                  {t(
                    'yearlyAssessment.optimiseCoverageModal-button-label-primary',
                  )}
                </Typography>
              </PrimaryButton>
            </ButtonAddon>
            <SecondaryButton block onClick={onOptimize}>
              <Typography ellipsis variant="p1Body">
                {t(
                  'yearlyAssessment.optimiseCoverageModal-button-label-secondary',
                )}
              </Typography>
            </SecondaryButton>
          </Column>
        </Body>
        <ImageContainer>
          <ImageFromCdn
            fallbackType="jpg"
            name="fire-extinguisher"
            type="webp"
          />
        </ImageContainer>
      </Container>
    </ModalBase>
  )
}

OptimiseCoverageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  locationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
