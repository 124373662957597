import { Column, PageActionsStyles } from '@/components'
import { toThemePixels } from '@/utils'
import styled from 'styled-components'

export const PageActions = styled(PageActionsStyles)`
  justify-content: space-between;
`

export const PageTitle = styled(Column)`
  margin-bottom: ${toThemePixels(8)};
`
