import { renderableType, useIsInPath } from '@/utils'
import { config } from '../config'
import { paths } from './paths'
import { Redirect } from './Redirect'

const funnelCategories = [
  config.liabilityLeadDomain,
  config.cyberLeadDomain,
  config.financialLossLeadDomain,
  config.professionalLiabilityLeadDomain,
  config.buildingLeadDomain,
  config.contentLeadDomain,
  config.legalLeadDomain,
  config.liabilitySsfDomain,
  config.contentSsfDomain,
  config.legalSsfDomain,
  config.gastronomyLeadDomain,
]

const checkoutUrls = {
  [config.liabilitySsfDomain]: paths.viewSsfCheckoutWithKey('LI'),
  [config.contentSsfDomain]: paths.viewSsfCheckoutWithKey('CO'),
  [config.legalSsfDomain]: paths.viewSsfCheckoutWithKey('LE'),
}

const recommendationUrls = {
  [config.liabilitySsfDomain]: paths.viewSsfOfferWithKey('LI'),
  [config.contentSsfDomain]: paths.viewSsfOfferWithKey('CO'),
  [config.legalSsfDomain]: paths.viewSsfOfferWithKey('LE'),
}

export const WithFunnelGuard = ({ element }) => {
  const isFunnelApp = funnelCategories.includes(window.location.host)
  const inCheckout = useIsInPath(checkoutUrls[window.location.host])
  const inRecommendation = useIsInPath(recommendationUrls[window.location.host])
  const inFunnel = useIsInPath(paths.funnel, paths.authRedirect)
  const inAssessment = useIsInPath(
    paths.cyberLead,
    paths.financialLossLead,
    paths.professionalLiabilityLead,
    paths.buildingLead,
    paths.liabilityLead,
    paths.liabilityLeadB,
    paths.contentLead,
    paths.legalLead,
    paths.gastronomyLead,
    paths.liabilitySSF,
    paths.contentSSF,
    paths.legalSSF,
  )

  if (isFunnelApp && (inCheckout || inRecommendation)) return element

  if (isFunnelApp && inAssessment) return <Redirect to={paths.funnel} />

  if (isFunnelApp && !inFunnel) return <Redirect to={paths.authRedirect} />

  return element
}

WithFunnelGuard.propTypes = {
  element: renderableType.isRequired,
}
