import { getCart, deleteFromCart, addToCart, submitCheckout } from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { flatten, groupBy, pipe, pluck, prop } from 'ramda'
import { useQuery } from 'react-query'

const keys = {
  cart: 'cart',
  count: 'count',
}

export const useCart = (options) =>
  useQuery(keys.cart, getCart, {
    select: pipe(pluck('items'), flatten, groupBy(prop('locationId'))),
    ...options,
  })

export const useDeleteFromCart = () =>
  useInvalidateOnSuccessMutation(keys.cart, (recommendationProductId) =>
    deleteFromCart(recommendationProductId),
  )
export const useAddProductToCart = () =>
  useInvalidateOnSuccessMutation(keys.cart, (id) => addToCart(id))

export const useSubmitCheckout = () =>
  useInvalidateOnSuccessMutation(
    [keys.cart, 'location'],
    ({ paymentId, items }) => submitCheckout({ paymentId, items }),
  )
