import { api } from './api'
import { camelToSnakeCase, snakeToCamelCase } from '@/utils'

export const getAttributes = async (
  language: 'en' | 'de' = 'de',
): Promise<{
  getAttributeLabel: (code: string) => string
  getAttributeDescription: (code: string) => string | undefined
}> => {
  const attributes = (await api.get(
    `/public/attributes?language=${language}`,
    // we need to cast for now because api client transforms return type in interceptor
  )) as Record<string, { name: string; description?: string }>

  return {
    /**
     * Returns the code as a fallback
     */
    getAttributeLabel(code: string): string {
      // some parts of application use snake_case, some camelCase
      // FIXME: remove this when we will use snake case for data everywhere
      const label =
        attributes[snakeToCamelCase(code)]?.name ??
        attributes[camelToSnakeCase(code)]?.name

      if (!label) {
        console.warn(`No attribute translation found for code: ${code}`)
      }

      return label ?? code
    },

    getAttributeDescription(code: string): string | undefined {
      return attributes[code]?.description
    },
  }
}
