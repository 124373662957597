import { AssessmentStatus } from '@/enums'
import { evolve, pipe, omit, pick, propEq } from 'ramda'

export const makeInitialValues = evolve({
  address: pipe(omit(['addressId'])),
})

export const transform = pipe(
  pick(['address', 'name', 'selfAssessment', 'riskChecklist']),
)

export const checkCompletedAssessment = pipe(
  propEq('selfAssessment', AssessmentStatus.completed),
)

export const checkPoaSigned = pipe(
  (data) =>
    data.riskChecklist.checks.find((check) => check.type === 'signedPOA')
      ?.isDone,
)
