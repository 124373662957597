import { renderableType, useDelayedToggle } from '@/utils'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Typography } from '../../atoms'
import {
  CollapseBody,
  CollapseHeader,
  CollapseIcon,
  CollapsibleContainer,
} from './styles'

export const Collapse = ({ title, children, isOpen, onCollapse, divide }) => {
  const [height, setHeight] = useState(0)
  const childRef = useCallback((node) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() => {
      setHeight(node.offsetHeight)
    })
    resizeObserver.observe(node)
  }, [])

  return (
    <>
      <CollapseHeader onClick={onCollapse}>
        <Typography variant="captionB">{title}</Typography>
        <CollapseIcon $isOpen={isOpen} />
      </CollapseHeader>
      <CollapseBody divide={divide} height={height || 0} isOpen={isOpen}>
        <div ref={childRef}>{children}</div>
      </CollapseBody>
    </>
  )
}

Collapse.propTypes = {
  children: PropTypes.node,
  divide: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  title: renderableType,
}

export const Collapsible = ({ isOpen, children, destroyOnClose = true }) => {
  const [childRef, setChildRef] = useState()
  const renderChildren = useDelayedToggle({ isOpen, closingTime: 400 })

  return (
    <CollapsibleContainer height={childRef?.offsetHeight || 0} isOpen={isOpen}>
      {(renderChildren || !destroyOnClose) && (
        <div ref={setChildRef}>{children}</div>
      )}
    </CollapsibleContainer>
  )
}

Collapsible.propTypes = {
  children: PropTypes.node,
  destroyOnClose: PropTypes.bool,
  isOpen: PropTypes.bool,
}
