import { ThemeColor } from '@/enums'
import { CheckLine } from '@/icons'
import PropTypes from 'prop-types'
import { Typography } from '../../atoms'
import {
  BreadcrumbBorder,
  BreadcrumbText,
  BreadcrumbsContainer,
  Step,
} from './styles'

export const Breadcrumbs = ({ steps, current, stepperBreadcrumbTitles }) => (
  <BreadcrumbsContainer>
    {steps.map((stepNr, i) => (
      <Step current={current === i} done={current > i}>
        <BreadcrumbBorder checked={current > i} current={current === i}>
          <Typography
            color={current === i ? ThemeColor.electric500 : ThemeColor.b50}
            variant="captionR"
          >
            {current > i ? (
              <CheckLine color={ThemeColor.b0} size={20} />
            ) : (
              stepNr + 1
            )}
          </Typography>
        </BreadcrumbBorder>
        <BreadcrumbText current={current === i} done={current > i}>
          {stepperBreadcrumbTitles[stepNr + 1]}
        </BreadcrumbText>
      </Step>
    ))}
  </BreadcrumbsContainer>
)

Breadcrumbs.propTypes = {
  current: PropTypes.number,
  stepperBreadcrumbTitles: PropTypes.arrayOf(PropTypes.string),
  steps: PropTypes.arrayOf(PropTypes.string),
}
