import { ButtonLink, Row, Typography } from '@/components'
import { ComponentColors } from '@/enums'
import { ArrowRight } from '@/icons'
import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  formatMoney,
} from '@/utils'
import { ResponsivePie } from '@nivo/pie'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { chartColors, getInternalChartText, prepareChartData } from './helpers'
import {
  CustomTooltipComponent,
  KPIChartContainer,
  KPILegendColor,
  KPILegendContainer,
  KPILegendItem,
  KPIOverviewContainer,
  MiddleTextWrapper,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    details: t('kpiOverview.details'),
    insuranceExpenses: t('kpiOverview.insuranceExpenses'),
    perYear: t('kpiOverview.perYear'),
  }
}

export const KPIOverview = ({ data, grossPrice = 0, onDetailsClick }) => {
  const chartData = useMemo(() => prepareChartData(data), [data])
  const messages = useMessages()

  return (
    <KPIOverviewContainer hasDetailsLink={!!onDetailsClick}>
      <KPIChart data={chartData} grossPrice={grossPrice} />
      <ChartLegend data={chartData} />
      <ButtonLink
        color={ComponentColors.primary}
        icon={ArrowRight}
        onClick={onDetailsClick}
        reverted
        size="medium"
      >
        {messages.details}
      </ButtonLink>
    </KPIOverviewContainer>
  )
}

KPIOverview.propTypes = {
  data: dynamicObjectPropType,
  grossPrice: PropTypes.number,
  onDetailsClick: PropTypes.func,
}

const CustomTooltip = ({ datum }) => {
  const datumName = [datum.data.name, ':'].join('')

  return (
    <CustomTooltipComponent>
      <KPILegendColor color={datum.color} />
      <Typography bold variant="p2Body">
        {datumName}
      </Typography>
      <Typography variant="p2Body">{formatMoney(datum.value)}</Typography>
    </CustomTooltipComponent>
  )
}

CustomTooltip.propTypes = {
  datum: PropTypes.shape({
    color: PropTypes.string.isRequired,
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    value: PropTypes.number.isRequired,
  }),
}

const InternalChartText = ({ grossPrice }) => {
  const messages = useMessages()
  // used to style the decimal to a smaller size

  const { integer, decimal } = getInternalChartText(grossPrice)
  const formattedDecimal = `,${decimal}€`

  return (
    <MiddleTextWrapper>
      <span>{messages.insuranceExpenses}</span>
      <Typography bold variant="h4">
        {integer}
        <Typography bold variant="badgeText">
          {formattedDecimal}
        </Typography>
      </Typography>
      <span>{messages.perYear}</span>
    </MiddleTextWrapper>
  )
}

InternalChartText.propTypes = {
  grossPrice: PropTypes.number,
}

const ChartLegend = ({ data }) => (
  <KPILegendContainer>
    {data.map((item) => (
      <KPILegendItem key={item.id}>
        <Row alignItems="center">
          <KPILegendColor color={item.color} />
          <Typography variant="badgeText">{item.name}</Typography>
        </Row>
        <Typography bold variant="badgeText">
          {formatMoney(item.value)}
        </Typography>
      </KPILegendItem>
    ))}
  </KPILegendContainer>
)

ChartLegend.propTypes = {
  data: dynamicArrayPropType,
}

const KPIChart = ({ data, grossPrice }) => (
  <KPIChartContainer>
    <ResponsivePie
      activeOuterRadiusOffset={3}
      borderColor={{
        from: 'color',
      }}
      borderWidth={1}
      colors={chartColors}
      cornerRadius={3}
      data={data}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      innerRadius={0.9}
      margin={{ right: 4, left: 4, top: 4, bottom: 4 }}
      padAngle={3}
      tooltip={({ datum }) => <CustomTooltip datum={datum} />}
      valueFormat={(value) => formatMoney(value)}
    />
    <InternalChartText grossPrice={grossPrice} />
  </KPIChartContainer>
)

KPIChart.propTypes = {
  data: dynamicObjectPropType,
  grossPrice: PropTypes.number,
}
