import { FormInput, FormPhone } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { anyPropMissing } from '@/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    firstName: t('firstName'),
    lastName: t('surname'),
    phoneNumber: t('phone'),
  }
}

export const UserProfileStep = () => {
  const messages = useMessages()
  const { values } = useFormContext()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.userProfile,
  )

  useOnNextClick(save)

  useNextDisabled(() => anyPropMissing(['name', 'surname', 'phone'], values), [
    values,
  ])

  useNextLoading(() => isLoading, [isLoading])

  useEffect(() => {
    document.getElementsByTagName('input')[0].focus()
  }, [])

  return (
    <>
      <FormInput label={messages.firstName} name="name" />
      <FormInput label={messages.lastName} name="surname" />
      <FormPhone label={messages.phoneNumber} name="phone" />
    </>
  )
}
