import { Breakpoint, Categories, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import {
  propTypeFromEnumKeys,
  useGoToPath,
  useTranslationSection,
  useIsMobile,
  makeQueryString,
} from '@/utils'
import PropTypes from 'prop-types'
import { Typography, PrimaryButton } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Column, Row } from '../../styled'
import {
  ButtonContainer,
  Container,
  RecommendationDetails,
  Tag,
} from './styles'

const useMessages = (category) => {
  const categoriesTranslations = useTranslationSection('categories')
  const insuranceStagesTranslations = useTranslationSection('insuranceStages')

  return {
    title: categoriesTranslations(`${category}.name`),
    description: categoriesTranslations(`${category}.shortDesc`),
    label: insuranceStagesTranslations('important'),
  }
}

export const RecommendationCardBase = ({
  categoryId,
  primaryDisabled,
  primaryText,
  important,
  onCardClick,
  onButtonClick,
  inlineButton,
}) => {
  const categoryData = Categories[categoryId]
  const messages = useMessages(categoryData.key)

  const handleButtonClick = (e) => {
    e.stopPropagation()
    onButtonClick()
  }

  return (
    <Container onClick={onCardClick}>
      <Row gap="24px" justifyContent="space-between" width="100%">
        <RecommendationDetails>
          <ShowWhen when={important}>
            <Tag>
              <Typography variant="captionR">{messages.label}</Typography>
            </Tag>
          </ShowWhen>
          <Column gap="4px">
            <Typography variant="captionB">{messages.title}</Typography>
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.description}
            </Typography>
          </Column>
          {!inlineButton && (
            <ButtonContainer>
              <PrimaryButton
                disabled={primaryDisabled}
                onClick={handleButtonClick}
                size="medium"
              >
                {primaryText}
              </PrimaryButton>
            </ButtonContainer>
          )}
        </RecommendationDetails>
        {inlineButton && (
          <ButtonContainer>
            <PrimaryButton
              block
              disabled={primaryDisabled}
              onClick={handleButtonClick}
              size="medium"
            >
              {primaryText}
            </PrimaryButton>
          </ButtonContainer>
        )}
      </Row>
    </Container>
  )
}

RecommendationCardBase.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  important: PropTypes.bool,
  inlineButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onCardClick: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
}

export const RecommendationCard = ({
  categoryId,
  locationId,
  recommendationId,
  primaryDisabled,
  primaryText,
  important,
}) => {
  const { isMobile } = useIsMobile(Breakpoint.sm)

  const goToInsurance = useGoToPath(
    `${paths.viewInsuranceWithKey({
      categoryId,
      locationId,
    })}${makeQueryString({ recommendationId })}`,
  )

  const goToRecommendation = useGoToPath(
    paths.viewRecommendationWithKey(recommendationId),
  )

  return (
    <RecommendationCardBase
      categoryId={categoryId}
      important={important}
      inlineButton={!isMobile}
      onButtonClick={goToRecommendation}
      onCardClick={goToInsurance}
      primaryDisabled={primaryDisabled}
      primaryText={primaryText}
    />
  )
}

RecommendationCard.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  important: PropTypes.bool,
  locationId: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
  recommendationId: PropTypes.string,
}
