import { ProgressiveLoader } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import PropTypes from 'prop-types'
import { useSaveAssessmentStep } from '../helpers'

export const AssessmentLoadingStep = ({ onNext, stepsMessages }) => {
  const { save } = useSaveAssessmentStep(AssessmentStepKeys.prepareData)

  const handleNext = () => {
    save()
    onNext()
  }

  return (
    <ProgressiveLoader
      delay={700}
      onComplete={handleNext}
      steps={stepsMessages}
    />
  )
}

AssessmentLoadingStep.propTypes = {
  onNext: PropTypes.func,
  stepsMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
}
