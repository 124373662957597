import { useToggable } from '@/utils'
import { useEffect } from 'react'

export const WithTimeoutRendering = ({
  delay,
  children,
  renderIfNotComplete = null,
}) => {
  const [show, , negate] = useToggable(null)

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      negate()
    }, delay)

    return () => clearTimeout(delayTimeout)
  }, [delay])

  if (!show) return renderIfNotComplete

  return children
}
