import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column, PageMainContent, Row } from '../../styled'

export const PageActions = styled(Row)`
  justify-content: flex-end;
  margin-top: ${toThemePixels(10)};

  button {
    width: calc(50% - 8px);
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    position: fixed;
    bottom: 0;
    padding: ${toThemePixels(6)} 0;
    width: calc(100% - ${toThemePixels(12)});
    background-color: ${themeColor(ThemeColor.b0)};
    margin: 0;

    button {
      width: 100%;
    }
  }
`

export const Container = styled(PageMainContent)`
  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-bottom: 100px;
  }
`

export const SearchContainer = styled(Column)`
  margin-bottom: ${toThemePixels(4)};
  gap: ${toThemePixels(4)};
`
