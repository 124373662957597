import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { OuterContainer, InnerContainer, CheckedCircle } from './styles'

export const RadioButton = ({
  checked,
  disabled,
  onChange,
  label,
  ...rest
}) => (
  <OuterContainer
    onClick={() => {
      if (!disabled) onChange?.(checked)
    }}
  >
    <InnerContainer {...rest} checked={checked} disabled={disabled}>
      {checked && <CheckedCircle />}
    </InnerContainer>
    {label && <Typography variant="placeholder">{label}</Typography>}
  </OuterContainer>
)

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
