import {
  Column,
  ModalBase,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { CloseModalButton } from '@/components/molecules/ModalBase/styles'
import { AssessmentStepKeys, Breakpoint, ThemeColor } from '@/enums'
import { Close, PiggyBank, Plus } from '@/icons'
import { HouseLightning } from '@/icons/HouseLightning'
import { PulseIcon } from '@/icons/Pulse'
import InsuranceAndProviderSelectorFormField from '@/modules/yearlyCheck/components/InsuranceAndProviderSelector'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { FormProvider, useFormContext } from '@/providers'
import { returns, themeBreakpointUp, useModal } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FixedFooter, FormContent, StepFooter, StepHeader } from './components'

const FormContainer = styled(Column)`
  gap: 1rem;
`

const AddInsuranceForm = ({ onClose }) => {
  const { t } = useTranslation()
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.currentInsurances,
    undefined,
    true,
  )

  const handleOnChange = () => {
    if (!values) return

    save().then(() => {
      onNext()
    })
  }

  return (
    <>
      <StepHeader
        description={t('yearlyAssessment.addInsuranceForm-description')}
        title={t('yearlyAssessment.addInsuranceForm-title')}
      />
      <CloseModalButton onClick={onClose}>
        <Close color={ThemeColor.b100} height={16} width={16} />
      </CloseModalButton>
      <FormContainer>
        <FormContent>
          <InsuranceAndProviderSelectorFormField />
        </FormContent>
        <StepFooter
          fixed
          isButtonLoading={isButtonLoading}
          onSubmit={handleOnChange}
        />
      </FormContainer>
    </>
  )
}
AddInsuranceForm.propTypes = {
  onClose: PropTypes.func,
}

const Footer = styled(FixedFooter)`
  gap: 1rem;
`

const stats = [
  {
    title: 'optimiseCoverageModal-stats-1',
    icon: PiggyBank,
  },
  {
    title: 'optimiseCoverageModal-stats-2',
    icon: HouseLightning,
  },
  {
    title: 'optimiseCoverageModal-stats-3',
    icon: PulseIcon,
  },
]

const InsuranceStatContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  height: 45vh;
`

const InsuranceStat = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
`

export const InsuranceSplash = ({ onContinue }) => {
  const { t } = useTranslation()
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.currentInsurances,
    returns({ insurances: [] }),
    true,
  )

  const { onNext } = useStepper()

  const handleSkip = () => {
    save().then(() => {
      onNext()
    })
  }

  return (
    <>
      <StepHeader
        description={t('yearlyAssessment.currentInsurance-description')}
        sectionLabel={t('yearlyAssessment.currentInsurance-category')}
        title={t('yearlyAssessment.currentInsurance-title')}
      />

      <InsuranceStatContent>
        {stats.map(({ icon, title }) => {
          const Icon = icon
          return (
            <InsuranceStat key={`optimise-coverage-modal-${icon}`}>
              <Icon color={ThemeColor.b100} height={24} stroke width={24} />
              <Column>
                <Typography bold variant="p1Body">
                  {t(`yearlyAssessment.${title}.title`)}
                </Typography>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t(`yearlyAssessment.${title}.description`)}
                </Typography>
              </Column>
            </InsuranceStat>
          )
        })}
      </InsuranceStatContent>
      <Footer>
        <SecondaryButton loading={isButtonLoading} onClick={handleSkip}>
          {t('yearlyAssessment.currentInsurance-skipButton')}
        </SecondaryButton>
        <PrimaryButton icon={() => <Plus size={18} />} onClick={onContinue}>
          {t('yearlyAssessment.currentInsurance-addButton')}
        </PrimaryButton>
      </Footer>
    </>
  )
}

InsuranceSplash.propTypes = {
  onContinue: PropTypes.func,
}

export const CurrentInsurances = ({ defaultValues }) => {
  const { onNext } = useStepper()
  const { isOpen, open, close } = useModal()

  return (
    <Container>
      <FormProvider initialValues={{ insurances: defaultValues }}>
        <InsuranceSplash onContinue={open} onSkip={onNext} />
        <ModalBase closable isOpen={isOpen} padding="0">
          <AddInsuranceForm onClose={close} />
        </ModalBase>
      </FormProvider>
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 102px);
  flex-direction: column;

  ${themeBreakpointUp(Breakpoint.md)} {
    height: 77vh;
  }
`

CurrentInsurances.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.array,
}
