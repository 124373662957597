import { pipe, append, join, __ } from 'ramda'
import { useTranslation } from 'react-i18next'

/**
 * @deprecated
 * This feels like unnecessary complication. I would just use the `t` function directly.
 */
export const useTranslationSection = (section, options) => {
  const { t } = useTranslation()

  if (options) return t(section, options)

  return pipe(append(__, [section]), join('.'), t)
}
