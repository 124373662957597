import { CalendarNew } from '@/icons'
import {
  capitalize,
  fromEventTarget,
  timestampToDateString,
  useClickOutsideEvent,
  useDerivedState,
  useModal,
} from '@/utils'
import PropTypes from 'prop-types'
import { pipe } from 'ramda'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { InputWithAddon } from '../../atoms'
import { WholeWidth } from '../../styled'
import { Calendar } from '../Calendar'
import { ModalBase } from '../ModalBase'
import { DateInput } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return { done: t('done') }
}

export const DatePicker = ({
  onChange,
  value,
  min = '1000-01-01',
  max = '2200-01-01',
  calendarHeader,
  ...props
}) => {
  const messages = useMessages()
  const inputRef = useRef()
  const { isOpen, toggle, close } = useModal()
  const [calendarValue, setCalendarValue] = useDerivedState(value)

  const handleChange = (e) => {
    if (
      Date.parse(min) > fromEventTarget(Date.parse)(e) ||
      Date.parse(max) < fromEventTarget(Date.parse)(e)
    ) {
      return onChange(NaN)
    }

    return pipe(fromEventTarget(Date.parse), onChange)(e)
  }

  const handleCalendarChange = () => {
    onChange(calendarValue)
    toggle()
    inputRef.current.value = timestampToDateString(calendarValue)
  }

  useClickOutsideEvent(
    inputRef.current,
    () => {
      if (!value) inputRef.current.value = ''
    },
    [value],
  )

  const DateComponent = useCallback(
    () => (
      <DateInput
        ref={inputRef}
        defaultValue={timestampToDateString(value)}
        max={max}
        min={min}
        onChange={handleChange}
        {...props}
      />
    ),
    [min, max],
  )
  return (
    <WholeWidth>
      <InputWithAddon
        addonAfter={() => <CalendarNew onClick={toggle} size="button" />}
        inputComponent={DateComponent}
      />
      {isOpen && (
        <ModalBase
          closable
          header={calendarHeader}
          isOpen={isOpen}
          onClose={close}
          onPrimaryClick={handleCalendarChange}
          primaryText={capitalize(messages.done)}
        >
          <Calendar
            maxDate={new Date(max)}
            minDate={new Date(min)}
            onChange={setCalendarValue}
            value={calendarValue}
          />
        </ModalBase>
      )}
    </WholeWidth>
  )
}

DatePicker.propTypes = {
  calendarHeader: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}
