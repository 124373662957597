import { PageActionsStyles } from '@/components'
import { Breakpoint } from '@/enums'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const PageActions = styled(PageActionsStyles)`
  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-wrap: wrap-reverse;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`

export const PageTitle = styled.div`
  margin-bottom: ${toThemePixels(8)};
`
