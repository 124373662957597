import { Categories, ThemeColor } from '@/enums'
import { formatMoney, themeColor, toThemePixels, useIsMobile } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CarrierLogo } from '../../atoms/CarrierLogo'
import { Typography } from '../../atoms/Typography'
import { Column, Row } from '../../styled'

export const CartProductContainer = styled(Row)`
  gap: ${toThemePixels(4)};

  img {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(3)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
    height: ${toThemePixels(12)};
    width: ${toThemePixels(12)};

    @media (max-width: 900px) {
      height: ${toThemePixels(8)};
      width: ${toThemePixels(8)};
      border-radius: ${toThemePixels(1)};
    }
  }
`

const TextWrapper = styled(Row)`
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${toThemePixels(2)};
  flex: 1;
  overflow: hidden;
`

const Price = styled(Typography)`
  text-align: end;

  > span {
    margin-left: 4px;
  }
`

export const CartProduct = ({
  carrierData: { logoUrl, name: carrierName },
  grossPrice,
  categoryId,
  paymentPeriod,
}) => {
  const { t } = useTranslation()
  const categoryTranslation = t(`categories.${Categories[categoryId].key}.name`)
  const { width } = useIsMobile()
  const isMobile = width <= 900

  return (
    <CartProductContainer>
      <CarrierLogo height="48px" name={logoUrl} />
      <TextWrapper>
        <Column flex="1" minWidth="0">
          <Typography ellipsis variant="p2Body">
            {carrierName}
          </Typography>
          {!isMobile && (
            <Typography
              color={ThemeColor.b50}
              ellipsis
              title={categoryTranslation}
              variant="badgeText"
            >
              {categoryTranslation}
            </Typography>
          )}
        </Column>
        <Column>
          <Price bold variant="badgeText">
            {formatMoney(grossPrice)}
            <Typography color={ThemeColor.b50} variant="badgeText">
              {t(`recommendationPricePeriods.${paymentPeriod}`)}
            </Typography>
          </Price>
        </Column>
      </TextWrapper>
    </CartProductContainer>
  )
}

CartProduct.propTypes = {
  carrierData: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  categoryId: PropTypes.string,
  grossPrice: PropTypes.number,
  paymentPeriod: PropTypes.string,
}
