import {
  BasicFormItem,
  Button,
  Column,
  Divider,
  EmptySearchState,
  InputWithAddon,
  ListSelect,
  ModalBase,
  Option,
  Row,
  Typography,
} from '@/components'
import { SearchContainer } from '@/components/organisms/AddInsuranceView/styles'
import { CardNode } from '@/components/organisms/GroupCardSelectWithModal/CardNode'
import {
  ButtonsContainer,
  CarrierContainer,
  Container,
  ContentWrapper,
  HeaderContainer,
  InsuranceGroup,
  SelectedCardTop,
  SelectedInsurancesGroup,
  SelectedStyledCard,
  StyledChecked,
  TitleContainer,
} from '@/components/organisms/GroupCardSelectWithModal/styles'
import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import { useCurrentInsuranceMessages } from '@/pages/SelfAssessmentPage/steps/CurrentInsurancesStep'
import { useSnackbar } from '@/providers'
import { dynamicArrayPropType, toThemePixels } from '@/utils'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

const SelectedProvidersWrapper = styled(Column)`
  padding: ${toThemePixels(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: ${toThemePixels(2)};
`

const SelectedProvider = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const SelectedCardNode = ({ item, removeProvider }) => {
  const { title, subtitle, value, providers } = item

  const { carriers } = useOutletContext()

  const findListItems = () =>
    providers.map((provider) =>
      carriers.find((option) => option.value === provider),
    )

  const providerItems = findListItems()

  return (
    <SelectedStyledCard>
      <SelectedCardTop>
        <TitleContainer>
          <Typography variant="captionB">{title}</Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {subtitle}
          </Typography>
        </TitleContainer>
        <StyledChecked />
      </SelectedCardTop>
      <SelectedProvidersWrapper>
        {providerItems.map((provider) => (
          <SelectedProvider key={provider}>
            <CarrierContainer>
              {provider.icon && <img alt="provider icon" src={provider.icon} />}
              <Typography color={ThemeColor.b50} variant="captionR">
                {provider.text}
              </Typography>
            </CarrierContainer>
            <ButtonsContainer>
              <div />
              <Button
                color="danger"
                onClick={() => removeProvider(value, provider.value)}
                size="medium"
                variant="outlined"
              >
                Remove
              </Button>
            </ButtonsContainer>
          </SelectedProvider>
        ))}
      </SelectedProvidersWrapper>
    </SelectedStyledCard>
  )
}

SelectedCardNode.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    providers: dynamicArrayPropType,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  removeProvider: PropTypes.func,
}

export const InsuranceAndProviderSelector = ({ onChange }) => {
  const { t } = useTranslation()
  const [selectedInsurances, setSelectedInsurances] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const cardOptions = useInsuranceGroups()
  const { carriers } = useOutletContext()
  const messages = useCurrentInsuranceMessages()

  const toggleModal = (isOpen, insurance = null) => {
    setModalOpen(isOpen)
    setCurrentSelection(insurance)
  }

  const removeProviderFromInsurance = (insuranceValue, providerValue) => {
    setSelectedInsurances((prev) =>
      prev.reduce((acc, cur) => {
        if (cur.value === insuranceValue) {
          if (cur.provider === providerValue) {
            return acc
          }
        }
        acc.push(cur)
        return acc
      }, []),
    )
  }

  const exportSelectedData = () =>
    selectedInsurances.map((ins) => ({
      carrierId: ins.provider,
      categoryId: ins.value,
    }))

  const snackbar = useSnackbar()

  const addProviderToInsurance = (provider) => {
    snackbar.success(messages.contractAdded, 2000)
    setSelectedInsurances((prev) => {
      const exists = prev.some(
        (ins) =>
          ins.value === currentSelection.value && ins.provider === provider,
      )

      if (!exists) {
        return [...prev, { ...currentSelection, provider, id: Date.now() }]
      }

      return prev
    })

    toggleModal(false)
    setCurrentSelection(null)
    setSearchTerm('')
  }

  const handleInsuranceClick = (insurance) => {
    toggleModal(true, insurance)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredCardOptions = cardOptions.filter((group) =>
    group.items.some((item) =>
      item.title.toLowerCase().startsWith(searchTerm.toLowerCase()),
    ),
  )

  const groupInsurancesByType = (insurances) =>
    insurances.reduce((acc, cur) => {
      const found = acc.find((ins) => ins.value === cur.value)
      if (found) {
        found.providers.push(cur.provider)
      } else {
        acc.push({ ...cur, providers: [cur.provider] })
      }
      return acc
    }, [])

  useEffect(() => {
    onChange([...exportSelectedData()])
  }, [selectedInsurances])

  const groupedInsurances = groupInsurancesByType(selectedInsurances)

  return (
    <Container>
      <ContentWrapper>
        <SearchContainer>
          <InputWithAddon
            addonBefore={() => <SearchIcon color={ThemeColor.b50} />}
            onChange={handleSearchChange}
            rounded
            value={searchTerm}
          />
          <Divider color={ThemeColor.b30} />
        </SearchContainer>
        {!searchTerm && groupedInsurances.length > 0 && (
          <SelectedInsurancesGroup>
            <Typography bold color={ThemeColor.b50} variant="badgeText">
              {t('selected')}
            </Typography>
            {groupedInsurances.map((item) => (
              <SelectedCardNode
                key={item.id}
                item={item}
                removeProvider={removeProviderFromInsurance}
              />
            ))}
          </SelectedInsurancesGroup>
        )}
        {filteredCardOptions.length ? (
          filteredCardOptions.map(({ groupName, items }) => (
            <InsuranceGroup key={groupName}>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {groupName}
              </Typography>
              {items.map((insurance) => (
                <CardNode
                  key={insurance.value}
                  onClick={() => handleInsuranceClick(insurance)}
                  subtext={insurance.subtitle}
                  text={insurance.title}
                  value={insurance.value}
                />
              ))}
            </InsuranceGroup>
          ))
        ) : (
          <EmptySearchState search={searchTerm} />
        )}
        {modalOpen && (
          <ModalBase
            closable
            enableClickOutside={false}
            isOpen={modalOpen}
            onClose={() => toggleModal(false)}
            shouldRenderInPortal
          >
            <HeaderContainer>
              <Typography bold variant="h4">
                {messages.selectProvider}
              </Typography>
            </HeaderContainer>
            <ListSelect
              onChange={(value) => {
                addProviderToInsurance(value)
              }}
              value={currentSelection?.provider || ''}
            >
              {carriers.map(({ text, value: optionValue, icon }) => (
                <Option key={optionValue} icon={icon} value={optionValue}>
                  {text}
                </Option>
              ))}
            </ListSelect>
          </ModalBase>
        )}
      </ContentWrapper>
    </Container>
  )
}

InsuranceAndProviderSelector.propTypes = {
  onChange: PropTypes.func,
}
const InsuranceAndProviderSelectorFormField = (props) => (
  <BasicFormItem
    component={InsuranceAndProviderSelector}
    name="insurances"
    {...props}
  />
)

export default InsuranceAndProviderSelectorFormField
