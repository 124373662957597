import {
  fetchTinkReports,
  finishTinkConnection,
  getTinkAccountCheckLink,
  getTinkConnections,
} from '@/api/integrations'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

const keys = {
  tinkIntegrationLink: 'tinkIntegrationLink',
  tinkReports: 'tinkReports',
  tinkConnections: 'tinkConnections',
}

export const useGetTinkConnections = () =>
  useQuery(keys.tinkConnections, getTinkConnections)

export const useTinkIntegrationLink = (config, options) =>
  useInvalidateOnSuccessMutation(
    keys.tinkIntegrationLink,
    () => getTinkAccountCheckLink(config),
    options,
  )

export const useRetrieveTinkReports = (options) =>
  useQuery(keys.tinkReports, fetchTinkReports, options)

export const useFinishTinkConnection = () =>
  useInvalidateOnSuccessMutation(
    [keys.tinkIntegrationLink, keys.tinkReports, keys.tinkConnections],
    finishTinkConnection,
  )
