import { toThemePixels } from '@/utils'
import styled, { keyframes, css } from 'styled-components'
import { ifProp, switchProp, ifNotProp } from 'styled-tools'
import { Typography } from '../../atoms'
import { Column, Row } from '../../styled'

const horizontalStyles = css`
  justify-content: space-between;
  align-items: flex-start;

  > * {
    width: fit-content;
  }
`

export const Container = styled.div`
  position: relative;
  gap: ${toThemePixels(2)};
  width: 100%;
  display: flex;
  margin-bottom: ${ifProp('noMargin', 0, toThemePixels(5))};
  flex-direction: ${switchProp('layout', {
    horizontal: 'row',
    vertical: 'column',
  })};
  ${ifProp({ layout: 'horizontal' }, horizontalStyles)};
`

const showError = keyframes`
  from {
    transform: translateY(-8px);
    opacity: 0;
  }
`

const animateHelperMessage = css`
  animation: ${showError} 0.4s;
`

export const HelperMessage = styled(Typography).attrs({
  variant: 'inputDescription',
})`
  position: ${ifProp('asChild', 'relative', 'absolute')};
  top: calc(100% + 4px);
  display: inline-block;
  ${ifNotProp('noAnimate', animateHelperMessage)};
`

export const LabelContainer = styled(Row)`
  align-items: center;
  flex-direction: ${ifProp({ layout: 'horizontal' }, 'row-reverse')};
  gap: ${toThemePixels(2)};

  svg {
    margin-bottom: -${toThemePixels(1)};
  }
`

export const FormRow = styled(Row)`
  gap: ${toThemePixels(5)};

  input {
    min-width: unset;
    width: 100%;
  }
`

export const DropdownModalTrigger = styled.div`
  cursor: pointer;
`

export const StyledDropdownModal = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  padding: 16px;
  opacity: ${ifProp('isOpen', 1, 0)};
  pointer-events: ${ifProp('isOpen', 'auto', 'none')};
  transition: opacity 0.3s;
`

export const ModalCloseButton = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  padding: 6px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: ${toThemePixels(8)};
    height: ${toThemePixels(8)};
    background-color: rgba(234, 236, 237, 0.5);
    border-radius: 100%;
    cursor: pointer;
    top: -1px;
    right: -1px;
    z-index: -1;
  }
`

export const StyledDropdownModalContent = styled(Column)`
  padding-top: 48px;
  width: 100%;
`
