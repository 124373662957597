import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '../../atoms'
import {
  ButtonsContainer,
  CarrierContainer,
  SectionWrapper,
  SelectedCardBottom,
  SelectedCardTop,
  SelectedStyledCard,
  StyledChecked,
  TitleContainer,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    remove: t('remove'),
  }
}

export const SelectedCardNode = ({
  text,
  subtext,
  listItem,
  resetText,
  onDelete,
  onResetClick,
  value,
}) => {
  const messages = useMessages()

  return (
    <SelectedStyledCard>
      <SelectedCardTop>
        <TitleContainer>
          <Typography variant="captionB">{text}</Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {subtext}
          </Typography>
        </TitleContainer>
        <StyledChecked />
      </SelectedCardTop>
      <SelectedCardBottom>
        <SectionWrapper>
          <CarrierContainer>
            {listItem.icon && <img alt="option icon" src={listItem.icon} />}
            <Typography color={ThemeColor.b50} variant="captionR">
              {listItem.text}
            </Typography>
          </CarrierContainer>
          <ButtonsContainer>
            <div />
            <Button
              onClick={() => onResetClick({ value, text })}
              size="medium"
              variant="outlined"
            >
              {resetText}
            </Button>
            <Button
              color="danger"
              onClick={() => onDelete(value)}
              size="medium"
              variant="outlined"
            >
              {messages.remove}
            </Button>
          </ButtonsContainer>
        </SectionWrapper>
      </SelectedCardBottom>
    </SelectedStyledCard>
  )
}

SelectedCardNode.propTypes = {
  listItem: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onDelete: PropTypes.func,
  onResetClick: PropTypes.func,
  resetText: PropTypes.string,
  subtext: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
