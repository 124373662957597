import { paths } from '@/routes/paths'

// These all send isolated analytics events on their own
// so we exclude them from the page view event to avoid duplicates
export const IGNORED_ANALYTICS_PATHS = [
  paths.cyberLead,
  paths.financialLossLead,
  paths.professionalLiabilityLead,
  paths.buildingLead,
  paths.liabilityLead,
  paths.liabilityLeadB,
  paths.contentLead,
  paths.legalLead,
  paths.gastronomyLead,
  paths.liabilitySSF,
  paths.contentSSF,
  paths.legalSSF,
  '/checkout/',
  '/ssf/offer/',
]
