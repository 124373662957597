import { Column } from '../../styled'
import { ThemeColor } from '@/enums'
import { themeColor, themeTransition } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const DropzoneContainer = styled(Column)`
  width: 100%;
  height: 200px;
  margin: 0 auto;
  gap: 24px;
`

export const DropArea = styled(Column)`
  border: 1px ${ifProp('isDragActive', 'dashed', 'solid')}
    ${ifProp(
      'isDragActive',
      themeColor(ThemeColor.b50),
      themeColor(ThemeColor.b30),
    )};
    gap: 12px;
  border-radius: 0.5rem;
  padding: 2rem;
  height: 200px;
  text-align: center;
  background: ${ifProp(
    'isDragActive',
    themeColor(ThemeColor.b0),
    themeColor(ThemeColor.b10),
  )};
  cursor: pointer;
  transition: ${themeTransition()}
  width: 100%;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${ifProp(
      'isDragActive',
      themeColor(ThemeColor.b0),
      themeColor(ThemeColor.b10),
    )};
  }

  >svg {
    flex-shrink: 0;
  }
`

export const DeleteButton = styled.button`
  cursor: pointer;
`
