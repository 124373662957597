import { ThemeColor } from '@/enums'
import { DownLine } from '@/icons'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'
import { Input, Row } from '../../styled'

export const DropdownIcon = styled(DownLine).attrs({ size: 'menu' })`
  position: absolute;
  width: 14px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%);
  right: ${toThemePixels(2)};

  transition: ${themeTransition()};
`

export const Container = styled.div`
  width: 100%;
  position: relative;

  ${DropdownIcon} {
    transform: ${ifProp('isOpen', 'translateY(-50%) rotate(180deg)')};

    path {
      stroke: ${ifProp('isOpen', themeColor(ThemeColor.b100))};
    }
  }
`

export const InputContainer = styled.div`
  width: inherit;
  position: relative;
  cursor: pointer;

  ${Input} {
    padding-right: ${toThemePixels(6)};
  }
`

const showOptions = keyframes`
  from {
    opacity: 0;
    max-height: 0px;
  }
`

export const OptionsContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: calc(100% + ${toThemePixels(2)});
  max-height: 216px;
  padding: ${toThemePixels(2)} 0;
  z-index: 10000;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  background-color: ${themeColor(ThemeColor.b10)};
  border-radius: ${toThemePixels(3)};
  overflow: hidden;
  overflow-y: auto;
  animation: ${showOptions} 0.4s;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);
`

export const StyledOption = styled(Row)`
  gap: ${toThemePixels(4)};
  padding: 0 ${toThemePixels(4)};
  height: 52px;
  transition: ${themeTransition()};
  align-items: center;

  cursor: pointer;

  color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b100),
    themeColor(ThemeColor.b50),
  )};
  &:not(:last-child) {
    border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  }

  &:hover {
    color: ${ifNotProp('disabled', themeColor(ThemeColor.b100))};
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: contained;
  }
`
