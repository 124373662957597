import {
  Divider,
  FormCardSelect,
  InputWithAddon,
  Option,
  Row,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { SearchIcon } from '@/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { InsuranceCarrierFormValues } from '../helpers/types'
import { insuranceCarrierValidationSchema } from '../helpers/validationSchema'

interface OutletContextProps {
  carriers: {
    value: string
    text: string
    icon: string
  }[]
}

const InsuranceCarrierForm = () => {
  const { t } = useTranslation()
  const { carriers } = useOutletContext<OutletContextProps>()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredCarriers = useMemo(() => {
    return carriers.filter((carrier) =>
      carrier.text.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  }, [carriers, searchTerm])

  return (
    <>
      <InputWithAddon
        addonBefore={() => <SearchIcon color={ThemeColor.b50} />}
        onChange={handleSearchChange}
        placeholder={t(
          'addInsuranceContractsSteps.insuranceCarrier.searchPlaceholder',
        )}
        rounded
        value={searchTerm}
      />
      <Divider color={ThemeColor.b30} />
      <FormCardSelect noMargin name="carrierId" showErrorHelperText={false}>
        {filteredCarriers.map((carrier) => (
          <Option key={carrier.value} value={carrier.value}>
            <Row alignItems="center" gap="8px">
              <img
                src={carrier.icon}
                alt={carrier.text}
                width="32px"
                height="32px"
              />
              <Typography variant="p2Body" color={ThemeColor.glass500}>
                {carrier.text}
              </Typography>
            </Row>
          </Option>
        ))}
      </FormCardSelect>
    </>
  )
}

export const InsuranceCarrierStep = () => {
  const { t } = useTranslation()
  const { onNext, onBack } = useStepper()

  const handleNext = async (values: InsuranceCarrierFormValues) => {
    onNext(values)
  }

  return (
    <StepLayout
      title={t('addInsuranceContractsSteps.insuranceCarrier.title')}
      subtitle={t('addInsuranceContractsSteps.insuranceCarrier.subtitle')}
      validationSchema={insuranceCarrierValidationSchema}
      onNext={handleNext}
      onBack={onBack}
    >
      <InsuranceCarrierForm />
    </StepLayout>
  )
}
