import { StepperWithSuccessPage } from '@/components'
import { AssessmentType, ThemeColor } from '@/enums'
import { useContactModal } from '@/providers'
import { theme } from '@/theme'
import {
  dynamicObjectPropType,
  propTypeFromEnumValues,
  useLanguage,
} from '@/utils'
import { Button } from '@surein/ui'
import { Phone } from 'lucide-react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import {
  AssessmentValuesProvider,
  makeSteps,
  makeStepsWithBreadcrumbs,
} from './helpers'
import { AssessmentSteps } from './steps'
import { StepWrapper } from './steps/StepWrapper'
import { Container } from './styles'

const prepareSteps = makeSteps(AssessmentSteps)

export const AssessmentStepper = ({
  initialValues,
  type,
  initialStep,
  onLogoClick,
  onFinish,
  stepKeys,
  stepProps,
  hideLastStepButton,
  skipSuccessPage,
  stepLocalStorageKey,
  valuesLocalStorageKey,
  hideContact = {},
  messages,
  stepperBreadcrumbTitles,
}) => {
  const { openContact } = useContactModal({
    hide: hideContact,
  })

  const steps = stepperBreadcrumbTitles
    ? makeStepsWithBreadcrumbs(AssessmentSteps, stepKeys)
    : prepareSteps(stepKeys)
  const language = useLanguage()

  const MemoizedStepWrapper = useCallback(
    (props) => (
      <StepWrapper
        {...props}
        messages={messages}
        stepLocalStorageKey={stepLocalStorageKey}
        valuesLocalStorageKey={valuesLocalStorageKey}
      />
    ),
    [language, stepLocalStorageKey, valuesLocalStorageKey],
  )

  return (
    <Container>
      <AssessmentValuesProvider initialValue={initialValues}>
        <StepperWithSuccessPage
          hideLastStepButton={hideLastStepButton}
          initialStep={initialStep}
          onFinish={onFinish}
          onLogoClick={onLogoClick}
          shouldUpdateUrl={type === AssessmentType.firstTimeUser}
          skipSuccessPage={skipSuccessPage}
          stepperBreadcrumbTitles={stepperBreadcrumbTitles}
          stepProps={stepProps}
          steps={steps}
          stepWrapper={MemoizedStepWrapper}
          subtitle={messages.assessmentSuccessSubtitle}
          successButtonText={messages.assessmentSuccessButton}
          title={messages.assessmentSuccessTitle}
          topRight={() => (
            <Button
              icon={() => (
                <Phone color={theme.color[ThemeColor.success1]} size={16} />
              )}
              onClick={openContact}
              size="sm"
              variant="muted"
            />
          )}
        />
      </AssessmentValuesProvider>
    </Container>
  )
}

AssessmentStepper.propTypes = {
  hideContact: PropTypes.shape({
    chat: PropTypes.bool,
    header: PropTypes.bool,
  }),
  hideLastStepButton: PropTypes.bool,
  initialStep: PropTypes.string,
  initialValues: dynamicObjectPropType,
  messages: PropTypes.shape({
    assessmentSuccessButton: PropTypes.string,
    assessmentSuccessSubtitle: PropTypes.string,
    assessmentSuccessTitle: PropTypes.string,
    subtitle: PropTypes.string,
    titles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  onFinish: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func,
  skipSuccessPage: PropTypes.bool,
  stepKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepLocalStorageKey: PropTypes.string,
  stepperBreadcrumbTitles: dynamicObjectPropType,
  stepProps: dynamicObjectPropType,
  type: propTypeFromEnumValues(AssessmentType),
  valuesLocalStorageKey: PropTypes.string,
}
