import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import { buttonSizePropType, buttonVariantPropType } from '../ButtonBase'
import { Container } from './styles'

export const IconButton = ({ children, ...rest }) => {
  const clonedChildren = cloneElement(children, {
    size: 'button',
  })

  return <Container {...rest}>{clonedChildren}</Container>
}

IconButton.propTypes = {
  children: renderableType,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: buttonSizePropType,
  variant: buttonVariantPropType,
}
