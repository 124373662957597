import { PrimaryButton } from '@/components/atoms'
import { Breakpoint } from '@/enums'
import { RightArrow } from '@/icons'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column } from '../../styled'

export const EmojiContainer = styled.div`
  font-size: ${toThemePixels(20)};
  margin-bottom: ${toThemePixels(6)};
`

export const ContentWrapper = styled(Column)`
  margin: auto;
  width: 100%;
  max-width: 500px;
  gap: ${toThemePixels(10)};
  align-items: center;
  justify-content: center;

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 90%;
  }
`

export const Content = styled(Column)`
  gap: ${toThemePixels(4)};
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  word-break: break-word;
`

export const StyledButton = styled(PrimaryButton)`
  margin-top: ${toThemePixels(8)};
`

export const StyledRightArrow = styled(RightArrow)`
  height: ${toThemePixels(4)};
  width: ${toThemePixels(4)};
  fill: none;
`
