import { SuccessOrderStepBase } from '@/pages/CheckoutPage/steps/SuccessOrderStep'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    subtitle: t('checkout.subtitles.publicSuccess'),
  }
}

export const PublicCheckoutSuccessStep = () => {
  const messages = useMessages()

  return <SuccessOrderStepBase subtitle={messages.subtitle} />
}
