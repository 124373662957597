import { Loader } from '@/components'
import { Categories } from '@/enums'
import { CheckoutBase } from '@/modules/checkout/components/CheckoutBase'
import { usePublicCart } from '@/services'
import {
  getLocalStorageItem,
  pushEventToDataLayer,
  renderableType,
  setLocalStorageItem,
  useGoToPath,
  useModal,
  useStateObject,
  useSteps,
} from '@/utils'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useForwardFunnelProduct } from '../SelfAssessmentPage/helpers'
import { SuccessPage } from '../SelfAssessmentPage/public/SuccessPage'
import { ThankYouPage } from '../SelfAssessmentPage/public/ThankYouPage'
import { getLocationIdFromToken, makeCheckoutSteps } from './helpers'
import { PublicCheckoutStepKeys } from './steps'
import { SignPoaTooltip } from './steps/PublicSignPoaStep'

const tooltips = {
  [PublicCheckoutStepKeys.poa]: <SignPoaTooltip />,
}

const useMessages = () => {
  const { categoryId } = useParams()
  const { t } = useTranslation()

  return {
    yourCart: t('yourMission'),
    categoryName: t(`categories.${Categories[categoryId].key}.name`),
    next: t('next'),
    contactTitle: t('need_help'),
    contactSubtitle: t('contact_description'),
    failedAnswersTitle: t('failed_answers_title'),
    failedAnswersSubtitle: t('failed_answers_subtitle'),
    titles: {
      payment: t('checkout.titles.payment'),
      killerQuestions: t('checkout.titles.killerQuestions'),
      poa: t('checkout.titles.poa'),
      paymentAndPoa: t('checkout.titles.paymentAndPoa'),
      companyDetails: t('checkout.titles.companyDetails'),
      startDate: t('checkout.titles.startDate'),
      overview: t('checkout.titles.overview'),
      success: t('checkout.titles.success'),
    },
    breadcrumbs: {
      startDate: t('checkout.breadcrumbs.startDate'),
      payment: t('checkout.breadcrumbs.payment'),
      paymentAndPoa: t('checkout.breadcrumbs.paymentAndPoa'),
      killerQuestions: t('checkout.breadcrumbs.killerQuestions'),
      poa: t('checkout.breadcrumbs.poa'),
      companyDetails: t('checkout.breadcrumbs.companyDetails'),
      overview: t('checkout.breadcrumbs.overview'),
      success: t('checkout.breadcrumbs.success'),
    },
    subtitles: {
      startDate: t('checkout.subtitles.startDate'),
      poa: t('checkout.subtitles.poa'),
      paymentAndPoa: t('checkout.subtitles.paymentAndPoa'),
      overview: t('checkout.subtitles.overview'),
      success: t('checkout.subtitles.success'),
      companyDetails: t('checkout.subtitles.companyDetails'),
      payment: t('checkout.subtitles.payment'),
    },
    from: t('from'),
  }
}

const PublicCheckoutPageContent = ({ steps, data = [] }) => {
  const { categoryId } = useParams()
  const { state } = useLocation()
  const { backPathname } = state || {}
  const messages = useMessages()
  const initialStepLocalStorageKey = `${categoryId}-checkout-current-step`
  const initialStep = getLocalStorageItem(initialStepLocalStorageKey)
  const locationId = useMemo(getLocationIdFromToken, [])

  const goBack = useGoToPath(backPathname)

  const stepState = useSteps({
    initialStep,
    steps,
    keyPropName: 'key',
  })
  const contactState = useModal()
  const killerQuestionsState = useModal()
  const successState = useModal()

  const [buttonState, setButtonState] = useStateObject({
    onClick: stepState.next,
    text: messages.next,
  })

  const { Component, key } = useMemo(() => stepState.data, [
    stepState.data?.key,
  ])

  const handleBack = () => {
    if (stepState.isFirst) {
      goBack()
    } else {
      stepState.back()
    }
  }

  const { funnel_product: funnelProduct } = useForwardFunnelProduct()

  const handleNext = useCallback(() => {
    setLocalStorageItem(initialStepLocalStorageKey, key)

    pushEventToDataLayer('Step Change', {
      funnel_product: funnelProduct,
      funnel_step: key,
    })

    stepState.next()
  }, [key])

  const handleGoToPayment = () => {
    stepState.goTo(PublicCheckoutStepKeys.payment)
  }

  useEffect(() => {
    if (!data.length) {
      contactState.open()
    }
  }, [data])

  if (contactState.isOpen) return <SuccessPage />

  if (successState.isOpen) return <ThankYouPage />

  return (
    <CheckoutBase
      buttonState={buttonState}
      data={data}
      isPublic
      killerQuestionsState={killerQuestionsState}
      steps={steps}
      stepState={stepState}
    >
      <Component
        closeKillerQuestions={killerQuestionsState.close}
        funnelProduct={funnelProduct}
        goToPayment={handleGoToPayment}
        locationId={locationId}
        onBack={handleBack}
        onNext={handleNext}
        openContact={contactState.open}
        openKillerQuestions={killerQuestionsState.open}
        openSuccess={successState.open}
        setButtonState={setButtonState}
      />
    </CheckoutBase>
  )
}

PublicCheckoutPageContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      carrierName: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      Component: renderableType.isRequired,
      key: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
}

export const PublicCheckoutPage = () => {
  const messages = useMessages()
  const { categoryId } = useParams()
  const { data, isLoading } = usePublicCart()

  if (isLoading) return <Loader />

  return (
    <PublicCheckoutPageContent
      data={data}
      steps={makeCheckoutSteps(categoryId, messages, tooltips)}
    />
  )
}
