import { UploadLine } from '@/icons'
import { dynamicArrayPropType, isUndefined } from '@/utils'
import PropTypes from 'prop-types'
import { FilePicker } from '../../atoms'
import { Container, UploadedFile } from './styles'

export const Upload = ({
  files = [],
  onChange,
  multiple,
  accept,
  maxMbSize,
  max,
  disabled,
  onFileClick,
}) => (
  <Container>
    <FilePicker
      accept={accept}
      disabled={disabled}
      max={isUndefined(max) ? undefined : max - files.length}
      maxMbSize={maxMbSize}
      multiple={multiple}
      onPick={onChange}
    >
      <UploadLine />
    </FilePicker>
    {files.map(({ name, fileName }) => (
      <UploadedFile key={name} onClick={() => onFileClick(name)}>
        {fileName}
      </UploadedFile>
    ))}
  </Container>
)

Upload.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  files: dynamicArrayPropType,
  max: PropTypes.number,
  maxMbSize: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onFileClick: PropTypes.func,
}
