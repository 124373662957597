import { Typography } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { Container, Detail } from './styles'

export const DetailsCard = ({ items, alignment = 'horizontal' }) => (
  <Container alignment={alignment}>
    {items.map(({ label, value }) => (
      <Detail alignment={alignment}>
        <Typography color={ThemeColor.b50} variant="p2Body">
          {label}
        </Typography>
        <Typography variant="captionR">{value}</Typography>
      </Detail>
    ))}
  </Container>
)

DetailsCard.propTypes = {
  alignment: PropTypes.oneOf(['vertical', 'horizontal']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
}
