import { AssessmentStepKeys } from '@/enums'
import { makeBusinessTypeSubmitValue } from '../../helpers'
import { ConditionalOfferAssessment } from './BasePage'

const contentAssessmentSteps = [
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.companyFoundedDateLead,
  AssessmentStepKeys.approxRevenue,
  AssessmentStepKeys.inventory,
  AssessmentStepKeys.companyDetails,
]

const makeSubmitValues = () => ({
  approxRevenue,
  inventory,
  businessType,
  companyFoundedDateLead,
  ...steps
}) => ({
  ...steps,
  lastYearFinances: approxRevenue,
  locationRent: inventory,
  businessType: makeBusinessTypeSubmitValue(businessType),
  companyFoundedDate: companyFoundedDateLead,
})

const makeStepProps = (messages) => ({
  [AssessmentStepKeys.approxRevenue]: {
    helperText: messages.helperText,
  },
})

export const ContentConditionalOffer = () => (
  <ConditionalOfferAssessment
    categoryId="CO"
    makeStepProps={makeStepProps}
    stepKeys={contentAssessmentSteps}
    transformSubmitValues={makeSubmitValues()}
  />
)
