import { FormDateSelect } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { currentYear, getTomorrow, isValidDate } from '@/utils'
import { isNil } from 'ramda'
import { useSaveAssessmentStep } from '../helpers'

export const StartDateStep = () => {
  const { value } = useFormItem('startDate')

  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.startDate,
  )

  useOnNextClick(save)

  useNextDisabled(() => !isValidDate(new Date(value)) || isNil(value), [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormDateSelect
      initialValue={Date.parse(getTomorrow())}
      maxDate={
        new Date(
          currentYear(),
          new Date().getMonth() + 3,
          new Date().getDate(),
          12,
        )
      }
      minDate={new Date()}
      name="startDate"
      shouldDisable={false}
    />
  )
}
