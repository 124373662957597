import { Image, Typography } from '@/components/atoms'
import { config } from '@/config'
import { ThemeColor } from '@/enums'
import {
  isHubspotChatLoaded,
  openHubspotChat,
  openInNewTab,
  themeColor,
} from '@/utils'
import { Badge, Card } from '@surein/ui'
import dayjs from 'dayjs'
import {
  Calendar,
  ChevronRight,
  Mail,
  MessageSquare,
  Phone,
} from 'lucide-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../styled'
import { ModalBase } from '../ModalBase'

const StyledGreenDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${themeColor(ThemeColor.success1)};
`

const StyledCard = styled(Card)`
  padding: 16px;
  border-radius: 16px;
  background-color: ${themeColor(ThemeColor.b10)};
  cursor: ${ifProp('onClick', 'pointer', 'default')};

  svg {
    width: 16px;
    height: 16px;
    color: ${themeColor(ThemeColor.electric500)};
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 50%;
  background-color: ${themeColor(ThemeColor.info3)};
`

const ContactModalEntry = ({ hidden, icon, text, subtitle, onClick }) => {
  if (hidden) return null

  return (
    <StyledCard onClick={onClick} variant="borderless">
      <Row justifyContent="space-between">
        <Row alignItems="center" gap="16px">
          <IconWrapper>{icon}</IconWrapper>
          <Column gap="4px">
            <Typography variant="p2Body">{text}</Typography>
            {typeof subtitle === 'string' ? (
              <Typography color={ThemeColor.b50} variant="badgeText">
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </Column>
        </Row>
        {onClick && <ChevronRight />}
      </Row>
    </StyledCard>
  )
}

ContactModalEntry.propTypes = {
  hidden: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.string.isRequired,
}

export const ContactModal = ({ isOpen, onClose, hasHeadquarters }) => {
  const { t } = useTranslation()
  const shouldShowChat = isHubspotChatLoaded()

  const configPhone = hasHeadquarters
    ? config.midMarketPhone
    : config.contactPhone
  const configCalendarUrl = hasHeadquarters
    ? config.midMarketCalendarUrl
    : config.appointmentUrl

  const handleOpenChat = () => {
    openHubspotChat()
    onClose()
  }

  const handleArrangeMeeting = () => {
    openInNewTab(configCalendarUrl)
    onClose()
  }

  const isWorkingHours = dayjs().hour() >= 9 && dayjs().hour() < 20

  const entries = [
    {
      icon: <MessageSquare />,
      text: t('contactModal.liveChat'),
      hidden: !shouldShowChat,
      subtitle: (
        <Badge isRounded variant="info">
          <Typography bold color={ThemeColor.electric500} variant="badgeText">
            {isWorkingHours
              ? t('contactModal.averageResponseTime')
              : t('contactModal.workingHoursHint')}
          </Typography>
        </Badge>
      ),
      onClick: handleOpenChat,
    },
    {
      icon: <Calendar />,
      text: t('contactModal.bookAnAppointment'),
      subtitle: t('contactModal.mondayToFriday'),
      onClick: handleArrangeMeeting,
    },
    {
      icon: <Phone />,
      text: t('contactModal.phone'),
      subtitle: configPhone,
    },
    {
      icon: <Mail />,
      text: t('contactModal.email'),
      subtitle: config.contactEmail,
    },
  ]

  return (
    <ModalBase closable isOpen={isOpen} onClose={onClose} padding="16px">
      <Column gap="20px">
        <Row alignItems="center" gap="12px">
          <Image src="/assets/chatblock.png" />
          <Column>
            <Typography bold variant="p1Body">
              Daniel
            </Typography>
            {isWorkingHours && (
              <Row alignItems="center" gap="8px">
                <StyledGreenDot />
                <Typography variant="p2Body">
                  {t('contactModal.online')}
                </Typography>
              </Row>
            )}
          </Column>
        </Row>
        <Column gap="4px">
          <Typography bold variant="h4">
            {t('contactModal.title')}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {t('contactModal.subtitle')}
          </Typography>
        </Column>
        {entries.map((entry) => (
          <ContactModalEntry key={entry.text} {...entry} />
        ))}
      </Column>
    </ModalBase>
  )
}

ContactModal.propTypes = {
  hasHeadquarters: PropTypes.bool,
  hide: PropTypes.shape({
    chat: PropTypes.bool,
    header: PropTypes.bool,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
