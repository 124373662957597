import { Column } from '@/components'
import { toThemePixels } from '@/utils'
import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
  margin-bottom: ${toThemePixels(5)};
`

export const PageHeader = styled(Column)`
  gap: ${toThemePixels(8)};
  margin-bottom: ${toThemePixels(8)};
`
