import { FormCompanyName, FormLocation } from '@/components'
import { AssessmentStepKeys, BusinessOrgs } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { splitAtLastIndex } from '@/utils'
import { includes } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useAssessmentValues, useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    businessName: t('businessName'),
    addressLabel: t('companyDetailsAddressLabel'),
    businessNameInfo: t('businessNameInfo'),
  }
}

export const CompanyDetailsStep = () => {
  const messages = useMessages()
  const {
    values: { userProfile },
  } = useAssessmentValues()
  const modifier = (name) => {
    if (includes(BusinessOrgs.eu, name)) {
      const [init] = splitAtLastIndex(' ', name)
      return `${init} Inh. ${[userProfile.name, userProfile.surname].join(' ')}`
    }
    return name
  }
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.companyDetails,
    (value) => ({
      ...value,
      name: modifier(value.name),
    }),
  )

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <>
      <FormCompanyName label={messages.businessName} name="name" />
      <FormLocation label={messages.addressLabel} name="address" />
    </>
  )
}
