import { api } from './api'

export const getTinkConnections = () => api.get('/user/psd2/connections')

export const getTinkAccountCheckLink = (config) =>
  api.post('/user/psd2/connections', config)

export const finishTinkConnection = () =>
  api.post('/user/psd2/connections/finished')

export const fetchTinkReports = () => api.get('/user/psd2/reports')
