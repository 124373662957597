import { NoopComponent } from '@/utils'

export const ShowWhen = ({
  when,
  children,
  fallback: Fallback = NoopComponent,
  ...props
}: {
  when: boolean
  children: React.ReactNode
  fallback?: any // TODO: fix type
}) => {
  if (when) return children

  return <Fallback {...props} />
}
