import styled from 'styled-components'
import { withSvgProps } from './helpers'
import { ReactComponent as SvgComponent } from './svg/pulse.svg'

const NoFillSvg = styled(SvgComponent)`
  fill: none;
`
const Svg = (props) => <NoFillSvg {...props} />

export const PulseIcon = withSvgProps(Svg)
