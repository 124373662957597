import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Step = styled(Row)`
  height: ${toThemePixels(15)};
  justify-content: space-between;
  align-items: center;
  gap: ${toThemePixels(2)};
  &:not(:last-child) {
    border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  }

  svg {
    flex-shrink: 0;
  }
`

export const IconWrapper = styled.div`
  min-height: ${toThemePixels(6)};
  min-width: ${toThemePixels(6)};
`
