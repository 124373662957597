import { Breakpoint } from '@/enums'
import { useFormContext } from '@/providers'
import { useLanguage, useIsMobile } from '@/utils'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import PropTypes from 'prop-types'
import { keys, omit } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from '../../atoms'
import { Column, Row } from '../../styled'
import { LocationAutocomplete } from '../LocationAutocomplete'
import { BasicFormItem } from './BasicFormItem'
import { FormInput } from './FormInput'
import { FormSelect } from './FormSelect'
import { makeCountries } from './helpers'
import { FormRow } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    streetAndNumber: t('streetAndNumber'),
    postCode: t('postCode'),
    city: t('city'),
    country: t('country'),
    address: t('address'),
    street: t('street'),
    streetNr: t('streetNr'),
  }
}

export const FormLocation = ({ name, country = 'de', disabled }) => {
  const { value, setFieldValue } = useFormContext()
  const val = value(name) || {}
  const messages = useMessages()
  const language = useLanguage()
  const countries = makeCountries(language)
  const { isMobile } = useIsMobile(Breakpoint.tab)

  useEffect(() => {
    if (!val.country)
      setFieldValue(name, {
        ...val,
        country: 'Deutschland',
      })
  }, [])

  useEffect(() => {
    if (val.street) {
      setFieldValue(name, {
        ...val,
        googleLocation: `${val.street} ${val.streetNr}, ${val.postalCode} ${val.city}, ${val.country}`,
      })
    }
  }, [JSON.stringify(omit(['googleLocation'], val))])

  return (
    <Column>
      <FormRow>
        <Row flex={5}>
          <FormInput
            disabled={disabled}
            label={messages.street}
            name={`${name}.street`}
          />
        </Row>
        <Row flex={1}>
          <FormInput
            disabled={disabled}
            hideArrows
            label={messages.streetNr}
            name={`${name}.streetNr`}
          />
        </Row>
      </FormRow>
      {isMobile ? (
        <Column>
          <FormInput
            disabled={disabled}
            hideArrows
            label={messages.postCode}
            name={`${name}.postalCode`}
            type="number"
          />
          <FormInput
            disabled={disabled}
            label={messages.city}
            name={`${name}.city`}
          />
        </Column>
      ) : (
        <FormRow>
          <FormInput
            disabled={disabled}
            hideArrows
            label={messages.postCode}
            name={`${name}.postalCode`}
            type="number"
          />
          <FormInput
            disabled={disabled}
            label={messages.city}
            name={`${name}.city`}
          />
        </FormRow>
      )}
      {!country && (
        <FormSelect
          disabled={disabled}
          label={messages.country}
          name={`${name}.country`}
        >
          {keys(countries).map((item) => (
            <Option key={item} value={countries[item]}>
              {`${countries[item]} ${getUnicodeFlagIcon(item)}`}
            </Option>
          ))}
        </FormSelect>
      )}
    </Column>
  )
}

FormLocation.propTypes = {
  country: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

export const FormLocatioon = ({
  name,
  country = 'de',
  label,
  disabled,
  ...props
}) => {
  const messages = useMessages()
  const language = useLanguage()
  const countries = makeCountries(language)
  const { isMobile } = useIsMobile(Breakpoint.tab)

  return (
    <Column>
      <BasicFormItem
        component={LocationAutocomplete}
        country={country}
        disabled={disabled}
        ignoreErrorKeys={['postalCode', 'city', 'country']}
        label={`${label || messages.address} (${messages.streetAndNumber})`}
        name={name}
        {...props}
      />
      {isMobile ? (
        <Column>
          <FormInput
            disabled={disabled}
            hideArrows
            label={messages.postCode}
            name={`${name}.postalCode`}
            type="number"
          />
          <FormInput
            disabled={disabled}
            label={messages.city}
            name={`${name}.city`}
          />
        </Column>
      ) : (
        <Row gap="20px">
          <FormInput
            disabled={disabled}
            hideArrows
            label={messages.postCode}
            name={`${name}.postalCode`}
            type="number"
          />
          <FormInput
            disabled={disabled}
            label={messages.city}
            name={`${name}.city`}
          />
        </Row>
      )}
      {!country && (
        <FormSelect
          disabled={disabled}
          label={messages.country}
          name={`${name}.country`}
        >
          {keys(countries).map((item) => (
            <Option key={item} value={countries[item]}>
              {`${countries[item]} ${getUnicodeFlagIcon(item)}`}
            </Option>
          ))}
        </FormSelect>
      )}
    </Column>
  )
}

FormLocatioon.propTypes = {
  ...FormLocation.propTypes,
  label: PropTypes.string,
}
