export const useScrollTop = () => {
  const scrollTop = (height) => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (_error) {
      window.scrollTo(height || 0, 0)
    }
  }

  return { scrollTop }
}
