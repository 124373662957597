import { PageMainContent } from '@/components'
import { config } from '@/config'
import { openInNewTab, useIsMobile } from '@/utils'
import { Button } from '@surein/ui'
import { PlusIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ReportClaimButton = styled(Button)`
  margin-bottom: 16px;
`

const SIDEBAR_HIDDEN_BREAKPOINT = 656

export const CustomerPortal = () => {
  const { t, i18n } = useTranslation()
  const { width } = useIsMobile()
  const isSidebarHidden = width < SIDEBAR_HIDDEN_BREAKPOINT

  return (
    <PageMainContent>
      <ReportClaimButton
        icon={PlusIcon}
        onClick={() => openInNewTab(config.reportDamageUrl[i18n.language])}
        variant="secondary"
      >
        {t('reportNewDamage')}
      </ReportClaimButton>
      <iframe
        height="80%"
        src={config.customerPortalUrl}
        title="customer portal"
        width={isSidebarHidden ? width / 1.15 : width / 1.5}
      />
    </PageMainContent>
  )
}
