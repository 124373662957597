import { __, assoc, concat, curry, join, keys, map, pipe, when } from 'ramda'

export const toPixels = pipe(String, concat(__, 'px'))

export const formatMoney = (number, currency = 'euro', options) => {
  const formats = {
    euro: {
      locale: 'de-DE',
      currency: 'EUR',
    },
    usd: {
      locale: 'en-US',
      currency: 'USD',
    },
  }

  return new Intl.NumberFormat(formats[currency].locale, {
    style: 'currency',
    currency: formats[currency].currency,
    ...options,
  })
    .format(number)
    .toString()
    .split(' ')
    .reverse()
    .join(' ')
}

export const timestampToDateString = (timestamp) => {
  if (!timestamp && timestamp !== 0) return ''
  const d = new Date(timestamp)
  const month = d.getMonth() + 1
  const day = d.getDate()
  const year = d.getFullYear()

  return pipe(
    map(when((a) => a < 10, pipe(String, concat('0')))),
    join('-'),
  )([year, month, day])
}

export const ddMMyyyy = (timestamp) => {
  if (!timestamp && timestamp !== 0) return ''
  const d = new Date(timestamp)
  const month = d.getMonth() + 1
  const day = d.getDate()
  const year = d.getFullYear()

  return pipe(
    map(when((a) => a < 10, pipe(String, concat('0')))),
    join('.'),
  )([day, month, year])
}

export const objectToArray = curry((keyPropName, object) =>
  keys(object).map((item) => assoc(keyPropName, item, object[item])),
)
