import { ImageFromCdn, Popover, ShowWhen } from '@/components'
import { ThemeColor } from '@/enums'
import { Info } from '@/icons'
import { renderableType, useLanguage } from '@/utils'
import { useIsMobile } from '@/utils/hooks/useIsMobile'
import PropTypes from 'prop-types'
import { graphicsImages, mobileGraphicsImages } from './helpers'
import { GraphicContainer, PopoverIconContainer } from './styles'

export const Graphic = ({ categoryId, popoverContent }) => {
  const language = useLanguage()
  const { isMobile } = useIsMobile()

  const imageName = isMobile
    ? mobileGraphicsImages[language][categoryId]
    : graphicsImages[language][categoryId]

  return (
    <GraphicContainer>
      <ShowWhen when={popoverContent}>
        <PopoverIconContainer>
          <Popover content={popoverContent} dark>
            <Info
              color={ThemeColor.b50}
              cursor="pointer"
              fill="none"
              size="menu"
              stroke
            />
          </Popover>
        </PopoverIconContainer>
      </ShowWhen>
      <ImageFromCdn fallbackType="jpg" name={imageName} type="webp" />
    </GraphicContainer>
  )
}

Graphic.propTypes = {
  categoryId: PropTypes.string,
  popoverContent: renderableType,
}
