import PropTypes from 'prop-types'
import { InputWithAddon } from '../InputWithAddon'
import { NumberInputBase } from '../NumberInputBase'
import { PlusMinus } from '../PlusMinus'
import { ButtonsContainer } from './styles'

export const NumberInput = ({
  value,
  onChange,
  min,
  max,
  disabled,
  ...rest
}) => (
  <InputWithAddon
    addonAfter={() =>
      !disabled && (
        <ButtonsContainer>
          <PlusMinus max={max} min={min} onChange={onChange} value={value} />
        </ButtonsContainer>
      )
    }
    disabled={disabled}
    inputComponent={NumberInputBase}
    onChange={onChange}
    value={value}
    {...rest}
  />
)

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
}
