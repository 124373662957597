import PropTypes from 'prop-types'
import {
  curry,
  map,
  pipe,
  filter,
  complement,
  path,
  allPass,
  identity,
  ifElse,
} from 'ramda'
import React, { createContext, isValidElement, useContext } from 'react'
import { createPortal } from 'react-dom'
import { isFragment } from 'react-is'
import { returns } from './function'
import { useReducerWithMiddleware, useStateObject } from './hooks'
import { isFunction, isUndefined } from './type'

export const safeRenderPropComponent = ifElse(isFunction, identity, returns)

export const NoopComponent = () => null

export const ConditionalComponent = ({ condition, children }) => {
  if (condition) return children
  return null
}

ConditionalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node])
    .isRequired,
  condition: PropTypes.bool.isRequired,
}

export const childrenToArray = (children) => {
  let childrenArr = []

  React.Children.forEach(children, (child) => {
    if (child === undefined || child === null) return

    if (Array.isArray(child))
      childrenArr = childrenArr.concat(childrenToArray(child))
    else if (isFragment(child) && child.props)
      childrenArr = childrenArr.concat(childrenToArray(child.props.children))
    else childrenArr.push(child)
  })

  return childrenArr
}

export const parseChildrenArray = pipe(
  childrenToArray,
  filter(allPass([isValidElement, complement(path(['props', 'hidden']))])),
  map((node) => {
    const key = !isUndefined(node.key) ? String(node.key) : undefined

    return {
      key,
      ...node.props,
      node,
    }
  }),
)

export const fromEventTarget = (func, key = 'value') =>
  pipe(path(['target', key]), func)

export const mapWithKey = curry((Comp, key, list) =>
  map((item) => <Comp key={item[key]} {...item} />, list),
)

export const preventDefault = (e) => e.preventDefault()

export const makeStore = (initialState, reducer, middlewareFn = identity) => {
  const dispatchContext = createContext(() => undefined)
  const storeContext = createContext(initialState)

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducerWithMiddleware(
      reducer,
      initialState,
      middlewareFn,
    )

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    )
  }

  StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
  }

  const useDispatch = () => useContext(dispatchContext)
  const useStore = () => useContext(storeContext)

  return [StoreProvider, useDispatch, useStore]
}

export const makeContext = () => {
  const context = createContext()

  const Provider = ({ initialValue, children }) => {
    const [values, dispatchValues] = useStateObject(initialValue)

    return (
      <context.Provider value={{ values, dispatchValues }}>
        {children}
      </context.Provider>
    )
  }

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    initialValue: PropTypes.object,
  }

  return [Provider, () => useContext(context)]
}

export const scrollToElement = (id, offset, parentId) => {
  const element = document.getElementById(id)
  let parent = window
  if (parentId) parent = document.getElementById(parentId)
  parent.scrollTo({ top: element.offsetTop - offset, behavior: 'smooth' })
}

export const maybeRenderInPortal = (Component, parent, shouldRenderInPortal) =>
  shouldRenderInPortal ? createPortal(Component, parent) : Component
