import { currentYear } from '@/utils'
import dayjs from 'dayjs'
import { range } from 'ramda'

export const makeDates = (month, year) => {
  let max
  if (!month) {
    max = 31
  } else {
    max = dayjs(`${year || currentYear()}-${month}-01`).daysInMonth()
  }

  return range(1, max + 1)
}
