import { GoogleIcon, SureinTrustLogo } from '@/icons'
import { themeColorFrom, toThemePixels } from '@/utils'
import { prop } from 'ramda'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  gap: ${toThemePixels(4.5)};
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 400px) {
    gap: 0;
    justify-content: space-between;
    width: 100%;
  }
`

export const Google = styled(Row)`
  gap: ${toThemePixels(2)};
  align-items: center;
`

export const GoogleRating = styled(Row)`
  align-items: center;
`

export const Rating = styled.span`
  color: ${themeColorFrom(prop('color'))};
  font-size: ${toThemePixels(3)};
  padding-left: ${toThemePixels(1)};
`

export const StyledSSL = styled(Row)`
  width: fit-content;
  border-radius: ${toThemePixels(2)};
  pointer-events: none;
  span {
    font-size: 12px;
  }
`

export const StyledSureinTrustLogo = styled(SureinTrustLogo)`
  width: ${toThemePixels(7)};
  height: ${toThemePixels(8)};
`

export const StyledGoogleIcon = styled(GoogleIcon)`
  width: ${toThemePixels(5.5)};
  height: ${toThemePixels(5.5)};
`
