import { useSegment } from '@/modules/analytics'
import { useModal } from '@/utils'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { ContactModal } from '../components/molecules/ContactModal/Component'

const defaultConfig = {
  hasHeadquarters: false,
  hide: {},
}

export const ModalContext = React.createContext()

export const ContactModalProvider = ({ children, config = defaultConfig }) => {
  const { open: openContact, close: closeContact, isOpen } = useModal()
  const analytics = useSegment()

  const trackAndOpenContact = () => {
    analytics.track('Contact Modal Open')
    openContact()
  }

  return (
    <ModalContext.Provider
      value={{ openContact: trackAndOpenContact, closeContact }}
    >
      {children}
      {isOpen && (
        <ContactModal
          hasHeadquarters={config.hasHeadquarters}
          hide={config.hide}
          isOpen={isOpen}
          onClose={closeContact}
        />
      )}
    </ModalContext.Provider>
  )
}

ContactModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    hasHeadquarters: PropTypes.bool,
    hide: PropTypes.shape({
      chat: PropTypes.bool,
      header: PropTypes.bool,
    }),
  }),
}

export const useContactModal = () => useContext(ModalContext)
