import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Row } from '../../styled'

export const Container = styled(Row)`
  gap: ${toThemePixels(1)};
  align-items: center;
  flex-direction: ${ifProp('reverse', 'row-reverse', 'row')};

  ${themeBreakpointDown(Breakpoint.xs)} {
    gap: 4px;
  }

  span {
    margin-top: 2px;
  }

  use {
    fill: ${themeColor(ThemeColor.warning3)};
  }
`
