import { ThemeColor } from '@/enums'
import { Info } from '@/icons'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Column, Row, Typography } from '../../../components'
import { useMessages } from '../helpers'
import { InsuranceCustomBaseView } from './InsuranceCustomBaseView'

const InfoContent = ({ info }) => (
  <Column gap="30px">
    {info.map((item) => (
      <Row gap="16px">
        <Typography singleLine variant="captionR">
          <Info
            color={ThemeColor.b50}
            cursor="pointer"
            fill="none"
            size="menu"
            stroke
          />
        </Typography>

        <Row>
          <Column gap="8px">
            <Typography singleLine variant="p1Body">
              {item.title}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p2Body">
              {item.description}
            </Typography>
          </Column>
        </Row>
      </Row>
    ))}
  </Column>
)

InfoContent.propTypes = {
  info: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string, title: PropTypes.string }),
  ),
}

export const PrivatePensionInsuranceView = () => {
  const { categoryId } = useParams()

  const messages = useMessages(categoryId)
  const contentTranslations = messages.finderSectionTranslations

  return (
    <InsuranceCustomBaseView
      popoverContent={<InfoContent info={contentTranslations.infoContent} />}
    />
  )
}
