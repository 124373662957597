import { api } from './api'

export const getCompanyPayments = (id) => api.get(`/payment/getAll/${id}`)

export const createPayments = ({
  name,
  iban,
  bic,
  priority,
  companyId,
  holder,
}) => api.post('/payment', { name, iban, bic, priority, companyId, holder })

export const updatePayments = (id, { name, iban, bic, priority }) =>
  api.put(`/payment/${id}`, { name, iban, bic, priority })

export const getPaymentsById = (id) => api.get(`/payment/getOne/${id}`)

export const deletePayments = (id) => api.delete(`/payment/${id}`)
