import {
  dynamicArrayPropType,
  renderableType,
  toPixels,
  useIsMobile,
} from '@/utils'
import { useEffect, useRef } from 'react'
import { SecondaryPageWidgetContainer } from '../styled'

export const ResponsivePageWidgetContainer = ({
  children,
  dependencies = [],
}) => {
  const ref = useRef()
  const { width } = useIsMobile()

  useEffect(() => {
    if (width <= 600 && ref.current) {
      const { offsetHeight } = ref.current.children[0] || {}

      ref.current.style.minHeight = toPixels(offsetHeight)
    }
  }, [width, ref.current, ...dependencies])

  return (
    <SecondaryPageWidgetContainer ref={ref}>
      {children}
    </SecondaryPageWidgetContainer>
  )
}

ResponsivePageWidgetContainer.propTypes = {
  children: renderableType,
  dependencies: dynamicArrayPropType,
}
