import { Typography } from '@/components'
import { Accordion } from '@surein/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaqContainer } from './styles'

export const Faqs = () => {
  const { t } = useTranslation()

  return (
    <FaqContainer>
      <div>
        <Typography bold variant="h3">
          {t('questions')}?
        </Typography>
      </div>
      {/* please do not use translations as part of presentation - we should have FAQs keys in component */}
      <Accordion items={t('faqs', { returnObjects: true })} />
    </FaqContainer>
  )
}
