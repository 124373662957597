import { config } from '@/config'
import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled, { css, keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Typography } from '../../atoms'

const backgroundUrl = `${config.cdn}/sign.png`

const TouchedStyles = css`
  background: none;
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${toThemePixels(2)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  height: 200px;

  background: ${ifProp(
    'error',
    themeColor(ThemeColor.danger4),
    themeColor(ThemeColor.b10),
  )};
  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger2),
      themeColor(ThemeColor.b30),
    )};

  canvas {
    width: 100%;
    height: 200px;
    background-image: url(${backgroundUrl});
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center 30%;

    ${ifProp('isTouched', TouchedStyles)};
  }
`

export const InfoSection = styled(Typography).attrs({
  variant: 'p2Body',
  color: ThemeColor.b50,
})`
  position: absolute;
  left: ${toThemePixels(4)};
  bottom: ${toThemePixels(6)};
  z-index: 2;
`

export const HorizontalLine = styled.div`
  height: 1px;
  background-color: ${themeColor(ThemeColor.b50)};
  position: absolute;
  left: ${toThemePixels(4)};
  right: ${toThemePixels(4)};
  bottom: ${toThemePixels(4)};
  z-index: 2;
`

const showClearIcon = keyframes`
0% {
  opacity: 0
}
`
export const Clear = styled.div`
  width: ${toThemePixels(10)};
  height: ${toThemePixels(10)};
  cursor: pointer;
  background-color: ${themeColor(ThemeColor.b0)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: 50%;
  position: absolute;
  top: ${toThemePixels(2)};
  right: ${toThemePixels(2)};
  animation: ${showClearIcon} 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
`
