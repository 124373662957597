import {
  getCompanyById,
  deleteCompany,
  createCompany,
  updateCompany,
  getCompanies,
  signPoa,
  createCompanyUser,
  getUsersByCompanyId,
  removeCompanyUser,
  updateCompanyUser,
  requestEditCompany,
  getCompanyPoaStatus,
  updateCompanyWithAddress,
  getContractsOverview,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useMutation, useQuery } from 'react-query'

const keys = {
  companies: 'companies',
  company: 'company',
  users: 'companyUsers',
  poa: 'poa',
}

export const useCompanies = (options) =>
  useQuery(keys.companies, getCompanies, options)

export const useCompany = (id, options) =>
  useQuery([keys.company, id], () => getCompanyById(id), options)

export const useCreateCompany = () =>
  useInvalidateOnSuccessMutation(keys.companies, ({ company, location }) =>
    createCompany({ company, location }),
  )

export const useUpdateCompany = (id) =>
  useInvalidateOnSuccessMutation(
    [keys.companies, [keys.company, id], 'profile'],
    ({ name }) => updateCompany(id, { name }),
  )

export const useUpdateCompanyWithAddress = (id, addressId) =>
  useInvalidateOnSuccessMutation(keys, ({ name, address }) =>
    updateCompanyWithAddress({
      id,
      name,
      address: { id: addressId, ...address },
    }),
  )

export const useDeleteCompany = (id) =>
  useInvalidateOnSuccessMutation(keys.companies, () => deleteCompany(id))

export const useSignPoa = (companyId) =>
  useInvalidateOnSuccessMutation(
    [
      [keys.company, companyId],
      [keys.poa, companyId],
      'location1',
      keys.companies,
      'profile',
    ],
    ({ signature, birthdate }) => signPoa({ companyId, signature, birthdate }),
  )

export const useCompanyUsers = (id, options) =>
  useQuery([keys.users, id], () => getUsersByCompanyId(id), options)

export const useCreateCompanyUser = (id) =>
  useInvalidateOnSuccessMutation(
    [keys.company, id],
    ({ companyId, name, surname, email, phone, isMd }) =>
      createCompanyUser(id || companyId, { name, surname, email, phone, isMd }),
  )
export const useUpdateCompanyUser = () =>
  useInvalidateOnSuccessMutation(keys.company, ({ id, name, surname, phone }) =>
    updateCompanyUser(id, { name, surname, phone }),
  )

export const useRemoveCompanyUser = (companyId) =>
  useInvalidateOnSuccessMutation(keys.company, (auth0) =>
    removeCompanyUser(auth0, companyId),
  )

export const useRequestEditCompany = (id) =>
  useMutation(() => requestEditCompany(id))

export const useCompanyPoaStatus = (id, options) =>
  useQuery([keys.poa, id], () => getCompanyPoaStatus(id), options)

export const useContractsOverview = () =>
  useQuery([keys.companies, 'insurances'], getContractsOverview)
