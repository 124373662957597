import { api } from './api'

export const getCart = () => api.get('/cart')

export const addToCart = (id) => api.post(`/cart/${id}`)

export const deleteFromCart = (recommendationProductId) =>
  api.delete(`/cart/${recommendationProductId}`)

export const getCartCount = () => api.get(`/cart/getCartCount`)

export const submitCheckout = ({ paymentId, items }) =>
  api.post(`/cart/checkout`, { paymentId, items })
