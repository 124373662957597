import { Image, SecondaryButton } from '@/components/atoms'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  gap: ${ifProp('withGap', toThemePixels(4), '0')};
  min-width: 250px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    min-width: 100%;
  }
`

export const Section = styled(Row)`
  justify-content: space-between;
  padding: ${toThemePixels(4.5)} 0;
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid ${themeColor(ThemeColor.b30)};
  }
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${themeColor(ThemeColor.b100)};
  &:not(:first-child) {
    border-top: 1px solid ${themeColor(ThemeColor.b30)};
  }
  &:hover {
    color: ${themeColor(ThemeColor.b100)};
  }
`

export const StyledButton = styled(SecondaryButton)`
  border-color: ${themeColor(ThemeColor.success1)};
  color: ${themeColor(ThemeColor.success1)};
  background-color: ${themeColor(ThemeColor.success4)};

  &:active {
    color: ${themeColor(ThemeColor.success3)};
    background-color: ${themeColor(ThemeColor.success4)};
  }

  &:hover {
    border-color: ${themeColor(ThemeColor.success1)} !important;
  }
`

export const StyledImage = styled(Image)`
  height: ${toThemePixels(12.5)};
  width: ${toThemePixels(12.5)};
  border-radius: 50%;
  object-fit: cover;
`

export const Header = styled.div`
  display: flex;
  flex-direction: ${ifProp('rowHeader', 'row', 'column')};
  gap: ${ifProp('rowHeader', toThemePixels(6))};
  margin-bottom: ${ifProp('rowHeader', toThemePixels(8))};
  flex-wrap: wrap;

  div:first-child {
    flex-shrink: 0;
  }

  h5 {
    margin: 0;
  }
`
