import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div`
  a {
    color: ${themeColor(ThemeColor.b100)};

    text-decoration: none;
  }
`

export const Details = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${toThemePixels(2)};
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`
export const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px 0px rgba(46, 92, 255, 0.1),
    0px -1px 0px 0px rgba(46, 92, 255, 0.1);
  padding: ${toThemePixels(5)} ${toThemePixels(1)};
  cursor: pointer;
  svg {
    width: ${toThemePixels(1.5)};
    height: ${toThemePixels(3)};
  }
`
export const SubsectionsTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${toThemePixels(6)};
  margin-top: ${toThemePixels(12)};
`
