import { Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Row)`
  background-color: ${themeColor(ThemeColor.b10)};
  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(15)} 0;
  }
  padding: ${toThemePixels(15)};
  justify-content: center;
  word-break: break-word;

  > div {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(4)};
    box-shadow: rgb(11 44 93 / 5%) 0px 6px 12px 0px;
    ${themeBreakpointDown(Breakpoint.sm)} {
      width: calc(100% - 40px);
    }
    width: calc(100% - 120px);
    padding: ${toThemePixels(10)};
  }
`
