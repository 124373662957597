import { ThemeColor } from '@/enums'
import { noop, openHubspotChat, openInNewTab } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { config } from '../../../config'
import { Typography } from '../../atoms/Typography'
import { Column, Row } from '../../styled'
import {
  Container,
  Header,
  Section,
  StyledButton,
  StyledImage,
  StyledLink,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    call: t('call'),
    scheduleCall: t('scheduleCall'),
    liveChat: t('liveChat'),
    email: t('contactModalEmail'),
    available: t('available'),
    availableNow: t('availableNow'),
    calendar: t('calendar'),
  }
}

export const ContactInfo = ({
  title,
  subtitle,
  showAvailableBtn,
  hasHeadquarters,
  onClose = noop,
  photo,
  rowHeader,
  withGap,
  titleSize,
  subtitleSize,
  hide = {},
}) => {
  const messages = useMessages()

  const configPhone = hasHeadquarters
    ? config.midMarketPhone
    : config.contactPhone
  const configCalendarUrl = hasHeadquarters
    ? config.midMarketCalendarUrl
    : config.appointmentUrl

  return (
    <Container withGap={withGap}>
      {!hide.header && (
        <Header rowHeader={rowHeader}>
          <Row justifyContent="space-between">
            {photo && <StyledImage name={photo} type="png" />}
            {showAvailableBtn && (
              <StyledButton
                color={ThemeColor.success2}
                onClick={openHubspotChat}
                size="medium"
              >
                {messages.availableNow}
              </StyledButton>
            )}
          </Row>
          {(title || subtitle) && (
            <Column gap={rowHeader ? '4px' : '8px'}>
              <Typography variant={titleSize || 'h4'}>{title}</Typography>
              <Typography
                color={ThemeColor.b50}
                variant={subtitleSize || 'p1Body'}
              >
                {subtitle}
              </Typography>
            </Column>
          )}
        </Header>
      )}
      <Column>
        <StyledLink href={`tel: ${configPhone}`}>
          <Section>
            <Typography variant="captionB">{messages.call}</Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {configPhone}
            </Typography>
          </Section>
        </StyledLink>
        <Section
          onClick={() => {
            openInNewTab(configCalendarUrl)
            onClose()
          }}
        >
          <Typography variant="captionB">{messages.scheduleCall}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.calendar}
          </Typography>
        </Section>
        {/* Todo: This section needs to be removed */}
        {!hide.chat && (
          <Section
            onClick={() => {
              onClose()
              openHubspotChat()
            }}
          >
            <Typography variant="captionB">{messages.liveChat}</Typography>
            <Typography color={ThemeColor.success2} variant="p1Body">
              {messages.available}
            </Typography>
          </Section>
        )}
        <StyledLink href={`mailto: ${config.contactEmail}`}>
          <Section>
            <Typography variant="captionB">{messages.email}</Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {config.contactEmail}
            </Typography>
          </Section>
        </StyledLink>
      </Column>
    </Container>
  )
}

ContactInfo.propTypes = {
  hasHeadquarters: PropTypes.bool,
  hide: PropTypes.shape({
    chat: PropTypes.bool,
    header: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
  photo: PropTypes.string,
  rowHeader: PropTypes.bool,
  showAvailableBtn: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleSize: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  withGap: PropTypes.bool,
}
