import React, { useCallback } from 'react'
import { CardSelect } from '../../atoms'
import { FormItem } from './Component'

interface FormCardSelectProps {
  name: string
  label?: string
  initialValue?: any
  info?: string
  helperText?: string
  children: React.ReactNode
  ignoreErrorKeys?: string[]
  layout?: any
  noMargin?: boolean
  showErrorHelperText?: boolean
}

interface ComponentProps {
  onChange?: (event: Event) => void
}

export const FormCardSelect = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  children,
  ignoreErrorKeys,
  layout,
  noMargin,
  showErrorHelperText,
  ...props
}: FormCardSelectProps) => {
  const Component = useCallback(
    ({ onChange, ...field }: ComponentProps) => (
      <CardSelect onSelect={onChange} {...field} {...props}>
        {children}
      </CardSelect>
    ),
    [children],
  )

  return (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      layout={layout}
      name={name}
      noMargin={noMargin}
      showErrorHelperText={showErrorHelperText}
    >
      <Component />
    </FormItem>
  )
}
