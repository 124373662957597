import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${themeColor(ThemeColor.b10)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: 12px;
  padding: 24px 16px;
`

export const MainCollapseTopContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  gap: 24px;
  padding-bottom: 24px;
`
