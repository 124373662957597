import { Breakpoint, ThemeColor } from '@/enums'
import {
  themeBreakpointDown,
  themeColor,
  themeTransition,
  toThemePixels,
} from '@/utils'
import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

const largeStyles = css`
  padding: 0 ${toThemePixels(6)};
  height: 52px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    span {
      font-size: ${toThemePixels(3.5)};
    }
  }
`

const mediumStyles = css`
  height: 40px;
  padding: 0 ${toThemePixels(3)};

  span {
    font-size: ${toThemePixels(3.5)};
  }

  > svg {
    height: ${toThemePixels(3.5)};
  }
`

const smallStyles = css`
  height: 32px;
  padding: 0 ${toThemePixels(2)};

  span {
    font-size: ${toThemePixels(3.5)};
  }

  > svg {
    height: ${toThemePixels(3.5)};
  }
`

const applySizeStyles = switchProp('size', {
  large: largeStyles,
  medium: mediumStyles,
  small: smallStyles,
})

const applyContentColor = (color) => css`
  color: ${themeColor(color)};
  fill: ${themeColor(color)};
  stroke: ${themeColor(color)};
`

const disabledStyles = css`
  border: none !important;
`

const containedStyles = css`
  ${applyContentColor(ThemeColor.b0)}
  background-color: ${themeColor(ThemeColor.b100)};

  &:hover:not(:disabled):not(:active) {
    ${applyContentColor(ThemeColor.b10)}
  }
  &:disabled {
    ${disabledStyles}
  }

  &:active {
    ${applyContentColor(ThemeColor.b100)}
    background-color: ${themeColor(ThemeColor.b0)};
    border-color: ${themeColor(ThemeColor.b100)};
  }
`

const outlinedStyles = css`
  ${applyContentColor(ThemeColor.b100)}
  background-color: ${themeColor(ThemeColor.b0)};
  border-color: ${themeColor(ThemeColor.b30)};

  &:hover:not(:disabled) {
    border-color: ${themeColor(ThemeColor.b100)};
  }

  &:disabled {
    ${disabledStyles}
  }

  &:active {
    ${applyContentColor(ThemeColor.b0)}
    background-color: ${themeColor(ThemeColor.b100)};
  }
`

const applyVariantStyles = switchProp(
  'variant',
  {
    contained: containedStyles,
    outlined: outlinedStyles,
  },
  containedStyles,
)

export const Container = styled.button`
  background: none repeat scroll 0 0 transparent;
  border: 1px solid transparent;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  user-select: none;
  transition: ${themeTransition()};

  border-radius: ${toThemePixels(10)};
  flex-shrink: 0;

  ${applySizeStyles}
  ${ifProp('variant', applyVariantStyles)}

  width: ${ifProp('block', '100%')};

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }
`
