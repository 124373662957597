import { useToggable } from './useToggable'

export const useModal = (defaultValue: boolean = false) => {
  const [isOpen, setOpen, toggle] = useToggable(defaultValue)

  const open = () => setOpen(true)
  const close = () => setOpen(false)

  return {
    isOpen,
    toggle,
    open,
    close,
  }
}
