import {
  dynamicArrayPropType,
  oneOrManyChildren,
  parseChildrenArray,
} from '@/utils'
import PropTypes from 'prop-types'
import { RadioButton } from '../../atoms'
import { Container } from './styles'

export const RadioGroup = ({
  value,
  onChange,
  disabled,
  alignment = 'vertical',
  children,
  columns,
  error,
}) => {
  const radios = parseChildrenArray(children)

  return (
    <Container alignment={alignment} columns={columns}>
      {radios.map(
        ({
          key,
          disabled: radioDisabled,
          value: radioValue,
          error: radioError,
          ...props
        }) => (
          <RadioButton
            key={key}
            checked={value === radioValue}
            disabled={disabled || radioDisabled}
            error={error || radioError}
            onChange={() => onChange(radioValue)}
            {...props}
          />
        ),
      )}
    </Container>
  )
}

RadioGroup.propTypes = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical', 'grid']),
  children: oneOrManyChildren.isRequired,
  columns: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: dynamicArrayPropType.isRequired,
}
