import PropTypes from 'prop-types'
import { Column } from '../../styled'
import { PaymentCard } from './PaymentCard'

export const PaymentSelect = ({ payments, onChange, value }) => (
  <Column alignItems="start" gap="16px" width="100%">
    {payments.map((item) => (
      <PaymentCard
        {...item}
        onClick={() => onChange(item)}
        selected={item.paymentId === value?.paymentId}
      />
    ))}
  </Column>
)

PaymentSelect.propTypes = {
  onChange: PropTypes.func,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      iban: PropTypes.string,
      name: PropTypes.string,
      paymentId: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    paymentId: PropTypes.string,
  }),
}
