import { Badge, Column, Row, Typography } from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { ArrowRight } from '@/icons'
import { paths } from '@/routes/paths'
import { makeAddress, useGoToPath } from '@/utils'
import { useOutletContext } from 'react-router-dom'
import { locationPropType } from './helpers'
import { DashboardCompanyCardItem, LocationCardContent } from './styles'

export const LocationCard = ({ name, locationId, address }) => {
  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))
  const { cart } = useOutletContext()

  return (
    <DashboardCompanyCardItem onClick={goToLocation}>
      <LocationCardContent>
        <Column gap="4px">
          <Row alignItems="center" gap="8px">
            <Typography bold variant="p1Body">
              {name}
            </Typography>
            <Badge
              color={ComponentColors.danger}
              count={cart[locationId]?.length}
            />
            <ArrowRight size="message" />
          </Row>
          <Typography color={ThemeColor.b50} variant="badgeText">
            {makeAddress(address)}
          </Typography>
        </Column>
      </LocationCardContent>
    </DashboardCompanyCardItem>
  )
}

LocationCard.propTypes = locationPropType
