import * as Yup from 'yup'

export const validationSchema = (messages) =>
  Yup.object().shape({
    address: Yup.object()
      .shape({
        city: Yup.string().required(messages.city),
        country: Yup.string().required(messages.country),
        postalCode: Yup.string().required(messages.postalCode),
        street: Yup.string().required(messages.street),
        streetNr: Yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOptions),
    companyId: Yup.string().required(messages.general),
    name: Yup.string().required(messages.name),
  })
