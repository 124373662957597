import { AdvisorBubble, Column, FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useFormItem, useNextLoading, useOnNextClick } from '@/providers'
import { Alert } from '@surein/ui'
import { TriangleAlert } from 'lucide-react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useSaveAssessmentStep, useStepperWarning } from '../helpers'
import { StepperTestimonial } from './StepperTestimonial'

export const ApproxRevenueStep = ({ helperText }) => {
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.approxRevenue,
  )

  const { t } = useTranslation()

  const { value } = useFormItem('approxTurnover')

  const { shouldShowWarning } = useStepperWarning(value)

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <Column gap="24px">
      <FormUnit
        helperText={helperText}
        name="approxTurnover"
        placeholder={t('currencyInputPlaceholder')}
        unit="€"
      />
      {shouldShowWarning && (
        <Alert
          icon={TriangleAlert}
          text={<Trans i18nKey="approxRevenueWarning" />}
          variant="warning"
        />
      )}
      <AdvisorBubble text={t('approxRevenueAdvisorHint')} />
    </Column>
  )
}

ApproxRevenueStep.propTypes = {
  helperText: PropTypes.string,
}

export const ApproxRevenueStepTestimonial = () => (
  <StepperTestimonial stepKey="approxRevenue" imageSrc="emily-headshot" />
)
