import * as Sentry from '@sentry/react'
import { AxiosInstance } from 'axios'

export function setupTracing(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    const span = Sentry.getActiveSpan()

    if (span) {
      config.headers['sentry-trace'] = Sentry.spanToTraceHeader(span)
      const baggageHeader = Sentry.spanToBaggageHeader(span)
      if (baggageHeader) {
        config.headers['baggage'] = baggageHeader
      }
    }

    return config
  })
}
