import { equals } from 'ramda'
import { useLocation } from 'react-router-dom'
import { makeQueryString } from '../url'
import { useSearchParams } from './useSearchParams'

export const useChangeSearchParam = () => {
  const search = useSearchParams()
  const { pathname } = useLocation()

  return (searchObj) => {
    const url = `${pathname}${makeQueryString({
      ...search,
      ...searchObj,
    })}`

    if (!equals(search, searchObj)) window.history.replaceState(null, null, url)
  }
}
