import { ComponentColors, ThemeColor } from '@/enums'
import { BuildingIcon, MyProfileIcon } from '@/icons'
import { paths } from '@/routes/paths'
import { useGoToPath, useTranslationSectionObject } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import {
  ButtonLink,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '../../components'
import { LinkWithPermissions } from './components/LinkWithPermissions'
import { LinksContainer, SectionTitle, StyledSettingsIcon } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  const menuTranslations = useTranslationSectionObject('profile.menu')
  const profileTranslations = useTranslationSectionObject('profile.titles')
  const recommendationTranslations = useTranslationSectionObject(
    'recommendations.success',
  )

  return {
    pageTitle: menuTranslations.profile,
    myProfile: profileTranslations.profile,
    poa: menuTranslations.signPOA,
    language: menuTranslations.language,
    payments: menuTranslations.payments,
    refer: recommendationTranslations.btnRecommend,
    companies: menuTranslations.companies,
    edit: menuTranslations.users,
    advisorText: t('settings_advisor_text'),
    integrations: menuTranslations.integrations,
    myDetails: t('my_details'),
    myCompany: t('companyPage.singleCompany'),
    account: t('account'),
    restrictedAccess: t('restrictedAccess'),
  }
}

export const Base = () => {
  const messages = useMessages()
  const { hasProtectedAccess } = useOutletContext()

  return (
    <>
      <PageMainContent>
        <Typography variant="h4">{messages.pageTitle}</Typography>
        <LinksContainer>
          <SectionTitle>
            <MyProfileIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.myDetails}
            </Typography>
          </SectionTitle>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.myProfile)}
            size="large"
          >
            {messages.myProfile}
          </ButtonLink>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!hasProtectedAccess}
            onClick={useGoToPath(paths.signPoa)}
            size="large"
          >
            {messages.poa}
          </LinkWithPermissions>
          <SectionTitle>
            <BuildingIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.myCompany}
            </Typography>
          </SectionTitle>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!hasProtectedAccess}
            onClick={useGoToPath(paths.integrations)}
            size="large"
          >
            {messages.integrations}
          </LinkWithPermissions>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.users)}
            size="large"
          >
            {messages.edit}
          </ButtonLink>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.companies)}
            size="large"
          >
            {messages.companies}
          </ButtonLink>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!hasProtectedAccess}
            onClick={useGoToPath(paths.payments)}
            size="large"
          >
            {messages.payments}
          </LinkWithPermissions>
          <SectionTitle>
            <StyledSettingsIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.account}
            </Typography>
          </SectionTitle>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.language)}
            size="large"
          >
            {messages.language}
          </ButtonLink>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.referral)}
            size="large"
          >
            {messages.refer}
          </ButtonLink>
        </LinksContainer>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
