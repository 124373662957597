import { dynamicObjectPropType, isObject, isString } from '@/utils'
import PropTypes from 'prop-types'
import { Select, Option } from '../../atoms'
import { makeOptions, usePlaces, formatValue, useParseAddress } from './helpers'

export const LocationAutocomplete = ({
  country,
  disabled,
  value,
  onChange,
  onFocus,
  ...props
}) => {
  const {
    ready,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlaces(country)

  const onSelect = (v) => {
    onChange(v)
    setValue(formatValue(v))
  }

  const parseAddressMutation = useParseAddress({ onSuccess: onSelect })

  const options = makeOptions(data)

  const onSearch = (val) => {
    if (val.length > 2) {
      setValue(val)
    } else clearSuggestions()
    onChange(val)
  }

  const handleFocus = () => {
    onFocus?.()
    if (value) setValue(formatValue(value))
  }

  const onBlur = () => {
    if (!isObject(value)) {
      onChange('')
    }
  }

  return (
    <Select
      autocomplete
      disabled={disabled || !ready}
      onBlur={onBlur}
      onFocus={handleFocus}
      onSearch={onSearch}
      onSelect={parseAddressMutation.mutate}
      value={isString(value) ? value : formatValue(value)}
      {...props}
    >
      {options.map(({ text, value: optionValue }) => (
        <Option key={optionValue} value={optionValue}>
          {text}
        </Option>
      ))}
    </Select>
  )
}

LocationAutocomplete.propTypes = {
  country: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    dynamicObjectPropType,
  ]),
}
