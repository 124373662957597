import PropTypes from 'prop-types'
import { ContactDataStepA } from './components/ContactDataStepA'

export const ContactDataStep = ({
  categoryId,
  onBack,
  transformSubmitValues,
  onFinish,
  handleCalculationSuccessful,
}) => {
  return (
    <ContactDataStepA
      onBack={onBack}
      handleCalculationSuccessful={handleCalculationSuccessful}
      transformSubmitValues={transformSubmitValues}
      categoryId={categoryId}
      onFinish={onFinish}
    />
  )
}

ContactDataStep.propTypes = {
  handleCalculationSuccessful: PropTypes.func,
  onBack: PropTypes.func,
  onFinish: PropTypes.func,
  transformSubmitValues: PropTypes.func,
  categoryId: PropTypes.string,
}
