import {
  BackComponent,
  Banner,
  ContactExpert,
  FormInput,
  FormLocation,
  Loader,
  PageActionsStyles,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { ComponentColors } from '@/enums'
import { FormProvider, useSnackbar } from '@/providers'
import {
  useLocationHome,
  useRequestEditLocation,
  useUpdateAddress,
  useUpdateLocation,
} from '@/services'
import {
  useGoBack,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  checkPoaSigned,
  checkCompletedAssessment,
  makeInitialValues,
  transform,
} from './helpers'
import { PageHeader } from './styles'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')
  const locationTranslations = useTranslationSection('locationPage')
  const snackbarTranslations = useTranslationSection('snackbars')
  const { t } = useTranslation()

  return {
    cancel: commonTranslations('cancel'),
    save: commonTranslations('save'),
    requestEdit: commonTranslations('requestEdit'),
    address: commonTranslations('address'),
    editLocation: locationTranslations('editLocation'),
    locationName: locationTranslations('locationName'),
    editRequestSuccess: snackbarTranslations('success.editRequest'),
    editRequestDisabled: snackbarTranslations('success.editRequestDisabled'),
    editLocationSuccess: snackbarTranslations('success.editLocation'),
    error: snackbarTranslations('error.generalMsg'),
    validations: useTranslationSectionObject('validations'),
    advisorText: t('company_advisor_text'),
  }
}

const PageForm = ({ data }) => {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { locationId } = useParams()
  const updateLocationMutation = useUpdateLocation(locationId)
  const updateAddressMutation = useUpdateAddress(data.address.addressId)
  const requestEditLocationMutation = useRequestEditLocation(locationId)

  const handleBack = useGoBack()

  const disabled = checkCompletedAssessment(data) && checkPoaSigned(data)

  const editService = ({ name, address }) =>
    disabled
      ? requestEditLocationMutation.mutateAsync()
      : Promise.all([
          updateLocationMutation.mutateAsync({
            name,
          }),
          updateAddressMutation.mutate(address),
        ])

  const handleSubmit = (values) =>
    editService(values)
      .then(() => {
        handleBack()
        snackbar.success(messages.editLocationSuccess)
      })
      .catch(() => snackbar.error(messages.error))
  return (
    <>
      <BackComponent />
      <PageHeader>
        <Typography variant="h4">{messages.editLocation}</Typography>
        {disabled && (
          <Banner
            color={ComponentColors.warning}
            text={messages.editRequestDisabled}
          />
        )}
      </PageHeader>
      <FormProvider
        initialValues={makeInitialValues(data)}
        onSubmit={handleSubmit}
        validationSchema={
          disabled ? null : validationSchema(messages.validations)
        }
      >
        <FormInput
          disabled={disabled}
          label={messages.locationName}
          name="name"
        />
        <FormLocation
          disabled={disabled}
          label={messages.address}
          name="address"
        />
        <PageActionsStyles>
          <SecondaryButton onClick={handleBack}>
            {messages.cancel}
          </SecondaryButton>
          <PrimaryButton type="submit">
            {disabled ? messages.requestEdit : messages.save}
          </PrimaryButton>
        </PageActionsStyles>
      </FormProvider>
    </>
  )
}

PageForm.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      addressId: PropTypes.number.isRequired,
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
      streetNr: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
    riskChecklist: PropTypes.shape({
      checks: PropTypes.arrayOf(
        PropTypes.shape({
          isDone: PropTypes.bool,
          type: PropTypes.oneOf([
            'onboarding',
            'signedPOA',
            'contractAdded',
            'bankAccountAdded',
          ]),
        }),
      ),
    }),
  }),
}

export const EditLocationPage = () => {
  const { locationId } = useParams()
  const messages = useMessages()
  const { data, isLoading } = useLocationHome(locationId, {
    select: transform,
  })

  if (isLoading) return <Loader />

  return (
    <>
      <PageMainContent>
        <PageForm data={data} />
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
