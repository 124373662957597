import { PrimaryButton, ShowWhen } from '@/components'
import { ArrowRightLine } from '@/icons'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SuccessContainer } from '../styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('order_success_title'),
    subtitle: t('checkout.subtitles.success'),
    buttonText: t('back_to_dashboard'),
    modalContents: t('checkout.upsellModalContent', { returnObjects: true }),
  }
}

export const SuccessOrderStepBase = ({ onFinish }) => {
  const messages = useMessages()

  return (
    <SuccessContainer>
      <ShowWhen when={Boolean(onFinish)}>
        <PrimaryButton icon={ArrowRightLine} onClick={onFinish} reverted>
          {messages.buttonText}
        </PrimaryButton>
      </ShowWhen>
    </SuccessContainer>
  )
}

SuccessOrderStepBase.propTypes = {
  onFinish: PropTypes.func,
}

export const SuccessOrderStep = ({ openSuccess }) => {
  const { locationId } = useParams()

  const goToDashboard = useGoToPath(paths.viewLocationWithKey(locationId))

  if (openSuccess) return openSuccess()

  return <SuccessOrderStepBase onFinish={goToDashboard} />
}

SuccessOrderStep.propTypes = {
  openSuccess: PropTypes.func,
}
