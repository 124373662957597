import { Column, FormCardSelect, Option, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { formatRiskAddress } from '@/pages/RecommendationPage/helpers'
import { theme } from '@/theme'
import { ChevronRight } from 'lucide-react'
import { useOutletContext } from 'react-router-dom'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { locationValidationSchema } from '../helpers/validationSchema'
import { LocationSelectionFormValues } from '../helpers/types'
import { Company } from '@/types/company'
import { Location } from '@/types/location'

interface OutletContextProps {
  companies: Company[]
}

const LocationSelectionForm = () => {
  const { companies } = useOutletContext<OutletContextProps>()

  return (
    <>
      {companies.map((company) => (
        <Fragment key={company.companyId}>
          <Typography variant="p2Body" color={ThemeColor.glass500}>
            {company.name}
          </Typography>
          <FormCardSelect name="locationData" showErrorHelperText={false}>
            {company.locations.map((location: Location) => (
              <Option
                key={location.locationId}
                value={{
                  locationId: location.locationId,
                  companyId: company.companyId,
                  poaSigned: company.poaSigned,
                }}
              >
                <Column>
                  <Row alignItems="center" gap="8px">
                    <Typography bold variant="p1Body" color={ThemeColor.b100}>
                      {location.name}
                    </Typography>
                    <ChevronRight color={theme.color.glass500} size="16" />
                  </Row>
                  <Typography variant="p2Body" color={ThemeColor.glass500}>
                    {formatRiskAddress(location.address)}
                  </Typography>
                </Column>
              </Option>
            ))}
          </FormCardSelect>
        </Fragment>
      ))}
    </>
  )
}

export const LocationSelectionStep = () => {
  const { t } = useTranslation()
  const { onBack, onNext, setCompanyData } = useStepper()

  const handleNext = (values: LocationSelectionFormValues) => {
    setCompanyData({
      companyId: values.locationData.companyId,
      locationId: values.locationData.locationId,
      poaSigned: values.locationData.poaSigned,
    })
    onNext(values)
  }

  return (
    <StepLayout
      title={t('addInsuranceContractsSteps.locationSelection.title')}
      subtitle={t('addInsuranceContractsSteps.locationSelection.subtitle')}
      validationSchema={locationValidationSchema}
      onNext={handleNext}
      onBack={onBack}
    >
      <LocationSelectionForm />
    </StepLayout>
  )
}
