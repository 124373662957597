import { Option, FormCardSelect } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    allYear: t('locationOpenSeasonAllYear'),
    notAllYear: t('locationOpenSeasonNotAllYear'),
  }
}

export const LocationOpenSeasonStep = () => {
  const messages = useMessages()
  const { value } = useFormItem('openSeason')
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationOpenSeason,
  )

  useOnNextClick(save)

  useNextDisabled(() => !value, [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormCardSelect centered name="openSeason">
      <Option key="allYear" value="allYear">
        {messages.allYear}
      </Option>
      <Option key="notAllYear" value="notAllYear">
        {messages.notAllYear}
      </Option>
    </FormCardSelect>
  )
}
