import { CarrierLogo, Row, Typography } from '@/components'
import { Categories, ThemeColor } from '@/enums'
import { ArrowRight } from '@/icons'
import { paths } from '@/routes/paths'
import {
  ddMMyyyy,
  dynamicArrayPropType,
  formatMoney,
  useGoToPath,
  useIsMobile,
} from '@/utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { pick } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reorderCellsForMobile, useMessages } from './helpers'
import {
  InsuranceEntryTextContainer,
  InsuranceEntryWrapper,
  LocationTableRightPrice,
  StyledTable,
  TableContainer,
  TableGrossPrice,
  TableScrollWrapper,
} from './styles'

const columnHelper = createColumnHelper()

const LocationTable = ({
  name,
  insurances,
  companyId,
  companyName,
  locationId,
  search,
}) => {
  const messages = useMessages()

  const aggregatedData = insurances.map((insurance) => ({
    ...insurance,
    locationName: name,
    companyName,
    policyNumber: insurance.policyNumber || '',
  }))

  const [data] = useState(() => [...aggregatedData])
  const [columnOrder, setColumnOrder] = useState([])

  const { isMobile } = useIsMobile()

  const columns = [
    columnHelper.accessor('carrierName', {
      header: () => <TableHeader text={messages.insurance} />,
      cell: ({ row }) => {
        const props = pick(
          ['categoryId', 'insuranceId', 'logoUrl', 'carrierName'],
          row.original,
        )
        return (
          <ContractsTableInsuranceEntry
            companyId={companyId}
            locationId={locationId}
            {...props}
          />
        )
      },
      size: 320,
    }),
    columnHelper.accessor('startDate', {
      header: () => <TableHeader text={messages.startDate} />,
      cell: (endDate) => ddMMyyyy(endDate.getValue()) || '-',
      size: 120,
    }),
    columnHelper.accessor('contractPaymentNextDate', {
      header: () => <TableHeader text={messages.nextPayment} />,
      cell: (contractPaymentNextDate) =>
        ddMMyyyy(Date.parse(contractPaymentNextDate.getValue())) || '-',
      size: 120,
    }),
    columnHelper.accessor('risksInsured', {
      header: () => <TableHeader text={messages.information} />,
      cell: (risksInsured) => (
        <Typography title={risksInsured.getValue()} variant="p2Body">
          {risksInsured.getValue() || '-'}
        </Typography>
      ),
      size: 220,
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('policyNumber', {
      header: () => <TableHeader text={messages.insuranceNumber} />,
      cell: (policyNumber) => (
        <Typography title={policyNumber.getValue()} variant="p2Body">
          {policyNumber.getValue() || '-'}
        </Typography>
      ),
    }),
    columnHelper.accessor('grossPrice', {
      header: () => <TableHeader text={messages.cost} />,
      cell: ({ row }) => {
        const price = Number(row.original.grossPrice)
        const { paymentPeriod } = row.original
        return (
          <TableGrossPrice>
            {' '}
            {price ? `${formatMoney(price)}/${paymentPeriod}` : '-'}
          </TableGrossPrice>
        )
      },
    }),
    columnHelper.accessor('locationName', {
      cell: (locationName) => locationName.getValue() || '-',
    }),
    columnHelper.accessor('companyName', {
      cell: (locationName) => locationName.getValue() || '-',
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    renderFallbackValue: '-',
    onColumnOrderChange: setColumnOrder,
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        locationName: false,
        companyName: false,
      },
    },
    state: {
      columnOrder,
      globalFilter: search,
    },
    enableFilters: true,
    enableColumnFilters: true,
    globalFilterFn: 'includesString',
  })

  useEffect(() => {
    if (isMobile) {
      table.setColumnOrder(reorderCellsForMobile(table.getAllLeafColumns()))
    } else {
      table.resetColumnOrder()
    }
  }, [isMobile])

  const rowVisibleCells = table
    .getRowModel()
    .rows.map((row) => row.getVisibleCells())

  const visibleGrossPrice = table
    .getFilteredRowModel()
    .flatRows.map((row) => row.original)
    .reduce((sum, item) => sum + item.grossPrice, 0)

  if (rowVisibleCells.length === 0) return null

  return (
    <TableContainer>
      <Row justifyContent="space-between">
        <Typography bold variant="p1Body">
          {name}
        </Typography>
        <LocationTableRightPrice alignItems="center" gap="4px">
          <Typography color={ThemeColor.b40} variant="badgeText">
            {messages.total}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {formatMoney(visibleGrossPrice || 0)}
            <Typography color={ThemeColor.b40} variant="badgeText">
              {`/${messages.year}`}
            </Typography>
          </Typography>
        </LocationTableRightPrice>
      </Row>
      <TableScrollWrapper>
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableScrollWrapper>
    </TableContainer>
  )
}

LocationTable.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  grossPrice: PropTypes.number.isRequired,
  insurances: dynamicArrayPropType.isRequired,
  locationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  search: PropTypes.string,
}

export default LocationTable

const TableHeader = ({ text }) => (
  <Typography bold color={ThemeColor.b50} variant="badgeText">
    {text}
  </Typography>
)

TableHeader.propTypes = {
  text: PropTypes.string.isRequired,
}

const ContractsTableInsuranceEntry = ({
  categoryId,
  insuranceId,
  locationId,
  logoUrl,
  carrierName,
}) => {
  const { t } = useTranslation()
  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({ categoryId, insuranceId, locationId }),
  )

  const categoryName = t(
    Categories[categoryId]
      ? `categories.${Categories[categoryId].key}.name`
      : t('common.insurance'),
  )

  return (
    <InsuranceEntryWrapper onClick={goToInsurance}>
      <CarrierLogo height="32px" name={logoUrl} width="32px" />
      <InsuranceEntryTextContainer>
        <Row alignItems="center">
          <Typography bold title={categoryName} variant="badgeText">
            {categoryName}
          </Typography>
          <ArrowRight size="message" />
        </Row>
        <Typography
          color={ThemeColor.b50}
          title={carrierName}
          variant="badgeText"
        >
          {carrierName}
        </Typography>
      </InsuranceEntryTextContainer>
    </InsuranceEntryWrapper>
  )
}

ContractsTableInsuranceEntry.propTypes = {
  carrierName: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  insuranceId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
}
