import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { SecondaryButton, Typography } from '../../atoms'
import {
  Content,
  ContentWrapper,
  EmojiContainer,
  StyledButton,
  StyledRightArrow,
} from './styles'

export const SuccessView = ({
  title,
  subtitle,
  buttonText,
  secondaryButtonText,
  onButtonClick,
  onSecondaryButtonClick,
}) => (
  <ContentWrapper>
    <Content>
      <EmojiContainer>🙌</EmojiContainer>
      <Typography variant="h3">{title}</Typography>
      <Typography color={ThemeColor.b50} variant="p1Body">
        {subtitle}
      </Typography>
      <StyledButton
        block
        icon={StyledRightArrow}
        onClick={onButtonClick}
        reverted
      >
        {buttonText}
      </StyledButton>
      {secondaryButtonText && (
        <SecondaryButton block onClick={onSecondaryButtonClick}>
          {secondaryButtonText}
        </SecondaryButton>
      )}
    </Content>
  </ContentWrapper>
)

SuccessView.propTypes = {
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
