import { ThemeColor, ThemeFontWeight } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../styled'

export const Container = styled(Column)`
  gap: ${toThemePixels(2)};
  max-height: ${toThemePixels(130.75)};
  border-radius: ${toThemePixels(3)};
`

export const OptionsContainer = styled.div`
  overflow-x: hidden;
`

export const StyledOption = styled(Row)`
  width: 100%;
  height: ${toThemePixels(16)};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${toThemePixels(2)};
  padding: 0 ${ifProp('icon', '0', toThemePixels(2))};
  &:not(:last-child) {
    border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  }

  span {
    font-weight: ${ifProp('selected', ThemeFontWeight.bold)};
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: unset;
  }
`
