import { AssessmentStepKeys } from '@/enums'
import { useLocationTypes } from '@/services/locationTypes'
import { useMemo, useState } from 'react'
import { makeBusinessTypeSubmitValue, makeFLLocationTypes } from '../../helpers'
import { ConditionalOfferAssessment } from './BasePage'

const liabilityAssessmentSteps = [
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.companyFoundedDateLead,
  AssessmentStepKeys.approxRevenue,
  AssessmentStepKeys.employeesCount,
  AssessmentStepKeys.approxLaborCosts,
]

const makeSubmitValues = () => ({
  approxRevenue,
  approxLaborCosts,
  businessType,
  companyFoundedDateLead,
  ...steps
}) => ({
  ...steps,
  lastYearFinances: { ...approxLaborCosts, ...approxRevenue },
  businessType: makeBusinessTypeSubmitValue(businessType),
  companyFoundedDate: companyFoundedDateLead,
})

const makeStepProps = (handleLocationTypeChange) => (messages) => ({
  [AssessmentStepKeys.businessType]: {
    onChange: handleLocationTypeChange,
  },
  [AssessmentStepKeys.approxRevenue]: {
    helperText: messages.helperText,
  },
  [AssessmentStepKeys.approxLaborCosts]: {
    helperText: messages.helperText,
  },
})

export const LiabilityConditionalOffer = () => {
  const [categoryId, setCategoryId] = useState('LI')
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const financialLossLocationTypes = useMemo(
    () => makeFLLocationTypes(locationTypesData?.verticalsMap || {}),
    [locationTypesData, locationTypesLoading],
  )

  const handleLocationTypeChange = (values) => {
    if (values) {
      if (
        !values.length ||
        values.find((value) => !financialLossLocationTypes.includes(value))
      ) {
        setCategoryId('LI')
      } else {
        setCategoryId('FL')
      }
    }
  }

  return (
    <ConditionalOfferAssessment
      categoryId={categoryId}
      makeStepProps={makeStepProps(handleLocationTypeChange)}
      stepKeys={liabilityAssessmentSteps}
      transformSubmitValues={makeSubmitValues()}
    />
  )
}
