import { ThemeColor } from '@/enums'
import { BurgerLine, PhoneLine, SureinLogoNew } from '@/icons'
import { useAuth, useContactModal } from '@/providers'
import { paths } from '@/routes/paths'
import {
  dynamicObjectPropType,
  isVerified,
  useGoToPath,
  useIsMobile,
  useModal,
} from '@/utils'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { IconButton, LanguageSwitch } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Relative, Row } from '../../styled'
import { ModalBase } from '../ModalBase'
import { SideBar } from '../SideBar'
import { Container } from './styles'

export const HeaderBase = ({
  backgroundColor,
  hasHeadquarters,
  hidden,
  cart,
  border,
  onLogoClick,
  hide = {},
  userCompanies,
  companies,
}) => {
  const { openContact } = useContactModal({
    hasHeadquarters,
    hide,
  })
  const menuState = useModal()

  return (
    <Container
      backgroundColor={backgroundColor}
      border={border}
      hidden={hidden}
    >
      <Relative>
        <SureinLogoNew height={24} onClick={onLogoClick} width={88} />
      </Relative>
      <Row alignItems="center" gap="8px">
        <ShowWhen when={!hide.language}>
          <LanguageSwitch />
        </ShowWhen>
        <IconButton
          aria-label="contact button"
          onClick={openContact}
          size="medium"
          variant="outlined"
        >
          <PhoneLine color={ThemeColor.b0} size="info" />
        </IconButton>
        <ShowWhen when={!hide.menu}>
          <IconButton onClick={menuState.open} size="medium" variant="outlined">
            <BurgerLine size="info" stroke />
          </IconButton>
        </ShowWhen>
      </Row>
      {menuState.isOpen && (
        <ModalBase isOpen onClose={menuState.close}>
          <SideBar
            cart={cart}
            companies={companies}
            hasHeadquarters={hasHeadquarters}
            onItemClick={menuState.close}
            userCompanies={userCompanies}
          />
        </ModalBase>
      )}
    </Container>
  )
}

HeaderBase.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  cart: dynamicObjectPropType,
  companies: PropTypes.array,
  hasHeadquarters: PropTypes.bool,
  hidden: PropTypes.bool,
  hide: PropTypes.shape({
    contact: PropTypes.shape({
      chat: PropTypes.bool,
      header: PropTypes.bool,
    }),
    language: PropTypes.bool,
    menu: PropTypes.bool,
  }),
  onLogoClick: PropTypes.func,
  userCompanies: PropTypes.array,
}

export const Header = ({
  backgroundColor,
  hasHeadquarters,
  hidden,
  simple,
  cart,
  border,
  onLogoClick,
  hideContact,
  userCompanies,
  companies,
}) => {
  const { isAuthenticated, user, isLoading } = useAuth()
  const { width } = useIsMobile()
  const withSidebar = width > 655
  const { pathname } = useLocation()

  const goToRoot = useGoToPath(paths.authRedirect)

  const shouldRenderMenu =
    isAuthenticated && isVerified(user) && !simple && !withSidebar

  const shouldRenderLanguage =
    !isLoading &&
    (!isAuthenticated ||
      pathname.includes('assessment') ||
      pathname.includes('funnel'))

  return (
    <HeaderBase
      backgroundColor={backgroundColor}
      border={border}
      cart={cart}
      companies={companies}
      hasHeadquarters={hasHeadquarters}
      hidden={hidden}
      hide={{
        contact: hideContact,
        language: !shouldRenderLanguage,
        menu: !shouldRenderMenu,
      }}
      onLogoClick={onLogoClick || goToRoot}
      userCompanies={userCompanies}
    />
  )
}

Header.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  cart: dynamicObjectPropType,
  companies: PropTypes.array,
  hasHeadquarters: PropTypes.bool,
  hidden: PropTypes.bool,
  hideContact: PropTypes.shape({
    chat: PropTypes.bool,
    header: PropTypes.bool,
  }),
  onLogoClick: PropTypes.func,
  simple: PropTypes.bool,
  userCompanies: PropTypes.array,
}
