import { ImageFromCdn, SecondaryButton, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import {
  themeBreakpointDown,
  themeBreakpointUp,
  themeColor,
  toThemePixels,
} from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { OptimiseCoverageModal } from './OptimiseCoverageModal'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 8px;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  background-color: #f8f8f8;
  gap: 2rem;
  overflow: hidden;

  ${themeBreakpointDown(Breakpoint.sm)} {
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 0;
  }
`

const StyledButton = styled(SecondaryButton)`
  width: fit-content;
  background-color: transparent;
  border: 1px solid ${themeColor(ThemeColor.b100)};
`

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  max-width: 400px;
  align-items: flex-start;
  justify-content: space-around;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(6)} ${toThemePixels(8)} ${toThemePixels(6)};
  }
`

const Heading = styled.h4`
  font-size: 20px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    font-size: 16px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100%;
    height: 140px;
    position: relative;
  }

  img {
    object-fit: cover;
    height: 100%;
    max-width: 150px;
    width: auto;

    ${themeBreakpointUp(Breakpoint.xl)} {
      max-width: 250px;
    }

    ${themeBreakpointDown(Breakpoint.sm)} {
      max-width: 100%;
      object-fit: cover;
      object-position: ${prop('backgroundImageX', 'center')}
        ${prop('backgroundImageY', 'center')};
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  }
`

export const OptimiseCoverCard = ({ locationId }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Container>
        <TextSection>
          <Heading>
            <Typography variant="h4">
              {t('yearlyAssessment.optimiseCoverageModal-title')}
            </Typography>
          </Heading>
          <StyledButton onClick={() => setIsOpen(true)} variant="outlined">
            <Typography bold variant="p1Body" />
            {t('yearlyAssessment.optimiseCoverCard-button-label')}
          </StyledButton>
        </TextSection>
        <ImageWrapper>
          <ImageFromCdn
            fallbackType="jpg"
            name="fire-extinguisher"
            type="webp"
          />
        </ImageWrapper>
      </Container>
      <OptimiseCoverageModal
        isOpen={isOpen}
        locationId={locationId}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

OptimiseCoverCard.propTypes = {
  locationId: PropTypes.string,
}
