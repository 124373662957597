import { i18nHelpers } from '@/i18n/init'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const LanguageIndicator = ({ data }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (
      !isEmpty(data) &&
      i18nHelpers.languages.includes(data.language) &&
      i18n.language !== data.language
    ) {
      i18n.changeLanguage(data.language || 'de')
    }
  }, [data?.language])

  return null
}

LanguageIndicator.propTypes = {
  data: PropTypes.shape({
    language: PropTypes.string,
  }),
}
