import { AssessmentStepKeys } from '@/enums'
import { useTranslation } from 'react-i18next'
import { LeadGenerationAssessment } from './BasePage'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    city: t('buildingCity'),
    cityInfo: t('buildingCityInfo'),
    postalCode: t('buildingPostalCode'),
    postalCodeInfo: t('buildingPostalCodeInfo'),
    street: t('buildingStreet'),
    streetInfo: t('buildingStreetInfo'),
    streetNr: t('buildingStreetNr'),
    streetNrInfo: t('buildingStreetNrInfo'),
  }
}

const buildingAssessmentSteps = [
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.constructionDate,
  AssessmentStepKeys.constructionDetails,
  AssessmentStepKeys.constructionArea,
  AssessmentStepKeys.createLead,
]

const makeSubmitValues = ({
  constructionDate,
  constructionDetails,
  constructionArea,
  ...steps
}) => ({
  ...steps,
  constructionDetails: {
    ...constructionDate,
    ...constructionDetails,
    ...constructionArea,
  },
})

const makeStepProps = (addressMessages, transformSubmitValues) => ({
  [AssessmentStepKeys.createLead]: {
    categoryId: 'BU',
    transformSubmitValues,
    addressMessages,
    show: {
      postalCode: true,
      street: true,
      streetNr: true,
      city: true,
    },
  },
})

export const BuildingLead = () => {
  const messages = useMessages()

  return (
    <LeadGenerationAssessment
      categoryId="BU"
      makeStepProps={makeStepProps(messages, makeSubmitValues)}
      stepKeys={buildingAssessmentSteps}
    />
  )
}
