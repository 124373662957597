import {
  createLocation,
  deleteLocation,
  getLocationById,
  getLocationHome,
  requestDeleteCompany,
  requestDeleteLocation,
  requestEditLocation,
  updateLocation,
  updateLocationChecklistSettings,
  updateLocationEssentialInsurances,
  updateLocationWithAddress,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useMutation, useQuery } from 'react-query'

const keys = {
  locations: 'locations',
  location: 'location',
  location1: 'location1',
}

export const useLocation = (id, options) =>
  useQuery([keys.location1, id], () => getLocationById(id), options)

export const useLocationHome = (id, options) =>
  useQuery([keys.location, id], () => getLocationHome(id), options)

export const useCreateLocation = (id) =>
  useInvalidateOnSuccessMutation(
    [keys.locations, 'companies'],
    ({ companyId, name, address }) =>
      createLocation({
        name,
        address,
        companyId: id || companyId,
      }),
  )

export const useUpdateLocation = (id) =>
  useInvalidateOnSuccessMutation(keys, ({ name }) =>
    updateLocation({ id, name }),
  )

export const useUpdateLocationEssentialInsurances = (id) =>
  useInvalidateOnSuccessMutation(keys, ({ essentialCategoryId }) =>
    updateLocationEssentialInsurances({ id, essentialCategoryId }),
  )

export const useUpdateLocationWithAddress = (id, addressId) =>
  useInvalidateOnSuccessMutation(keys, ({ name, address }) =>
    updateLocationWithAddress({
      id,
      name,
      address: { id: addressId, ...address },
    }),
  )

export const useDeleteLocation = (id) =>
  useInvalidateOnSuccessMutation(keys.locations, () => deleteLocation(id))

export const useRequestDeleteLocation = (id) =>
  useMutation(() => requestDeleteLocation(id))

export const useRequestDeleteCompany = (id) =>
  useMutation(() => requestDeleteCompany(id))

export const useRequestEditLocation = (id) =>
  useMutation(() => requestEditLocation(id))

export const useUpdateLocationChecklistSettings = (id) =>
  useInvalidateOnSuccessMutation([[keys.location, id]], () =>
    updateLocationChecklistSettings(id),
  )
