import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PrimaryButton, Typography } from '../../atoms'
import { ContactInfo } from '../../molecules'
import { Column, Card } from '../../styled'
import { Contact, Container, Emoji, PageInfo } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    title: t('error_view_title'),
    subtitle: t('error_view_subtitle'),
    buttonText: t('error_view_button_text'),
    contactTitle: t('error_view_contact_title'),
    contactSubtitle: t('error_view_contact_subtitle'),
  }
}

export const ErrorView = ({ onBack }) => {
  const messages = useMessages()

  return (
    <Container>
      <PageInfo>
        <Emoji>⛈</Emoji>
        <Column alignItems="center" gap="8px">
          <Typography variant="h4">{messages.title}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.subtitle}
          </Typography>
        </Column>
        <PrimaryButton block onClick={onBack}>
          {messages.buttonText}
        </PrimaryButton>
      </PageInfo>
      <Contact>
        <Card responsive withoutBorder>
          <ContactInfo
            photo="chatblock"
            showAvailableBtn
            subtitle={messages.contactSubtitle}
            title={messages.contactTitle}
            withGap
          />
        </Card>
      </Contact>
    </Container>
  )
}

ErrorView.propTypes = {
  onBack: PropTypes.func.isRequired,
}
