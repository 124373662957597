import { useCallback, useEffect } from 'react'

export const useFloatPositioning = ({
  isOpen,
  ref,
  relatedToRef,
  onOpen,
  onPositionTop,
  onPositionBottom,
  onPositionRight,
  onPositionLeft,
  noRepositionTop,
}) => {
  const handlePositioning = useCallback(() => {
    if (isOpen) {
      const component = ref.current
      const elementBounds = relatedToRef.current?.getBoundingClientRect()
      const right =
        window.innerWidth - elementBounds.right + elementBounds.width / 2

      component.style.position = 'fixed'
      component.style.transform = 'translate(50%)'
      component.style.right = `${right}px`
      component.style.top = `${elementBounds.bottom + 16}px`
      onOpen?.(component, elementBounds)

      if (right + component.offsetWidth / 2 > window.innerWidth) {
        component.style.transform = `translateX(calc(50% + ${
          right + component.offsetWidth / 2 - window.innerWidth
        }px))`
        onPositionRight?.(component, elementBounds)
      }

      if (window.innerWidth - elementBounds.right < component.offsetWidth / 2) {
        component.style.transform = `translateX(${
          elementBounds.width / 2 + window.innerWidth - elementBounds.right
        }px)`
        onPositionLeft?.(component, elementBounds)
      }

      if (
        window.innerHeight - elementBounds.bottom >
          component.offsetHeight + 16 ||
        elementBounds.top < component.offsetHeight + 16
      ) {
        onPositionBottom?.(component, elementBounds)
        component.style.top = `${elementBounds.bottom + 16}px`
      } else if (!noRepositionTop) {
        onPositionTop?.(component, elementBounds)
        component.style.top = 'unset'
        component.style.bottom = `${
          window.innerHeight - elementBounds.top + 16
        }px`
      }
    }
  }, [isOpen])

  useEffect(() => {
    handlePositioning()

    document.addEventListener('wheel', handlePositioning)

    return () => {
      document.removeEventListener('wheel', handlePositioning)
    }
  }, [handlePositioning])
}
