import { Column, ImageFromCdn, Typography } from '@/components'
import { Categories, ThemeColor } from '@/enums'
import {
  useCreateDraftRecommendation,
  useUpdateDraftRecommendation,
} from '@/services'
import { propTypeFromEnumKeys } from '@/utils'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getStoredAssessmentValues,
  getStoredConfirmationId,
  setStoredConfirmationId,
} from '../helpers'
import { AssessmentLoadingStep } from './AssessmentLoadingStep'
import { PrepareOfferImageContainer } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    steps: [
      t('assessmentLoadingStep1'),
      t('liabilityAssessmentLoadingStep2'),
      t('liabilityAssessmentLoadingStep3'),
      t('assessmentLoadingStep5'),
    ],
    prepareOfferImageTitle: t('prepareOfferImageTitle'),
    prepareOfferImageContent: t('prepareOfferImageContent'),
  }
}

export const PrepareOfferStep = ({
  onNext,
  onSuccess,
  categoryId,
  transformSubmitValues,
}) => {
  const messages = useMessages()
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const confirmationId = getStoredConfirmationId(categoryId)
  const values = getStoredAssessmentValues(categoryId)

  const createDraftRecommendationMutation = useCreateDraftRecommendation(
    categoryId,
  )

  const updateDraftRecommendationMutation = useUpdateDraftRecommendation(
    confirmationId,
    categoryId,
  )

  const handleLoadingComplete = () => {
    setIsLoading(false)
  }

  const handleFail = () => {
    onNext()
  }

  useEffect(() => {
    const mutation = confirmationId
      ? updateDraftRecommendationMutation
      : createDraftRecommendationMutation

    mutation.mutate(transformSubmitValues(values), {
      onSuccess: (res) => {
        setStoredConfirmationId(categoryId, res.id)
        setData(res)
      },
      onError: handleFail,
    })
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      if (data.recommendation.isSuccessful) {
        onSuccess()
      } else {
        handleFail()
      }
    }
  }, [isLoading, data])

  return (
    <AssessmentLoadingStep
      onNext={handleLoadingComplete}
      stepsMessages={messages.steps}
    />
  )
}

PrepareOfferStep.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  onNext: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  transformSubmitValues: PropTypes.func.isRequired,
}

export const PrepareOfferImage = () => {
  const messages = useMessages()

  return (
    <PrepareOfferImageContainer>
      <ImageFromCdn
        fallbackType="jpg"
        name="carrier-logos-transparent"
        type="webp"
      />
      <Column gap="16px" maxWidth="492px">
        <Typography variant="h3">{messages.prepareOfferImageTitle}</Typography>
        <Typography color={ThemeColor.b50} variant="h5">
          {messages.prepareOfferImageContent}
        </Typography>
      </Column>
    </PrepareOfferImageContainer>
  )
}
