import { ThemeColor } from '@/enums'
import { withSvgProps } from './helpers'
import { ReactComponent as SvgComponent } from './svg/check-circle.svg'

const Svg = (props) => <SvgComponent {...props} />

export const Icon = withSvgProps(Svg)
export const CheckCircle = (props) => (
  <Icon {...props} color={ThemeColor.b0} size={50} stroke={false} />
)
