import { updateAddress } from './address'
import { api } from './api'

export const getLocationById = (id) => api.get(`/location/${id}`)

export const getLocationHome = (id) => api.get(`/location/${id}/home`)

export const createLocation = ({ name, address, companyId }) =>
  api.post('/location', { name, address, companyId })

export const updateLocation = ({ id, name }) =>
  api.put(`/location/${id}`, { name })

export const updateLocationEssentialInsurances = ({
  id,
  essentialCategoryId,
}) => api.put(`/location/${id}`, { essentialCategoryId })

export const updateLocationWithAddress = ({ id, name, address }) =>
  Promise.all([updateAddress(address), updateLocation({ id, name })])

export const deleteLocation = (id) => api.delete(`/location/${id}`)

export const requestDeleteLocation = (id) =>
  api.post(`/request/delete`, {
    resType: 'location',
    resId: id,
  })

export const requestDeleteCompany = (id) =>
  api.post(`/request/delete`, {
    resType: 'company',
    resId: id,
  })

export const requestEditLocation = (id) =>
  api.post(`/request/edit`, {
    resType: 'location',
    resId: id,
  })

export const requestEditCompany = (id) =>
  api.post(`/request/edit`, {
    resType: 'company',
    resId: id,
  })
export const requestEditUser = (id) =>
  api.post(`/request/edit`, {
    resType: 'user',
    resId: id,
  })

export const getCompanyPoaStatus = (id) =>
  api.get(`/company/check-poa/${id}`, {
    resType: 'user',
    resId: id,
  })

export const updateLocationChecklistSettings = (id) =>
  api.put(`/location/${id}`, {
    riskChecklistSettings: {
      skipContractAdded: true,
    },
  })
