import { PublicCheckoutStartDateStep } from '@/pages/PublicCheckoutPage/steps/PublicCheckoutStartDateStep'
import { PublicCheckoutOverviewStep } from './PublicCheckoutOverviewStep'
import { PublicCheckoutSuccessStep } from './PublicCheckoutSuccessStep'
import { PublicCompanyDetailsStep } from './PublicCompanyDetailsStep'
import { PublicKillerQuestionsStep } from './PublicKillerQuestionsStep'
import { PublicPaymentAndPoaStep } from './PublicPaymentAndPoaStep'
import { PublicPaymentMethodStep } from './PublicPaymentMethodStep'
import { PublicSignPoaStep } from './PublicSignPoaStep'

export const PublicCheckoutStepKeys = {
  killerQuestions: 'killerQuestions',
  payment: 'payment',
  paymentAndPoa: 'paymentAndPoa',
  companyDetails: 'companyDetails',
  overview: 'overview',
  poa: 'poa',
  success: 'success',
  startDate: 'startDate',
}

export const publicCheckoutSteps = {
  [PublicCheckoutStepKeys.startDate]: PublicCheckoutStartDateStep,
  [PublicCheckoutStepKeys.payment]: PublicPaymentMethodStep,
  [PublicCheckoutStepKeys.paymentAndPoa]: PublicPaymentAndPoaStep,
  [PublicCheckoutStepKeys.killerQuestions]: PublicKillerQuestionsStep,
  [PublicCheckoutStepKeys.companyDetails]: PublicCompanyDetailsStep,
  [PublicCheckoutStepKeys.poa]: PublicSignPoaStep,
  [PublicCheckoutStepKeys.overview]: PublicCheckoutOverviewStep,
  [PublicCheckoutStepKeys.success]: PublicCheckoutSuccessStep,
}
