import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  padding: ${toThemePixels(4)} ${toThemePixels(6)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(1.5)};
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    gap: ${toThemePixels(1)};
    align-items: center;

    svg {
      transform: scale(0.8);
    }
  }
`
