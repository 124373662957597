import { ThemeColor } from '@/enums'
import { renderableType } from '@/utils'
import PropTypes from 'prop-types'
import { PrimaryButton, SecondaryButton, Typography } from '../../atoms'
import { ModalBase } from '../ModalBase'
import { ConfirmationModalHeader, ModalActions } from './styles'

export const Alert = ({
  isOpen,
  onClose,
  content,
  onConfirm,
  rejectText,
  confirmText,
  title,
}) => (
  <ModalBase closable isOpen={isOpen} onClose={onClose}>
    {title || content ? (
      <ConfirmationModalHeader>
        <Typography variant="h4">{title}</Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {content}
        </Typography>
      </ConfirmationModalHeader>
    ) : null}
    <ModalActions singleButton={!rejectText}>
      {rejectText && (
        <SecondaryButton onClick={onClose}>{rejectText}</SecondaryButton>
      )}
      <PrimaryButton block={!rejectText} onClick={onConfirm}>
        {confirmText}
      </PrimaryButton>
    </ModalActions>
  </ModalBase>
)
Alert.propTypes = {
  confirmText: PropTypes.string,
  content: renderableType,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  rejectText: PropTypes.string,
  title: renderableType,
}
