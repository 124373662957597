import { safeTimestamp } from '@/utils'
import dayjs from 'dayjs'
import { range } from 'ramda'

export const makeDates = ({ month, year, minDate, maxDate }) => {
  const currentMonth = dayjs(`${year}-${month}-01`)

  const dates = range(1, currentMonth.daysInMonth() + 1)

  const weekDay = currentMonth.weekday()

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < weekDay; index++) {
    dates.unshift('')
  }

  return dates.map((item) => {
    if (!item)
      return {
        value: '',
        text: '',
      }
    const timestamp = safeTimestamp(
      `${year}-${String(month).padStart(2, '0')}-${String(item).padStart(
        2,
        '0',
      )}`,
    )

    return {
      value: timestamp,
      text: item,
      disabled: timestamp < minDate || timestamp > maxDate,
    }
  })
}
