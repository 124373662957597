import { Breakpoint, ThemeColor } from '@/enums'
import { Close } from '@/icons'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

const showTooltip = keyframes`
  from {
    opacity:0
  }
`

export const Container = styled.div`
  position: relative;
  width: fit-content;
`

export const Content = styled.div`
  max-width: 500px;
  z-index: 10000;
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(5)} ${toThemePixels(6)} ${toThemePixels(5)}
    ${toThemePixels(5)};
  animation: ${showTooltip} 0.1s linear;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  background-color: ${ifProp(
    'dark',
    themeColor(ThemeColor.b10),
    themeColor(ThemeColor.b0),
  )};
  text-overflow: ellipsis;
  font-size: ${toThemePixels(3.5)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: ${toThemePixels(80)};
  }
`

export const StyledClose = styled(Close)`
  cursor: pointer;
`
