import {
  props,
  not,
  any,
  curry,
  allPass,
  identity,
  length,
  both,
  complement,
} from 'ramda'
import { isNumber } from './type'

export const anyProp = curry((fn, propNames, object) =>
  any(fn, props(propNames, object)),
)

export const anyPropMissing = curry(anyProp(both(complement(isNumber), not)))

export const notEmptyOrFalsy = allPass([identity, length])
