import { Column, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { theme } from '@/theme'
import { AdaptiveTooltip } from '@surein/ui'
import { AlarmClock, Info } from 'lucide-react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { CountdownRow } from '../styles'
import { useLanguage } from '@/utils'

interface LocalizedCountdownProps {
  targetDateInMs: number
  days: string
  hours: string
  minutes: string
  seconds: string
}

const LocalizedCountdown = ({
  days,
  hours,
  minutes,
  seconds,
}: LocalizedCountdownProps) => {
  const language = useLanguage()
  const dayIndicator = language === 'en' ? 'd' : 'T'
  return (
    <Typography color={ThemeColor.danger1} variant="footnote">
      {days}
      {dayIndicator} {hours}:{minutes}:{seconds}
    </Typography>
  )
}

const countdownRenderer = ({ formatted, completed }: CountdownRenderProps) => {
  if (completed) {
    return null
  } else {
    return <LocalizedCountdown {...formatted} targetDateInMs={0} />
  }
}

const CountdownTooltipContent = () => {
  const { t } = useTranslation()
  return (
    <Column gap="8px">
      <AlarmClock color={theme.color.danger1} />
      <Typography color={ThemeColor.glass900} variant="footnote">
        {t('countdownTooltipTitle')}
      </Typography>
      <Typography color={ThemeColor.glass500} variant="micro">
        {t('countdownTooltipSubtitle')}
      </Typography>
    </Column>
  )
}

interface CountdownComponentProps {
  targetDateInMs: number
  withTooltip?: boolean
}

export const CountdownComponent = ({
  targetDateInMs,
  withTooltip,
}: CountdownComponentProps) => {
  return withTooltip ? (
    <AdaptiveTooltip
      // FIXME: AdaptiveTooltip type is marked as string only
      // @ts-expect-error - we should fix AdaptiveTooltip types
      content={<CountdownTooltipContent />}
    >
      <CountdownRow alignItems="center" gap="4px" withTooltip={withTooltip}>
        <Countdown date={targetDateInMs} renderer={countdownRenderer} />
        <Info color={theme.color.danger1} size={12} />
      </CountdownRow>
    </AdaptiveTooltip>
  ) : (
    <CountdownRow alignItems="center" gap="4px">
      <Countdown date={targetDateInMs} renderer={countdownRenderer} />
    </CountdownRow>
  )
}
