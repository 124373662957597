import {
  createAccountV2,
  createConditionalRecommendation,
  createDraftRecommendation,
  createLead,
  createPublicCheckoutPaymentMethod,
  createPublicCheckoutPoa,
  doPublicCheckout,
  getPublicCart,
  getPublicCheckoutCompany,
  getPublicCheckoutKillerQuestions,
  getPublicCheckoutOverview,
  getPublicCheckoutPaymentMethod,
  getPublicCheckoutPoa,
  getPublicFile,
  getPublicKillerQuestionsKeys,
  retrieveConditionalRecommendation,
  updateDraftRecommendation,
  updatePublicCart,
  updatePublicCheckoutCompany,
  updatePublicCheckoutKillerQuestions,
  updatePublicCheckoutOverview,
  updatePublicCheckoutPaymentMethod,
  validateTemporaryToken,
} from '@/api'
import {
  previewResponseFile,
  returns,
  safeTimestamp,
  useInvalidateOnSuccessMutation,
} from '@/utils'
import { equals, evolve, head, pipe, when } from 'ramda'
import { useMutation, useQuery } from 'react-query'

export const useCreateDraftRecommendation = (categoryId: string) =>
  // TODO: remove any
  useMutation((values: any) =>
    createDraftRecommendation({ categoryId, ...values }),
  )

export const useUpdateDraftRecommendation = (
  confirmationId: string,
  categoryId: string,
) =>
  useInvalidateOnSuccessMutation(
    ['draftRecommendation', confirmationId],
    (values) =>
      updateDraftRecommendation({
        confirmationId,
        assessmentValues: { ...values, categoryId },
      }),
  )

export const useCreateAccountV2 = (categoryId: string) =>
  useMutation(
    // TODO: fix any
    ({
      email,
      phone,
      name,
      surname,
      assessmentValues,
      marketingPid,
      marketingMid,
      partner,
    }: any) =>
      createAccountV2({
        categoryId,
        assessmentValues,
        email,
        name,
        surname,
        phone,
        marketingPid,
        marketingMid,
        partner,
      }),
  )

export const useCreateLead = (categoryId: string) =>
  useMutation(
    // TODO: fix any
    ({ email, phone, name, surname, assessmentValues, address }: any) =>
      createLead({
        categoryId,
        email,
        name,
        surname,
        phone,
        assessmentValues,
        address,
      }),
  )

export const tempAuthQueryKeys = {
  recommendation: 'public-recommendation',
  cart: 'public-cart',
  killerQuestionsKeys: 'public-killerQuestionsKeys',
  killerQuestions: 'public-killerQuestions',
  paymentMethod: 'public-paymentMethod',
  companyDetails: 'public-companyDetails',
  overview: 'public-overview',
  poa: 'public-poa',
  checkout: 'checkout',
}

export const useValidateTempToken = (
  token: string,
  options: { enabled: boolean },
) => useQuery(['temp-token', token], validateTemporaryToken, options)

export const useCreateOrRetrieveConditionalRecommendation = (
  recommendationId: string,
) =>
  useQuery(tempAuthQueryKeys.recommendation, () => {
    if (recommendationId === null) return createConditionalRecommendation()
    return retrieveConditionalRecommendation(recommendationId)
  })

export const usePublicCart = () =>
  useQuery(tempAuthQueryKeys.cart, getPublicCart)

export const useUpdatePublicCart = () =>
  useInvalidateOnSuccessMutation(tempAuthQueryKeys.cart, updatePublicCart)

export const usePublicKillerQuestionsKeys = (categoryId: string) =>
  useQuery([tempAuthQueryKeys.killerQuestionsKeys, categoryId], () =>
    getPublicKillerQuestionsKeys(categoryId),
  )

export const usePublicCheckoutKillerQuestions = () =>
  useQuery(tempAuthQueryKeys.killerQuestions, getPublicCheckoutKillerQuestions)

export const useUpdateCheckoutKillerQuestions = () =>
  useInvalidateOnSuccessMutation(
    tempAuthQueryKeys.killerQuestions,
    updatePublicCheckoutKillerQuestions,
  )

export const usePublicCheckoutPaymentMethod = () =>
  // @ts-expect-error fix getPublicCheckoutPaymentMethod return type
  useQuery(tempAuthQueryKeys.paymentMethod, getPublicCheckoutPaymentMethod, {
    select: head,
  })

export const useSavePublicCheckoutPaymentMethod = () =>
  useInvalidateOnSuccessMutation(
    tempAuthQueryKeys.paymentMethod,
    ({ name, iban }) => createPublicCheckoutPaymentMethod({ name, iban }),
  )

export const useUpdatePublicCheckoutPaymentMethod = (paymentId: string) =>
  useInvalidateOnSuccessMutation(
    tempAuthQueryKeys.paymentMethod,
    ({ name, iban }) =>
      updatePublicCheckoutPaymentMethod({ name, iban, paymentId }),
  )

const transformPublicCompany = evolve({
  name: when(equals('company name'), returns('')),
  address: evolve({
    city: when(equals('city'), returns('')),
    street: when(equals('street'), returns('')),
  }),
})

export const usePublicCheckoutCompany = () =>
  useQuery(tempAuthQueryKeys.companyDetails, getPublicCheckoutCompany, {
    select: transformPublicCompany,
  })

export const useUpdatePublicCheckoutCompany = () =>
  useInvalidateOnSuccessMutation(
    tempAuthQueryKeys.companyDetails,
    ({ name, address }) => updatePublicCheckoutCompany({ name, address }),
  )

const transformCheckoutOverview = pipe(
  head,
  // @ts-expect-error pls rewrite this pipe to native JS
  evolve({ startDate: safeTimestamp }),
)

export const usePublicCheckoutOverview = () =>
  // @ts-expect-error fix getPublicCheckoutOverview return type
  useQuery(tempAuthQueryKeys.overview, getPublicCheckoutOverview, {
    select: transformCheckoutOverview,
  })

export const useUpdatePublicCheckoutOverview = (
  recommendationProductId: string,
) =>
  useInvalidateOnSuccessMutation(tempAuthQueryKeys.overview, ({ startDate }) =>
    updatePublicCheckoutOverview({ recommendationProductId, startDate }),
  )

export const usePublicCheckoutPoa = () =>
  useQuery(tempAuthQueryKeys.poa, getPublicCheckoutPoa)

export const useDownloadPublicFile = () =>
  useMutation(getPublicFile, {
    onSuccess: previewResponseFile,
  })

export const useCreatePublicCheckoutPoa = () =>
  useInvalidateOnSuccessMutation(
    tempAuthQueryKeys.poa,
    ({ birthdate, signature }) =>
      createPublicCheckoutPoa({ birthdate, signature }),
  )

export const useDoPublicCheckout = () =>
  useInvalidateOnSuccessMutation(tempAuthQueryKeys.checkout, () =>
    doPublicCheckout(),
  )
