import { PlusCircleLine } from '@/icons'
import { useFormContext } from '@/providers'
import { dynamicObjectPropType, oneChild, useTranslationSection } from '@/utils'
import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
import { last } from 'ramda'
import { Button, SecondaryButton } from '../../atoms'
import { FlexEnd } from '../../styled'
import { AddButtonContainer } from './styles'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')

  return {
    delete: commonTranslations('delete'),
  }
}

export const FormList = ({ name, children, initialValues, addText }) => {
  const { values, customValidate } = useFormContext()
  const messages = useMessages()

  const handleAdd = (onSuccess) => () => customValidate({ onSuccess })

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <>
          {values[name].map(({ itemKey = 0 }, key) => (
            <div key={itemKey}>
              {children({ parent: name, index: key })}
              {values[name].length > 1 && (
                <FlexEnd>
                  <Button
                    onClick={() => remove(key)}
                    size="small"
                    variant="outlined"
                  >
                    {messages.delete}
                  </Button>
                </FlexEnd>
              )}
            </div>
          ))}
          <AddButtonContainer>
            <SecondaryButton
              block
              icon={PlusCircleLine}
              onClick={handleAdd(() =>
                push({
                  itemKey: (last(values.insurances).itemKey || 0) + 1,
                  ...initialValues,
                }),
              )}
            >
              {addText}
            </SecondaryButton>
          </AddButtonContainer>
        </>
      )}
    </FieldArray>
  )
}

FormList.propTypes = {
  addText: PropTypes.string.isRequired,
  children: oneChild.isRequired,
  initialValues: dynamicObjectPropType,
  name: PropTypes.string.isRequired,
}
