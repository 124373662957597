import { Breakpoint } from '@/enums'
import { themeBreakpointDown, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const StyledIFrame = styled.iframe`
  width: 100vw;
  height: calc(100vh + 100px);
  position: fixed;
  top: 70px;
  margin: 0 -24px;
  margin-top: -150px;
  @media (max-width: 428px) {
    margin: ${toThemePixels(6)} 0 0 0;
  }
  ${themeBreakpointDown(Breakpoint.tab)} {
    top: 0;
  }
`
