import {
  AdvisorBubble,
  BasicFormItem,
  Column,
  FormDatepicker,
  FormInputField,
  Row,
  SignatureCanvas,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { ShieldCheck } from '@/icons'
import { useSegment } from '@/modules/analytics'
import { StyledLinkTypography } from '@/pages/SelfAssessmentPage/steps/styles'
import { useContactModal, useFormContext, useSnackbar } from '@/providers'
import { useSignPoa } from '@/services'
import { themeColor, toThemePixels } from '@/utils'
import { Alert } from '@surein/ui'
import { InfoIcon } from 'lucide-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { StyledRow } from '../styles'
import { isMd, useValidateCheckoutStep } from './helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    birthdate: t('birthday'),
    contactMD: t('snackBars.success.contactMD'),
    poaAdvisorBubbleText: t('poaAdvisorBubbleText'),
    signPoaErrorHint: t('signPoaErrorHint'),
    general: t('validations.general'),
    over18: t('validations.over18'),
  }
}

const StyledSSL = styled(Row)`
  width: fit-content;
  padding: ${toThemePixels(2)};
  background: ${themeColor(ThemeColor.success4)};
  border: 1px solid ${themeColor(ThemeColor.success3)};
  border-radius: ${toThemePixels(2)};
  margin-bottom: 4px;
`

const StyledLink = styled(StyledLinkTypography)`
  font-weight: 700;
`

const SignPoaForm = () => {
  const messages = useMessages()
  const { errors } = useFormContext()
  const { openContact } = useContactModal()

  return (
    <Column gap="16px">
      {errors.signature && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={
            <Trans
              components={
                <StyledLink onClick={openContact} variant="captionB" />
              }
              i18nKey={messages.signPoaErrorHint}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}
      <AdvisorBubble text={messages.poaAdvisorBubbleText} />
      <Column gap="4px">
        <StyledSSL alignItems="center" gap="4px">
          <ShieldCheck color={ThemeColor.b0} />
          <Typography singleLine variant="badgeText">
            <Trans i18nKey="mockRecommendationModalText.ssl" />
          </Typography>
        </StyledSSL>
        <BasicFormItem
          component={SignatureCanvas}
          name="signature"
          showErrorHelperText={false}
        />
        <StyledRow>
          <FormInputField
            label={messages.name}
            name="fullname"
            showValidCheck
          />
          <FormDatepicker
            label={messages.birthdate}
            max={new Date()}
            name="birthdate"
            showCalendar={false}
          />
        </StyledRow>
      </Column>
    </Column>
  )
}

export const SignPoaStep = ({ setButtonState, companyId, onNext }) => {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { user } = useOutletContext()
  const { values } = useFormContext()
  const analytics = useSegment()

  const signPoaMutation = useSignPoa(companyId)

  const handleFinishSignPoa = async () => {
    await analytics.track('app_poa_signed')
    onNext()
  }

  const handleSubmit = () => {
    if (isMd(companyId, user)) {
      signPoaMutation.mutate(values, {
        onSuccess: handleFinishSignPoa,
      })
    } else {
      snackbar.error(messages.contactMD)
    }
  }

  const handleNext = useValidateCheckoutStep(handleSubmit)

  useEffect(() => {
    setButtonState({ onClick: handleNext, loading: signPoaMutation.isLoading })

    return () => {
      setButtonState({ loading: false })
    }
  }, [values, signPoaMutation.isLoading])

  return <SignPoaForm setButtonState={setButtonState} />
}

SignPoaStep.propTypes = {
  companyId: PropTypes.string,
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
