import {
  BackComponent,
  ButtonLink,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Row,
  TextWithLink,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import {
  addressObjectPropType,
  makeAddress,
  mapWithKey,
  useGoToPath,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes, { arrayOf } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { getCanEditCompany } from '../helpers'
import { ButtonContainer, ItemContainer, PageTitle } from '../styles'
import { LinkWithPermissions } from './LinkWithPermissions'

const useMessages = () => {
  const { t } = useTranslation()
  const profileTranslations = useTranslationSectionObject('profile.menu')
  const commonTranslations = useTranslationSection('common')

  return {
    pageTitle: profileTranslations.companies,
    addCompany: t('add_company_or_location_text'),
    addLocation: t('add_location'),
    edit: commonTranslations('edit'),
    advisorText: t('company_advisor_text'),
  }
}

const LocationListItem = ({ locationId, name, address }) => {
  const messages = useMessages()
  const goToEditLocation = useGoToPath(paths.editLocationWithKey(locationId))

  return (
    <CompanyCardItem>
      <ItemContainer>
        <Column gap="4px">
          <Typography variant="p2Body">{name}</Typography>
          <Typography color={ThemeColor.b50} variant="badgeText">
            {makeAddress(address)}
          </Typography>
        </Column>
        <ButtonContainer>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={goToEditLocation}
            size="small"
          >
            {messages.edit}
          </ButtonLink>
        </ButtonContainer>
      </ItemContainer>
    </CompanyCardItem>
  )
}

const ProfileCompanyCard = ({ locations, companyId: id, name }) => {
  const messages = useMessages()
  const goToNewLocation = useGoToPath(paths.addLocationWithKey(id))

  const { user } = useOutletContext()
  const canEditCompany = getCanEditCompany(user.companies, id)

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <Row alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="captionR">{name}</Typography>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!canEditCompany}
            onClick={goToNewLocation}
            size="small"
          >
            {`+ ${messages.addLocation}`}
          </LinkWithPermissions>
        </Row>
      </CompanyCardHeader>
      {mapWithKey(LocationListItem, 'locationId', locations)}
    </CompanyCard>
  )
}

export const ProfileCompanies = () => {
  const { companies, hasProtectedAccess } = useOutletContext()
  const messages = useMessages()

  const goToAddCompany = useGoToPath(paths.addCompany)

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.pageTitle}</Typography>
        </PageTitle>
        <Column gap="16px">
          {mapWithKey(ProfileCompanyCard, 'companyId', companies || [])}
          <TextWithLink>
            <Trans
              components={
                <LinkWithPermissions
                  color={ComponentColors.primary}
                  disabled={!hasProtectedAccess}
                  onClick={goToAddCompany}
                  size="small"
                />
              }
              i18nKey={messages.addCompany}
            />
          </TextWithLink>
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}

ProfileCompanyCard.propTypes = {
  companyId: PropTypes.string,
  locations: arrayOf(
    PropTypes.shape({
      locationId: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
}
LocationListItem.propTypes = {
  address: PropTypes.shape(addressObjectPropType),
  locationId: PropTypes.string,
  name: PropTypes.string,
}
