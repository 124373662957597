import { PrimaryButton } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { ArrowRightLine, CheckThin } from '@/icons'
import { IconBackground } from '@/pages/SelfAssessmentPage/public/styles'
import { themeBreakpointUp } from '@/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useStepper } from '../../hooks/useStepper'

const SuccessStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 86px);

  ${themeBreakpointUp(Breakpoint.md)} {
    height: 77vh;
  }
`

const ThankYouContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  gap: 1rem;
`
const TextAlign = styled.p`
  text-align: center;
`
const Footer = styled.div`
  padding: 24px;
`
export const SuccessStep = () => {
  const { t } = useTranslation()
  const { onNext } = useStepper()

  return (
    <SuccessStepContainer>
      <ThankYouContainer>
        <IconBackground>
          <CheckThin color={ThemeColor.success3} height="36px" width="36px" />
        </IconBackground>
        <h2>{t('yearlyAssessment.successStep-title')}</h2>
        <TextAlign>{t('yearlyAssessment.successStep-description')}</TextAlign>
      </ThankYouContainer>
      <Footer>
        <PrimaryButton onClick={onNext} suffixIcon={ArrowRightLine}>
          {t('yearlyAssessment.successStep-cta')}
        </PrimaryButton>
      </Footer>
    </SuccessStepContainer>
  )
}
