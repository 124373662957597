import { LoadingIcon } from '@/icons'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
100% {
    transform: rotate(360deg);
}
`

export const StyledLoadingIcon = styled(LoadingIcon)`
  animation: ${rotate} 1.1s linear infinite;
`
