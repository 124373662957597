import { DragAndDrop } from '..'
import { BasicFormItem } from './BasicFormItem'

interface FormDragAndDropProps {
  name: string
  label?: string
  initialValue?: any
  info?: string
  helperText?: string
  ignoreErrorKeys?: string[]
  layout?: any
  noMargin?: boolean
  uploaderText?: string
}

export const FormDragAndDrop = (props: FormDragAndDropProps) => (
  <BasicFormItem component={DragAndDrop} {...props} />
)
