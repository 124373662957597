import {
  BackComponent,
  CheckBox,
  ContactExpert,
  FormInput,
  FormPhone,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SuccessView,
  Typography,
} from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { useSteps } from '@/pages/Profile/helpers'
import {
  ButtonWrapper,
  ReferralWrapper,
  PageHeader,
  CheckboxWrapper,
} from '@/pages/Profile/styles'
import { FormProvider } from '@/providers'
import { paths } from '@/routes/paths'
import { useSendMessage } from '@/services'
import {
  useGoToPath,
  useScrollTop,
  useTranslationSectionObject,
  useIsMobile,
} from '@/utils'
import { keys } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './validators'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    referral: {
      ...useTranslationSectionObject(`profile.referral`),
    },
    common: { ...useTranslationSectionObject('common') },
    validations: useTranslationSectionObject('validations'),
    advisorText: t('recommend_advisor_text'),
  }
}

export const ReferralPage = () => {
  const goToDashboard = useGoToPath(paths.dashboard)
  const messages = useMessages()
  const { nextStep, isActive } = useSteps(1)
  const createmutation = useSendMessage()
  const formText = (obj) =>
    keys(obj)
      .map((k) => `${k}: "${obj[k]}"`)
      .join(', ')
  const { scrollTop } = useScrollTop()
  const onFormSubmit = (values) => {
    createmutation
      .mutateAsync(`referral program request for ${formText(values)}`)
      .then(() => {
        nextStep()
        scrollTop()
      })
  }
  const [iAgree, setIAgree] = useState(false)
  const toggleIAgree = () => setIAgree(!iAgree)
  const { isMobile } = useIsMobile(Breakpoint.sm)

  return (
    <>
      <PageMainContent>
        <BackComponent />
        {isActive(1) ? (
          <ReferralWrapper>
            <PageHeader>
              <Typography variant="h4">{messages.referral.title}</Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {messages.referral.subtitle}
              </Typography>
            </PageHeader>
            <FormProvider
              onSubmit={onFormSubmit}
              validationSchema={validationSchema(messages.validations)}
            >
              <FormInput
                label={messages.referral.company.label}
                name="company"
              />
              <FormInput
                label={messages.referral.contactPerson.label}
                name="Owner"
              />
              <FormInput
                label={messages.referral.contactEmail.label}
                name="Email"
              />
              <FormPhone
                label={messages.referral.contactPhone.label}
                name="Phone"
              />
              <CheckboxWrapper>
                <CheckBox
                  checked={iAgree}
                  label={messages.referral.iConfirm}
                  onChange={toggleIAgree}
                />
              </CheckboxWrapper>
              <ButtonWrapper>
                <PrimaryButton
                  block={isMobile}
                  disabled={!iAgree}
                  type="submit"
                >
                  {messages.referral.btn}
                </PrimaryButton>
              </ButtonWrapper>
            </FormProvider>
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.referral.referalCondition}
            </Typography>
          </ReferralWrapper>
        ) : (
          <SuccessView
            buttonText={messages.referral.success.btn}
            onButtonClick={goToDashboard}
            subtitle={messages.referral.success.subtitle}
            title={messages.referral.success.title}
          />
        )}
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
