import { anyPropType, oneOrManyChildren, renderableType } from '@/utils'

export const WithWrapper = ({
  wrapper: Wrapper,
  children,
  wrapWhen,
  ...props
}) => {
  if (wrapWhen) {
    return <Wrapper {...props}>{children}</Wrapper>
  }

  return children
}

WithWrapper.propTypes = {
  children: oneOrManyChildren,
  wrapper: renderableType,
  wrapWhen: anyPropType,
}
