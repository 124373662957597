import { complement, curry, map, propEq } from 'ramda'
// eslint-disable-next-line import/no-cycle
import { isUndefined } from './type'

export const noop = () => {}

export const call = (fn) => (...e) => fn(...e)

export const callWithoutParams = (fn) => () => fn()

export const returns = (x) => () => x

export const pipeWithResult = (...func) => (...p) => {
  const [fn, ...rest] = func

  if (isUndefined(fn)) return p[0]

  return pipeWithResult(...rest)(fn(...p), ...p)
}

export const modifyParams = curry((fn, modifier, params) =>
  fn(...map(modifier, params)),
)

export const toAll = (...func) => (...params) => {
  const [fn, ...rest] = func

  if (isUndefined(fn)) return

  fn(...params)
  toAll(...rest)(...params)
}

export const spread = (fn) => (e) => fn(...e)

export const packArgs = (fn) => (...e) => fn(e)

export const propNotEq = curry((propName, value) =>
  complement(propEq(propName, value)),
)

export const ifUndefined = curry((defaultValue, value) =>
  value === undefined ? defaultValue : value,
)

export const returnIfMinus1 = curry((newVal, check) => {
  if (check === -1) return newVal
  return check
})
