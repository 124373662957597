import {
  dynamicObjectPropType,
  isObject,
  makePathnameWithLanguage,
  useLanguage,
} from '@/utils'
import PropTypes from 'prop-types'
import { evolve, ifElse } from 'ramda'
import { Navigate } from 'react-router-dom'

const makeRedirectPath = (language) =>
  ifElse(
    isObject,
    evolve({ pathname: makePathnameWithLanguage(language) }),
    makePathnameWithLanguage(language),
  )

export const Redirect = ({ to, state }) => {
  const language = useLanguage()

  const makeTo = makeRedirectPath(language)

  return <Navigate state={state} to={makeTo(to)} />
}

Redirect.propTypes = {
  state: dynamicObjectPropType,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  ]).isRequired,
}
