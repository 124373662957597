/* eslint-disable no-nested-ternary */
import { Button, Column, FormItem, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  themeColor,
  useModal,
} from '@/utils'
import { AutoComplete, Skeleton } from '@surein/ui'
import { Trash } from 'lucide-react'
import PropTypes from 'prop-types'
import { useState, useCallback, useMemo, useEffect, cloneElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { ModalWrapper } from './ModalWrapper'
import { DropdownModalTrigger } from './styles'

const Card = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  gap: 32px;
`

const StyleTrashButton = styled(Button)`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor(ThemeColor.danger4)};
  user-select: none;
  transition: 0.2s;
  padding: 12px;
  &:hover {
    border-color: ${ifProp('disabled', 'none', themeColor(ThemeColor.danger2))};
  }

  &:active {
    span {
      color: ${ifProp('disabled', 'none', themeColor(ThemeColor.b100))};
    }
  }
`

export const findBusinessType = (option, verticalsMap, getVerticalLabel) => {
  const verticalCode = Object.keys(verticalsMap).find((key) =>
    verticalsMap[key].includes(option.value),
  )

  if (!verticalCode || option.created) {
    return {
      label: getVerticalLabel?.('other'),
      value: 'other',
    }
  }
  return {
    label: getVerticalLabel?.(verticalCode),
    value: verticalCode,
  }
}

const BusinessTypeCard = ({ item, onClear }) => {
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const type = useMemo(
    () =>
      findBusinessType(
        item,
        locationTypesData?.verticalsMap ?? {},
        locationTypesData?.getVerticalLabel,
      ),
    [item.value, locationTypesData],
  )

  return (
    <Card>
      <Column width="100%">
        {locationTypesLoading ? (
          <Column>
            <Skeleton count={2} withParagraphEnding />
          </Column>
        ) : (
          <>
            <Typography responsive variant="p1Body">
              {item.label}
            </Typography>
            <Typography color={ThemeColor.b50} responsive variant="badgeText">
              {type?.label ?? t('locationTypes.other')}
            </Typography>
          </>
        )}
      </Column>
      <StyleTrashButton onClick={onClear}>
        <Trash color={theme.color[ThemeColor.danger1]} size="16px" />
      </StyleTrashButton>
    </Card>
  )
}

BusinessTypeCard.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onClear: PropTypes.func.isRequired,
}

const AutoCompleteModalWrapper = ({ children, shouldFocusOnRender }) => {
  const { open, close, isOpen } = useModal()
  const clonedChildren = cloneElement(children, { autoFocus: true })

  useEffect(() => {
    if (shouldFocusOnRender) open()
  }, [])

  return (
    <>
      <DropdownModalTrigger onClick={open}>
        {!isOpen && children}
      </DropdownModalTrigger>
      <ModalWrapper close={close} isOpen={isOpen}>
        {clonedChildren}
      </ModalWrapper>
    </>
  )
}

AutoCompleteModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  shouldFocusOnRender: PropTypes.bool,
}

export const FormAutoCompleteItem = ({
  name,
  label,
  info,
  helperText,
  options,
  ignoreErrorKeys,
  layout,
  initialValue,
  onValueChange,
  onClear,
  omitOptions,
  allowCreate,
  isLoading,
  onBlur,
  shouldRenderInModal,
  shouldFocusOnRender,
  sortFn,
}) => {
  const { t } = useTranslation()

  const [selected, setSelected] = useState(initialValue)
  const handleSelect = useCallback(
    (option) => {
      setSelected(option)
      if (onValueChange) {
        onValueChange(option)
      }
    },
    [onValueChange],
  )

  useEffect(() => {
    setSelected(initialValue)
  }, [initialValue])

  const handleClear = useCallback(() => {
    setSelected(null)
    if (onClear) {
      onClear()
    }
  }, [onClear])

  const handleBlur = (fieldName) => {
    if (onBlur) {
      onBlur(fieldName)
    }
  }

  return selected ? (
    <BusinessTypeCard item={selected} onClear={handleClear} />
  ) : shouldRenderInModal ? (
    <AutoCompleteModalWrapper shouldFocusOnRender={shouldFocusOnRender}>
      <FormItem
        helperText={helperText}
        ignoreErrorKeys={ignoreErrorKeys}
        info={info}
        initialValue={initialValue}
        label={label}
        layout={layout}
        name={name}
        noMargin
      >
        <AutoComplete
          createNewLabel={allowCreate ? t('createNewOption') : undefined}
          emptyMessage={t('noResultsFound')}
          isLoading={isLoading}
          name={name}
          omitOptions={omitOptions}
          onBlur={() => handleBlur(name)}
          onValueChange={handleSelect}
          options={options}
          placeholder={t('businessTypePlaceholder')}
          sortFn={sortFn}
        />
      </FormItem>
    </AutoCompleteModalWrapper>
  ) : (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      layout={layout}
      name={name}
      noMargin
    >
      <AutoComplete
        createNewLabel={allowCreate ? t('createNewOption') : undefined}
        emptyMessage={t('noResultsFound')}
        isLoading={isLoading}
        name={name}
        omitOptions={omitOptions}
        onBlur={() => handleBlur(name)}
        onValueChange={handleSelect}
        options={options}
        placeholder={t('businessTypePlaceholder')}
        sortFn={sortFn}
      />
    </FormItem>
  )
}

FormAutoCompleteItem.propTypes = {
  allowCreate: PropTypes.bool,
  helperText: PropTypes.string,
  ignoreErrorKeys: dynamicArrayPropType,
  info: PropTypes.string,
  initialValue: dynamicObjectPropType,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string.isRequired,
  omitOptions: dynamicArrayPropType,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onValueChange: PropTypes.func,
  options: dynamicArrayPropType,
  shouldFocusOnRender: PropTypes.bool,
  shouldRenderInModal: PropTypes.bool,
  sortFn: PropTypes.func,
}
