import { ThemeColor } from '@/enums'
import { Checked } from '@/icons'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Spinner, Typography } from '../../atoms'
import { WithTimeoutRendering } from '../../hoc'
import { Column } from '../../styled'
import { IconWrapper, Step } from './styles'

export const ProgressiveLoader = ({ steps = [], delay = 1400, onComplete }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onComplete()
    }, delay * (steps.length + 1))

    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return (
    <Column>
      {steps?.map((item, i) => (
        <Step>
          <Typography color={ThemeColor.b50} variant="captionB">
            {item}
          </Typography>
          <IconWrapper>
            <WithTimeoutRendering delay={delay * i}>
              <WithTimeoutRendering
                delay={delay}
                renderIfNotComplete={<Spinner />}
              >
                <Checked fill="transparent" size="button" />
              </WithTimeoutRendering>
            </WithTimeoutRendering>
          </IconWrapper>
        </Step>
      ))}
    </Column>
  )
}

ProgressiveLoader.propTypes = {
  delay: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string),
}
