import { StepOnSubmitValues } from '@/modules/addInsurances/components/helpers/types'
import { InsuranceCarrierStep } from '@/modules/addInsurances/components/steps/InsuranceCarrierStep'
import { InsuranceCategoryStep } from '@/modules/addInsurances/components/steps/InsuranceCategoryStep'
import { LocationSelectionStep } from '@/modules/addInsurances/components/steps/LocationSelectionStep'
import { PolicyNumberStep } from '@/modules/addInsurances/components/steps/PolicyNumberStep'
import { SignPOAStep } from '@/modules/addInsurances/components/steps/SignPOAStep'
import { SuccessStep } from '@/modules/addInsurances/components/steps/SuccessStep'
import { UploadDocumentsStep } from '@/modules/addInsurances/components/steps/UploadDocumentsStep'
import {
  StepperProvider,
  useStepper,
} from '@/modules/addInsurances/hooks/useStepper'
import { useSnackbar } from '@/providers'
import {
  useCreateMultipleInsurances,
  useUploadInsuranceFiles,
} from '@/services'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, MainContent } from './styles'

export type Step = (props: StepProps) => JSX.Element

interface BaseStepperProps {
  success: boolean
  isLoading: boolean
}

interface DetermineCurrentStepProps {
  success: boolean
  steps: Step[]
  currentStep: number
  poaSigned: boolean
}

export interface StepProps {
  isSubmitting: boolean
}

const makeSteps = (shouldShowLocationSelection: boolean): Step[] =>
  [
    shouldShowLocationSelection && LocationSelectionStep,
    InsuranceCategoryStep,
    InsuranceCarrierStep,
    PolicyNumberStep,
    UploadDocumentsStep,
  ].filter((step): step is Step => Boolean(step)) // filter out falsy values for conditional steps

const determineCurrentStep = ({
  success,
  steps,
  currentStep,
  poaSigned,
}: DetermineCurrentStepProps) => {
  if (success && !poaSigned) {
    return SignPOAStep
  }
  if (success) {
    return SuccessStep
  }
  return steps[currentStep]
}

const BaseStepper = ({ success, isLoading }: BaseStepperProps) => {
  const { steps, currentStep, companyData } = useStepper()
  const CurrentStep = determineCurrentStep({
    success,
    steps,
    currentStep,
    poaSigned: companyData?.poaSigned ?? false,
  })

  return (
    <MainContent>
      <Container>{<CurrentStep isSubmitting={isLoading} />}</Container>
    </MainContent>
  )
}

export const AddInsuranceContractsPage = () => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const createMultipleInsurancesMutation = useCreateMultipleInsurances()
  const uploadInsuranceFilesMutation = useUploadInsuranceFiles('')
  const snackbar = useSnackbar()

  const isLoading =
    createMultipleInsurancesMutation.isLoading ||
    uploadInsuranceFilesMutation.isLoading

  const handleSuccess = async (values: Partial<StepOnSubmitValues>) => {
    const files = (values.files || []).map(({ file }) => file)

    try {
      const { created } = await createMultipleInsurancesMutation.mutateAsync({
        locationId: values.locationData!.locationId,
        data: [
          {
            carrierId: values.carrierId!,
            policyNumber: values.policyNumber,
            categoryId: values.categoryId!,
            contractStatus: 'transfer-initiated',
          },
        ],
      })
      if (files.length === 0) {
        setSuccess(true)
        return
      }
      await uploadInsuranceFilesMutation.mutateAsync({
        id: created[0],
        files,
        // file category and not categoryId
        category: 'contract',
      })
      setSuccess(true)
    } catch (_error) {
      snackbar.error(t('snackbars.error.generalMsg'))
    }
  }

  return (
    <StepperProvider onFinish={handleSuccess} makeSteps={makeSteps}>
      <BaseStepper success={success} isLoading={isLoading} />
    </StepperProvider>
  )
}
