import {
  AdvisorBubble,
  Column,
  FormPhone,
  Header,
  ImageFromCdn,
  ModalBase,
  Row,
  TrustPartners,
  Typography,
} from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { Shield } from '@/icons'
import { useSegment } from '@/modules/analytics'
import { BasePage } from '@/pages/RecommendationPage/BasePage'
import { mockResponse } from '@/pages/RecommendationPage/helpers'
import { FormProvider, useContactModal } from '@/providers'
import { paths } from '@/routes/paths'
import { tempAuthQueryKeys, useCreateAccountV2 } from '@/services'
import { theme } from '@/theme'
import {
  deleteLocalStorageItems,
  getLocalStorageItem,
  noop,
  setLocalStorageItem,
  themeBreakpointDown,
  themeColor,
  useGoToPath,
  useIsMobile,
} from '@/utils'
import { Button } from '@surein/ui'
import jwtDecode from 'jwt-decode'
import { ArrowRight, ChevronLeftIcon, Phone } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { makeStoredAssessmentValuesKey } from '../helpers'
import {
  ContactDataImageTextWrapper,
  ContactDataImageWrapper,
  OfferReadyImageContainer,
} from '../styles'
import { StyledSSL } from './styles'
import { validationSchema } from './validationSchema'
import { useMemo } from 'react'

const ModalContent = styled(Column)`
  min-height: 550px;
  width: 520px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: auto;
    height: 100%;
  }
`

const ModalHeader = styled(Column)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px;
  gap: 16px;
  background-color: ${themeColor(ThemeColor.b0)};
  border-radius: 16px 16px 0 0;
  z-index: 10;

  img {
    width: 74px;
    height: 20px;
    border-radius: 0;
  }
`

const Content = styled(Column)<{ hasError?: boolean }>`
  flex-grow: 1;
  padding: ${ifProp('hasError', '56px 0 142px 0', '56px 0 80px 0')};
  gap: 24px;
`

const Footer = styled(Column)`
  padding: 0 16px 36px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 16px;
  border-radius: 0 0 16px 16px;
  background: linear-gradient(
    0,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(0, 212, 255, 0) 100%
  );
`

const FooterButtons = styled(Row)`
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    opacity: 1;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    button:last-child {
      width: 100%;
    }
  }
`

interface MockRecommendationProps {
  categoryId: string
  onBack: () => void
  onFinish: () => void
  transformSubmitValues?: (values: any) => any
}

// eslint-disable-next-line import/no-unused-modules
export const MockRecommendation = ({
  categoryId,
  onBack,
  onFinish,
  transformSubmitValues,
}: MockRecommendationProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const valuesLocalStorageKey = makeStoredAssessmentValuesKey(categoryId)
  const assessmentValues = getLocalStorageItem(valuesLocalStorageKey)
  const createAccountMutation = useCreateAccountV2(categoryId)
  const { isMobile } = useIsMobile()
  const goToRoot = useGoToPath(paths.authRedirect, undefined, {
    login_hint: assessmentValues.contactData.email,
  })
  const { openContact } = useContactModal()
  const hasFormError = useMemo(() => {
    return createAccountMutation.isError
  }, [createAccountMutation.isError])

  const [searchParams] = useSearchParams()
  const analytics = useSegment()

  // Marketing specific params
  const { marketingPid, marketingMid } = useOutletContext<{
    marketingPid: string
    marketingMid: string
  }>()
  const partner = searchParams.get('partner')

  const localStorageKeys = [
    `${categoryId}-values`,
    `${categoryId}-current-step`,
    `${categoryId}-confirmation-id`,
    `${categoryId}-recommendation-id`,
  ]

  const handleSubmit = ({ phone }: { phone: string }) => {
    const [name, ...surname] = assessmentValues.contactData.name.split(' ')
    const formattedSurname = surname.join(' ') || '_'

    const body = {
      assessmentValues,
      name,
      surname: formattedSurname,
      phone,
      email: assessmentValues.contactData.email,
      partner,
      marketingPid,
      marketingMid,
    }

    if (transformSubmitValues) {
      body.assessmentValues = transformSubmitValues(body.assessmentValues)
    }

    createAccountMutation.mutate(body, {
      onSuccess: async (data: { accessToken: string }) => {
        deleteLocalStorageItems(localStorageKeys)
        queryClient.resetQueries(tempAuthQueryKeys.recommendation)
        if (data?.accessToken) {
          const { user_id: userId } = jwtDecode<{ user_id: string }>(
            data?.accessToken,
          )
          setLocalStorageItem('access-token', data?.accessToken)
          analytics.identify(userId, {
            email: body.email,
            first_name: body.name,
            last_name: body.surname,
          })
          await analytics.track('ssf_recommendation_phone_number')
          onFinish()
        }
      },
      // @ts-expect-error fix type on the action - our API client is overwriting response types in interceptors
      onError: async (data: { response?: { data?: { code: string } } }) => {
        if (data.response?.data?.code === 'USER_ALREADY_EXISTS') {
          await analytics.track('ssf_returning_user')
          goToRoot()
        } else {
          await analytics.track('ssf_general_error')
        }
      },
    })
  }

  return (
    <>
      <Header />
      <BasePage
        data={mockResponse}
        noBack
        onChoose={noop}
        handleFileClick={noop}
      />

      <ModalBase isOpen padding="16px" closable={false}>
        <ModalContent>
          <ModalHeader>
            <Row alignItems="center" justifyContent="space-between">
              <ImageFromCdn
                name="SureinLogo2024"
                onClick={noop}
                type="svg"
                width={74}
              />
              <Button
                icon={() => (
                  <Phone color={theme.color[ThemeColor.success1]} size={16} />
                )}
                onClick={openContact}
                size="sm"
                variant="muted"
              />
            </Row>
          </ModalHeader>
          <Content hasError={hasFormError}>
            {isMobile && (
              <ContactDataImageWrapper>
                <OfferReadyImageContainer>
                  <ImageFromCdn
                    fallbackType="jpg"
                    name="offer-example-blurred"
                    type="jpg"
                  />
                  <ImageFromCdn
                    fallbackType="jpg"
                    name="carrierBubbles"
                    type="webp"
                  />
                </OfferReadyImageContainer>
                <ContactDataImageTextWrapper>
                  <Typography bold variant="h5">
                    {t('contactDataImageText.title')}
                  </Typography>
                  <Typography color={ThemeColor.b50} variant="footnote">
                    {t('contactDataImageText.subtitle')}
                  </Typography>
                </ContactDataImageTextWrapper>
              </ContactDataImageWrapper>
            )}
            <Typography bold variant="h4">
              {t('mockRecommendationModalText.title')}
            </Typography>
            {!isMobile && (
              <AdvisorBubble
                text={t('mockRecommendationModalText.advisorText')}
              />
            )}
            <FormProvider
              onSubmit={handleSubmit}
              validationSchema={validationSchema({
                phone: t('validations.phone'),
              })}
            >
              <Column gap="24px">
                <FormPhone
                  label={t('phone')}
                  name="phone"
                  noMargin
                  placeholder="0123 456 7890"
                />
                <StyledSSL alignItems="center" gap="4px">
                  <Shield width={24} height={24} color={ThemeColor.success1} />
                  <Typography variant="footnote">
                    {t('mockRecommendationModalText.ssl')}
                  </Typography>
                </StyledSSL>
                {isMobile && (
                  <AdvisorBubble
                    text={t('mockRecommendationModalText.advisorText')}
                  />
                )}
                <Footer>
                  <FooterButtons>
                    <Button
                      disabled={createAccountMutation.isLoading}
                      icon={() => <ChevronLeftIcon size={24} />}
                      onClick={onBack}
                      shape="square"
                      size="md"
                      variant="muted"
                    />
                    <Button
                      loading={createAccountMutation.isLoading}
                      shape="square"
                      type="submit"
                      variant="primary"
                      suffixIcon={ArrowRight}
                    >
                      {t('mockRecommendationModalText.cta')}
                    </Button>
                  </FooterButtons>
                  {!isMobile && (
                    <Row justifyContent="center">
                      <TrustPartners mode="dark" hide={{ ssl: true }} />
                    </Row>
                  )}
                </Footer>
              </Column>
            </FormProvider>
          </Content>
        </ModalContent>
      </ModalBase>
    </>
  )
}
