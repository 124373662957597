import { componentColorPropType, oneOrManyChildren } from '@/utils'
import PropTypes from 'prop-types'
import { buttonSizePropType } from '../ButtonBase'
import { Container } from './styles'

export const ButtonLink = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
)

ButtonLink.propTypes = {
  children: oneOrManyChildren,
  color: componentColorPropType,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  reverted: PropTypes.bool,
  size: buttonSizePropType,
}
