import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  max-width: ${toThemePixels(13)};
  max-height: ${toThemePixels(13)};
  padding: ${toThemePixels(4)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${toThemePixels(1)};
  border-radius: ${toThemePixels(2)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  background: ${themeColor(ThemeColor.b10)};
`
