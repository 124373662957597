import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Input, Row } from '../../styled'

export const Content = styled(Column)`
  max-width: 500px;
  gap: ${toThemePixels(4)};
  margin: 0 auto;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${toThemePixels(3)};
  margin-top: ${toThemePixels(2)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
  }
`

export const StyledInput = styled(Input)`
  color: ${themeColor(ThemeColor.b50)};
`

export const PageHeader = styled(Column)`
  gap: ${toThemePixels(4)};
  margin-bottom: ${toThemePixels(4)};
`

export const ButtonContainer = styled(Row)`
  justify-content: ${ifProp('singleButton', 'center', 'space-between')};
  margin-top: ${toThemePixels(4)};

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    gap: ${toThemePixels(4)};
  }
`
