import { makeQueryString } from '@/utils'
import { api } from './api'
import { DocumentType } from '@/types/file'

export type PostFilesApiResponse = {
  fileId: string
  archive: boolean
  belongsToUuid: string
  type: string
  tag: string | null
  name: string
  uploaderId: string | null
  createdAt: string
  updatedAt: string
  fileType: string // TODO: add enum
  visibleToUser: boolean
  displayName: string
  category: string | null
}[]

export const postFiles = (
  id: string,
  files: File[],
  type: DocumentType,
  category?: string,
): Promise<PostFilesApiResponse> => {
  const formData = new FormData()

  files.forEach((file) => formData.append('file', file))

  // typing api.post directly would not work - our api client is using middlewares to modify return type
  return api.post(
    `/file/${id}${makeQueryString({ type, category })}`,
    formData,
    {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
      },
    },
  )
}

/**
 * TODO: create return type
 */
export const downloadFile = (name: string) =>
  api.get(`file${makeQueryString({ name })}`)

/**
 * TODO: create return type
 */
export const onMessageSend = (msg: string) =>
  api.post('request/general ', {
    message: msg,
  })

/**
 * TODO: create return type
 */
export const exportCsv = (companyId: string) =>
  api.get(`company/insurances/export?format=csv&company_id=${companyId}`, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
