import {
  useGetTinkConnections,
  useRetrieveTinkReports,
  useTinkIntegrationLink,
} from '@/services/integrations'
import { isEmpty } from 'ramda'
import { goToExternalUrl } from '../url'

const anyConnectionPending = (connections = []) =>
  connections.some((connection) => connection.status === 'pending')

const anyConnectionCompleted = (connections = []) =>
  connections.some((connection) => connection.status === 'connected')

const defaultConfig = {
  source: 'profile-integrations',
}

export const useBankingIntegration = (
  config = defaultConfig,
  shouldFetchIntegrations = true,
) => {
  const {
    data: connections,
    isLoading: isGetTinkConnectionsLoading,
  } = useGetTinkConnections()

  const {
    mutateAsync: tinkIntegrationLinkMutateAsync,
    isLoading: isTinkIntegrationLinkDataLoading,
  } = useTinkIntegrationLink(config)

  const redirectUserToTink = () => {
    tinkIntegrationLinkMutateAsync().then((response) => {
      if (response?.link) {
        goToExternalUrl(response.link)
      }
    })
  }

  const {
    data: tinkReportsData = {},
    isLoading: isTinkReportsDataLoading,
  } = useRetrieveTinkReports({
    enabled:
      Boolean(shouldFetchIntegrations) &&
      anyConnectionCompleted(connections?.connections),
  })

  return {
    redirectUserToTink,
    canGenerateTinkLink:
      anyConnectionPending(connections?.connections) ||
      isEmpty(connections?.connections),
    connections: connections?.connections,
    isLoading:
      isTinkIntegrationLinkDataLoading ||
      isTinkReportsDataLoading ||
      isGetTinkConnectionsLoading,
    reports: tinkReportsData,
  }
}
