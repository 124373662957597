/* eslint-disable no-nested-ternary */
import {
  ButtonLink,
  Column,
  ContactExpert,
  ImageFromCdn,
  InputWithAddon,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  Row,
  TextBadge,
  TextWithLink,
  Typography,
} from '@/components'
import { config } from '@/config'
import { ComponentColors, ThemeColor } from '@/enums'
import { ArrowRight, SearchIcon } from '@/icons'
import { Redirect } from '@/routes/Redirect'
import { paths } from '@/routes/paths'
import { useContractsOverview } from '@/services'
import {
  fromEventTarget,
  getFileNameAndExtension,
  includesIgnoringCase,
  makeLocationPathFromCompanies,
  mapWithKey,
  shouldRedirectToDashboard,
  useGoToPath,
  useIsMobile,
} from '@/utils'
import PropTypes from 'prop-types'
import { evolve, isEmpty, map, partition, pluck, prop } from 'ramda'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { CompanyDisplay } from './CompanyDisplay'
import { KPIOverview } from './KPIOverview/Component'
import {
  CompaniesColumn,
  EmptyCompanySearchContainer,
  HeadquartersColumn,
  PageHeader,
  ProHeader,
  ProHeaderLogoWrapper,
  ProHeaderTextContainer,
  ProSection,
} from './styles'

const filterCompanies = (companies = [], search) =>
  companies
    .filter(
      (company) =>
        includesIgnoringCase(search, company.name) ||
        company.locations.find((location) =>
          includesIgnoringCase(search, location.name),
        ),
    )
    .map((company) => {
      const filteredCompany = company.locations.filter((location) =>
        includesIgnoringCase(search, location.name),
      )
      const isPerfectMatch = search.toLowerCase() === company.name.toLowerCase()
      return {
        ...company,
        locations: isPerfectMatch ? company.locations : filteredCompany,
      }
    })

const useMessages = (name, search) => {
  const { t } = useTranslation()

  return {
    addCompany: t('add_company_or_location_text'),
    dashboard: t('dashboard'),
    yourAdvisorText: t('dashboard_advisor_text'),
    pageTitle: t('welcome_text_dashboard', { name }),
    pageSubtitle: t('subtext_dashboard'),
    companiesAndLocations: t('companiesAndLocations'),
    searchByCompanyOrLocation: t('searchByCompanyOrLocation'),
    noResultsFound: t('noResultsFound'),
    noMatchInSearch: t('noMatchInSearch', { search }),
    createCompany: t('createCompany'),
    companyOverview: t('companyOverview'),
  }
}

export const Dashboard = () => {
  const { user } = useOutletContext()
  const [search, setSearch] = useState('')
  const messages = useMessages(user.name, search)
  const { companies: data = [] } = useOutletContext()
  const isMobile = useIsMobile()

  const {
    data: contractsOverviewData,
    isLoading: isContractsOverviewDataLoading,
  } = useContractsOverview()

  const goToCompaniesAndLocations = useGoToPath(paths.companies)
  const goToContractsOverview = useGoToPath(paths.contractsOverview)

  if (isEmpty(data)) return <Redirect to={paths.authRedirect} />

  if (!shouldRedirectToDashboard(data))
    return (
      <Redirect
        to={makeLocationPathFromCompanies(paths)(
          map(evolve({ locations: pluck('locationId') }), data),
        )}
      />
    )
  const [headquarters, companies] = partition(prop('isHeadquarters'), data)

  const headquartersWithLogo = headquarters.find((hq) => !!hq.logoUrl)

  const [
    headquartersLogoUrl,
    headquartersLogoExtension,
  ] = getFileNameAndExtension(headquartersWithLogo?.logoUrl || '')

  if (isContractsOverviewDataLoading) return <Loader />

  const filteredCompanies = filterCompanies(companies, search)
  const filteredHeadquarters = filterCompanies(headquarters, search)

  return (
    <>
      <PageMainContent>
        <ProSection>
          {headquarters.length && headquartersLogoUrl ? (
            <>
              <ProHeader
                logoUrl={`${config.cdn}/${headquartersWithLogo.bannerUrl}`}
              >
                <ProHeaderTextContainer
                  hasLogo={headquarters.length && headquartersLogoUrl}
                >
                  <Row alignItems="center" gap="6px">
                    <Typography
                      color={ThemeColor.b0}
                      responsive
                      variant={isMobile ? 'inputLabel' : 'button'}
                    >
                      {messages.companyOverview}
                    </Typography>
                    <TextBadge bgOpacity="0.5" text="Pro" />
                  </Row>
                  <Typography
                    color={ThemeColor.b0}
                    responsive
                    variant={isMobile ? 'h4' : 'h3'}
                  >
                    {messages.pageTitle}
                  </Typography>
                </ProHeaderTextContainer>
                <Column>
                  <ProHeaderLogoWrapper>
                    <ImageFromCdn
                      name={headquartersLogoUrl}
                      type={headquartersLogoExtension}
                    />
                  </ProHeaderLogoWrapper>
                </Column>
              </ProHeader>
              <KPIOverview
                data={contractsOverviewData.kpis}
                grossPrice={contractsOverviewData.kpis.totalGrossPrice}
                onDetailsClick={goToContractsOverview}
              />
            </>
          ) : headquarters.length ? (
            <ProHeaderTextContainer hasLogo={!!headquartersLogoUrl}>
              <Row alignItems="center" gap="6px">
                <Typography
                  bold={!headquartersLogoUrl}
                  color={headquartersLogoUrl ? ThemeColor.b0 : ThemeColor.b100}
                  responsive
                  variant={isMobile ? 'inputLabel' : 'button'}
                >
                  {messages.companyOverview}
                </Typography>
                <TextBadge
                  bgOpacity={headquartersLogoUrl ? 0.5 : 1}
                  text="Pro"
                />
              </Row>
              <Typography
                bold={!headquartersLogoUrl}
                color={headquartersLogoUrl ? ThemeColor.b0 : ThemeColor.b100}
                responsive
                variant={isMobile ? 'h4' : 'h3'}
              >
                {messages.pageTitle}
              </Typography>
            </ProHeaderTextContainer>
          ) : (
            <PageHeader>
              <Typography variant="h4">{messages.pageTitle}</Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {messages.pageSubtitle}
              </Typography>
            </PageHeader>
          )}
        </ProSection>
        <CompaniesColumn hasHeadquarters={!!headquarters.length}>
          <Typography bold variant="h4">
            {messages.companiesAndLocations}
          </Typography>
          <InputWithAddon
            addonBefore={() => <SearchIcon />}
            onChange={fromEventTarget(setSearch)}
            placeholder={messages.searchByCompanyOrLocation}
            value={search}
          />
          {!!headquarters.length && (
            <HeadquartersColumn>
              {mapWithKey(CompanyDisplay, 'companyId', filteredHeadquarters)}
            </HeadquartersColumn>
          )}
          {search &&
          filteredCompanies.length === 0 &&
          filteredHeadquarters.length === 0 ? (
            <EmptyCompanySearch searchMessage={messages.noMatchInSearch} />
          ) : (
            mapWithKey(CompanyDisplay, 'companyId', filteredCompanies)
          )}
          <TextWithLink>
            <Trans
              components={
                <ButtonLink
                  color={ComponentColors.primary}
                  onClick={goToCompaniesAndLocations}
                />
              }
              i18nKey={messages.addCompany}
            />
          </TextWithLink>
        </CompaniesColumn>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.yourAdvisorText} />
      </PageWidgetContainer>
    </>
  )
}

const EmptyCompanySearch = ({ searchMessage }) => {
  const messages = useMessages()
  const goToCompaniesAndLocations = useGoToPath(paths.companies)

  return (
    <EmptyCompanySearchContainer>
      <SearchIcon size="button" />
      <Column gap="4px">
        <Typography bold variant="p1Body">
          {messages.noResultsFound}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {searchMessage}
        </Typography>
      </Column>
      <ButtonLink onClick={goToCompaniesAndLocations}>
        <Row alignItems="center">
          <Typography bold color={ThemeColor.electric500} variant="p1Body">
            {messages.createCompany}
          </Typography>
          <ArrowRight size="message" />
        </Row>
      </ButtonLink>
    </EmptyCompanySearchContainer>
  )
}

EmptyCompanySearch.propTypes = {
  searchMessage: PropTypes.string,
}
