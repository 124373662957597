import { useEffect, useReducer } from 'react'

export const useReducerWithMiddleware = (
  reducer,
  initialState,
  middlewareFn,
) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    middlewareFn(state)
  }, [state])

  return [state, dispatch]
}
