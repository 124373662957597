import {
  adjust,
  flip,
  head,
  ifElse,
  includes,
  join,
  map,
  match,
  modulo,
  pipe,
  reverse,
  split,
} from 'ramda'
import { returns } from './function'
import { isUndefined } from './type'

export const bitwiseNot = (bool) => +!bool

export const toInt = (number, radix = 10) => parseInt(number, radix)

const formatNumber = pipe(
  reverse,
  match(/.{1,3}/g),
  reverse,
  map(reverse),
  join('.'),
)

export const formatNumberValue = ifElse(
  isUndefined,
  returns(''),
  pipe(
    String,
    ifElse(
      includes(','),
      pipe(split(','), adjust(0, formatNumber), head), // join(',')),
      formatNumber,
    ),
  ),
)

export const isFloat = pipe(flip(modulo)(1), Boolean)
