import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import {
  AddDocumentsFormValues,
  StepOnSubmitValues,
} from '../components/helpers/types'
import { Company } from '@/types/company'
import { Step } from '@/pages/AddInsuranceContractsPage'

interface OutletContextProps {
  companies: Company[]
}

interface StepperProviderProps {
  children: React.ReactNode
  defaultValues?: Partial<AddDocumentsFormValues>
  categoryId?: string | null
  makeSteps: (shouldShowLocationSelection: boolean) => Step[]
  onFinish?: (values: Partial<StepOnSubmitValues>) => void
}

interface StepperContextProps {
  steps: Step[]
  currentStep: number
  onNext: <T = unknown>(values: T) => void
  onBack: () => void
  formValues: Partial<AddDocumentsFormValues>
  isFirst: boolean
  isLast: boolean
  categoryId: string | null
  setCategoryId: (categoryId: string | null) => void
  companyData: CompanyData | null
  setCompanyData: (companyData: CompanyData | null) => void
}

interface CompanyData {
  companyId: string
  locationId: string
  poaSigned: boolean
}

const StepperContext = createContext<StepperContextProps>({
  steps: [],
  currentStep: 0,
  onNext: <T,>(_values: T) => {},
  onBack: () => {},
  formValues: {},
  isFirst: true,
  isLast: false,
  categoryId: null,
  setCategoryId: () => {},
  companyData: null,
  setCompanyData: () => {},
})

export const useStepper = () => useContext(StepperContext)

export const StepperProvider = ({
  children,
  defaultValues,
  makeSteps,
  onFinish,
}: StepperProviderProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [categoryId, setCategoryId] = useState<string | null>(null)
  const [companyData, setCompanyData] = useState<CompanyData | null>(null)
  const { companies } = useOutletContext<OutletContextProps>()

  useEffect(() => {
    // If there is only one company and one location, set the company data
    if (companies.length === 1 && companies[0].locations.length === 1) {
      setCompanyData({
        companyId: companies[0].companyId,
        locationId: companies[0].locations[0].locationId,
        poaSigned: companies[0].poaSigned,
      })

      // Adjust the form values if there is only one company and one location
      setFormValues({
        ...formValues,
        ...{
          locationData: {
            companyId: companies[0].companyId,
            locationId: companies[0].locations[0].locationId,
            poaSigned: companies[0].poaSigned,
          },
        },
      })
    }
  }, [companies])

  const shouldShowLocationSelection =
    companies.length > 1 || companies[0].locations.length > 1

  const steps = useMemo(() => makeSteps(shouldShowLocationSelection), [
    shouldShowLocationSelection,
    makeSteps,
  ])

  const isFirst = currentStep === 0
  const isLast = steps.length - 1 === currentStep

  const [formValues, setFormValues] = useState(defaultValues ?? {})

  const onNext = <T,>(values: T) => {
    const updatedFormValues = { ...formValues, ...values }
    setFormValues(updatedFormValues)

    if (!isLast) {
      setCurrentStep((prev) => prev + 1)
    } else {
      onFinish?.({ ...updatedFormValues })
    }
  }

  const onBack = () =>
    currentStep > 0 ? setCurrentStep((prev) => prev - 1) : null

  return (
    <StepperContext.Provider
      value={{
        steps,
        currentStep,
        onNext,
        onBack,
        formValues,
        isFirst,
        isLast,
        categoryId,
        setCategoryId,
        companyData,
        setCompanyData,
      }}
    >
      {children}
    </StepperContext.Provider>
  )
}
