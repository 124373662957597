import { Breakpoint } from '@/enums'
import { theme } from '@/theme/theme'
import PropTypes from 'prop-types'
import { values } from 'ramda'
import { useLayoutEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export const useIsMobile = (breakpoint) => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useLayoutEffect(() => {
    handleSize()

    window.addEventListener('resize', handleSize)

    return () => window.removeEventListener('resize', handleSize)
  }, [])

  return {
    mobileDevice: isMobile,
    isMobile: windowSize.width < (theme.breakpoints.values[breakpoint] || 769),
    width: windowSize.width,
    height: windowSize.height,
    isLandscape: window.matchMedia('(orientation: landscape)').matches,
    windowSize,
  }
}
useIsMobile.propTypes = {
  breakpoint: PropTypes.oneOf(values(Breakpoint)),
}
