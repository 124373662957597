/* eslint-disable react/no-unused-prop-types */
import {
  fromEventTarget,
  isNumber,
  oneOrManyChildrenOfType,
  parseChildrenArray,
  renderableType,
  useClickOutsideEvent,
  useDerivedState,
  useModal,
} from '@/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Input } from '../../styled'
import {
  filterOptions,
  getTextFromOptionValue,
  selectOptionValuePropType,
} from './helpers'
import { OptionNode } from './OptionNode'
import {
  Container,
  InputContainer,
  OptionsContainer,
  DropdownIcon,
  StyledOption,
} from './styles'

export const Option = () => null

Option.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: renderableType,
  value: selectOptionValuePropType,
}

export const Select = ({
  value = '',
  onSelect,
  onSearch,
  onBlur,
  onIconError,
  children,
  error,
  disabled,
  onFocus,
  autocomplete = false,
  ...props
}) => {
  const parsedChildren = parseChildrenArray(children)
  const [ref, setRef] = useState()

  const [inputValue, setInputValue] = useDerivedState(
    isNumber(value) ? value : value || '',
    autocomplete ? undefined : getTextFromOptionValue(parsedChildren),
  )

  const { isOpen, open, close } = useModal()

  const options = filterOptions(inputValue, parsedChildren)

  const handleFocus = () => {
    if (!props.disabled) open()
    onFocus?.()
  }

  const handleChange = (e) => {
    fromEventTarget(setInputValue)(e)
    if (onSearch) fromEventTarget(onSearch)(e)
  }

  const handleBlur = () => {
    if (
      !autocomplete &&
      value &&
      inputValue !== getTextFromOptionValue(parsedChildren, value)
    ) {
      onSelect()
    }
    onBlur?.()
    close()
  }

  const handleSelect = (val) => {
    onSelect(val)
    close()
  }

  const handleTabClick = (event) => {
    if (event.key === 'Tab') close()
  }

  useClickOutsideEvent(ref, handleBlur, [inputValue, onBlur])

  return (
    <Container
      ref={setRef}
      isOpen={isOpen && (!autocomplete || options.length)}
    >
      <InputContainer>
        <Input
          disabled={disabled}
          error={error}
          onChange={handleChange}
          readOnly
          value={inputValue}
          {...props}
          onFocus={handleFocus}
          onKeyDown={handleTabClick}
        />
        {!autocomplete && <DropdownIcon onClick={handleFocus} />}
      </InputContainer>
      {isOpen && Boolean(!autocomplete || options.length) && (
        <OptionsContainer>
          {options.length
            ? options.map(
                ({
                  key,
                  value: optionValue,
                  children: text,
                  icon,
                  disabled: optionDisabled,
                }) => (
                  <OptionNode
                    key={key}
                    disabled={disabled || optionDisabled}
                    icon={icon}
                    onClick={handleSelect}
                    onIconError={onIconError}
                    selected={text === inputValue}
                    text={text}
                    value={optionValue}
                  />
                ),
              )
            : !autocomplete && <StyledOption>No options.</StyledOption>}
        </OptionsContainer>
      )}
    </Container>
  )
}

export const selectChildrenPropType = oneOrManyChildrenOfType(Option)

Select.propTypes = {
  autocomplete: PropTypes.bool,
  children: selectChildrenPropType,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onIconError: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: selectOptionValuePropType,
}
