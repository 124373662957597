import { FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    inventoryHelperText: t('inventoryHelperText'),
    placeholder: t('currencyInputPlaceholder'),
  }
}

export const InventoryStep = () => {
  const messages = useMessages()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.inventory,
  )

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormUnit
      helperText={messages.inventoryHelperText}
      name="totalEquipmentsCost"
      placeholder={messages.placeholder}
      unit="€"
    />
  )
}
