import { AssessmentStepKeys } from '@/enums'
import { FormProvider } from '@/providers'
import { flipObject, oneOrManyChildren, setLocalStorageItem } from '@/utils'
import PropTypes from 'prop-types'
import { equals, replace } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssessmentValues } from '../helpers'
import { makeValidationSchema } from '../validationSchema'
import { StepContainer, StepTitle } from './styles'

const useValidationMessages = () => {
  const { t } = useTranslation()
  const validations = t('validations', { returnObjects: true })

  return {
    ...validations,
    required: validations.general,
    startDateNotValidLe: t('start_date_not_valid_le'),
    makeMin: (min) => t('validations.min', { min }),
    makeMax: (max) => t('validations.max', { max }),
  }
}

const Steps = flipObject(AssessmentStepKeys)

const StepForm = ({
  step,
  valuesLocalStorageKey,
  stepLocalStorageKey,
  messages,
  children,
}) => {
  const stepName = Steps[step]
  const validationMessages = useValidationMessages()
  const { values, dispatchValues } = useAssessmentValues()

  const handleChange = (newValues) => {
    if (!equals(newValues, values[stepName]))
      dispatchValues({ [stepName]: newValues })
  }

  useEffect(() => {
    if (valuesLocalStorageKey) {
      setLocalStorageItem(valuesLocalStorageKey, values)
    }
  }, [values, valuesLocalStorageKey])

  useEffect(() => {
    if (stepLocalStorageKey) {
      setLocalStorageItem(stepLocalStorageKey, step)
    }
  }, [step, stepLocalStorageKey])

  return (
    <FormProvider
      initialValues={values[stepName]}
      onChange={handleChange}
      validationSchema={makeValidationSchema(validationMessages)[stepName]}
    >
      <StepContainer>
        {messages.titles[step] && (
          <StepTitle>
            {replace(
              '{{locationName}}',
              values.locationDetails?.name,
              messages.titles[step],
            )}
          </StepTitle>
        )}
        {children}
      </StepContainer>
    </FormProvider>
  )
}

StepForm.propTypes = {
  children: oneOrManyChildren,
  messages: PropTypes.shape({
    subtitle: PropTypes.string,
    subtitles: PropTypes.objectOf(PropTypes.string),
    titles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  step: PropTypes.string,
  stepLocalStorageKey: PropTypes.string,
  valuesLocalStorageKey: PropTypes.string,
}

export const StepWrapper = ({
  messages,
  step,
  stepLocalStorageKey,
  valuesLocalStorageKey,
  children,
}) => {
  const Form = useCallback(
    (props) => (
      <StepForm
        messages={messages}
        step={step}
        stepLocalStorageKey={stepLocalStorageKey}
        valuesLocalStorageKey={valuesLocalStorageKey}
      >
        {props.children}
      </StepForm>
    ),
    [step, stepLocalStorageKey, valuesLocalStorageKey],
  )

  return <Form>{children}</Form>
}

StepWrapper.propTypes = {
  children: oneOrManyChildren,
  messages: PropTypes.shape({
    subtitle: PropTypes.string,
    titles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  step: PropTypes.string,
  stepLocalStorageKey: PropTypes.string,
  valuesLocalStorageKey: PropTypes.string,
}
