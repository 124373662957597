import { BackComponent } from '@/components'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import { Button } from '@surein/ui'
import { RotateCcw } from 'lucide-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TopActionsContainer } from './styles'

const pathByCategoryId = {
  LI: paths.liabilitySSF,
  CO: paths.contentSSF,
  LE: paths.legalSSF,
  FL: paths.liabilitySSF,
  default: paths.authRedirect,
}

export const TopActions = ({ onBack, noBack, categoryId }) => {
  const { t } = useTranslation()
  const goToRecalculation = useGoToPath(
    pathByCategoryId[categoryId] || pathByCategoryId.default,
  )

  return (
    !noBack && (
      <TopActionsContainer flexDirection={noBack ? 'row-reverse' : 'row'}>
        <BackComponent noContentGutter onBack={onBack} />
        {false && (
          <Button icon={RotateCcw} onClick={goToRecalculation} variant="text">
            {t('recalculate')}
          </Button>
        )}
      </TopActionsContainer>
    )
  )
}

TopActions.propTypes = {
  categoryId: PropTypes.string,
  noBack: PropTypes.bool,
  onBack: PropTypes.func,
}
