import { useTranslation } from 'react-i18next'
import { insuranceGroupsWithKeys } from '../constants/insuranceGroupsWithKeys'

export const useInsuranceGroups = () => {
  const { t } = useTranslation()
  const groupedInsurances = t('groupedInsurances', { returnObjects: true })

  return insuranceGroupsWithKeys.map((group) => ({
    groupName: t(`currentInsuranceGroups.${group.groupName}`),
    items: group.keys.map((key) => ({
      value: key,
      ...groupedInsurances[key],
    })),
  }))
}
