import { ThemeColor } from '@/enums'
import { themeColor, themeTransition, toThemePixels } from '@/utils'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, PageLayoutColumn } from '../../styled'

export const Container = styled(PageLayoutColumn)`
  grid-column: col-start / span 2;

  @media (max-width: 655px) {
    padding-top: ${toThemePixels(13)};
    min-height: unset;
  }

  @media (min-width: 656px) and (max-width: 980px) {
    position: fixed;
  }
`

export const SideBarContent = styled(Column)`
  gap: ${toThemePixels(1)};
  position: sticky;
  top: 76px;

  @media (max-width: 655px) {
    gap: ${toThemePixels(3)};
  }
`

const hoverStyles = css`
  > span {
    color: ${themeColor(ThemeColor.b100)};
  }

  svg {
    fill: ${themeColor(ThemeColor.b100)};
  }
`

export const SideBarItem = styled.div`
  display: flex;
  height: ${toThemePixels(7.5)};
  align-items: center;
  gap: ${toThemePixels(1)};

  svg {
    min-width: 18px;
  }

  > span {
    transition: ${themeTransition()};
    color: ${themeColor(ThemeColor.b50)};

    @media (max-width: 655px) {
      font-size: ${toThemePixels(4)};
      font-weight: 400;
    }
  }

  ${ifProp('isActive', hoverStyles)};

  &:hover {
    ${hoverStyles};
  }
`

export const AddContractButtonContainer = styled.div`
  > button {
    width: 100%;
  }
`
