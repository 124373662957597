import { Breakpoint } from '@/enums'
import { ClearIcon } from '@/icons'
import { useIsMobile } from '@/utils'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Clear, Container, HorizontalLine, InfoSection } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    signPoa_here: t('signPoa_here'),
    signPoa_hereMobile: t('signPoa_hereMobile'),
  }
}

export const SignatureCanvas = ({ onChange, value }) => {
  const messages = useMessages()
  const [ref, setRef] = useState()
  const [canvasValue, setCanvasValue] = useState()
  const [isTouched, setIsTouched] = useState(false)

  const { mobileDevice } = useIsMobile(Breakpoint.sm)

  useEffect(() => {
    if (canvasValue) {
      onChange(ref?.getTrimmedCanvas?.()?.toDataURL('image/png'))
    } else if (value) {
      onChange()
    }
  }, [canvasValue])

  const handleClear = () => {
    ref.clear()
    setCanvasValue()
    setIsTouched(false)
  }

  return (
    <Container isTouched={isTouched || canvasValue}>
      <ReactSignatureCanvas
        ref={setRef}
        clearOnResize={false}
        onBegin={() => setIsTouched(true)}
        onEnd={() => {
          setCanvasValue(ref?.toDataURL('image/png'))
        }}
      />
      {canvasValue ? (
        <Clear onClick={handleClear}>
          <ClearIcon fill="none" />
        </Clear>
      ) : (
        <InfoSection>
          {mobileDevice ? messages.signPoa_hereMobile : messages.signPoa_here}
        </InfoSection>
      )}
      <HorizontalLine />
    </Container>
  )
}

SignatureCanvas.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
