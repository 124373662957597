import * as Sentry from '@sentry/react'
import { api } from './api'

export const getUserProfile = async () => {
  const user = await api.get(`/user/profile`)

  Sentry.setUser({
    id: user.userId,
    role: 'user',
  })

  return user
}

export const updateUser = (id, { name, surname, phone }) =>
  api.put(`/user/${id}`, { name, surname, phone })

export const updateUserLanguage = (id, { language }) =>
  api.put(`/user/${id}`, { language })
