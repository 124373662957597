import { useFormContext } from '@/providers'
import { isNumber, isValidDate, useModal } from '@/utils'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { MonthSelect } from '../MonthSelect'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'
import { ModalWrapper } from './ModalWrapper'
import { DropdownModalTrigger, HelperMessage } from './styles'
import { ThemeColor } from '@/enums'
import { Column } from '@/components/styled'

const makeValue = (value) => {
  const date = new Date(value)
  if (isNumber(value) && isValidDate(date)) {
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    }
  }

  return value
}

const MonthSelectModalWrapper = ({
  children,
  open,
  close,
  isOpen,
  currentField,
}) => (
  <>
    <DropdownModalTrigger onClick={open}>
      {!isOpen && children}
    </DropdownModalTrigger>
    <ModalWrapper close={close} currentField={currentField} isOpen={isOpen}>
      <Column gap="4px">{children}</Column>
    </ModalWrapper>
  </>
)

MonthSelectModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  currentField: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
}

export const FormMonthSelect = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  ignoreErrorKeys,
  showErrorHelperText,
  shouldRenderInModal,
  ...props
}) => {
  const { setErrors } = useFormContext()

  const { open, close, isOpen } = useModal()
  const [currentField, setCurrentField] = useState(null)

  useEffect(() => {
    if (currentField && isOpen) {
      document.querySelector(currentField)?.focus()
      setCurrentField?.(null)
    }
  }, [currentField])

  const Component = useCallback(
    ({ onChange, value, errorText, ...field }) => {
      const handleOnChange = ({ month, year }) => {
        setErrors({})
        if (month && year) {
          onChange(Date.parse(new Date(year, month - 1)))
          if (shouldRenderInModal) close()
        } else {
          onChange({ month, year })
        }
      }

      return shouldRenderInModal ? (
        <MonthSelectModalWrapper
          close={close}
          currentField={currentField}
          isOpen={isOpen}
          open={open}
        >
          <MonthSelect
            onChange={handleOnChange}
            value={makeValue(value)}
            {...field}
            {...props}
            monthKey="month"
            setCurrentField={setCurrentField}
            yearKey="year"
          />
          {/* Fixme: bit of a hack, needs refactoring the useCallback with some time */}
          {errorText && isOpen && (
            <HelperMessage asChild color={ThemeColor.danger2}>
              {errorText}
            </HelperMessage>
          )}
        </MonthSelectModalWrapper>
      ) : (
        <MonthSelect
          onChange={handleOnChange}
          value={makeValue(value)}
          {...field}
          {...props}
          monthKey="month"
          yearKey="year"
        />
      )
    },
    [isOpen, shouldRenderInModal],
  )

  return (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      name={name}
      noMargin
      showErrorHelperText={showErrorHelperText}
    >
      <Component />
    </FormItem>
  )
}
FormMonthSelect.propTypes = formItemPropType
