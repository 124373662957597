import { ThemeColor } from '@/enums'
import { themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  background-color: ${themeColor(ThemeColor.b10)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: 122px;
  padding: ${toThemePixels(2)} ${toThemePixels(3)} ${toThemePixels(2)}
    ${toThemePixels(2)};
  gap: ${toThemePixels(2)};
  align-items: center;

  p {
    margin: 0;
    font-size: 14px;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid ${themeColor(ThemeColor.b30)};
  }
`
