import { Column, PageMainContent } from '@/components'
import { themeBreakpointDown } from '@/utils'
import styled from 'styled-components'

export const MainContent = styled(PageMainContent)`
  grid-column: col-start 4 / span 7;

  @media (max-width: 1160px) {
    grid-column: col-start 4 / span 7;
  }

  @media (max-width: 980px) {
    grid-column: col-start 5 / span 8;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2 !important;
  }

  ${themeBreakpointDown('sm')} {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const Container = styled(Column)`
  height: 85vh;
  width: 100%;

  ${themeBreakpointDown('sm')} {
    height: 87.5vh;
  }
`
