import { FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    helperText: t('approxHelperText'),
  }
}

export const ConstructionAreaStep = () => {
  const messages = useMessages()
  const { value } = useFormItem('constructionArea')
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.constructionArea,
  )

  useOnNextClick(save)

  useNextDisabled(() => !value && typeof value !== 'number', [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormUnit
      helperText={messages.helperText}
      name="constructionArea"
      unit="sqm"
    />
  )
}
