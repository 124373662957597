import { ThemeColor } from '@/enums'
import { MailIcon } from '@/icons'
import {
  renderableType,
  returns,
  useModal,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { SecondaryButton, Typography } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Row } from '../../styled'
import { ContactModal } from '../ContactModal'
import { Container, Header, StyledImage } from './styles'

const useMessages = () => {
  const servicesTranslations = useTranslationSectionObject('services')
  const { t } = useTranslation()

  return {
    buttonText: servicesTranslations.contacts,
    fromYourAdvisor: t('fromYourAdvisor'),
    advisorText: t('question_advisor_text'),
  }
}

export const ContactExpert = ({
  title,
  text,
  reverted,
  defaultButton: DefaultButton = SecondaryButton,
  buttonComponent: ButtonComponent,
  noMobilePadding,
  buttonText,
  textColor = ThemeColor.b100,
}) => {
  const messages = useMessages()
  const { isOpen, open, close } = useModal()
  const { hasHeadquarters } = useOutletContext()

  return (
    <Container noMobilePadding={noMobilePadding}>
      <Header reverted={reverted}>
        <Row alignItems="center" gap="4px">
          <MailIcon fill="none" />
          <Typography color={ThemeColor.b50} variant="p2Body">
            {title || messages.fromYourAdvisor}
          </Typography>
        </Row>
        <StyledImage name="chatblock" type="png" />
      </Header>
      <Typography color={textColor} variant="p1Body">
        {text || messages.advisorText}
      </Typography>
      <ShowWhen fallback={returns(ButtonComponent)} when={!ButtonComponent}>
        <DefaultButton onClick={open} size="medium">
          {buttonText || messages.buttonText}
        </DefaultButton>
      </ShowWhen>
      {isOpen && (
        <ContactModal
          hasHeadquarters={hasHeadquarters}
          isOpen={isOpen}
          onClose={close}
        />
      )}
    </Container>
  )
}

ContactExpert.propTypes = {
  buttonComponent: renderableType,
  buttonText: PropTypes.string,
  defaultButton: renderableType,
  noMobilePadding: PropTypes.bool,
  reverted: PropTypes.bool,
  text: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
}
