import { Column, PrimaryButton, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { ArrowRightLine } from '@/icons'
import { themeBreakpointUp, toThemePixels } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const FormContainer = styled(Column)`
  gap: 1rem;
`

const fixedStyles = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

export const FixedFooter = styled.div`
  background: #fff;
  padding: 16px;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 ${toThemePixels(4)} ${toThemePixels(4)};

  align-items: center;
  justify-content: flex-end;
  display: flex;

  ${ifProp('fixed', fixedStyles)}
`

export const StepContainer = styled.div`
  > form {
    display: flex;
    height: calc(100vh - 86px);
    flex-direction: column;
  }

  ${themeBreakpointUp(Breakpoint.md)} {
    > form {
      height: 77vh;
    }
  }
`

export const FormContent = styled.div`
  overflow: auto;
  padding: 0 24px 24px 24px;

  flex: 1;
`
const StepHeaderContainer = styled(Column)`
  gap: 8px;
  padding: 24px;
`
export const StepHeader = ({ sectionLabel, title, description }) => (
  <StepHeaderContainer>
    <Typography color={ThemeColor.b50} variant="p1Body">
      {sectionLabel}
    </Typography>
    <Typography bold variant="h4">
      {title}
    </Typography>
    <Typography color={ThemeColor.b50} variant="p2Body">
      {description}
    </Typography>
  </StepHeaderContainer>
)

StepHeader.propTypes = {
  description: PropTypes.string,
  sectionLabel: PropTypes.string,
  title: PropTypes.string,
}

export const StepFooter = ({
  onSubmit,
  isDisabled,
  onSubmitLabel,
  fixed,
  isButtonLoading,
}) => {
  const { t } = useTranslation()
  return (
    <FixedFooter fixed={fixed}>
      <PrimaryButton
        disabled={isDisabled}
        icon={() => <ArrowRightLine size={18} />}
        loading={isButtonLoading}
        onClick={onSubmit}
        reverted
      >
        {onSubmitLabel ?? t('yearlyAssessment.confirmAndContinue')}
      </PrimaryButton>
    </FixedFooter>
  )
}

StepFooter.propTypes = {
  fixed: PropTypes.bool,
  isButtonLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitLabel: PropTypes.string,
}
