import { X } from 'lucide-react'
import PropTypes from 'prop-types'
import {
  ModalCloseButton,
  StyledDropdownModal,
  StyledDropdownModalContent,
} from './styles'

export const ModalWrapper = ({ children, isOpen, close }) => {
  if (!isOpen) return null

  return (
    <StyledDropdownModal isOpen={isOpen}>
      <ModalCloseButton onClick={close}>
        <X size={18} />
      </ModalCloseButton>
      <StyledDropdownModalContent>{children}</StyledDropdownModalContent>
    </StyledDropdownModal>
  )
}

ModalWrapper.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func,
  isOpen: PropTypes.bool,
}
