import { FormCardSelect, Option } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { keys } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    options: t('essentialCategoryIdOptions', {
      returnObjects: true,
    }),
  }
}

export const LocationActivitiesStep = () => {
  const messages = useMessages()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationActivities,
  )

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormCardSelect
      alignment="vertical"
      initialValue={[]}
      multiple
      name="essentialCategoryId"
    >
      {keys(messages.options).map((item) => (
        <Option key={item} value={item}>
          {messages.options[item]}
        </Option>
      ))}
    </FormCardSelect>
  )
}
