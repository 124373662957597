import { identity } from 'ramda'
import { useEffect, useState } from 'react'
import { isNumber } from '../type'

export const useDerivedState = (defaultValue, modifier = identity) => {
  const [value, setValue] = useState(modifier(defaultValue))
  useEffect(() => {
    if (defaultValue || isNumber(defaultValue)) {
      setValue(modifier(defaultValue))
    }
  }, [defaultValue])

  return [value, setValue]
}
