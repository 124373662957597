import { useUpdateUserLanguage } from '@/services'
import { useClickOutsideEvent, useModal } from '@/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useAuth } from '../../../providers/AuthProvider'
import { Relative } from '../../styled'
import { ButtonAction, OptionsContainer } from './styles'
import { ButtonSize } from '@/components/atoms/ButtonBase/types'

const languageIconMap: Record<string, string> = {
  de: '🇩🇪',
  en: '🇬🇧',
}

export const LanguageSwitch = ({ size }: { size: ButtonSize }) => {
  const { i18n } = useTranslation()
  const { isAuthenticated, user } = useAuth()
  const updateLanguageMutation = useUpdateUserLanguage(user?.sub)
  const queryClient = useQueryClient()

  const handleLanguageChange = async (language: string) => {
    if (isAuthenticated) {
      await updateLanguageMutation.mutateAsync({
        language,
      })
    }
    i18n.changeLanguage(language)
    queryClient.invalidateQueries('location-types')
    queryClient.invalidateQueries('attributes')
  }

  return (
    <LanguageSwitchBase
      onChange={handleLanguageChange}
      size={size}
      value={i18n.language}
    />
  )
}

export const LanguageSwitchBase = ({
  size,
  value,
  onChange,
}: {
  size: ButtonSize
  value: string
  onChange: (language: string) => Promise<void>
}) => {
  const { i18n } = useTranslation()
  const { isOpen, close, toggle } = useModal()
  // https://stackoverflow.com/questions/56455887/react-usestate-or-useref
  const [ref, setRef] = useState<HTMLDivElement | null>()

  useClickOutsideEvent(ref, close)

  const handleLanguageChange = async (language: string) => {
    if (language === value) {
      return
    }
    await onChange(language)
    close()
  }

  if (!i18n.options.supportedLngs) {
    return null
  }

  const availableLanguages =
    // cimode - https://www.i18next.com/how-to/faq#how-to-handle-with-changes-in-e2e-tests
    i18n.options.supportedLngs?.filter((language) => language !== 'cimode') ??
    []

  return (
    <Relative ref={setRef}>
      <ButtonAction onClick={toggle} size={size} variant="outlined">
        {languageIconMap[value]}
      </ButtonAction>
      <OptionsContainer isOpen={isOpen}>
        {availableLanguages
          .filter((language) => language !== value)
          .map((language) => (
            <ButtonAction
              key={language}
              onClick={() => handleLanguageChange(language)}
              size={size}
              variant="outlined"
            >
              {languageIconMap[language]}
            </ButtonAction>
          ))}
      </OptionsContainer>
    </Relative>
  )
}
