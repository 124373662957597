import { FormUnit } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
  useSkipData,
} from '@/providers'
import { useSaveAssessment } from '@/services'
import { anyPropMissing } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('locationRentTitle'),
    totalEquipmentsCost: t('locationRentTotalEquipmentsCost'),
    totalEquipmentsCostInfo: t('totalEquipmentsCostInfo'),
    skipForNow: t('skipForNow'),
    skipDescription: t('skipDescription'),
    checkboxLabel: t('locationRentCheckboxLabel'),
  }
}

export const LocationRentStep = () => {
  const { locationId } = useParams()
  const messages = useMessages()
  const { values } = useFormContext()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationRent,
    (value) => ({
      ...value,
      rentedArea: value.rentedArea || 0,
      annualBruttoRent: value.annualBruttoRent || 0,
    }),
  )
  const { mutateAsync: saveAssessmentMutation } = useSaveAssessment(locationId)

  const onSkipClick = () => {
    if (locationId) {
      saveAssessmentMutation({
        [AssessmentStepKeys.locationRent]: {
          rentedArea: 0,
          annualBruttoRent: 0,
          totalEquipmentsCost: 0,
        },
      })
    }
  }

  useOnNextClick(save)

  useSkipData(() => ({
    text: messages.skipDescription,
    skipButton: messages.skipForNow,
    withModal: false,
    onSkipClick,
  }))

  useNextDisabled(() => anyPropMissing(['totalEquipmentsCost'], values), [
    values,
  ])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormUnit
      label={messages.totalEquipmentsCost}
      name="totalEquipmentsCost"
      unit="€"
    />
  )
}
