import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup'

export const validationSchema = (messages) =>
  Yup.object().shape({
    companyId: Yup.string().required(messages.company),
    email: Yup.string().email(messages.validEmail).required(messages.email),
    name: Yup.string().required(messages.name),
    phone: Yup.string().test('isValid', messages.phoneIncorrect, (val) =>
      isValidPhoneNumber(`+${val}`),
    ),
    surname: Yup.string().required(messages.surname),
  })
