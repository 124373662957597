import { ThemeColor } from '@/enums'
import { pixelsFromProp, themeColor } from '@/utils'
import { prop } from 'ramda'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Flex } from '../../styled'

export const Container = styled.div`
  width: 110%;
  max-width: ${pixelsFromProp('$width')};
  overflow: hidden;

  @media (max-width: ${({ $width }) => $width + 48}px) {
    width: calc(100vw - 48px);

    & + div {
      display: none;

      & + div {
        display: none;
      }
    }
  }
`

export const CarouselViewport = styled(Flex)`
  transform: translateX(${({ $width, $active }) => $width * $active}px);

  @media (max-width: ${({ $width }) => $width + 48}px) {
    transform: translateX(calc((100vw - 48px) * ${prop('$active')}));
  }
`

export const CarouselItem = styled.div`
  width: 100%;
  flex-shrink: 0;
`

export const Step = styled.div`
  height: 2px;
  width: 16px;
  cursor: pointer;
  transition: background-color 500ms ease;
  background-color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b100),
    themeColor(ThemeColor.b30),
  )};
`
