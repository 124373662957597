import { primaryColorFromComponentColor } from '@/utils'
import styled from 'styled-components'
import { switchProp } from 'styled-tools'

const getSize = switchProp(
  'size',
  {
    small: '10px',
    medium: '12px',
    large: '16px',
  },
  '16px',
)

export const Container = styled.div`
  background-color: ${primaryColorFromComponentColor};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${getSize};
  min-width: ${getSize};
  height: ${getSize};

  span {
    line-height: 12px;
  }
`
