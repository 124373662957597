import { Column, Row } from '@/components'
import { ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Column)`
  grid-column: col-start 3 / span 9;
  gap: ${toThemePixels(4)};

  &:has(table) {
    grid-column: col-start 3 / span 10;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2 !important;
  }
`

export const TableContainer = styled(Column)`
  align-items: flex-start;
  gap: ${toThemePixels(4)};
  align-self: stretch;
  border-radius: ${toThemePixels(4)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  background-color: ${themeColor(ThemeColor.b0)};
  overflow-x: hidden;

  > div:first-child {
    width: 100%;
    padding: ${toThemePixels(4)} ${toThemePixels(4)} 0 ${toThemePixels(4)};
  }

  > div:first-child span {
    white-space: normal;
  }
`

export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  overflow-x: scroll;

  th {
    text-align: left;
    padding-bottom: ${toThemePixels(3)};
    border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  }

  td {
    padding: ${toThemePixels(2)} ${toThemePixels(2)} 0 ${toThemePixels(0)};
    min-width: ${toThemePixels(25)};
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  thead > tr > th:first-child {
    padding-left: 8px;
  }

  tbody > tr > td:first-child {
    padding-left: ${toThemePixels(2)};
  }
`

export const InsuranceEntryWrapper = styled(Row)`
  padding: ${toThemePixels(4)} ${toThemePixels(2)} 0 0;
  cursor: pointer;
  align-items: center;
  gap: ${toThemePixels(2)};
  width: ${toThemePixels(60)};

  ${themeBreakpointDown('sm')} {
    padding: ${toThemePixels(4)} ${toThemePixels(2)} 0 0;
    min-width: ${toThemePixels(50)};
    flex-wrap: no-wrap;
  }
`

export const InsuranceEntryTextContainer = styled(Column)`
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  }
  svg {
    flex-shrink: 0;
    margin-left: ${toThemePixels(1.25)};
    > path {
      stroke: ${themeColor(ThemeColor.b40)};
    }
  }

  ${themeBreakpointDown('md')} {
    span {
      max-width: 25ch;
    }
  }
`

export const LocationTablesWrapper = styled(Column)`
  gap: ${toThemePixels(4)};
  margin-top: ${toThemePixels(2)};
  margin-bottom: ${toThemePixels(2)};
  display: none;

  &:has(table) {
    display: flex;
  }
`

export const LocationTableRightPrice = styled(Row)`
  align-items: center;
  gap: ${toThemePixels(1)};

  > span:last-child {
    text-transform: lowercase;
  }
`

export const CompanyUserAddon = styled(Row)`
  gap: ${toThemePixels(2)};
  align-items: center;

  > img {
    width: ${toThemePixels(8)};
    height: ${toThemePixels(8)};
    border-radius: 50%;
  }
`

export const SearchBarContainer = styled.div`
  padding: ${toThemePixels(2)} 0;

  input {
    background-color: ${themeColor(ThemeColor.b0)};
    max-width: ${toThemePixels(128)};
  }
`

export const TotalGrossPriceContainer = styled(Row)`
  align-items: center;
  gap: ${toThemePixels(1)};

  > span:first-child {
    margin-right: ${toThemePixels(1)};
  }
`

export const TableGrossPrice = styled.div`
  text-transform: lowercase;
`

export const TableScrollWrapper = styled.div`
  width: 100%;
  padding: 0 ${toThemePixels(4)} ${toThemePixels(4)} ${toThemePixels(4)};
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export const EmptyContractsSearchContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(3)};
  padding: ${toThemePixels(6)} 0;
  text-align: center;
  display: flex;
  > div {
    max-width: ${toThemePixels(100)};
  }
`

export const CompanyAndLocationList = styled(Column)`
  :has(table) {
    ${EmptyContractsSearchContainer} {
      display: none;
    }
  }
`
