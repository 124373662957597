import { useState } from 'react'
import { useModal } from './useModal'

export const useItemDialog = (defaultItem, updateOnClose = true) => {
  const { isOpen, open, close } = useModal()
  const [item, setItem] = useState(defaultItem)

  const openItemDialog = (newItem) => {
    setItem(newItem)
    open()
  }

  const closeItemDialog = () => {
    close()
    if (updateOnClose) setItem(defaultItem)
  }

  return {
    item,
    isOpen,
    openItemDialog,
    closeItemDialog,
  }
}
