import { divide, curry } from 'ramda'
import { modifyParams } from './function'

export const divideBy = curry((fn, x, y) => modifyParams(divide, fn, [x, y]))

export const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0 // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100
  if (isNegativeOrNaN) return 0

  if (isTooHigh) return 100
  return +percentage
}
