import { not } from 'ramda'
import { useState } from 'react'

export const useToggable = (
  defaultValue: boolean = false,
): [boolean, (v: boolean) => void, () => void] => {
  const [value, setValue] = useState(Boolean(defaultValue))
  const negate = () => setValue(not)

  return [value, setValue, negate]
}
