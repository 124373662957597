import { dynamicObjectPropType } from '@/utils'
import PropTypes from 'prop-types'
import { mergeLeft } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Option, Select } from '../../atoms'
import { getYears } from './helpers'
import { Container } from './styles'

export const MonthSelect = ({
  value = {},
  onChange,
  yearKey = 'year',
  monthKey = 'month',
  minDate = new Date('1900'),
  maxDate = new Date(),
  setCurrentField,
  shouldDisable = true,
  error,
}) => {
  const { t } = useTranslation()
  const months = t('months', { returnObjects: true })

  const years = useMemo(() => getYears(minDate, maxDate), [minDate, maxDate])

  const handleChange = (newValue) => onChange(mergeLeft(newValue, value))

  return (
    <Container>
      <Select
        error={error}
        id="month-select"
        onClick={() => setCurrentField?.('#month-select')}
        onSelect={(month) => handleChange({ [monthKey]: month })}
        placeholder={t('common.month')}
        value={value[monthKey]}
      >
        {months.map((item, i) => {
          const disabled =
            new Date(`${value[yearKey]}-${String(i + 1).padStart(2, '0')}`) >
              maxDate ||
            new Date(`${value[yearKey]}-${String(i + 2).padStart(2, '0')}`) <
              minDate

          return (
            <Option
              key={item}
              disabled={shouldDisable && disabled}
              value={i + 1}
            >
              {item}
            </Option>
          )
        })}
      </Select>
      <Select
        disabled={years.length === 1}
        error={error}
        id="year-select"
        onClick={() => setCurrentField?.('#year-select')}
        onSelect={(year) => handleChange({ [yearKey]: year })}
        placeholder={t('common.year')}
        value={value[yearKey]}
      >
        {years.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    </Container>
  )
}

MonthSelect.propTypes = {
  error: PropTypes.bool,
  maxDate: dynamicObjectPropType,
  minDate: dynamicObjectPropType,
  monthKey: PropTypes.string,
  onChange: PropTypes.func,
  setCurrentField: PropTypes.func,
  shouldDisable: PropTypes.bool,
  value: PropTypes.number,
  yearKey: PropTypes.string,
}
