import {
  AdvisorBubble,
  BasicFormItem,
  Column,
  FormDatepicker,
  FormInputField,
  Loader,
  Row,
  SignatureCanvas,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { ShieldCheck } from '@/icons'
import { useSegment } from '@/modules'
import { StyledRow } from '@/pages/CheckoutPage/styles'
import { StyledLinkTypography } from '@/pages/SelfAssessmentPage/steps/styles'
import { FormProvider, useContactModal, useFormContext } from '@/providers'
import {
  useCreatePublicCheckoutPoa,
  useDoPublicCheckout,
  usePublicCheckoutPoa,
} from '@/services'
import { theme } from '@/theme'
import {
  clearLocalStorage,
  pushEventToDataLayer,
  themeColor,
  toThemePixels,
} from '@/utils'
import { Alert, Popover, PopoverContent, PopoverTrigger } from '@surein/ui'
import { Info, InfoIcon } from 'lucide-react'
import PropTypes from 'prop-types'
import { __, applySpec, join, pipe, props } from 'ramda'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { makePoaValidationSchema } from './validationSchema'

const StyledSSL = styled(Row)`
  width: fit-content;
  padding: ${toThemePixels(2)};
  background: ${themeColor(ThemeColor.success4)};
  border: 1px solid ${themeColor(ThemeColor.success3)};
  border-radius: ${toThemePixels(2)};
  margin-bottom: 4px;
`

const StyledLink = styled(StyledLinkTypography)`
  font-weight: 700;
`

const PopoverExplanation = styled(Row)`
  gap: 8px;

  > div:first-child {
    display: flex;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background: ${themeColor(ThemeColor.info3)};
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: ${themeColor(ThemeColor.info1)};
  }
`

export const SignPoaTooltip = () => {
  const { t } = useTranslation()
  const popoverExplanationPoints = t('signPoaPopoverExplanations', {
    returnObjects: true,
  })

  return (
    <Popover>
      <PopoverTrigger>
        <Row alignItems="center" gap="4px">
          <Info color={theme.color[ThemeColor.b50]} size="16px" />
          <Typography bold color={ThemeColor.b50} underline variant="badgeText">
            {t('whatIsThis')}
          </Typography>
        </Row>
      </PopoverTrigger>
      <PopoverContent>
        {Object.entries(popoverExplanationPoints).map(([_, point], index) => (
          <PopoverExplanation>
            <div>{index + 1}</div>
            <Typography key={point} variant="badgeText">
              {point}
            </Typography>
          </PopoverExplanation>
        ))}
      </PopoverContent>
    </Popover>
  )
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    birthdate: t('birthday'),
    next: t('next'),
    complete: t('complete'),
    poaAdvisorBubbleText: t('poaAdvisorBubbleText'),
    signPoaErrorHint: t('signPoaErrorHint'),
    general: t('validations.general'),
    over18: t('validations.over18'),
  }
}

const SignPoaForm = ({ setButtonState }) => {
  const { errors, submitForm, customValidate } = useFormContext()
  const messages = useMessages()
  const { openContact } = useContactModal()
  const analytics = useSegment()

  const handleSubmit = async () => {
    submitForm()
    await analytics.track('ssf_checkout_completed')
  }

  useEffect(() => {
    setButtonState({
      onClick: () =>
        customValidate({
          onSuccess: handleSubmit,
        }),
      text: messages.complete,
    })

    return () => {
      setButtonState({
        text: messages.next,
      })
    }
  }, [])

  return (
    <Column gap="16px">
      {errors.signature && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={
            <Trans
              components={
                <StyledLink onClick={openContact} variant="captionB" />
              }
              i18nKey={messages.signPoaErrorHint}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}
      <AdvisorBubble text={messages.poaAdvisorBubbleText} />
      <Column gap="4px">
        <StyledSSL alignItems="center" gap="4px">
          <ShieldCheck color={ThemeColor.b0} />
          <Typography singleLine variant="badgeText">
            <Trans i18nKey="mockRecommendationModalText.ssl" />
          </Typography>
        </StyledSSL>
        <BasicFormItem
          component={SignatureCanvas}
          name="signature"
          showErrorHelperText={false}
        />
        <StyledRow>
          <FormInputField
            label={messages.name}
            name="fullname"
            showValidCheck
          />
          <FormDatepicker
            label={messages.birthdate}
            max={new Date()}
            name="birthdate"
            showCalendar={false}
          />
        </StyledRow>
      </Column>
    </Column>
  )
}

SignPoaForm.propTypes = {
  setButtonState: PropTypes.func,
}

export const makeInitialValues = pipe(
  applySpec({
    fullname: pipe(props(['name', 'surname']), join(' ')),
    birthdate: pipe(props(['birthdate']), ([birthday]) =>
      birthday ? Date.parse(new Date(birthday)) : null,
    ),
  }),
)

export const PublicSignPoaStep = ({
  setButtonState,
  openContact,
  funnelProduct,
  openSuccess,
}) => {
  const { data, isLoading } = usePublicCheckoutPoa()
  const analytics = useSegment()
  const messages = useMessages()

  const doCheckoutMutation = useDoPublicCheckout()
  const { mutateAsync, isLoading: poaIsLoading } = useCreatePublicCheckoutPoa()

  const handleError = () => {
    openContact()
  }

  const handleSubmit = async ({ birthdate, signature }) => {
    try {
      await mutateAsync({ birthdate, signature })
      await doCheckoutMutation.mutateAsync(null)

      pushEventToDataLayer('Step Change', {
        funnel_product: funnelProduct,
        funnel_step: 'funnel_finished',
      })

      await analytics.track('ssf_poa_signed')

      clearLocalStorage()
      openSuccess()
    } catch (_error) {
      handleError()
    }
  }

  useEffect(() => {
    setButtonState({
      loading: poaIsLoading || doCheckoutMutation.isLoading,
    })
  }, [poaIsLoading, doCheckoutMutation.isLoading])

  if (isLoading) return <Loader />

  return (
    <FormProvider
      initialValues={makeInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={makePoaValidationSchema(messages)}
    >
      <SignPoaForm setButtonState={setButtonState} />
    </FormProvider>
  )
}

PublicSignPoaStep.propTypes = {
  funnelProduct: PropTypes.string,
  openContact: PropTypes.func,
  openSuccess: PropTypes.func,
  setButtonState: PropTypes.func,
}
