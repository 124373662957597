import { Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils'
import { friendlyFormatIBAN } from 'ibantools'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`

const RadioButtonLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`

const RadioButton = styled.input`
  appearance: none;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-color: ${themeColor(ThemeColor.b10)};

  &:checked::before {
    content: '';
    display: block;
    border-radius: 50%;
    background: ${themeColor(ThemeColor.b100)};
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked {
    border-color: ${themeColor(ThemeColor.b100)};
  }

  &:hover {
    border-color: #a1a1a1;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: #f0f0f0;
      border-color: #e0e0e0;
    `}
`

const PaymentRadio = ({ paymentMethods, value, onChange, disabled }) => {
  const handleSelect = (selectedValue) => {
    if (!disabled && onChange) {
      onChange(selectedValue)
    }
  }

  return (
    <RadioContainer>
      {paymentMethods.map((method) => (
        <RadioButtonWrapper
          key={method.paymentId}
          disabled={disabled}
          isSelected={value?.paymentId === method.paymentId}
          onClick={() => handleSelect(method)}
        >
          <RadioButtonLabel>
            <Typography color={ThemeColor.b100} singleLine variant="p2Body">
              {friendlyFormatIBAN(method.iban)}
            </Typography>
            <Typography color={ThemeColor.b50} singleLine variant="p2Body">
              {method.name}
            </Typography>
          </RadioButtonLabel>
          <RadioButton
            checked={value?.paymentId === method.paymentId}
            disabled={disabled}
            name="payment"
            type="radio"
            value={method.paymentId}
          />
        </RadioButtonWrapper>
      ))}
    </RadioContainer>
  )
}

PaymentRadio.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      iban: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      paymentId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string, // New prop for disabling/enabling the radio buttons
}

export default PaymentRadio
