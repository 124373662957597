import GTM from 'react-gtm-module'
import { config } from '../config'

export const initGTM = () => {
  if (config.gtmId) {
    GTM.initialize({
      gtmId: config.gtmId,
      auth: config.gtmAuth,
      preview: config.gtmPreview,
    })
  }
}

export const pushEventToDataLayer = (event, params = {}) => {
  if (config.gtmId) {
    GTM.dataLayer({ dataLayer: { event, ...params } })
  }
}
