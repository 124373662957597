/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { PublicPageContent, Typography, WithGap } from '@/components'
import { isArray, isString, useTranslationSectionObject } from '@/utils'
import { prop, reject } from 'ramda'
import { Container } from './styles'

export const PolicyPage = () => {
  const messages = useTranslationSectionObject('privacyPolicy')

  return (
    <PublicPageContent>
      <Container>
        <WithGap gap={6}>
          <Typography responsive variant="h1">
            {messages.title}
          </Typography>
          {messages.content.map(({ title, content }, i) => (
            <>
              <Typography responsive variant="h3">
                {i + 1} {title}
              </Typography>
              {isString(content) ? (
                <Typography variant="p1Body">{content}</Typography>
              ) : (
                content.map((item, j) =>
                  isString(item) ? (
                    <Typography variant="p1Body">{item}</Typography>
                  ) : isArray(item) ? (
                    <ul>
                      {item.map((subItem, l) =>
                        isString(subItem) ? (
                          <li key={l}>
                            <Typography variant="p1Body">{subItem}</Typography>
                          </li>
                        ) : (
                          <ul>
                            {subItem.map((listItem, m) => (
                              <li key={m}>
                                <Typography variant="p1Body">
                                  {listItem}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        ),
                      )}
                    </ul>
                  ) : (
                    <>
                      <Typography responsive variant="h3">
                        {i + 1}.
                        {j + 1 - reject(prop('subtitle'), content).length}{' '}
                        {item.subtitle}
                      </Typography>
                      {isString(item.content) ? (
                        <Typography variant="p1Body">{item.content}</Typography>
                      ) : (
                        item.content.map((subItem, k) =>
                          isString(subItem) ? (
                            <Typography variant="p1Body">{subItem}</Typography>
                          ) : isArray(subItem) ? (
                            <ul>
                              {subItem.map((listItem, l) =>
                                isString(listItem) ? (
                                  <li key={l}>
                                    <Typography variant="p1Body">
                                      {listItem}
                                    </Typography>
                                  </li>
                                ) : (
                                  <ul>
                                    {listItem.map((subListItem, m) => (
                                      <li key={m}>
                                        <Typography variant="p1Body">
                                          {subListItem}
                                        </Typography>
                                      </li>
                                    ))}
                                  </ul>
                                ),
                              )}
                            </ul>
                          ) : (
                            <>
                              <Typography responsive variant="h3">
                                {i + 1}.{j + 1}.{k + 1} {subItem.subsubtitle}
                              </Typography>
                              {subItem.content.map((text) => (
                                <Typography variant="p1Body">{text}</Typography>
                              ))}
                            </>
                          ),
                        )
                      )}
                    </>
                  ),
                )
              )}
            </>
          ))}
        </WithGap>
      </Container>
    </PublicPageContent>
  )
}
