import { getCarriers } from '@/api'
import { useQuery } from 'react-query'

const keys = {
  carriers: 'carriers',
  carrier: 'carrier',
}

export const useCarriers = (options) =>
  useQuery(keys.carriers, getCarriers, options)
