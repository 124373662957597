import { FormDragAndDrop } from '@/components/molecules/FormItem/FormDragAndDrop'
import { useTranslation } from 'react-i18next'
import { useStepper } from '../../hooks/useStepper'
import { StepLayout } from '../StepLayoutProps'
import { UploadDocumentsFormValues } from '../helpers/types'
import { StepProps } from '@/pages/AddInsuranceContractsPage'

export const UploadDocumentsStep = ({ isSubmitting }: StepProps) => {
  const { t } = useTranslation()
  const { onBack, onNext } = useStepper()

  const handleNext = async (values: UploadDocumentsFormValues) => {
    onNext({ files: values.files })
  }

  const handleSkip = () => {
    onNext({ files: [] })
  }

  return (
    <StepLayout
      title={t('addInsuranceContractsSteps.uploadDocuments.title')}
      subtitle={t('addInsuranceContractsSteps.uploadDocuments.subtitle')}
      onNext={handleNext}
      onBack={onBack}
      onSkip={handleSkip}
      isLoading={isSubmitting}
    >
      <FormDragAndDrop
        name="files"
        uploaderText={t(
          'addInsuranceContractsSteps.uploadDocuments.dragzoneText',
        )}
      />
    </StepLayout>
  )
}
