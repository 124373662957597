import {
  BackComponent,
  ContactExpert,
  FormCheckBox,
  FormInput,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  SecondaryButton,
  Typography,
} from '@/components'
import { usePaymentMethodValidation } from '@/pages/EditPaymentPage/usePaymentMethodValidation'
import { FormProvider, useSnackbar } from '@/providers'
import { useCreatePayment } from '@/services'
import {
  useTranslationSection,
  useTranslationSectionObject,
  useGoBack,
  useHistoryState,
  useGoToPath,
} from '@/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { PageActions, PageTitle } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  const commonTranslations = useTranslationSection('common')

  return {
    cancel: commonTranslations('cancel'),
    save: commonTranslations('save'),
    general: { ...useTranslationSectionObject('common') },
    snackBars: { ...useTranslationSectionObject('snackbars') },
    payments: { ...useTranslationSectionObject('profile.payments') },
    validations: useTranslationSectionObject('validations'),
    add: t('add_payment_details'),
    advisorText: t('advisor_text_base'),
  }
}

const PageForm = () => {
  const { companyId } = useParams()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { from } = useHistoryState()
  const validationSchema = usePaymentMethodValidation()

  const goBack = useGoBack()
  const goToFrom = useGoToPath(from, {}, { fromPayments: true })

  const onLeave = () => {
    if (from) goToFrom()
    else goBack()
  }

  const createPaymentMutation = useCreatePayment()

  const addNewPayment = (values) =>
    createPaymentMutation
      .mutateAsync(values)
      .then(() => {
        snackbar.success(messages.snackBars.success.addPayment)
        onLeave()
      })
      .catch(() => snackbar.error(messages.snackBars.error.general))

  const handleSubmit = (values) =>
    addNewPayment({
      iban: values.iban,
      bic: values.bic,
      name: values.name,
      priority: values.priority ? 1 : 0,
      companyId,
    })

  return (
    <FormProvider onSubmit={handleSubmit} validationSchema={validationSchema}>
      <FormInput label={messages.payments.name.label} name="name" />
      <FormInput label={messages.payments.iban.label} name="iban" />
      <PageActions>
        <FormCheckBox
          initialValue={false}
          label={messages.payments.isStandard}
          name="priority"
        />
      </PageActions>
      <PageActions>
        <SecondaryButton onClick={onLeave} size="large">
          {messages.cancel}
        </SecondaryButton>
        <PrimaryButton size="large" type="submit">
          {messages.general.save}
        </PrimaryButton>
      </PageActions>
    </FormProvider>
  )
}
export const AddPaymentPage = () => {
  const messages = useMessages()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{messages.add}</Typography>
        </PageTitle>
        <PageForm />
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={messages.advisorText} />
      </PageWidgetContainer>
    </>
  )
}
