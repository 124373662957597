import {
  Absolute,
  Carousel,
  Column,
  Header,
  ImageFromCdn,
  PrimaryButton,
  Row,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { GoogleRatingIcon, ShieldCheck, SureinTrustLogo } from '@/icons'
import { GoogleTrustStars } from '@/icons/GoogleTrustStars'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'
import { useContactModal } from '@/providers'
import { Check } from 'lucide-react'
import PropTypes from 'prop-types'
import { range } from 'ramda'
import { Trans, useTranslation } from 'react-i18next'
import {
  CheckIconBackground,
  ImageContainer,
  StyledSSL,
  SuccessPageContainer,
  SuccessPageHeader,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('leadSuccessTitle'),
    anExpertWillContact: t('anExpertWillContact'),
    contact: t('common.contactUs'),
    owner: t('owner'),
    managingDirector: t('managingDirector'),
    leadSuccessTestimonials: t('leadSuccessTestimonials', {
      returnObjects: true,
    }),
  }
}

const testimonials = [
  {
    name: 'Jutta Tischendorf',
    role: '{{owner}}: Cafe Tischendorf',
    img: 'testimonial1',
  },
  {
    name: 'Constantin von Bergmann-Korn',
    role: 'Partnership Manager Adyen',
    img: 'testimonial2',
  },
  {
    name: 'Cem Yilmaz',
    role: '{{md}}: Glüxgefühl',
    img: 'testimonial3',
  },
  {
    name: 'Felix Leisegang',
    role: '{{owner}}: Meatballery',
    img: 'testimonial4',
  },
]

const TrustPartners = () => (
  <Row alignItems="center" gap="18px">
    <StyledSSL alignItems="center" gap="4px">
      <ShieldCheck color={ThemeColor.success4} />
      <Typography singleLine variant="badgeText">
        <Trans i18nKey="ssl" />
      </Typography>
    </StyledSSL>
    <TrustPilotExtended width="170px" />
    <SureinTrustLogo height="28px" width="32px" />
    <GoogleTrustStars height="30px" width="120px" />
  </Row>
)

export const SuccessPage = ({ onLogoClick }) => {
  const messages = useMessages()
  const { openContact } = useContactModal()

  return (
    <Absolute left={0} right={0} top={0}>
      <Header onLogoClick={onLogoClick} simple />
      <SuccessPageContainer>
        <SuccessPageHeader>
          <Column gap="32px">
            <Column gap="10px">
              <CheckIconBackground>
                <Check />
              </CheckIconBackground>
              <Typography bold variant="h2">
                {messages.title}
              </Typography>
            </Column>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.anExpertWillContact}
            </Typography>
            <PrimaryButton onClick={openContact} size="large">
              {messages.contact}
            </PrimaryButton>
            <TrustPartners mode="light" />
          </Column>
          <ImageContainer>
            <ImageFromCdn fallbackType="jpg" name="the-team" type="webp" />
          </ImageContainer>
        </SuccessPageHeader>
        <Column alignItems="center" gap="72px">
          <Carousel autoPlayDuration={5000} width={680}>
            {testimonials.map(({ name, role, img }, i) => (
              <Column gap="12px">
                <Row gap="16px">
                  <ImageFromCdn
                    fallbackType="jpg"
                    name={img}
                    type="webp"
                    width={56}
                  />
                  <Column gap="4px">
                    <Typography color={ThemeColor.b50} variant="p2Body">
                      {name}
                    </Typography>
                    <Typography color={ThemeColor.b50} variant="badgeText">
                      {role
                        .replace('{{owner}}', messages.owner)
                        .replace('{{md}}', messages.managingDirector)}
                    </Typography>
                    <Row>
                      {range(1, 6).map(() => (
                        <GoogleRatingIcon
                          color={ThemeColor.warning1}
                          size="menu"
                        />
                      ))}
                    </Row>
                  </Column>
                </Row>
                <Typography color={ThemeColor.b50} variant="p1Body">
                  {messages.leadSuccessTestimonials[i]}
                </Typography>
              </Column>
            ))}
          </Carousel>
          <div />
        </Column>
      </SuccessPageContainer>
    </Absolute>
  )
}

SuccessPage.propTypes = {
  onLogoClick: PropTypes.func,
}
