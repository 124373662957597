import { useAddress } from '@/services'
import { toObject } from '@/utils'
import { map, pipe, head, find, equals, props, mergeAll, join } from 'ramda'
import { prop } from 'styled-tools'
import usePlacesAutocomplete from 'use-places-autocomplete'

export const usePlaces = (country) => {
  const params = country
    ? {
        requestOptions: { componentRestrictions: { country } },
        cache: false,
      }
    : {}

  return usePlacesAutocomplete(params)
}

export const makeOptions = map(({ place_id: placeId, description }) => ({
  value: placeId,
  text: description,
}))

export const formatValue = pipe(props(['street', 'streetNr']), join(' '))

const transform = (addressComponents) => (type, key) =>
  pipe(
    find(pipe(prop('types'), head, equals(type))),
    prop('long_name'),
    toObject(key),
  )(addressComponents)

const makeAddressDetail = pipe(head, prop('address_components'), transform)

const getGoogleLocation = pipe(
  head,
  prop('formatted_address'),
  toObject('googleLocation'),
)

const makeDetails = (gapiResult) => {
  const getDetail = makeAddressDetail(gapiResult)

  return mergeAll([
    getDetail('route', 'street'),
    getDetail('street_number', 'streetNr'),
    getDetail('locality', 'city'),
    getDetail('country', 'country'),
    getDetail('postal_code', 'postalCode'),
    getGoogleLocation(gapiResult),
  ])
}

export const useParseAddress = ({ onSuccess }) =>
  useAddress({
    onSuccess: (v) => {
      onSuccess(makeDetails(v))
    },
  })
