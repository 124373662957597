import { mergeLeft, omit } from 'ramda'
import { useState } from 'react'

export const useStateObject = (initialValue) => {
  const [values, setValues] = useState(initialValue || {})

  const dispatchValues = (newValues) => {
    setValues(mergeLeft(newValues))
  }

  const removeValues = (...keys) => {
    setValues(omit(keys))
  }

  return [values, dispatchValues, removeValues, setValues]
}
