import PropTypes from 'prop-types'
import { TabText, StyledTab } from './styles'

export const TabNode = ({ active, handleClick, tabKey, text }) => (
  <StyledTab active={active} onClick={() => handleClick(tabKey)}>
    <TabText>{text}</TabText>
  </StyledTab>
)

TabNode.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  tabKey: PropTypes.string.isRequired,
  text: PropTypes.elementType.isRequired,
}
