export const makeRecommendationAnalyticsPayload = (recommendation) => {
  const sortedProducts = recommendation.recommendation?.recommendationProducts?.sort(
    (a, b) => a.order - b.order,
  )
  const shortenedProducts = sortedProducts.map((product) => ({
    carrierName: product.carrierData?.name,
    price: product.grossPrice,
  }))

  return {
    recommendationId: recommendation.id,
    categoryId: recommendation.recommendation?.categoryId,
    products: shortenedProducts,
  }
}

export const makeBoughtProductAnalyticsPayload = (
  recommendationProductId,
  recommendation,
) => {
  const boughtProduct = recommendation.recommendation?.recommendationProducts?.find(
    (product) => product.recommendationProductId === recommendationProductId,
  )

  return {
    carrierName: boughtProduct.carrierData?.name,
    price: boughtProduct.grossPrice,
  }
}
