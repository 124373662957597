import { Loader, SecondaryButton, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useAuth, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useResendVerificationEmail } from '@/services'
import { isVerified, useGoToPath } from '@/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  ContentWrapper,
  EnvelopeContainer,
  StyledColumn,
  TextWrapper,
} from './styles'

const useMessages = (email) => {
  const { t } = useTranslation()

  return {
    title: t('not_verified_title'),
    subtitle: t('not_verified_subtitle', { email }),
    buttonText: t('go_to_my_email'),
    resend: t('didnt_recieve_anything'),
    emailSend: t('verification_email_send'),
    another: t('signup_another_email'),
  }
}

export const NotVerified = () => {
  const { user, logout, isLoading } = useAuth()
  const messages = useMessages(user?.email)
  const redirect = useGoToPath(paths.authRedirect)
  const snackbar = useSnackbar()
  const resendVerificationEmailMutation = useResendVerificationEmail()

  if (isLoading) return <Loader />

  useEffect(() => {
    if (isVerified(user)) redirect()
  }, [user])

  const handleClick = () =>
    resendVerificationEmailMutation
      .mutateAsync()
      .then(() => snackbar.success(messages.emailSend))

  return (
    <Container>
      <EnvelopeContainer>💌</EnvelopeContainer>
      <ContentWrapper>
        <TextWrapper>
          <Typography variant="h4">{messages.title}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.subtitle}
          </Typography>
        </TextWrapper>
        <StyledColumn>
          <SecondaryButton block onClick={() => logout()}>
            {messages.another}
          </SecondaryButton>
          <SecondaryButton block onClick={handleClick}>
            {messages.resend}
          </SecondaryButton>
        </StyledColumn>
      </ContentWrapper>
    </Container>
  )
}
