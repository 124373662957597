import { FormCardSelect, Option } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { keys } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    options: t('lookingForOptions', {
      returnObjects: true,
    }),
  }
}

export const HowCanWeHelpStep = () => {
  const messages = useMessages()
  const { value } = useFormItem('lookingFor')
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.howCanWeHelp,
  )

  useOnNextClick(save)

  useNextDisabled(() => !value || !value.length, [value])

  useNextLoading(() => isLoading, [isLoading])

  return (
    <FormCardSelect multiple name="lookingFor">
      {keys(messages.options).map((item) => (
        <Option key={item} value={item}>
          {messages.options[item]}
        </Option>
      ))}
    </FormCardSelect>
  )
}
