import { Column, Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export const KPIOverviewContainer = styled(Row)`
  width: 100%;
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(2)};
  align-items: center;
  gap: ${toThemePixels(6)};
  padding: ${toThemePixels(3)} ${toThemePixels(6)};
  position: relative;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;
    padding-bottom: ${ifProp(
      'hasDetailsLink',
      toThemePixels(12),
      toThemePixels(3),
    )};
  }

  > button {
    position: absolute;
    bottom: ${toThemePixels(2)};
    right: ${toThemePixels(4)};
    svg {
      height: ${toThemePixels(2.5)};
      width: ${toThemePixels(2.5)};

      > path {
        stroke: ${themeColor(ThemeColor.info1)};
      }
    }
  }
`

export const KPIChartContainer = styled(Column)`
  height: ${toThemePixels(50)};
  width: ${toThemePixels(80)};
  max-height: 100%;
  border-radius: ${toThemePixels(2)};
  position: relative;
  justify-content: center;
  min-width: 0;
`

export const CustomTooltipComponent = styled(Row)`
  background-color: white;
  padding: ${toThemePixels(2)};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: ${toThemePixels(2)};
  align-items: center;
  gap: ${toThemePixels(1)};

  > span {
    white-space: nowrap;
  }
`

export const KPILegendContainer = styled(Column)`
  width: 100%;
  justify-content: center;
  gap: ${toThemePixels(2)};
`

export const KPILegendItem = styled(Row)`
  justify-content: space-between;
  gap: ${toThemePixels(2)};
`

export const KPILegendColor = styled.div`
  background-color: ${prop('color')};
  height: ${toThemePixels(3)};
  width: ${toThemePixels(3)};
  border-radius: 50%;
  margin-right: ${toThemePixels(1)};
  flex-shrink: 0;
`

export const MiddleTextWrapper = styled(Column)`
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  gap: ${toThemePixels(2)};
  pointer-events: none;

  > span:first-child {
    font-size: ${toThemePixels(2.5)};
    color: ${themeColor(ThemeColor.b50)};
  }

  > span:last-child {
    font-weight: 700;
    font-size: ${toThemePixels(2.5)};
    color: ${themeColor(ThemeColor.b50)};
  }
`
