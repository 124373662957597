import {
  BasicFormItem,
  Column,
  FormCheckBox,
  FormDate,
  FormInput,
  InfoTooltip,
  Loader,
  Row,
  SignatureCanvas,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { StyledRow } from '@/pages/CheckoutPage/styles'
import { FormProvider, useFormContext } from '@/providers'
import {
  useCreatePublicCheckoutPoa,
  usePublicCheckoutPaymentMethod,
  usePublicCheckoutPoa,
  useSavePublicCheckoutPaymentMethod,
  useUpdatePublicCheckoutPaymentMethod,
} from '@/services'
import { timestampToDateString } from '@/utils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PoaTitleContainer } from '../styles'
import { makeInitialValues } from './PublicSignPoaStep'
import { makePaymentsAndPoaValidationSchema } from './validationSchema'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    general: t('validations.general'),
    paymentName: t('paymentName'),
    sepa: t('sepa'),
    poa: t('checkout.titles.poa'),
    whatIsThis: t('whatIsThis'),
    poaHelperText: t('poaHelperText'),
    signPoaSubtitle: t('signPoa_subtitle'),
  }
}

export const PublicPaymentAndPoaStep = ({ onNext, setButtonState }) => {
  const {
    data: paymentData,
    isLoading: isPaymentDataLoading,
  } = usePublicCheckoutPaymentMethod()
  const { data: poaData, isLoading: isPoaDataLoading } = usePublicCheckoutPoa()

  const createPaymentMethodMutation = useSavePublicCheckoutPaymentMethod()
  const updatePaymentMethodMutation = useUpdatePublicCheckoutPaymentMethod(
    paymentData?.paymentId,
  )
  const messages = useMessages()

  const createPoaMutation = useCreatePublicCheckoutPoa()

  const handleSubmit = async ({ name, iban, birthdate, signature }) => {
    const paymentMutation = paymentData?.paymentId
      ? updatePaymentMethodMutation
      : createPaymentMethodMutation

    await paymentMutation.mutateAsync({ name, iban })

    await createPoaMutation.mutateAsync({ birthdate, signature })

    onNext()
  }

  useEffect(() => {
    setButtonState({
      loading:
        createPaymentMethodMutation.isLoading ||
        updatePaymentMethodMutation.isLoading ||
        createPoaMutation.isLoading,
    })
  }, [
    createPaymentMethodMutation.isLoading,
    updatePaymentMethodMutation.isLoading,
    createPoaMutation.isLoading,
  ])

  if (isPaymentDataLoading || isPoaDataLoading) return <Loader />

  return (
    <FormProvider
      initialValues={{ ...paymentData, ...makeInitialValues(poaData) }}
      onSubmit={handleSubmit}
      validationSchema={makePaymentsAndPoaValidationSchema(messages)}
    >
      <PaymentAndPOAForm setButtonState={setButtonState} />
    </FormProvider>
  )
}

PublicPaymentAndPoaStep.propTypes = {
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}

export const PaymentAndPOAForm = ({ setButtonState }) => {
  const messages = useMessages()
  const { values, setErrors, submitForm } = useFormContext()

  useEffect(() => {
    setButtonState({ onClick: submitForm })
  }, [])

  useEffect(() => {
    setErrors({})
  }, [values])
  return (
    <>
      <FormInput label={messages.paymentName} name="name" />
      <FormInput label="IBAN" name="iban" />
      <FormCheckBox label={messages.sepa} name="sepa" />
      <PoaTitleContainer>
        <Row alignItems="center" gap="12px">
          <Typography singleLine variant="h3">
            {messages.poa}
          </Typography>
          <InfoTooltip
            content={messages.poaHelperText}
            dark
            iconColor={ThemeColor.b50}
            text={
              <Typography
                bold
                color={ThemeColor.b50}
                pointer
                underline
                variant="captionR"
              >
                {messages.whatIsThis}
              </Typography>
            }
          />
        </Row>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {messages.signPoaSubtitle}
        </Typography>
      </PoaTitleContainer>
      <Column gap="4px">
        <BasicFormItem component={SignatureCanvas} name="signature" />
        <StyledRow>
          <FormInput disabled label={messages.name} name="fullname" />
          <FormDate
            label={messages.birthdate}
            max={timestampToDateString(Date.now())}
            name="birthdate"
          />
        </StyledRow>
      </Column>
    </>
  )
}

PaymentAndPOAForm.propTypes = {
  setButtonState: PropTypes.func,
}
