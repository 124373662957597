import { onMessageSend } from '@/api'
import { dynamicObjectPropType, log, oneOrManyChildren } from '@/utils'
import { withAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Header } from '../molecules/Header'
import { ErrorView } from '../organisms/ErrorView'
import { PageLayout } from '../styled'

const ErrorBoundaryFallback = ({
  error,
  componentStack,
  resetError,
  auth0,
}) => {
  const { isAuthenticated } = auth0 || {}

  const handleBack = () => {
    window.history.back()
    resetError()
  }

  useEffect(() => {
    if (isAuthenticated && !import.meta.env.REACT_APP_DEBUG) {
      onMessageSend(
        `\nLogged from Error Boundary occurring in ${
          window.location.href
        }:\n -${error.toString()};\n -${componentStack}`,
      )
    }
    log(error.toString(), componentStack)
  }, [])

  return (
    <PageLayout simple>
      <Header simple />
      <ErrorView onBack={handleBack} />
    </PageLayout>
  )
}

ErrorBoundaryFallback.propTypes = {
  auth0: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
  componentStack: PropTypes.string,
  error: dynamicObjectPropType,
  resetError: PropTypes.func.isRequired,
}

export const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag('url', window.location.href)
    }}
    fallback={withAuth0(ErrorBoundaryFallback)}
  >
    {children}
  </Sentry.ErrorBoundary>
)

ErrorBoundary.propTypes = {
  children: oneOrManyChildren.isRequired,
}
