import { call, isUndefined, parseChildrenArray, returns } from '@/utils'
import PropTypes from 'prop-types'
import {
  append,
  either,
  equals,
  ifElse,
  includes,
  pipe,
  reject,
  __,
  identity,
} from 'ramda'
import { Option, selectChildrenPropType } from '../Select'
import { selectOptionValuePropType } from '../Select/helpers'
import { Typography } from '../Typography'
import { OptionNode } from './OptionNode'
import { CardSelectContainer, Container } from './styles'

export const CardSelect = ({
  children,
  disabled,
  error,
  max,
  multiple,
  onSelect,
  alignment = 'vertical',
  value = [],
  centered,
  hoc = identity,
}) => {
  const options = parseChildrenArray(children.filter(Boolean))

  const selected = ifElse(returns(multiple), includes(__, value), equals(value))

  const handleSelect = ifElse(
    returns(multiple),
    pipe(
      ifElse(
        includes(__, value),
        call(pipe(equals, reject(__, value))),
        ifElse(
          either(() => max > value.length, returns(isUndefined(max))),
          append(__, value),
          returns(value),
        ),
      ),
      call(onSelect),
    ),
    call(onSelect),
  )

  return (
    <Container>
      <CardSelectContainer alignment={alignment}>
        {options.map(
          (
            {
              key,
              value: optionValue,
              children: text,
              disabled: optionDisabled,
              node,
            },
            i,
          ) =>
            node.type === Option
              ? hoc(
                  <OptionNode
                    key={key}
                    centered={centered}
                    disabled={disabled || optionDisabled}
                    error={error}
                    onClick={handleSelect}
                    selected={selected(optionValue)}
                    value={optionValue}
                    width={100 / options.length - (options.length - 1)}
                  >
                    <Typography ellipsis variant="placeholder">
                      {text}
                    </Typography>
                  </OptionNode>,
                  optionValue,
                )
              : children[i],
        )}
      </CardSelectContainer>
    </Container>
  )
}

CardSelect.propTypes = {
  alignment: PropTypes.oneOf(['vertical', 'grid']),
  centered: PropTypes.bool,
  children: selectChildrenPropType,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hoc: PropTypes.func,
  max: PropTypes.number,
  multiple: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  value: selectOptionValuePropType,
}
