import { bitwiseNot } from '@/utils'
import PropTypes from 'prop-types'
import { not, when, pipe } from 'ramda'
import { useCallback } from 'react'
import { RadioButton } from '../../atoms'
import { FormItem } from './Component'
import { formItemValuePropType } from './helpers'

export const FormRadio = ({
  name,
  initialValue,
  ignoreErrorKeys,
  ...props
}) => {
  const Component = useCallback(
    ({ onChange, value, ...field }) => (
      <RadioButton
        checked={Boolean(value)}
        onChange={when(not, pipe(bitwiseNot, onChange))}
        {...field}
        {...props}
      />
    ),
    [],
  )

  return (
    <FormItem
      ignoreErrorKeys={ignoreErrorKeys}
      initialValue={initialValue}
      name={name}
    >
      <Component />
    </FormItem>
  )
}

FormRadio.propTypes = {
  ignoreErrorKeys: PropTypes.arrayOf(PropTypes.string),
  initialValue: formItemValuePropType,
  name: PropTypes.string.isRequired,
}
