import {
  FormInput,
  FormLocation,
  Loader,
  PrimaryButton,
  SecondaryButton,
  PageActionsStyles,
  Typography,
  PageMainContent,
} from '@/components'
import { FormProvider, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useCompany,
  useRequestEditCompany,
  useUpdateAddress,
  useUpdateCompany,
} from '@/services'
import {
  useGoBack,
  useGoToPath,
  useHistoryState,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeInitialValues } from './helpers'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')
  const companyTranslations = useTranslationSectionObject('companyPage')
  const snackBarsTranslation = useTranslationSection('snackbars')
  return {
    error: snackBarsTranslation('error.generalMsg'),
    editCompany: snackBarsTranslation('success.editCompany'),
    editRequest: snackBarsTranslation('success.editRequest'),
    editRequestDisabled: snackBarsTranslation('success.editRequestDisabled'),

    cancel: commonTranslations('cancel'),
    save: commonTranslations('save'),
    address: commonTranslations('address'),
    name: companyTranslations?.addCompanyFields.companyName,
    title: companyTranslations.editCompany,
    requestEdit: commonTranslations('requestEdit'),
    validations: useTranslationSectionObject('validations'),
  }
}

const PageForm = ({ data }) => {
  const { from } = useHistoryState()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { companyId } = useParams()
  const updateCompanyMutation = useUpdateCompany(companyId)
  const updateAddressMutation = useUpdateAddress(data.address.addressId)
  const requestEditCompanyMutation = useRequestEditCompany(companyId)
  const goAfterSubmit = useGoToPath(from || paths.dashboard)

  const handleBack = useGoBack()

  const disabled = data.poaSigned

  useEffect(() => {
    if (disabled) {
      snackbar.info(messages.editRequestDisabled)
    }
  }, [companyId])

  const editService = (values) =>
    disabled
      ? requestEditCompanyMutation.mutateAsync()
      : Promise.all([
          updateCompanyMutation.mutateAsync({
            name: values.name,
          }),
          updateAddressMutation.mutateAsync(values.address),
        ])

  const handleSubmit = (values) =>
    editService(values)
      .then(() => {
        snackbar.success(disabled ? messages.editRequest : messages.editCompany)
        goAfterSubmit()
      })
      .catch(() => snackbar.error(messages.error))

  return (
    <FormProvider
      initialValues={makeInitialValues(data)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(messages.validations)}
    >
      <FormInput
        disabled={disabled}
        label={messages.name.placeholder}
        name="name"
      />
      <FormLocation
        disabled={disabled}
        label={messages?.address}
        name="address"
      />
      <PageActionsStyles>
        <SecondaryButton onClick={handleBack} size="large">
          {messages.cancel}
        </SecondaryButton>
        <PrimaryButton size="large" type="submit">
          {disabled ? messages.requestEdit : messages.save}
        </PrimaryButton>
      </PageActionsStyles>
    </FormProvider>
  )
}

PageForm.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      addressId: PropTypes.number,
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
      streetNr: PropTypes.string,
    }),
    name: PropTypes.string,
    poaSigned: PropTypes.bool,
  }),
}

export const EditCompanyPage = () => {
  const messages = useMessages()
  const { companyId } = useParams()
  const { data, isLoading } = useCompany(companyId)

  if (isLoading) return <Loader />

  return (
    <PageMainContent>
      <Typography variant="h2">{messages.editLocation}</Typography>
      <PageForm data={data} />
    </PageMainContent>
  )
}
